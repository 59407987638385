import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Spinner, Table } from "react-bootstrap";
import { Card, Row, Col } from "@themesberg/react-bootstrap";
import { toast } from "react-hot-toast";
import { postApi } from "../../../redux/api";
import Scrollbar from "../../sub-treasury/cash-backing/ScrollBar";
import { Typeahead } from "react-bootstrap-typeahead";
import "./table.css";
import { Routes } from "../../../routes";
import BackButton from "../../../components/BackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCheck,
  faEdit,
  faEye,
  faFastBackward,
  faListUl,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "../../../redux/utils";

function NewRecurrentPaymentSchedule() {
  const history = useNavigate();
  const approval_no = useParams().approval_no;
  const [pvInfo, setPvInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [mdaAccount, setMdaAccount] = useState([]);

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: "select",
    }).then((data) => {
      console.log(data);
      setMdaAccount(data.result);
    });
  }, []);

  useEffect(() => {
    if (approval_no) {
      setLoading(true);
      postApi(`get_pv_collection`, {
        form: {
          query_type: "pv_by_approval",
          approval_no: approval_no,
          pv_status: "DAG Approved",
        },
        // status : "Paid",
        // batch_no
      })
        .then((data) => {
          console.log("data", data);
          if (data.result && data.result.length) {
            setPvInfo(data.result);
          }
        })
        .catch((err) => {
          console.log(err);
          // alert(err);
          // setLoadSpinner(false);
        })
        .finally(() => setLoading(false));
    }
  }, [approval_no]);

  const handleApprove = () => {
    setSubmitting(true);

    postApi(`post_contractor_schedule_array`, {
      contractScheduleTable: pvInfo,
      query_type: "insert",
    })
      .then((data) => {
        if (data.success) {
          console.log(data);
          toast.success("Payment Initiated Successfully!");
          // setBatchCode(data.contractor_code)
          // updateBudgetCode('contractor_code', 'update')
          // handleSubmitReset()
          // toggle()account_type
          // setPaymentScheduleTable([])
          history(Routes.expenditureControl.expControlIndex);
        }
      })
      .catch((err) => {
        toast.error(err);
        // setLoadSpinner(false);
      })
      .finally(() => setSubmitting(false));
  };
  const totalAmount = pvInfo.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.amount);
  }, 0);

  return (
    <>
      <BackButton />
      <Card className="my-2">
        <Card.Header>
          <h5>Create Payment Schedule</h5>
          {/* <h4>Release No.: {approval_no}</h4> */}
        </Card.Header>

        <Card.Body>
          {pvInfo.length ? (
            <Card border="light" className="p-2 pb-0">
              <Row>
                <Col md={8}>
                  <p className="fw-bold">
                    Approval No.: {pvInfo[0].approval_no}
                  </p>
                </Col>
                <Col>
                  <button
                    style={{
                      backgroundColor: "yellow",
                      border: "none",
                      // height: "60%",
                      //   padding: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 10,
                      cursor: "pointer",
                    }}
                    className="me-2"
                    onClick={() =>
                      history(
                        `${Routes.cashBacking.releaseCollectionPDF}/${pvInfo[0].approval_no}`
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faEye} className="me-1" />
                    View Release Doc
                  </button>
                </Col>
              </Row>
            </Card>
          ) : null}
          {loading && <Spinner />}

          {/* {JSON.stringify(pvInf)} */}
          <Scrollbar height={"50vh"}>
            <table id="customers">
              <thead>
                <th style={{ width: "5%" }}>PV Code</th>
                <th style={{ width: "10%" }}>Mda Name</th>
                <th style={{ width: "10%" }}>Admin Code</th>
                <th style={{ width: "10%" }}>Economic code</th>
                {/* <th>Economic Description</th> */}
                <th style={{ width: "10%" }}>Payee Account Details</th>
                <th>Amount</th>
                <th style={{width:'10%'}}></th>
              </thead>

              <tbody>
                {pvInfo &&
                  pvInfo?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "5%" }}>{item.pv_code}</td>
                      <td style={{ width: "10%" }}>
                        <div style={{ width: 150, overflow: "inherit" }}>
                          {item.mda_name}
                        </div>
                      </td>
                      <td style={{ width: "10%" }}>{item.mda_code}</td>
                      <td style={{ width: "10%" }}>{item.economic_code}</td>
                      {/* <td>{item.economic_description}</td> */}
                      <td style={{ width: "10%" }}>
                        {/* <Typeahead
                      size="sm"
                      // disabled={id ? true : false}
                      id="20"
                      // disabled={id ? true : false}
                      onChange={(selected) => {
                        if(selected && selected.length) {
                            const newData = []
                            pvInfo.forEach(pv => {
                                if(pv.mda_code === item.mda_code && pv.economic_code === item.economic_code) {
                                    // console.log(pv)
                                    newData.push({
                                        ...pv,
                                        mda_account_bank_name: selected[0].bank_name,
                                        mda_account_name: selected[0].account_name,
                                        mda_account_no: selected[0].account_number,
                                        mda_account_sort_code: selected[0].sort_code,
                                        contractor_acc_name:selected[0].account_name,
                                        contractor_acc_no: selected[0].account_number,
                                        contractor_bank_name: selected[0].bank_name
                                    })
                                } else {
                                    newData.push(pv)
                                }
                            })
                            console.log(newData, selected)
                            setPvInfo(newData)
                        }
                      }}
                      onInputChange={(text) => console.log(text)}
                      options={mdaAccount}
                      labelKey={(option) => `${option.account_name}`}
                      placeholder="Select---"
                    //   ref={mdaTypeahead}
                    /> */}
                        {/* {JSON.stringify(item)} */}
                        {
                          <>
                            {" "}
                            <div>
                              <b>Bank Name:</b> {item.contractor_bank_name}
                            </div>
                            <div>
                              <b>Account Name:</b> {item.contractor_acc_name}
                            </div>
                            <div>
                              <b>Account No.:</b> {item.contractor_acc_no}
                            </div>
                            <div>
                              <b>Account Type:</b> {item.account_type}
                            </div>
                            <div>
                              <b>Sort Code:</b> {item.sort_code}
                            </div>
                          </>
                        }
                      </td>
                      <td style={{ textAlign: "right", width: "10%" }}>
                        {formatNumber(item?.amount)}
                      </td>
                      <td >
                        <Button
                          onClick={() => {
                            // if(isReview) {
                            //     history(
                            //         `${Routes.pv.viewPvReview}/${item.approval_no}?approval_type=${item.approval_type}`
                            //       )
                            // } else if (isApprove) {
                            history(
                              `${Routes.pv.pvReportView}/${item.approval_no}?approval_type=${item.approval_type}`
                            );
                            // }
                          }}
                        >
                          PV
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <span style={{ float: "right" }}>
              <b>Total: </b>
              {formatNumber(totalAmount)}
            </span>
          </Scrollbar>
          {/* </Row> */}
          <center className="">
            <Button onClick={handleApprove}>
              {submitting ? (
                <>
                  <Spinner /> Please wait..
                </>
              ) : (
                "Create Schedule"
              )}
            </Button>
          </center>
        </Card.Body>
      </Card>
    </>
  );
}

export default NewRecurrentPaymentSchedule;
