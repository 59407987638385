import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer'
const COL1_WIDTH = 40
const COL_AMT_WIDTH = 20
const COLN_WIDTH = (100 - (COL1_WIDTH + COL_AMT_WIDTH)) / 2

const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 30,
    paddingLeft: 30,
    fontFamily: 'Times-Roman',
  },

  headerPage: {},

  topRightHeader: {
    // display : 'flex',
    // justifyContent : 'between',
    //  flexDirection: 'row',
    textAlign: 'right',
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },

  topLeftHeader: {
    // flexDirection: 'row',
    // textAlign : 'left',
    // marginTop : '100px',
    marginBottom: '25px',
    fontSize: '12px',
    color: 'black',
    fontWeight: 'bold',
    lineHeight: 1.5,
    flexDirection: 'row',

    justifyContent: 'space-between',
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },
  middleHeader: {
    // flexDirection: 'row',
    padding: 10,
    fontSize: '14px',
    textAlign: 'center',
    // textDecoration : 'underline',
    fontWeight: 'bolder',
    lineHeight: 1.2,
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },
  middleHeader1: {
    // flexDirection: 'row',
    padding: 10,
    fontSize: '14px',
    textAlign: 'center',
    textDecoration: 'underline',
    fontWeight: 'bolder',
    lineHeight: 1.2,
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },

  body1: {
    textAlign: 'justify',
    marginTop: '10px',
    fontSize: 14,
    lineHeight: 2,
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },

  // table :{fontSize : '12px',
  // border : '1px solid black',
  // padding : 10},

  table: {
    display: 'table',
    width: '100%',
    fontSize: 12,
    // marginVertical: 6,
    border: '1px solid black',
    padding: 5,
    // textAlign : 'center'
  },

  table1: {
    display: 'table',
    width: '100%',
    fontSize: 12,
    // marginVertical: 6,
    border: '1px solid black',
    padding: 5,
    textAlign: 'center',
    fontWeight: 'bold',
  },

  tableRow: {
    flexDirection: 'row',
  },
  tableRowTotal: {
    flexDDirection: 'row',
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%',
  },
  tableColHeader: {
    width: COLN_WIDTH + '%',
  },
  tableColAmtHeader: {
    width: COL_AMT_WIDTH + '%',
  },
  tableCol1: {
    width: COL1_WIDTH + '%',
  },
  tableColAmt: {
    width: COL_AMT_WIDTH + '%',
  },
  tableCol: {
    width: COLN_WIDTH + '%',
  },
  tableColTotal: {
    width: 2 * COLN_WIDTH + '%',
  },
  tableCellHeader: {
    // marginRight: 5,
    fontWeight: 'bold',
  },
  tableCell: {
    marginVertical: 1,
    // marginRight: 4,
  },

  lineHead: {
    marginVertical: 10,
    fontSize: 13,
    marginLeft: 22,
  },
})

export default function PaymentAdvicePDFTemplate({
  remittance = [],
  tresuryDetails = {},
  mdaDetails = [],
}) {
  return (
    <>
      <PDFViewer height="1000" width="1000">
        <Document>
          {remittance.map((item) => (
            <Page size="A4" style={styles.page}>
              <View style={styles.middleHeader}>
                <Text>OFFICE OF THE ACCOUNTANT GENERAL </Text>
                <Text style={{ marginTop: 5 }}>
                  {' '}
                  MINISTRY OF FINANCE, KATSINA STATE
                </Text>
                <Text style={{ marginTop: 5 }}> SUB TREASURY</Text>
              </View>

              <View style={styles.topRightHeader}>
                <View></View>

                <View
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    lineHeight: '1.5',
                  }}
                >
                  <Text>{tresuryDetails.treasury_source_account}</Text>
                </View>
              </View>

              <View style={styles.topLeftHeader}>
                <View>
                  <Text
                    style={{
                      textDecoration: 'underline',
                      fontSize: 14,
                      fontWeight: 'bold',
                    }}
                  >
                    {item.mda_name}
                  </Text>
                  <Text>________________________</Text>
                  <Text>________________________</Text>
                </View>

                <View>
                  <Text>Date: {item.date}</Text>
                </View>
              </View>

              <View style={styles.middleHeader1}>
                <Text>PAYMENT ADVICE SLIP </Text>
                {/* <Text>{JSON.stringify(item)}</Text> */}
              </View>

              <View style={styles.body1}>
                <Text>
                  Please note that an instruction has been sent to our Bank to
                  settle {item.contractor_name} the sum of{' '}
                  {`N${parseFloat(item.amount).toLocaleString('en')}`} in favour
                  of {item.contractor} for {item.description}.
                </Text>
              </View>

              <View>
                {mdaDetails
                  .filter((itf) => itf.mda_name === item.mda_name)
                  .map((itk, idx) => (
                    <>
                      <Text style={{ fontSize: 14 }}>
                        {idx + 1}
                        {'. '}
                        <span>{itk.narration}</span>{' '}
                        {`N${parseFloat(itk.amount).toLocaleString('en')}`}
                      </Text>
                      <Text style={styles.lineHead}>
                        Head:________________ SubHead: ________________
                      </Text>
                    </>
                  ))}
              </View>

              <View>
                <Text style={{ fontSize: 14, marginVertical: 15 }}>
                  {/* Let us have your official reciept as early as possible */}
                  Above for your notice
                </Text>
              </View>

              <Text>________________</Text>

              <View style={{ display: 'flex', justifyContent: 'between' }}>
                <Text style={{ fontSize: 13, marginTop: 15 }}>
                  For Accountant General
                </Text>
                <Text style={{ fontSize: 13, marginTop: 10 }}>Katsina State</Text>
              </View>
            </Page>
          ))}
        </Document>
      </PDFViewer>
    </>
  )
}
