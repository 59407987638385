import React, { useCallback } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import Cards from "react-credit-cards";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchApi, postApi, verifyToken } from "../../../redux/api";
import CustomTable from "../../../components/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-hot-toast";
import {
  faBook,
  faChartLine,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import BackButton from "../../../components/BackButton";
import { Routes } from "../../../routes";
// import PaymentModal from './PaymentModal'
import { MODULE_TYPES, formatNumber } from "../../../redux/utils";
import CustomButton from "../../../components/CustomButton";
import { bankList } from "../../accounts/AccountForm";
import PaymentPreviewModal from "./PaymentPreviewModal";
import { Badge, ButtonGroup } from "react-bootstrap";

function DSTOffice({ match, pageType = "batch", type = null }) {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [focus, setFocus] = useState("name");
  const [deleteAllWarningModalIsOpen, setDeleteAllWarningModalOpen] =
    useState(false);
  const [deleteSignleWarningModalIsOpen, setDeleteSignleWarningModalOpen] =
    useState(false);
  const toggleDeleteAll = () => setDeleteAllWarningModalOpen((p) => !p);
  const toggleDeleteSingle = () => setDeleteSignleWarningModalOpen((p) => !p);
  const [warningIsOpen, setWarningModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const [_loading, _setLoading] = useState(false);
  const isReceivingOffice = location.pathname.includes("receiving-office");
  const isChequesOffice = location.pathname.includes("cheques-office");
  const isDstPendingScheduleffice = location.pathname.includes(
    "/dst-pending-schedule"
  );
  const isSTReview = location.pathname.includes("sub-treasurer-payment-review");
  const isApprovedPayment = location.pathname.includes("approved-payments");
  const isDstCashbackingCheck = location.pathname.includes(
    "dst-office/view-schedule"
  );

  const toggleWarning = () => setWarningModalIsOpen((p) => !p);

  const history = useNavigate();
  const goBackFun = () => {
    history(-2);
  };
  const batch_no = useParams().batch_no;
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([]);
  const [tresuryDetails, setTresuryDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [processing, setProcessing] = useState(false);

  const [changeAmount, setChangeAmount] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle1 = () => setModal((p) => !p);
  const [rowData, setRowData] = useState([]);
  const [paymentAccountList, setPaymentAccountList] = useState([]);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState();
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);

  useEffect(() => {
    fetchApi("pan_details/get")
      .then((resp) => {
        if (resp) {
          setPaymentAccountList(resp.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getApprovalInfoByLineItem = async (info) => {
    try {
      return await postApi(`post_approval_collection`, {
        form: {
          query_type: "get_by_line_item",
          approval_no: info.approval_no,
          mda_code: info.mda_code,
          mda_economic_code: info.economic_code,
        },
      });
    } catch (error) {
      return error;
    }
  };

  const [form, setForm] = useState({
    batch_number: "",
    date: "",
    no_of_mda: "",
    no_of_payments: "",
    total_amount: "",
    bulk_description: "Disbursement",
    cheque_number: "",
    query_type: "insert",
    status: "uncommited",
    amount: "",
  });
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggle = () => setModalIsOpen((p) => !p);
  const isST = user.role === "sub_treasury";
  const isUpdatePage = pageType === "update";
  const isCommitPage = pageType === "commit";

  useEffect(() => {
    setLoading(true);
    postApi(`get_batch_list`, {
      query_type: "select_by_batch_no",
      status: isChequesOffice
        ? "Schedule Approved By DST"
        : isDstPendingScheduleffice
        ? "Schedule Approved By Cheques Office"
        : isSTReview
        ? "Payment Approved By DST"
        : isApprovedPayment
        ? "Payment Approved By Sub Treasurer"
        : "Schedule Approved By Checking Office",
      batch_no,
      // type: user.role,
    })
      .then((data) => {
        console.log("data", data);
        if (data.result && data.result.length) {
          setPaymentScheduleTable(data.result);
          setTresuryDetails(data.result[0]);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    batch_no,
    isReceivingOffice,
    isApprovedPayment,
    isDstPendingScheduleffice,
    isChequesOffice,
    isSTReview,
  ]);

  const reviewPayment = () => {
    postApi(`post_contractor_payment_schedule_array`, {
      paymentScheduleTable: rowData
        .filter(
          (it) =>
            it.approvalInfo &&
            it.approvalInfo.cb_amount &&
            it.approvalInfo.cb_amount > 0
        )
        .map((item) => ({ ...item.approvalInfo, ...item.pvInfo })),
      query_type: "update_payment",
      status: isDstPendingScheduleffice
        ? "Payment Approved By DST"
        : isSTReview
        ? "Payment Approved By Sub Treasurer"
        : "",
      paymentAccount:
        paymentScheduleTable && paymentScheduleTable.length
          ? paymentScheduleTable[0].payment_account
          : "",
      bulkDescription:
        paymentScheduleTable && paymentScheduleTable.length
          ? paymentScheduleTable[0].bulk_description
          : "",
      userId: user.username,
      // status: isExpControl ? "Director Expenditure Control Approved" : isDAGOffice ? 'DAG Approved' : 'audior_approved',
      // type: user.role,
    })
      .then((data) => {
        if (data.success) {
          toast.success("Payment Approved Successfully");
          history(-2);
          // if(isExpControl) {
          //   history(Routes.expenditureControl.dirExpPaymentScheduleIndex)
          // } else if (isDAGOffice) {
          //   history(Routes.dag.dagIndex)
          // }
        }
      })

      .catch((err) => {
        toast.error(err);
      });
  };

  const handleApi = () => {
    // if (isST) {
    if (
      ((selectedPaymentAccount &&
        selectedPaymentAccount !== "")
        || (paymentScheduleTable[0]?.payment_account_name&&paymentScheduleTable[0]?.payment_account_name!=="")
        // &&
        // form?.bulk_description &&
        // form?.bulk_description !== ""
      ) 
        // ||
      // isDstCashbackingCheck
    ) {
      postApi(`post_contractor_payment_schedule_array`, {
        paymentScheduleTable: rowData
          .filter(
            (it) =>
              it.approvalInfo &&
              it.approvalInfo.cb_amount &&
              it.approvalInfo.cb_amount > 0
          )
          .map((item) => ({ ...item.approvalInfo, ...item.pvInfo })),
        query_type: "update_payment",
        status: isChequesOffice
          ? "Schedule Approved By Cheques Office"
          : "Schedule Approved By DST",
        paymentAccount: selectedPaymentAccount,
        bulkDescription: form?.bulk_description && form?.bulk_description !== "" ? form?.bulk_description : "Payment", 
        userId: user.username,
        // status: isExpControl ? "Director Expenditure Control Approved" : isDAGOffice ? 'DAG Approved' : 'audior_approved',
        // type: user.role,
      })
        .then((data) => {
          if (data.success) {
            toast.success("Payment Approved Successfully");
            if (isChequesOffice) {
              history(
                `${Routes.paymentSchedule.paymentSchedulePdf}/${batch_no}`
              );
            } else {
              history(-2);
            }
            // if(isExpControl) {
            //   history(Routes.expenditureControl.dirExpPaymentScheduleIndex)
            // } else if (isDAGOffice) {
            //   history(Routes.dag.dagIndex)
            // }
          }
        })

        .catch((err) => {
          toast.error(err);
        });
    } else {
      alert("Please select a payment account!");
      setWarningModalIsOpen(false)
    }
    // } else {
    //   postApi(`post_payment_schedule_array`, {
    //     paymentScheduleTable,
    //     query_type: 'update',
    //     // status:isExpControl ? "Director Expenditure Control Approved" : isDAGOffice ? 'DAG Approved' : 'auditor_approved',
    //     type: user.role,
    //   })
    //     .then((data) => {
    //       if (data.success) {
    //         toast.success('Payment Approved Successfully')
    //         // if(isExpControl) {
    //         //   history(Routes.expenditureControl.dirExpPaymentScheduleIndex)
    //         // } else if (isDAGOffice) {
    //         //   history(Routes.dag.dagIndex)
    //         // }
    //       }
    //     })

    //     .catch((err) => {
    //       toast.error(err)
    //     })
    // }
  };

  const handleAllReturned = () => {
    if (isST) {
      postApi(`post_contractor_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: "update",
        status: "remove",
        type: user.role,
        contractor_code: batch_no,
      })
        .then((data) => {
          if (data.success) {
            console.log(data);
            history(-2);
          }
        })

        .catch((err) => {
          console.log(err);
          toast.success("An error occurred");
        });
    } else {
      postApi(`post_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: "update",
        status: "remove",
        type: user.role,
        contractor_code: batch_no,
      })
        .then((data) => {
          if (data.success) {
            console.log(data);
            history(-2);
          }
        })

        .catch((err) => {
          console.log(err);
          toast.error("An error occurred");
        });
    }
  };

  const submitIndividualItem = (id) => {
    const newArray = paymentScheduleTable.filter((itm, idx) => itm.id === id);
    if (isST) {
      postApi(`post_contractor_payment_schedule_array`, {
        paymentScheduleTable: newArray,
        query_type: "update",
        type: user.role,
        status: "remove",
      })
        .then((data) => {
          if (data.success) {
            toast.success("Deleted Successfully");
            history(-2);
          }
        })

        .catch((err) => {
          toast.error(err);
        });
    } else {
      console.log("newArray", newArray);
      postApi(`post_payment_schedule_array`, {
        paymentScheduleTable: newArray,
        query_type: "update",
        type: user.role,
        status: "remove",
      })
        .then((data) => {
          if (data.success) {
            console.log(data);
            history(-2);
          }
        })

        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const handleChange = (id, name, value, e) => {
    // const {name, value} = e.target

    // const value1 = addCommas(removeNonNumeric(value))
    // const value1 = addCommas(value)
    const newArray = [];

    paymentScheduleTable.forEach((itm, idx) => {
      if (itm.id === id) {
        newArray.push({ ...itm, [name]: value });
      } else {
        newArray.push(itm);
      }
    });

    setPaymentScheduleTable(newArray);
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;

    setForm((p) => ({ ...p, [name]: value }));
  };

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: "select_payment_list",
      status: isCommitPage ? "auditor_approved" : "pending",
      batch_no,
      type: user.role,
    })
      .then((data) => {
        console.log("gg", data);
        if (data.result) {
          setPaymentDetails(data.result);
          const formData = data.result[0];
          setForm((p) => ({ ...p, ...formData }));
          // setPaymentScheduleTable(data.result);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      });
  }, [batch_no, user.role, isCommitPage]);

  const updatePaymentSchedule = () => {
    if (isST) {
      postApi(`post_contractor_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: "update_by_batch_no",
        status: "Committed",
        cheque_number: form.cheque_number,
        arabic_date: form.arabic_date,
        type: user.role,
      })
        .then((data) => {
          if (data.success) {
            console.log("hell", data);
            // setOpenPdf(true)
            // if (type === MODULE_TYPES.MAIN_TREASURY) {
            //   history(`${Routes.payments.viewPDF}/${batch_no}`)
            // } else if (type === MODULE_TYPES.SUB_TREASURY) {
            history(`${Routes.payments.viewStPDF}/${batch_no}`);
            // }
          }
        })

        .catch((err) => {
          toast.error(err);
        });
    } else {
      postApi(`post_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: "update_by_batch_no",
        status: "Committed",
        cheque_number: form.cheque_number,
        arabic_date: form.arabic_date,
        type: user.role,
      })
        .then((data) => {
          if (data.success) {
            console.log("hell", data, type);
            // setOpenPdf(true)
            if (type === MODULE_TYPES.MAIN_TREASURY) {
              history(`${Routes.payments.viewPDF}/${batch_no}`);
            } else if (type === MODULE_TYPES.SUB_TREASURY) {
              history(`${Routes.payments.viewStPDF}/${batch_no}`);
            }
          }
        })

        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const submitChequeNo = () => {
    postApi(`post_check_details`, {
      form,
      type: user.role,
    })
      .then((data) => {
        updatePaymentSchedule();
        if (data.success) {
          console.log("hello", data);

          // toggle()
          // history("/dashboard/intAudit")
        }
      })

      .catch((err) => {
        toast.error(err);
      });
  };

  const totalAmount = paymentScheduleTable.reduce(
    (a, b) => a + parseFloat(b.amount),
    0
  );

  // const renderRow = (item, index) =>  (
  //   <tr key={index}>
  //     <td>{item.pv_code}</td>
  //     <td>{item.mda_name}</td>
  //     <td style={{ width: "5%" }}  >{item.description}</td>
  //     <td>{item.approval_no}</td>
  //     <td className='text-end'>{formatNumber(item.amount)}</td>
  //     <td className='text-end'>{formatNumber(item.amount)}</td>
  //     <td>
  //       <>
  //         <Button
  //             className="me-1 m-1"
  //             size="sm" disabled
  //             // onClick={() => {setItemToDelete(item.id); toggleDeleteSingle()}}
  //           >
  //             Attachment
  //           </Button>
  //           <Button
  //             className="btn-danger"
  //             size="sm"
  //             // onClick={() => {setItemToDelete(item.id); toggleDeleteSingle()}}
  //           >
  //             Return
  //           </Button>
  //       </>
  //     </td>
  //   </tr>
  // )
  // const renderTableBody = () => {
  //    paymentScheduleTable.map(item => {
  //     getApprovalInfoByLineItem(item).then(resp => (
  //        <h1>ssd</h1>
  //     )).catch(err => {
  //       return "Error"
  //     })

  //   })
  // }

  const renderRow = (item, index, disableAction = false) => (
    <tr key={index}>
      <td>{item.pvInfo.pv_code}</td>
      <td>{item.pvInfo.mda_name}</td>
      <td style={{ width: "5%" }}>{item.pvInfo.description}</td>
      <td>{item.pvInfo.approval_no}</td>
      <td className="text-end">{formatNumber(item.pvInfo.amount)}</td>
      <td className="text-end">
        {item.approvalInfo ? formatNumber(item.approvalInfo.cb_amount) : "0"}
      </td>
      <td>
        <div>
          <b>Account Name:</b> {item.pvInfo.contractor_acc_name}
        </div>
        <div>
          <b>Account No:</b> {item.pvInfo.contractor_acc_no}
        </div>
        <div>
          <b>Bank Name:</b> {item.pvInfo.contractor_bank}
        </div>
      </td>
      <td className="text-center">
        <Badge className="p-1">{item?.pvInfo?.payment_status}</Badge>
      </td>
      <td>
        <Button
          className="me-1 m-1"
          size="sm"
          onClick={() =>
            history(
              `${Routes.cashBacking.releaseCollectionPDF}/${item.pvInfo.approval_no}`
            )
          }
        >
          Release
        </Button>
        <Button
          className="me-1 m-1"
          size="sm"
          onClick={() => {
            history(
              `${Routes.pv.pvIndividualView}/${item.pvInfo.approval_no}?approval_type=${item.pvInfo.approval_type}&pv_code=${item.pvInfo.pv_code}`
            );
          }}
        >
          PV
        </Button>

        <Button className="me-1 m-1" size="sm" disabled>
          Attachments
        </Button>
      </td>
      {disableAction ||
      isDstPendingScheduleffice ||
      isSTReview ||
      isApprovedPayment ? null : (
        <td>
          <>
            <Button className="btn-danger" size="sm">
              Return
            </Button>
          </>
        </td>
      )}
    </tr>
  );

  useEffect(() => {
    const fetchData = async () => {
      const updatedData = await Promise.all(
        paymentScheduleTable.map(async (item) => {
          try {
            const resp = await getApprovalInfoByLineItem(item);
            return {
              pvInfo: item,
              approvalInfo:
                resp.result && resp.result.length ? resp.result[0] : {},
            };
          } catch (error) {
            console.error("Error fetching data:", error);
            return { pvInfo: item, approvalInfo: {} };
          }
        })
      );
      setRowData(updatedData.filter((item) => item !== null));
    };

    if (paymentScheduleTable.length) {
      fetchData();
    }
  }, [paymentScheduleTable]);

  return (
    <>
      <Row>
        <Col md={3} className="">
          <BackButton />
        </Col>
        <Col md={6}>
          <h4 className="text-center">
            <FontAwesomeIcon icon={faBook} className="me-2" />{" "}
            {isUpdatePage || isCommitPage ? "Payment List" : "Review Schedule"}
          </h4>
        </Col>
      </Row>
      {/* {JSON.stringify(type)} */}

      {isUpdatePage ? null : (
        <Card className="p-2">
          <Row>
            <Col md={4}>
              {/* {isST ? null : (
                <h6>Bank Name : {tresuryDetails.treasury_bank_name}</h6>
              )} */}

              <h6>Batch No. : {batch_no}</h6>
              {/* {JSON.stringify(isChequesOffice)} */}
              {
                //<h6>Balance :</h6>
              }
            </Col>
            <Col md={4}>
              <h6>Total Amount : {formatNumber(totalAmount)}</h6>
            </Col>

            {isDstPendingScheduleffice || isSTReview || isApprovedPayment ? (
              <Col md={4}>
                <Button
                  className="me-1 m-1"
                  size="sm"
                  onClick={() =>
                    history(
                      `${Routes.paymentSchedule.paymentSchedulePdf}/${batch_no}`
                    )
                  }
                >
                  View Schedule
                </Button>
              </Col>
            ) : null}
          </Row>
{/* {JSON.stringify(selectedPaymentAccount)}jkjkjkj */}
          <Row>
            <Col md={8}>
              {isDstCashbackingCheck ? (
                <div className="my-4 row">
                  <div className="col-md-6">
                    <label>
                      Select Payment Account
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      onChange={({ target: { value } }) =>
                        setSelectedPaymentAccount(value)
                      }
                    >
                      <option value="">--select--</option>
                      {paymentAccountList
                        .filter((item) => item.type === "SUB-TREASURY ACCOUNT")
                        .map((item, id) => {
                          let selected = bankList.find(
                            (i) => i.code === item.bank_code
                          );
                          return (
                            <option value={item.id} key={id}>
                              {item.account_name} ({item.account_number} -{" "}
                              {selected.title})
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    {/* <label>
                      Bulk Descripton<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      value={form.bulk_description}
                      onChange={({ target: { value } }) =>
                        setForm((p) => ({ ...p, bulk_description: value }))
                      }
                    /> */}
                  </div>
                </div>
              ) : (
                <div>
                  <h6>Payment Account:</h6>
                  {paymentScheduleTable && paymentScheduleTable.length ? (
                    <div>
                      <div>
                        Account Name:{" "}
                        {paymentScheduleTable[0].payment_account_name}
                      </div>
                      <div>
                        Bank Name:{" "}
                        {
                          bankList.find(
                            (i) =>
                              i.code ===
                              paymentScheduleTable[0].payment_bank_code
                          )?.title
                        }
                      </div>
                      <div>
                        Account Number:{" "}
                        {paymentScheduleTable[0].payment_account_number}
                      </div>
                    </div>
                  ) : (
                    <div>Not Selected</div>
                  )}
                </div>
              )}
            </Col>
            <Col>
              {/* {isApprovedPayment ? (
                <h4>
                  Payment Status: <span className="text-danger">Pending </span>
                </h4>
              ) : null} */}
            </Col>
          </Row>
        </Card>
      )}

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        {loading && <p>Please wait...</p>}
        <Card.Body className="p-0">
          {/* {JSON.stringify(rowData)} */}

          <table id="customers">
            <thead>
              <tr>
                <th>PV CODE</th>
                <th>MDA NAME</th>
                <th style={{ width: "5%" }}>DESCRIPTION</th>
                <th>APPROVAL</th>
                <th>AMOUNT</th>
                <th>
                  {isDstPendingScheduleffice || isSTReview || isApprovedPayment
                    ? "AMOUNT PAID"
                    : "CASH BACKED AMOUNT"}
                </th>
                <th>PAYEE'S ACCOUNT DETAILS</th>
                <th>PAYMENT STATUS</th>
                <th>DOCUMENTS</th>
                {isDstPendingScheduleffice ||
                isSTReview ||
                isApprovedPayment ? null : (
                  <th>ACTION</th>
                )}
              </tr>
            </thead>
            <tbody>
              {rowData.map((item, index) => renderRow(item, index))}
            </tbody>
          </table>
          {/* <CustomTable
            size="sm"
            fields={fields}
            data={paymentScheduleTable}
            // currentPage={currentPage}
            // handlePageChange={handlePageChange}
          /> */}
        </Card.Body>
        {isUpdatePage ? null : isApprovedPayment ? (
          <Card.Footer>
            <center className="my-2">
              <CustomButton onClick={() => setPaymentModalIsOpen(true)}>
                <img
                  src="https://asset.brandfetch.io/idxE04AMjS/idL34Q6qIN.png"
                  alt="interswitch_logo"
                  height={"30px"}
                  className="me-2"
                />
                Process Payment
              </CustomButton>
            </center>
          </Card.Footer>
        ) : (
          <Card.Footer>
            <center>
              {isCommitPage ? null : (
                <Button
                  className="btn-danger me-3 "
                  onClick={() => {
                    setDeleteAllWarningModalOpen(true);
                  }}
                >
                  Return
                </Button>
              )}{" "}
              <CustomButton
                className=""
                loading={loading}
                onClick={
                  isCommitPage
                    ? toggle
                    : isDstPendingScheduleffice ||
                      isSTReview ||
                      isApprovedPayment
                    ? reviewPayment
                    : isChequesOffice
                    ? handleApi
                    : toggleWarning
                }
              >
                <FontAwesomeIcon icon={faCheck} className="me-2" />
                {isCommitPage
                  ? "Continue"
                  : isChequesOffice
                  ? "Approve & Print Schedule"
                  : isDstPendingScheduleffice || isSTReview || isApprovedPayment
                  ? "Review Payment"
                  : "Approve Schedule"}
              </CustomButton>
            </center>
          </Card.Footer>
        )}
      </Card>
      <PaymentPreviewModal
        isOpen={paymentModalIsOpen}
        toggle={() => setPaymentModalIsOpen((p) => !p)}
        paymentList={rowData}
        goBackFun={goBackFun}
      />
      {/* <PaymentModal
        toggle={toggle}
        submitChequeNo={submitChequeNo}
        modal={modal}
        paymentDetails={paymentDetails}
        form={form}
        handleChange={handleChange1}
      /> */}
      <Modal show={warningIsOpen} onHide={toggleWarning} size="xl">
        <Modal.Header onHide={toggleWarning}>
          <h4>Confirm Payments</h4>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5>The following payment will be processed</h5>

          {rowData.length ? (
            <table id="customers">
              <thead>
                <tr>
                  <th>PV CODE</th>
                  <th>MDA NAME</th>
                  <th style={{ width: "5%" }}>DESCRIPTION</th>
                  <th>APPROVAL</th>
                  <th>AMOUNT</th>
                  <th>CASH BACKED AMOUNT</th>
                  <th>PAYEE'S ACCOUNT DETAILS</th>
                  <th>PAYMENT STATUS</th>
                  <th>DOCUMENTS</th>
                </tr>
              </thead>
              <tbody>
                {rowData
                  .filter(
                    (it) =>
                      it.approvalInfo &&
                      it.approvalInfo.cb_amount &&
                      it.approvalInfo.cb_amount > 0
                  )
                  .map((item, index) => renderRow(item, index, true))}
              </tbody>
            </table>
          ) : (
            <div>
              <Alert>NO PV WITH CASH BACKING FOUND</Alert>
            </div>
          )}
        </Modal.Body>
        {rowData.length ? (
          <Modal.Footer>
            <div>
              <Button
                outline
                className="m-1 p-1 px-3 bg-danger"
                onClick={toggleWarning}
              >
                Cancel
              </Button>
              <Button
                color="danger"
                className="m-1 p-1 px-3"
                onClick={handleApi}
                loading={_loading}
              >
                Process Payments
              </Button>
            </div>
          </Modal.Footer>
        ) : null}
      </Modal>
      {/* <Modal show={deleteSignleWarningModalIsOpen} onHide={toggleDeleteSingle}>
          <Modal.Header onHide={toggleDeleteSingle}>Return</Modal.Header>
          <Modal.Body className="text-center">
            <h5>Are you sure you want to return this item? </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              
              <Button outline className="btn-primary m-1 p-1 px-3" onClick={toggleDeleteAll}>
                Cancel
              </Button>
              <Button
                color="danger"
                className="m-1 p-1 px-3"
                onClick={() => submitIndividualItem(itemToDelete)}
                loading={_loading}
              >
                Return
              </Button>
            </div>
          </Modal.Footer>
        </Modal> */}
    </>
  );
}

export default DSTOffice;
