export const sumBudgetData = (data) => {
  const totals = data?.reduce(
    (acc, curr) => {
      acc.budget_personnel += curr.budget_personnel || 0;
      acc.actual_personnel += curr.actual_personnel || 0;
      acc.budget_overhead += curr.budget_overhead || 0;
      acc.actual_overhead += curr.actual_overhead || 0;
      acc.budget_capital += curr.budget_capital || 0;
      acc.actual_capital += curr.actual_capital || 0;
      return acc;
    },
    {
      budget_personnel: 0,
      actual_personnel: 0,
      budget_overhead: 0,
      actual_overhead: 0,
      budget_capital: 0,
      actual_capital: 0,
    }
  );

  return [
    parseInt(totals?.budget_personnel),
    parseInt(totals?.actual_personnel),
    parseInt(totals?.budget_overhead),
    parseInt(totals?.actual_overhead),
    parseInt(totals?.budget_capital),
    parseInt(totals?.actual_capital),
  ];
};

export const sumMainData = (data) => {
  const totals = data?.reduce(
    (acc, curr) => {
      acc.budget_admin += curr?.budget_admin || 0;
      acc.actual_admin += curr.actual_admin || 0;
      acc.budget_economic += curr.budget_economic || 0;
      acc.actual_economic += curr.actual_economic || 0;
      acc.budget_law += curr.budget_law || 0;
      acc.actual_law += curr.actual_law || 0;
      acc.budget_regional += curr.budget_regional || 0;
      acc.actual_regional += curr.actual_regional || 0;
      acc.budget_social += curr.budget_social || 0;
      acc.actual_social += curr.actual_social || 0;
      return acc;
    },
    {
      budget_admin: 0,
      actual_admin: 0,
      budget_economic: 0,
      actual_economic: 0,
      budget_law: 0,
      actual_law: 0,
      budget_regional: 0,
      actual_regional: 0,
      budget_social: 0,
      actual_social: 0,
    }
  );

  return [
    parseInt(totals?.budget_admin),
    parseInt(totals?.actual_admin),
    parseInt(totals?.budget_economic),
    parseInt(totals?.actual_economic),
    parseInt(totals?.budget_law),
    parseInt(totals?.actual_law),
    parseInt(totals?.budget_regional),
    parseInt(totals?.actual_regional),
    parseInt(totals?.budget_social),
    parseInt(totals?.actual_social),
  ];
};

export const data2 = (val) => {
  return [
    {
      name: "Personnel",
      "Quarterly Budget": val[0],
      "Actual for Quarter": val[1],
      Performance: 30,
    },
    {
      name: "Overhead",
      "Quarterly Budget": val[2],
      "Actual for Quarter": val[3],
      Performance: 30,
    },
    {
      name: "Debt Service",
      "Quarterly Budget": 1,
      "Actual for Quarter": 0,
      Performance: 30,
    },
    {
      name: "Other Recurrent",
      "Quarterly Budget": 3,
      "Actual for Quarter": 2,
      Performance: 30,
    },
    {
      name: "Capital",
      "Quarterly Budget": val[4],
      "Actual for Quarter": val[5],
      Performance: 30,
    },
  ];
};

export const data3 = (val) => {
  return [
    {
      name: "Administration",
      "Quarterly Budget": val[0],
      "Actual for Quarter": val[1],
      Performance: 30,
    },
    {
      name: "Economic",
      "Quarterly Budget": val[2],
      "Actual for Quarter": val[3],
      Performance: 30,
    },
    {
      name: "Law and Justice",
      "Quarterly Budget": val[4],
      "Actual for Quarter": val[5],
      Performance: 30,
    },
    {
      name: "Regional",
      "Quarterly Budget": val[6],
      "Actual for Quarter": val[7],
      Performance: 30,
    },
    {
      name: "Social",
      "Quarterly Budget": val[8],
      "Actual for Quarter": val[9],
      Performance: 30,
    },
  ];
};

export const data4 = [
  {
    name: "701 General Pub_service",
    "Quarterly Budget": 31,
    "Actual for Quarter": 16,
    Performance: 53,
  },
  {
    name: "702 Defence",
    "Quarterly Budget": 0,
    "Actual for Quarter": 0,
    Performance: 0,
  },
  {
    name: "703 Public Order & Safety",
    "Quarterly Budget": 4,
    "Actual for Quarter": 3,
    Performance: 70,
  },
  {
    name: "704 Economic Affairs",
    "Quarterly Budget": 12,
    "Actual for Quarter": 7.5,
    Performance: 64,
  },
  {
    name: "705 Enviro. Protection",
    "Quarterly Budget": 2,
    "Actual for Quarter": 1,
    Performance: 66,
  },
  {
    name: "706 Housing & Comm. Amenities",
    "Quarterly Budget": 12,
    "Actual for Quarter": 2,
    Performance: 9,
  },
  {
    name: "707 Health",
    "Quarterly Budget": 15,
    "Actual for Quarter": 4,
    Performance: 30,
  },
  {
    name: "708 Recreation, Culture & Religion",
    "Quarterly Budget": 2.5,
    "Actual for Quarter": 3,
    Performance: 90,
  },
  {
    name: "709 Education",
    "Quarterly Budget": 26,
    "Actual for Quarter": 10,
    Performance: 37,
  },
  {
    name: "710 Social Protection",
    "Quarterly Budget": 5,
    "Actual for Quarter": 2.5,
    Performance: 52,
  },
];

export const pieChartData = [
  { name: "Start Alloc. (inc Deriv.)", value: 17, color: "#F68332" },
  { name: "VAT", value: 3, color: "#73B24B" },
  { name: "Openning Balance", value: 13, color: "#4673CA" },
  { name: "Other FAAC", value: 21, color: "#FFC600" },
  { name: "IGR", value: 46, color: "#A8A8A8" },
];

export const pieChartData1 = [
  { name: "Personnel", value: 42, color: "#5999D2" },
  { name: "Capital", value: 29, color: "#4473C5" },
  { name: "Other Recurrent", value: 2, color: "#FFC600" },
  { name: "Debt Service", value: 0, color: "#A8A8A8" },
  { name: "Overhead", value: 25, color: "#F68332" },
];

export const pieChartData2 = [
  { name: "Administration", value: 26, color: "#5999D2" },
  { name: "Economic", value: 36, color: "#4473C5" },
  { name: "Law and Justice", value: 4, color: "#A8A8A8" },
  { name: "Regional", value: 34, color: "#F68332" },
];

export const pieChartData3 = [
  { name: "701 General Pub_service", value: 33, color: "#5A9BD5" },
  { name: "702 Defence", value: 0, color: "#95440B" },
  { name: "703 Public Order & Safety", value: 5, color: "#A4A6A4" },
  { name: "704 Economic Affairs ", value: 16, color: "#FFC000" },
  { name: "705 Envir. Protection", value: 2, color: "#4371C4" },
  { name: "706 Housing & Comm.", value: 2, color: "#72AD40" },
  { name: "707 Health", value: 9, color: "#245D8E" },
  { name: "708 Recreation Culture & Religion", value: 7, color: "#95440B" },
  { name: "709 Education", value: 21, color: "#626262" },
  { name: "710 Social Protection", value: 5, color: "#987300" },
];
