import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./signin.css";
import {
  faAngleLeft,
  faEnvelope,
  faQuestionCircle,
  faStar,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Image,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/state-govt-logo.png";
import { Routes } from "../../routes";
import { useDispatch } from "react-redux";
import CustomButton from "../../components/CustomButton";
import { login } from "../../redux/actions/auth";
import BgImage from "../../assets/img/state-govt-logo.png";
import { LoaderWrapper } from "../HomePage";
import { mainTreasuryModules } from "./user-admin/modules";
import WhatsAppFloatingIcon from "../components/WhatsAppFloatingIcon";
import toast from "react-hot-toast";

const getDefaultRoute = (func) => {
  let allRoutes = [];
  mainTreasuryModules.forEach(m => {
    if(m.subMenu) {
      m.subMenu.forEach(s => allRoutes.push(s))
    } else {
      allRoutes.push(m)
    }
  })

  let foundRoute = allRoutes.find(
    (a) => a.title === func
  );

  return foundRoute;
}

export default () => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();
  const emailInputRef = useRef(null);

  const navigate = useNavigate();
  const isMobile = () => {
    return window.innerWidth > 992;
  };
  const marginTop = isMobile ? -120 : -67;

  const handleChange = ({ target: { name, value } }) =>
    setForm((prev) => ({ ...prev, [name]: value }));
  const getDefaultRoute = (func) => {
    let allRoutes = [];
    mainTreasuryModules.forEach(m => {
      if(m.subMenu) {
        m.subMenu.forEach(s => allRoutes.push(s))
      } else {
        allRoutes.push(m)
      }
    })
  
    let foundRoute = allRoutes.find(
      (a) => a.title === func
    );
  
    return foundRoute;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      login(
        form,
        (user) => {
          setLoading(false);
          if (user && user.mda_name && user.mda_name !== "") {
            // alert(JSON.stringify(user))
            if (!user.email || !user.signature) {
              toast(
                (t) => (
                  <span>
                    <p>
                      Please update your profile with your email address to
                      start getting notifications to your inbox.
                    </p>
                    {/* {!user.signature ? "and signature" : ""}.{" "} */}
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary btn-sm "
                        onClick={() => {
                          toast.dismiss(t.id);
                          navigate("/user/profile");
                        }}
                      >
                        {/* {!user.email && !user.signature */}
                        Go to your profile
                        {/* // : !user.email
                        // ? "add email"
                        // : !user.signature
                        // ? "add signature"
                        : null} */}
                      </button>
                    </div>
                  </span>
                ),
                { duration: 3000 }
              );
            }
            // alert("MDA")

            
          } else {
            // history(Routes.DashboardOverview.path);
          }

          const firstItem = user.functionalities.split(",")[0];
          // const route = mainTreasuryModules[4].subMenu.find(
          //   (a) => a.title === firstItem
          // );

          const route = getDefaultRoute(firstItem)

          if (route) {
            console.log(route, "FOUND ROUTE");
            history(route.route);
          } else {
            history(Routes.approval.approvalIndex);
          }
        },
        (error) => {
          setLoading(false);
        }
      )
    );
  };

  return (
    <LoaderWrapper>
      <main>
        <img
          src={logo}
          style={{
            width: "100%",
            height: "100vh",
            opacity: 0.2,
            position: "absolute",
            alignItems: "center",
            zIndex: -1,
          }}
          className="img-fluid"
          alt="logo-img"
        />
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 pb-lg-3 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <Image src={logo} className="kifmis-logo" />
                    <h5 className="mb-0 mt-2">
                      KATSINA STATE INTEGRATED FINANCIAL MANAGEMENT INFORMATION
                      SYSTEM 
                      <br />
                      (KIFMIS)
                    </h5>
                  </div>
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    {/* {alert && (
                       {alert}
                      </div>
                    )} <div className="alert alert-danger" role="alert">
                       */}
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Your Username</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          value={form.username}
                          name="username"
                          onChange={handleChange}
                          autoFocus
                          required
                          type="text"
                          placeholder="abubakar"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Your Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            value={form.password}
                            name="password"
                            onChange={handleChange}
                            type="password"
                            placeholder="Password"
                          />
                        </InputGroup>
                      </Form.Group>
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <Form.Check type="checkbox">
                          <FormCheck.Input
                            id="defaultCheck5"
                            className="me-2"
                          />
                          <FormCheck.Label
                            htmlFor="defaultCheck5"
                            className="mb-0"
                          >
                            Remember me
                          </FormCheck.Label>
                        </Form.Check>
                        <Link
                          className="small text-end"
                          to="/examples/forgot-password"
                        >
                          Lost password?
                        </Link>
                      </div>
                    </Form.Group>
                    <CustomButton
                      loading={loading}
                      variant="primary"
                      type="submit"
                      className="w-100"
                    >
                      Sign in
                    </CustomButton>
                  </Form>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: 12,
                      fontWeight: "bold",
                      marginTop: 10,
                      color: "#087A15",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      style={{
                        marginRight: 20,
                      }}
                    />
                    Support Contact: 09113472208
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div>
          <WhatsAppFloatingIcon phoneNumber="+2349113472208" />
        </div>
      </main>
    </LoaderWrapper>
  );
};
