import React, { useState, useEffect } from "react";
import {
  Modal,
  Col,
  Card,
  Row,
} from "@themesberg/react-bootstrap";
import CustomBarChart from "./NewCharts/CustomBarChart";
import PieChartComponent from "./NewCharts/CustomPieChart";
import {
  data2,
  data3,
  data4,
  pieChartData,
  pieChartData1,
  pieChartData2,
  pieChartData3,
  sumBudgetData,
  sumMainData,
} from "./data";
import moment from "moment";

import reportsLineChartData from "./data/reportsLineChartData";

import { fetchApi } from "../../redux/api";
import { formatNumber } from "../../redux/utils";
import TextInput from "../../components/TextInput";

const getCurrentQuarter = () => {
  const month = new Date().getMonth() + 1;
  if (month >= 1 && month <= 3) return "Q1";
  if (month >= 4 && month <= 6) return "Q2";
  if (month >= 7 && month <= 9) return "Q3";
  if (month >= 10 && month <= 12) return "Q4";
};
const getQuarterDateRange = (quarter) => {
  const year = new Date().getFullYear();
  switch (quarter) {
    case "Q1":
      return { from: `${year}-01-01`, to: `${year}-03-31` };
    case "Q2":
      return { from: `${year}-04-01`, to: `${year}-06-30` };
    case "Q3":
      return { from: `${year}-07-01`, to: `${year}-09-30` };
    case "Q4":
      return { from: `${year}-10-01`, to: `${year}-12-31` };
    default:
      return { from: "", to: "" };
  }
};

function NewDashboard() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState(null);
  const [selectQuarter, setSelectQuarter] = useState({
    quarter: getCurrentQuarter(),
    from: "",
    to: "",
  });

  const { sales, tasks } = reportsLineChartData;
  const [mda, setMda] = useState([]);
  const [budgeted, setBudgeted] = useState([]);
  const [expense, setExpense] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [budgetActual, setBudgetActual] = useState([]);
  const [revenueByEconomic, setrevenueByEconomic] = useState([]);
  const [expByEconomic, setExpByEconomic] = useState([]);
  const [mainSector, setMainSector] = useState([]);

  const getAllData = (query_type, cb) => {
    fetchApi(
      `reports/budget-report-ag?query_type=${query_type}&fromDate=${moment().format(
        "YYYY-MM-DD"
      )}&toDate=${moment().format("YYYY-MM-DD")}`
    )
      .then((data) => {
        // `dashboard-budget?query_type=${query_type}`).then((data) => {
        cb(data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllData("mda", setMda);
    getAllData("budget_amount", setBudgeted);
    getAllData("expenses", setExpense);
    getAllData("revenue", setRevenue);
    getAllData("revenue_economic", setrevenueByEconomic);
    getAllData("exp_economic", setExpByEconomic);
    getAllData("exp_main_sector", setMainSector);
    getAllData("budget-actual", (resp) => {
      // setBudgetActual(resp);
      const finalData = [
        {
          name: "TOTAL REVENUE",
          "Total Budget": resp[0]["Total Budget"],
          "Total Actual": resp[0]["Total Actual"],
          Performance:
            parseFloat(
              parseFloat(resp[0]["Total Actual"] || 0) /
                parseFloat(resp[0]["Total Budget"])
            ) * 100,
          // amt: 120,
        },
        {
          name: "TOTAL EXPENDITURE",
          "Total Budget": resp[0]["Total Budget"],
          "Total Actual": resp[0]["Total Actual"],
          Performance:
            parseFloat(
              parseFloat(resp[0]["Total Actual"] || 0) /
                parseFloat(resp[0]["Total Budget"])
            ) * 100,
          // amt: 120,
        },
      ];
      setBudgetActual(finalData);
    });
  }, []);

  const toggleModal = (chart) => {
    setSelectedChart(chart);
    setModalOpen(!modalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectQuarter((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "quarter" && value !== "custom") {
      const dateRange = getQuarterDateRange(value);
      setSelectQuarter((prevState) => ({
        ...prevState,
        from: dateRange.from,
        to: dateRange.to,
      }));

      console.log("Date Range:", dateRange);
    }
  };
  const handleCustomDateChange = (e) => {
    const { name, value } = e.target;
    setSelectQuarter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const getCustomDate = (quarter) => {
    if (quarter === "custom") {
      return (
        <div className="d-flex">
          <div>
            <label>From</label> <br />
            <TextInput
              type="date"
              id="from"
              name="from"
              className="w-100"
              value={selectQuarter.from}
              onChange={handleCustomDateChange}
            />
          </div>
          <div>
            <label>To</label> <br />
            <TextInput
              type="date"
              id="to"
              name="to"
              className="w-100"
              value={selectQuarter.to}
              onChange={handleCustomDateChange}
            />
          </div>
        </div>
      );
    }
    return null;
  };
  let newRev = revenueByEconomic?.map((item) => ({
    ...item,
    name: item.description,
    "Quarterly Budget": item.approve_amount,
    "Actual for Quarter": item.actual_amount,
    Performance: 30,
  }));
 
  let new_total = sumBudgetData(expByEconomic);
  let newMain = sumMainData(mainSector);

  return (
    <Row className="m-0 p-0">
      {/* {JSON.stringify(newMain)} */}
      <Modal
        size="lg"
        className="p-3 rounded"
        isOpen={modalOpen}
        toggle={toggleModal}
        centered
        style={{
          marginLeft: 300,
          fontSize: 12,
        }}
      >
        <Modal.Header toggle={toggleModal}>
          {selectedChart && selectedChart.title}
        </Modal.Header>
        <Modal.Body>{selectedChart && selectedChart.chart}</Modal.Body>
      </Modal>
      <Row>
        <Col md={12}>
          <div className="d-flex justify-content-between mb-3">
            <div className="col-md-4">
              <label>Select Quarter</label>
              <select
                className="form-control"
                name="quarter"
                value={selectQuarter.quarter}
                onChange={handleChange}
              >
                <option value="Q1">Q1</option>
                <option value="Q2">Q2</option>
                <option value="Q3">Q3</option>
                <option value="Q4">Q4</option>
                <option value="custom">Custom</option>
              </select>
            </div>
            <div className="col-md-6 ">
              {getCustomDate(selectQuarter.quarter)}
            </div>
          </div>
        </Col>
      </Row>

      <Col md={7}>
        <Card body className="my-2">
          <div
            style={{
              fontSize: 12,
            }}
          >
            <Row className="m-0 p-0">
              <div
                style={{
                  width: "100%",
                }}
              >
                <h6
                  className="text-center fw-bold"
                  style={{
                    width: "90%",
                  }}
                >
                  2024 AGGREGATE PERFORMANCE
                </h6>
                <span
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 12,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  className="text-primary"
                  onClick={() =>
                    toggleModal({
                      title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                      chart: (
                        <CustomBarChart
                          data={budgetActual}
                          width={600}
                          height={400}
                          uvDataKey="Total Budget"
                          pvDataKey="Total Actual"
                        />
                      ),
                    })
                  }
                >
                  expand
                </span>
              </div>
              <CustomBarChart
                data={budgetActual}
                width={420}
                height={400}
                uvDataKey="Total Budget"
                pvDataKey="Total Actual"
              />
            </Row>
          </div>
        </Card>
      </Col>
      <Col
        md={5}
        className="card mt-2"
        style={{
          height: 480,
          fontSize: 15,
          fontWeight: "bold",
        }}
      >
        {/* <Arrow /> */}
        <h4 className="text-center mt-2">
          {moment().format("YYYY")} {selectQuarter.quarter} Flow of Funds
        </h4>
        <div
          className="d-flex justify-content-betwee text-light"
          style={{ position: "absolute", top: "30%" }}
        >
          <p style={{ marginRight: 80, marginLeft: 20 }}>Incoming (Revenue)</p>
          <p>{formatNumber(revenue[0]?.total || 0)}</p>
        </div>
        <img
          src={require("./image.png")}
          alt="arror-leftright"
          className="img-fluid mt-5"
        />

        <div
          className="d-flex justify-content-betwee text-light"
          style={{ position: "absolute", top: "55%" }}
        >
          <p style={{ marginRight: 50, marginLeft: 28, marginTop: 10 }}>
            Outgoing (Expenditure)
          </p>
          <p>{formatNumber(expense[0]?.total || 0)}</p>
        </div>
      </Col>

      <Col md={7}>
        <Card
          body
          className="my-2"
          // style={{
          //   height: "24em",
          // }}
        >
          <div
            style={{
              fontSize: 12,
            }}
          >
            <Row className="m-0 p-0">
              <div
                style={{
                  width: "100%",
                }}
              >
                <h6
                  className="text-center fw-bold"
                  style={{
                    width: "90%",
                  }}
                >
                  2024 REVENUE PERFORMANCE BY ECONOMIC
                </h6>
                <span
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 12,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  className="text-primary"
                  onClick={() =>
                    toggleModal({
                      title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                      chart: (
                        <CustomBarChart
                          data={newRev}
                          width={600}
                          height={400}
                          uvDataKey="Quarterly Budget"
                          pvDataKey="Actual for Quarter"
                        />
                      ),
                    })
                  }
                >
                  expand
                </span>
              </div>
              <CustomBarChart
                data={newRev}
                width={420}
                height={400}
                uvDataKey="Quarterly Budget"
                pvDataKey="Actual for Quarter"
              />
            </Row>
          </div>
        </Card>
      </Col>
      <Col md={5}>
        <Card body className="my-2">
          <div
            style={{
              fontSize: 12,
            }}
          >
            <Row className="m-0 p-0">
              <div
                style={{
                  width: "100%",
                }}
              >
                <h6
                  className="text-center fw-bold"
                  style={{
                    width: "90%",
                  }}
                >
                  2024 REVENUE COMPOSITION BY ECONOMIC
                </h6>
                <span
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 12,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  className="text-primary"
                  onClick={() =>
                    toggleModal({
                      title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                      chart: <PieChartComponent data={pieChartData} />,
                    })
                  }
                >
                  expand
                </span>
              </div>
              <PieChartComponent data={pieChartData} />
            </Row>
          </div>
        </Card>
      </Col>

      <Col md={7}>
        <Card body className="my-2">
          <div
            style={{
              fontSize: 12,
            }}
          >
            <Row className="m-0 p-0">
              <div
                style={{
                  width: "100%",
                }}
              >
                <h6
                  className="text-center fw-bold"
                  style={{
                    width: "80%",
                  }}
                >
                  2024 EXPENDITURE PERFORMANCE BY ECONOMIC
                </h6>
                <span
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 12,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  className="text-primary"
                  onClick={() =>
                    toggleModal({
                      title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                      chart: (
                        <CustomBarChart
                          data={data2(new_total)}
                          width={600}
                          height={400}
                          uvDataKey="Quarterly Budget"
                          pvDataKey="Actual for Quarter"
                        />
                      ),
                    })
                  }
                >
                  expand
                </span>
              </div>
              <CustomBarChart
                data={data2(new_total)}
                width={420}
                height={400}
                uvDataKey="Quarterly Budget"
                pvDataKey="Actual for Quarter"
              />
            </Row>
          </div>
        </Card>
      </Col>
      <Col md={5}>
        <Card body className="my-2">
          <div
            style={{
              fontSize: 12,
            }}
          >
            <Row className="m-0 p-0">
              <div
                style={{
                  width: "100%",
                }}
              >
                <h6
                  className="text-center fw-bold"
                  style={{
                    width: "90%",
                  }}
                >
                  2024 EXPENDITURE COMPOSITION BY ECONOMIC
                </h6>
                <span
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 12,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  className="text-primary"
                  onClick={() =>
                    toggleModal({
                      title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                      chart: <PieChartComponent data={pieChartData1} />,
                    })
                  }
                >
                  expand
                </span>
              </div>
              <PieChartComponent data={pieChartData1} />
            </Row>
          </div>
        </Card>
      </Col>

      <Col md={7}>
        <Card body className="my-2">
          <div
            style={{
              fontSize: 12,
            }}
          >
            <Row className="m-0 p-0">
              <div
                style={{
                  width: "100%",
                }}
              >
                <h6
                  className="text-center fw-bold"
                  style={{
                    width: "90%",
                  }}
                >
                  2024 EXPENDITURE PERFORMANCE BY MAIN SECTOR
                </h6>
                <span
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 12,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  className="text-primary"
                  onClick={() =>
                    toggleModal({
                      title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                      chart: (
                        <CustomBarChart
                          data={data3(newMain)}
                          width={600}
                          height={400}
                          uvDataKey="Quarterly Budget"
                          pvDataKey="Actual for Quarter"
                        />
                      ),
                    })
                  }
                >
                  expand
                </span>
              </div>
              <CustomBarChart
                data={data3(newMain)}
                width={420}
                height={400}
                uvDataKey="Quarterly Budget"
                pvDataKey="Actual for Quarter"
              />
            </Row>
          </div>
        </Card>
      </Col>
      <Col md={5}>
        <Card body className="my-2">
          <div
            style={{
              fontSize: 12,
            }}
          >
            <Row className="m-0 p-0">
              <div
                style={{
                  width: "100%",
                }}
              >
                <h6
                  className="text-center fw-bold"
                  style={{
                    width: "90%",
                  }}
                >
                  2024 EXPENDITURE COMPOSITION BY MAIN SECTOR
                </h6>
                <span
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 12,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  className="text-primary"
                  onClick={() =>
                    toggleModal({
                      title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                      chart: <PieChartComponent data={pieChartData2} />,
                    })
                  }
                >
                  expand
                </span>
              </div>
              <PieChartComponent data={pieChartData2} />
            </Row>
          </div>
        </Card>
      </Col>

      <Col md={7}>
        <Card body className="my-2">
          <div
            style={{
              fontSize: 12,
            }}
          >
            <Row className="m-0 p-0">
              <div
                style={{
                  width: "100%",
                }}
              >
                <h6
                  className="text-center fw-bold"
                  style={{
                    width: "80%",
                  }}
                >
                  2024 EXPENDITURE PERFORMANCE BY FUNCTION
                </h6>
                <span
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 12,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  className="text-primary"
                  onClick={() =>
                    toggleModal({
                      title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                      chart: (
                        <CustomBarChart
                          data={data4}
                          width={600}
                          height={400}
                          uvDataKey="Quarterly Budget"
                          pvDataKey="Actual for Quarter"
                        />
                      ),
                    })
                  }
                >
                  expand
                </span>
              </div>
              <CustomBarChart
                data={data4}
                width={420}
                height={400}
                uvDataKey="Quarterly Budget"
                pvDataKey="Actual for Quarter"
              />
            </Row>
          </div>
        </Card>
      </Col>
      <Col md={5}>
        <Card body className="my-2">
          <div
            style={{
              fontSize: 12,
            }}
          >
            <Row className="m-0 p-0">
              <div
                style={{
                  width: "100%",
                }}
              >
                <h6
                  className="text-center fw-bold"
                  style={{
                    width: "90%",
                  }}
                >
                  2024 EXPENDITURE COMPOSITION BY MAIN SECTOR
                </h6>
                <span
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 12,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  className="text-primary"
                  onClick={() =>
                    toggleModal({
                      title: "2024 REVENUE PERFORMANCE BY ECONOMIC",
                      chart: <PieChartComponent data={pieChartData3} />,
                    })
                  }
                >
                  expand
                </span>
              </div>
              <PieChartComponent data={pieChartData3} />
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default NewDashboard;
