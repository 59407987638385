import React, { useState, useEffect, useCallback } from "react";
import { Col, Form, Row, Button, Card } from "@themesberg/react-bootstrap";
import BackButton from "../../../components/BackButton";
import { fetchApi, postApi } from "../../../redux/api";
import { useNavigate, useParams } from "react-router-dom";
import { faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Routes } from "../../../routes";
import toast from "react-hot-toast";
import NewPVBankDetails from "../../sub-treasury/pv-collection/NewPVBankDetails";
import { Modal } from "react-bootstrap";
import CustomButton from "../../../components/CustomButton";
import { useSelector } from "react-redux";

function ViewCashbackingRequest() {
  const user = useSelector(s => s.auth.user)
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false)
  const history = useNavigate();
  const [form, setForm] = useState({
    economic_code: "",
    collection_date: "",
    mda_name: "",
    mda_code: "",
    approval_date: "",
    approval_type: "",
    amount: "",
    narration: "",
    letter_head_title: "",
    mda_account_bank_name: "",
    mda_account_no: "",
    mda_account_sort_code: "",
    mda_account_name: "",
  });
  const [scheduleModalOpen, setScheduleModalOpen ] = useState(false)

  const { approval_no } = useParams();

  const getApprovalCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: "select_by_approval_no",
        approval_no: approval_no,
      },
    })
      .then((data) => {
        console.log("data", data);
        if (data.result && data.result.length > 0) {
          const result = data.result[0];
          setForm(result);
        }
      })
      .catch((err) => {
        // Handle error
      })
      .finally(() => {
        setLoading(false);
      });
  }, [approval_no]);

  useEffect(() => {
    getApprovalCollection();
  }, [approval_no]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const submitRequest = async () => {
    setSubmitting(true)

    const paymentResp = await fetchApi('number-generator?query_type=select&description=payment')

    // console.log(paymentResp, 'paymentResp')
    if(paymentResp.success && paymentResp.results && paymentResp.results.length) {
        const scheduleId = paymentResp.results[0].next_code;
        console.log(scheduleId, 'scheduleId');
        console.log(form, 'form')

        await postApi('post_approval_collection' /*"update-approval"*/, {form: {
          ...form,
            query_type: 'create_cb_request',// 'cb_requested', //create_cb_request
            remarks: scheduleId,
            status: "Cashbacking Request Raised",
            approval_status: "Cashbacking Request Raised",
            approval_no: form.approval_no,
            userId: user.username,
            mda_name: user ? user.mda_name : "",
            payment_account_name:form.mda_account_name,
            payment_bank_name:form.mda_account_bank_name,
            payment_acc_no:form.contractor_acc_no,
            payment_acc_type:form.mda_account_type,
            payment_acc_sort_code:form.mda_account_sort_code,
        }})

        await postApi('number-generator', {
            query_type: 'update',
            description: 'payment',
            code: scheduleId
        })
    }
    
    setTimeout(() => {
        setSubmitting()
        setScheduleModalOpen(false)
        history(Routes.cashBacking.cashBackingRequestPDF)
    }, 1000);
  }

  return (
    <>
      <BackButton />
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">
            <FontAwesomeIcon icon={faCheckDouble} className="me-2" />
            View CashBacking Request
          </h5>
          <Form>
            {/* {JSON.stringify(form)} */}
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Release Type</Form.Label>
                  <Form.Control
                    size="sm"
                    placeholder="Enter Economic Code"
                    value={form.approval_type}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Collection Date</Form.Label>
                  <Form.Control
                    size="sm"
                    placeholder="Enter Collection Date"
                    value={form.collection_date}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Approval Date</Form.Label>
                  <Form.Control
                    size="sm"
                    placeholder="Enter Approval Number"
                    value={form.approval_date}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>MDA Name</Form.Label>
                  <Form.Control
                    size="sm"
                    placeholder="Enter MDA Name"
                    value={form.mda_name}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Admin Code</Form.Label>
                  <Form.Control
                    size="sm"
                    placeholder="Enter Approval Date"
                    value={form.mda_code}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Economic Code</Form.Label>
                  <Form.Control
                    size="sm"
                    placeholder="Enter Approval Type"
                    value={form.economic_code}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Economic Description</Form.Label>
                  <Form.Control
                    size="sm"
                    placeholder="Enter Amount"
                    value={form.description_code}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Narration</Form.Label>
                  <Form.Control
                    size="sm"
                    placeholder="Enter Amount"
                    value={form.narration}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Letter Title</Form.Label>
                  <Form.Control
                    size="sm"
                    placeholder="Enter Letter Head Title"
                    value={form.letter_head_title}
                    name="letter_head_title"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <NewPVBankDetails showOrg={false} form={form} setForm={setForm} />
            <center>
              <CustomButton
                variant="primary"
                loading={loading}
                size="sm"
                style={{
                  marginTop: 10,
                }}
                onClick={(e) => {e.preventDefault();setScheduleModalOpen(true)}}
              >
                Send Request
              </CustomButton>
            </center>
          </Form>
        </Card.Body>
      </Card>

      <Modal show={scheduleModalOpen} onHide={() => setScheduleModalOpen(p=>!p)}>
            <Modal.Header><h5>Confirm</h5></Modal.Header>
            <Modal.Body>
            <h5>Are you sure you want to submit this request? This action cannot be reversed.</h5>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton loading={loading} onClick={submitRequest}><FontAwesomeIcon icon={faCheck} /> Continue</CustomButton>
            </Modal.Footer>
        </Modal>
    </>
  );
}

export default ViewCashbackingRequest;
