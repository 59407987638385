import { TOKEN_KEY } from "./actions/auth";
import jwt from "jsonwebtoken";

// export const apiURL = 'https://tbeams-1b57ea51021d.herokuapp.com';
// export const apiURL = "http://127.0.0.1:3589";
// export const apiURL = "http://192.168.43.199:45459";
// export const apiURL = "http://localhost:45890";
// export const apiURL = 'http://192.168.0.106/:3589'

// export const apiURL = 'http://127.0.0.1:3589';

 // export const apiURL = 'http://127.0.0.1:45459';

// export const apiURL = 'https://galaxybis.ebudgetkano.ng/tbeams-server'
// export const apiURL = "https://galaxybis.ebudgetkano.ng/katsina-server";
// export const apiURL = 'http://172.20.10.3:45459';
export const apiURL = "https://bstg-kteb.katsinastate.gov.ng"; 

// export const apiURL = "http://localhost:45459";

export const fetchApi = async (url) => {
  try {
    const authToken = localStorage.getItem(TOKEN_KEY);
    const request = await fetch(`${apiURL}/${url}`, {
      method: "GET",
      headers: { "Content-Type": "application/json", authorization: authToken },
    });
    return request.json();
  } catch (error) {
    return error;
  }
};
export const postApi = async (url, data = {}) => {
  try {
    const authToken = localStorage.getItem(TOKEN_KEY);
    const request = await fetch(`${apiURL}/${url}`, {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: authToken },
      body: JSON.stringify(data),
    });
    return request.json();
  } catch (error) {
    return error;
  }
};

export const deleteApi = async (url, data = {}) => {
  try {
    const authToken = localStorage.getItem(TOKEN_KEY);
    const request = await fetch(`${apiURL}/${url}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", authorization: authToken },
      body: data ? JSON.stringify(data) : null,
    });
    return request.json();
  } catch (error) {
    return error;
  }
};

export const updateApi = async (url = "", data = []) => {
  try {
    const authToken = localStorage.getItem(TOKEN_KEY);
    const request = await fetch(`${apiURL}/${url}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: authToken,
      },
      body: JSON.stringify(data),
    });
    return request.json();
  } catch (error) {
    return error;
  }
};

function unflatten(arr) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;
  for (var i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.title] = arrElem;
    mappedArr[arrElem.title]["children"] = [];
  }

  for (var title in mappedArr) {
    if (mappedArr.hasOwnProperty(title)) {
      mappedElem = mappedArr[title];
      if (mappedElem.subhead) {
        mappedArr[mappedElem["subhead"]]["children"].push(mappedElem);
      } else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
}

export const getSector = (obj = {}, query = "", cd = (f) => f) => {
  postApi(`account?query_type=${query}`, obj)
    .then((data) => {
      console.log(data);
      if (data.success && data?.results) {
        cd(data?.results);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export { unflatten };

export const createToken = (data) => {
  // const payload = JSON.stringify(data)
  data.exp = 126489600000000000000;
  return jwt.sign(data, "028f6601f5c0476cbd4150e4f6ecce33");
};

export const createTokenPin = (data) => {
  // const payload = JSON.stringify(data)
  data.exp = 126489600000000000000;
  return jwt.sign(data, "028f6601f5c0476cbd4150e4f6ecce33");
};

// Function to verify a token
export const verifyToken = async (token) => {
  try {
    return jwt.verify(token, "028f6601f5c0476cbd4150e4f6ecce33");
  } catch (e) {
    return null;
  }
};
function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}


export function toWordsconver(s) {
  // / System for American Numbering
  var th_val = ["", "thousand", "million", "billion", "trillion"];
  // System for uncomment this line for Number of English
  // var th_val = ['','thousand','million', 'milliard','billion'];

  var dg_val = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
  ];
  var tn_val = [
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
  ];
  var tw_val = [
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
  ];

   // Check if the input is zero
   if (s === 0 || s === "0") {
    return "zero";
   }

  if (s) {
      s = s.toString() || 0;
      s = s.replace(/[\\, ]/g, "");
      if (s != parseInt(s)) return "not a number ";
      var x_val = s.indexOf(".");
      if (x_val == -1) x_val = s.length;
      if (x_val > 15) return "too big";
      var n_val = s.split("");
      var str_val = "";
      var sk_val = 0;
      for (var i = 0; i < x_val; i++) {
          if ((x_val - i) % 3 == 2) {
              if (n_val[i] == "1") {
                  str_val += tn_val[Number(n_val[i + 1])] + " ";
                  i++;
                  sk_val = 1;
              } else if (n_val[i] != 0) {
                  str_val += tw_val[n_val[i] - 2] + " ";
                  sk_val = 1;
              }
          } else if (n_val[i] != 0) {
              str_val += dg_val[n_val[i]] + " ";
              if ((x_val - i) % 3 == 0) str_val += "hundred ";
              sk_val = 1;
          }
          if ((x_val - i) % 3 == 1) {
              if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + " ";
              sk_val = 0;
          }
      }
      if (x_val != s.length) {
          var y_val = s.length;
          str_val += "point ";
          for (var e = x_val + 1; e < y_val; e++) str_val += dg_val[n_val[e]] + " ";
      }
      return str_val.replace(/\s+/g, " ");
  }
}
