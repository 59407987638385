import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Card,
  Button,
  Form,
  Modal,
  Container,
  Spinner,
} from "@themesberg/react-bootstrap";

import BackButton from "../../../components/BackButton";
import CustomForm from "../../../components/CustomForm";
import { apiURL, fetchApi, postApi } from "../../../redux/api";
import {
  faCheckDouble,
  faEdit,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "../../../redux/utils";
import { selecOffer } from "../../../redux/utils";
import Attachment from "../../../components/Attachment";
import { useDropzone } from "react-dropzone";
import useQuery from "../../../hooks/useQuery";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { toast } from "react-hot-toast";
// import { toast } from ' react-hot-toast';
import { Typeahead } from "react-bootstrap-typeahead";
import { Routes } from "../../../routes";

export const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];

export default ({ match }) => {
  const query = useQuery();
  const mdaname = query.get("mda_name");
  const mdacode = query.get("mda_code");
  const imageId1 = query.get("imageId");
  const mdaRef = useRef();
  const history = useNavigate();
  const [state, setState] = useState({});
  const [treasuryAccount, setTreasuryAccount] = useState([]);
  const [mdaAccount, setMdaAccount] = useState([]);
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([]);
  const [budgetSummary, setBudgetSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [economicCode, setEconomicCode] = useState([]);
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const [childCode, setChildCode] = useState([]);
  const [batch_no, setBatch_no] = useState("");
  const tresuryTypeahead = useRef();
  const economicTypeahead = useRef();
  const [approvalImageInfo, setApprovalImageInfo] = useState([]);
  const mdaCodeTypeahead = useRef();
  const mdaTypeahead = useRef();
  const [modal, setModal] = useState(false);
  const [batchCode, setBatchCode] = useState("");
  const today = moment().format("MM/DD/YYYY");
  const _id = query.get("id");
  const imageId = moment().format("YYYYMMDDhhmmss");
  const [mdaBudgetBalance, setMdaBudgetBalance] = useState(true);
  const amount = 1234567.89;
  const formattedAmount = amount.toLocaleString(); // "1,234,567.89"

  // moment(testDate).format('MM/DD/YYYY');
  const id = useParams().id;
  const string = moment("2020/05/12").toISOString(true);
  // console.log("string", match)
  const [form, setForm] = useState({
    collection_date: moment().format("YYYY-MM-DD"),
    approval_date: moment().format("YYYY-MM-DD"),
    mda_name: "",
    mda_code: "",
    mda_description: "",
    mda_budget_balance: "",
    mda_economic_code: "",
    approved_by: "",
    approval: "Approved",
    query_type: "insert",
    imageId,
    narration: "Being payment for ",
    approval_no: "",
  });

  const handleSubmit = () => {
    if (
      parseFloat(form.approved_amount) > parseFloat(form.mda_budget_balance)
    ) {
      setShowModal(true);
      return;
    }

    setLoading(true);

    postApi("your-s", { formData: form })
      .then((response) => {
        console.log("Form submitted successfully:", response);
      })
      .catch((error) => {
        console.error("Form submission failed:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "image/*, .pdf, text/plain" });

  const toggle = () => setModal(!modal);

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p);
  };

  const viewAttachment = () => {
    fetchApi(`fetch-approval-images?approval_no=${imageId1}`, (resp) => {
      attachmentModalToggle();
      if (resp.results && resp.results.length) {
        setApprovalImageInfo(resp.results);
      } else {
        setApprovalImageInfo({});
      }
    });
  };
  const handleReset = () => {
    setForm((p) => ({
      ...p,
      mda_account_name: "",
      mda_account_no: "",
      mda_bank_name: "",
      mda_acct_sort_code: "",
      mda_code: "",
      mda_name: "",
      mda_description: "",
      mda_budget_balance: "",
      mda_economic_code: "",
      amount: "",
      description: "",
      attachment: "No Attachment",
      payment_type: "No Approval",
      budget: "No Budget",
      approval: "",
      narration: "",
    }));

    clearEconomicTypeahead();
    // clearMdaTypeahead()
    clearMdaCodeTypeahead();
  };

  const updateBudgetCode = (description, query_type) => {
    postApi(`update-budget-code`, {
      description,
      query_type,
    })
      .then((d) => {
        console.log(d);
        if (d.result) {
          setBatchCode(d.batch_code1);
          console.log("batchCode", batchCode);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleSubmitReset = () => {
    setForm((p) => ({
      ...p,
      collection_date: "",
      approval_date: "",
      mda_name: "",
      mda_description: "",
      mda_budget_balance: "",
      mda_economic_code: "",
      approved_by: "",
    }));

    clearEconomicTypeahead();
    // clearMdaTypeahead()
    clearMdaCodeTypeahead();
    // clearTreasuryTypeahead()
  };
  const getList1 = (economic_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_distinct&mda_code=${form.mda_code}&economic_code=${economic_code}`
    )
      //   fetch(`${api}/get-budget-summary1`,{
      //   method: "POST",
      //   headers: {
      //     "Content-type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     query_type:'select',
      //     mda_code : form.mda_code,
      //     economic_code

      //   }),
      // })
      .then((d) => {
        console.log(d);
        if (d.result) {
          // setBudgetSummary(d.result)
          setForm((p) => ({
            ...p,
            description: d.result[0].budget_description,
            mda_budget_balance: d.result[0].budget_amount,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  // useEffect(() => {

  //   getList1()

  // }, [])

  const getList = () => {
    fetchApi(`get-budget-summary?query_type=list`)
      .then((d) => {
        console.log("d", d);
        if (d.result) {
          setBudgetSummary(d.result);
        }
      })
      .catch((err) => console.log(err));
  };
  //

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: "select",
    }).then((data) => {
      if (data.result) {
        console.log(data.result);

        const treasuryAccount = data.result.filter(
          (item) => item.account_type === "treasury_source_account"
        );
        setTreasuryAccount(treasuryAccount);

        const mdaAccount = data.result.filter(
          (item) => item.account_type === "mda_source_account"
        );
        setMdaAccount(mdaAccount);
      }
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type === "checkbox") {
      setForm((p) => ({ ...p, [name]: value }));
    } else {
      setForm((p) => ({ ...p, [name]: value }));
    }
  };

  const filterData = (economic_code) => {
    const newTresury = budgetSummary.filter(
      (item) =>
        item.economic_code == economic_code && item.mda_code == form.mda_code
    );

    const selectedItem = newTresury[0];
    // setForm(p => ({...p,

    //     description: selectedItem.budget_description,
    // mda_budget_balance: selectedItem.budget_amount,

    //      }))

    console.log("new", newTresury.mda_code);
  };

  const handleMdaAccount = (selected) => {
    if (selected.length) {
      console.log("mda_co", selected);

      const selectedItem = selected[0];
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_bank_name: selectedItem.bank_name,
        mda_account_name: selectedItem.account_name,
        mda_account_no: selectedItem.account_number,
        child_code: selectedItem.bank_name,
        mda_acct_sort_code: selectedItem.sort_code,
        // mda_name : selectedItem.mda_info
      }));

      // const group_id =  selected[0].group_id
      // fetchEventList(group_id)
    } else {
      console.log("no item selected");
    }
    console.log(selected);
  };

  const handleTresuryAcct = (selected) => {
    if (selected.length) {
      console.log(selected);
      const selectedItem = selected[0];
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        treasury_account_name: selectedItem.account_name,
        treasury_account_no: selectedItem.account_number,
        treasury_bank_name: selectedItem.bank_name,
        treasury_source_account: selectedItem.account_info,
      }));
      // const group_id =  selected[0].group_id
      // fetchEventList(group_id)
    } else {
      console.log("no item selected");
    }
    console.log(selected);
  };

  const getList3 = (economic_code) => {
    fetchApi(
      `get-budget-summary?query_type=fetch_budget&mda_code=${form.mda_code}&economic_code=${economic_code}`
    )
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log("Budget", d);
          const selectedItem = d.result[0];
          setForm((p) => ({
            ...p,
            description: selectedItem.budget_description,
            mda_budget_balance:
              selectedItem.budget_amount == 0
                ? "Budget Exausted"
                : selectedItem.budget_amount,
            budget:
              selectedItem.budget_amount == 0
                ? "Budget Exausted"
                : selectedItem.budget_amount,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEconomicCode = (selected) => {
    if (selected.length) {
      console.log("selected budget", selected);
      const selectedItem = selected[0];
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_economic_code: selectedItem.economic_code,
        // budget : selectedItem.economic_code
      }));
      getList3(selectedItem.economic_code);
    } else {
      console.log("no item selected");
    }

    console.log(selected);
  };

  const getList2 = useCallback((mda_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_economic_code&&mda_code=${mda_code}`
    )
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log(d);
          setEconomicCode(d.result);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getList5 = useCallback(() => {
    if (mdacode) {
      fetchApi(
        `get-budget-summary?query_type=select_economic_code&&mda_code=${mdacode}`
      )
        .then((d) => {
          console.log(d);
          if (d.result) {
            console.log(d);
            setEconomicCode(d.result);
            economicTypeahead.current.focus();
          }
        })
        .catch((err) => console.log(err));
    }
    mdaRef.current.focus();
  }, [mdacode]);
  //

  useEffect(() => {
    getList5();
  }, [getList5]);

  const handleMdaCode = (selected) => {
    if (selected.length) {
      console.log(selected);
      const selectedItem = selected[0];
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_code: selectedItem.mda_code,
        mda_name: selectedItem.mda_name,
        // mda_economic_code : selectedItem.economic_code
      }));

      getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const fetchBuget = (mda_code1, economic_code1) => {
    const newArray = budgetSummary.filter(
      (item, idx) =>
        item.mda_info === mda_code1 && item.economic_code === economic_code1
    );
    const selectedItem = newArray[0];
    setForm((p) => ({
      ...p,
      description: selectedItem.budget_description,
      mda_budget_balance: selectedItem.budget_amount,
    }));
  };

  const handleDelete = (index) => {
    const newArray = paymentScheduleTable.filter((itm, idx) => index !== idx);
    setPaymentScheduleTable(newArray);
  };

  const handleEdit = (index) => {
    const newArray = paymentScheduleTable.filter((itm, idx) => index === idx);
    // form(newArray[0])
    console.log(newArray[0]);
  };

  const handleArray = () => {
    setPaymentScheduleTable([...paymentScheduleTable, form]);
    handleReset();
  };

  const addBatchNo = () => {};

  const handleApi = () => {
    if (
      parseFloat(form.approved_amount) > parseFloat(form.mda_budget_balance)
    ) {
      setShowModal(true);
      return;
    } else
      postApi(`post_approval_collection`, {
        form,
      })
        .then((data) => {
          if (data.success) {
            console.log(data);
            handleApi1(data.approval_no);
            // setBatchCode(data.batch_code1)
            // updateBudgetCode('batch_code', 'update')
            handleSubmitReset();
            toast.success("Submitted successfully!");
            history(Routes.approval.approvalIndex);
            // toggle()
            // setPaymentScheduleTable([])
          }
        })

        .catch((err) => {
          toast.error(err);
          // setLoadSpinner(false);
        });
  };

  const handleUpdate = () => {
    postApi(`post_payment_schedule`, {
      mda_account_name: form.mda_account_name,
      mda_account_no: form.mda_account_no,
      mda_bank_name: form.mda_bank_name,
      mda_acct_sort_code: form.mda_acct_sort_code,
      mda_code: form.mda_code,
      mda_name: form.mda_name,
      mda_description: form.mda_description,
      mda_budget_balance: form.mda_budget_balance,
      mda_economic_code: form.mda_economic_code,
      amount: form.amount,
      description: form.description,
      attachment: form.attachment,
      payment_type: form.payment_type,
      budget: form.budget,
      approval: form.approval,
      status: form.status,
      narration: form.narration,
      budget: form.mda_budget_balance,
      approval: form.approval,
      query_type: "UPDATE_BY_ID",
      id,
    })
      .then((data) => {
        if (data.success) {
          console.log(data);
          handleSubmitReset();
          toast.success("Update Successfull");

          history(Routes.batchProcessing.index);
        }
      })

      .catch((err) => {
        toast.error(err);
        // setLoadSpinner(false);
      });
  };

  const fetchMdaBankDetails = useCallback(() => {
    const query_type = "select";
    fetchApi(`select_mda_bank_details`, {
      query_type,
    })
      .then((data) => {
        console.log(data);
        setChildCode(data.result);
      })

      .catch((err) => {
        console.log(err);
      });

    console.log(childCode);
  });

  useEffect(() => {
    fetchMdaBankDetails();
  }, [fetchMdaBankDetails]);

  const clearTreasuryTypeahead = () => {
    tresuryTypeahead.current.clear();
  };

  const clearEconomicTypeahead = () => {
    economicTypeahead.current.clear();
  };

  const clearMdaTypeahead = () => {
    mdaTypeahead.current.clear();
  };

  const clearMdaCodeTypeahead = () => {
    // mdaCodeTypeahead.current.clear();
  };

  useEffect(() => {
    postApi(`post_payment_schedule`, {
      query_type: "select_by_id",
      id,
    }).then((data) => {
      // console.log("datay", data.result[0]);
      if (data?.result?.length) {
        setForm(data.result[0]);
        setMdaBudgetBalance(data.result[0].mda_budget_balance);
        mdaTypeahead.current.setState({
          text: data.result[0].mda_account_name,
        });

        economicTypeahead.current.setState({
          text: data.result[0].mda_economic_code,
        });
        tresuryTypeahead.current.setState({
          text: data.result[0].treasury_account_name,
        });
        mdaCodeTypeahead.current.setState({ text: data.result[0].mda_name });
      }
    });
  }, [id]);

  const handleApi1 = (approval_no) => {
    // console.log(form)

    if (form.approved_amount && form.approved_amount !== "") {
      const formData = new FormData();
      form.approval_no = approval_no;
      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.append("files", acceptedFiles[i]);
      }

      if (!acceptedFiles.length) {
        setForm((p) => ({ ...p, approval: "not approved" }));
        formData.append("form", JSON.stringify(form));
        // // formData.append('event_name', options[0].event_name)

        fetch(`${apiURL}/post_images`, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            // handleApi()
            // setLoadSpinner(false)
            console.log(result);
            // removeAll()
            // toggle()
            // let acceptedFiles = []

            // localStorage.setItem("key", JSON.stringify(result.token));
            // setName(result.user)
            // history("/homepage/payment_table");

            // console.log(result);
          })

          .catch((err) => {
            console.log(err);
            // alert(err);
            // setLoadSpinner(false)
          });
      } else {
        formData.append("form", JSON.stringify(form));
        // // formData.append('event_name', options[0].event_name)

        fetch(`${apiURL}/post_images`, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            // handleApi()
            // setLoadSpinner(false)
            console.log(result);
            // removeAll()
            // toggle()
            // let acceptedFiles = []

            // localStorage.setItem("key", JSON.stringify(result.token));
            // setName(result.user)
            // history("/homepage/payment_table");

            // console.log(result);
          })

          .catch((err) => {
            console.log(err);
            // alert(err);
            // setLoadSpinner(false)
          });
      }
      // Object.keys(form).forEach(item => {
      //   formData.append(item, form[item])
      // })

      // formData.append('user', name.email)
      //  formData.append('group_id', groupName)
    } else {
      // toast.dismiss();
      toast.success("Please enter a Valid amount");
    }
  };

  const getApprovalById = useCallback(() => {
    if (_id) {
      fetchApi(
        `get-approve-col?id=${_id}`,
        (res) => {
          if (res.success) {
            setForm({
              ...res.result[0],
              approved_amount: res.result[0].amount,
              query_type: "update_app",
            });
          }
        },
        (err) => {
          toast.error(`error occured ${err}`);
        }
      );
    }
  }, [_id]);

  useEffect(() => {
    getApprovalById();
  }, [getApprovalById]);

  return (
    <>
      <BackButton />

      <Card>
        <Card.Body>
          <h5 className="mb-4">
            <FontAwesomeIcon icon={faCheckDouble} className="me-2" /> New
            Approval Collection
          </h5>
          <Form>
            <CustomForm
              handleChange={handleChange}
              fields={[
                {
                  label: "Collection Date",
                  value: form.collection_date,
                  name: "collection_date",
                  type: "date",
                },
                {
                  label: "Approval Date",
                  value: form.approval_date,
                  name: "approval_date",
                  type: "date",
                },
                {
                  label: "Select MDA",
                  type: "custom",
                  component: (item) => (
                    <>
                      <Form.Label>Select MDA</Form.Label>
                      <Typeahead
                        size="sm"
                        // disabled={id ? true : false}
                        id="20"
                        onChange={(selected) => handleMdaCode(selected)}
                        onInputChange={(text) => console.log(text)}
                        options={budgetSummary}
                        labelKey={(option) => `${option.mda_name}`}
                        placeholder="Select---"
                        //   ref={mdaCodeTypeahead}
                        defaultInputValue={mdaname ? mdaname : ""}
                        ref={mdaRef}
                      />
                    </>
                  ),
                  //   value: form.account_number,
                  //   name: 'account_number',
                },
                {
                  label: "Administrative Code",
                  value: form.mda_code,
                  name: "mda_code",
                  disabled: true,
                },
                {
                  label: "Select Economic Code",
                  type: "custom",
                  component: (item) => (
                    <>
                      <Form.Label>Select Economic Code</Form.Label>
                      <Typeahead
                        size="sm"
                        // disabled={id ? true : false}
                        id="20"
                        onChange={(selected) => handleEconomicCode(selected)}
                        onInputChange={(text) => console.log(text)}
                        options={economicCode}
                        labelKey={(option) =>
                          `${option.economic_code} - ${option.description}`
                        }
                        placeholder="Select---"
                        ref={economicTypeahead}
                        // defaultInputValue={form.mda_name}
                      />
                    </>
                  ),
                  //   value: form.account_description,
                  //   name: 'account_description',
                },
                {
                  label: "Description",
                  value: form.description,
                  name: "description",
                  disabled: true,
                },
                // {
                //   label: 'Budget Balance',
                //   value: form.mda_budget_balance,
                //   name: form.mda_budget_balance.toLocaleString(),
                //   disabled: mdaBudgetBalance === 'Budget Exausted' ? false : true,
                // },
                {
                  label: `Budget Balance(${selecOffer(
                    form.mda_budget_balance
                  )})`,
                  value: form.mda_budget_balance,
                  //   type: 'select',
                  name: selecOffer(form.mda_budget_balance),
                  disabled:
                    mdaBudgetBalance === "Budget Exausted" ? false : true,
                },
                {
                  label: "Approved By",
                  value: form.approved_by,
                  type: "select",
                  options: ["Exco", "Others", "HE"],
                  name: "approved_by",
                },
                {
                  label: `Approved Amount (${formatNumber(
                    form.approved_amount
                  )})`,
                  value: form.approved_amount,
                  //   type: 'select',
                  name: "approved_amount",
                },
                {
                  label: `Narration`,
                  value: form.narration,
                  type: "textarea",
                  name: "narration",
                },
              ]}
            />

            {!id ? (
              <>
                <Attachment
                  acceptedFiles={acceptedFiles}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  isDragActive={isDragActive}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                />
              </>
            ) : null}
            {_id ? (
              <Button variant="link" onClick={viewAttachment}>
                View Attachment
              </Button>
            ) : null}

            <center>
              {!id ? (
                <Button
                  disabled={loading}
                  onClick={handleApi}
                  // size="sm"
                  // className="btn-success"
                >
                  {loading && <Spinner />}
                  {_id ? "Update" : "Submit"}
                </Button>
              ) : (
                <Button
                  disabled={loading}
                  onClick={handleUpdate}
                  //   size="sm"
                  // className="btn-success"
                >
                  {loading && <Spinner />}
                  Update
                </Button>
              )}
            </center>
            {/* <ViewImage
              attachmentModalIsOpen={attachmentModalIsOpen}
              attachmentModalToggle={attachmentModalToggle}
              approvalImageInfo={approvalImageInfo}
              imageExtensions={imageExtensions}
            /> */}
            <ViewImage
              showModal={showModal}
              setShowModal={setShowModal}
              handleSubmit={handleSubmit}
              form={form}
            />
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
const ViewImage = ({ showModal, setShowModal, handleSubmit, form }) => {
  const approvedAmount = form.approved_amount ? form.approved_amount : "";
  return (
    <>
      {/* <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
      <Modal.Header show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
        Approval No.: {approvalImageInfo.approval_no}
      </Modal.Header>
      <Modal.Body className="text-center">
        <Container>
          <div className="image-container">
            {approvalImageInfo.length ? (
              approvalImageInfo.map((item, index) => imageExtensions.some((extension) => item.image_url.toLowerCase().endsWith(extension)
              ) ? (
                <Card.Img
                  top
                  src={`${apiURL}/uploads/${item.image_url}`}
                  alt={'approval'} />
              ) : (
                <Card.Body>
                  <Card.Link
                    href={`${apiURL}/uploads/${item.image_url}`}
                    target="_blank"
                  >
                    View PDF
                  </Card.Link>
                </Card.Body>
              )
              )
            ) : (
              <h4>No attachment found.</h4>
            )}
          </div>
        </Container>
      </Modal.Body>
    </Modal> */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The Approved Amount Of ({approvedAmount}) is greater than the Budget
          Balance.
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              variant="danger"
              className="m-1 p-1 px-3"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="m-1 p-1 px-3"
              onClick={handleSubmit}
            >
              Submit for AIE
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
