import React, { useEffect, useState } from "react";

import { Form } from "@themesberg/react-bootstrap";

import CustomForm from "../../../components/CustomForm";
import { useParams } from "react-router-dom";
import { postApi } from "../../../redux/api";
import { Typeahead } from "react-bootstrap-typeahead";
import { Col, FormLabel, Row } from "react-bootstrap";
import { bankList, accountTypes } from "../../accounts/AccountForm";

export default ({ form = {}, setForm = (f) => f, showOrg=true }) => {
  const [pvInfo, setPvInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const approval_no = useParams().approval_no;
  const [mdaAccount, setMdaAccount] = useState([]);
  //   const [bankDetails, setBankDetails] = useState({});

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: "select",
    }).then((data) => {
      console.log(data);
      setMdaAccount(data.result);
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type === "checkbox") {
      setForm((p) => ({ ...p, [name]: value }));
    } else {
      setForm((p) => ({ ...p, [name]: value }));
    }
  };

  useEffect(() => {
    if (approval_no) {
      setLoading(true);
      postApi(`get_pv_collection`, {
        form: {
          query_type: "pv_by_approval",
          approval_no: approval_no,
          pv_status: "Assistant Director Expenditure Control Approved",
        },
      })
        .then((data) => {
          console.log("data", data);
          if (data.result && data.result.length) {
            setPvInfo(data.result);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [approval_no]);

  return (
    <>
      {/* {JSON.stringify(bankDetails)} */}
      <Form>
      {showOrg ?  <Row>
          <Col md={12}>
            <CustomForm
              handleChange={handleChange}
              fields={[
                {
                  label: "Organisation",
                  name: "mda_name",
                  value: form.mda_name,
                },
              ]}
            />
          </Col>
          <Col md={12}>
            <CustomForm
              handleChange={handleChange}
              fields={[
                {
                  label: "Sub Organisation",
                  name: "sub",
                  value: form.sub,
                },
                {
                  label: "Sub Sub Organisation",
                  value: form.sub_sub,
                  name: "sub_sub",
                },
                {
                  label: "Sub Sub Sub Organisation",
                  value: form.sub_sub_sub,
                  name: "sub_sub_sub",
                },
                {
                  label: "Payment to",
                  value: form.payment_to,
                  name: "payment_to",
                },
              ]}
            />
          </Col>
        </Row> : null}
        <Row>
          <h6 className="my-2 text-center">Bank Details</h6>
          <Col md={12}>
            <CustomForm
              handleChange={handleChange}
              fields={[
                {
                  label: "Account Name",
                  name: "mda_account_name",
                  value: form.mda_account_name,
                },
                {
                  label: "Bank Name",
                  type: "custom",
                  component: () => (
                    <>
                      <FormLabel>Bank Name</FormLabel>
                      <select
                        onChange={({ target: { value } }) => {
                          setForm((p) => ({
                            ...p,
                            mda_account_bank_name: value,
                          }));
                        }}
                        value={form.mda_account_bank_name}
                        name="mda_account_bank_name"
                        className="form-control form-control-sm"
                      >
                        <option value={""}>--select--</option>
                        {bankList.map((item, id) => (
                          <option key={id} value={item.title}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </>
                  ),
                  // name: "mda_account_bank_name",
                  // value: form.mda_account_bank_name,
                },
                {
                  label: "Account Type",
                  type: "custom",
                  component: () => (
                    <>
                      <FormLabel>Account Type</FormLabel>
                      <select
                        onChange={({ target: { value } }) => {
                          setForm((p) => ({
                            ...p,
                            mda_account_type: value,
                          }));
                        }}
                        value={form.mda_account_type}
                        name="mda_account_type"
                        className="form-control form-control-sm"
                      >
                        <option value={""}>--select--</option>
                        {accountTypes.map((item, id) => (
                          <option key={id} value={item.title}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </>
                  ),
                  // name: "mda_account_bank_name",
                  // value: form.mda_account_bank_name,
                },
                {
                  label: "Account Number",
                  name: "mda_account_no",
                  value: form.mda_account_no,
                },
                {
                  label: "Sort code",
                  value: form.mda_account_sort_code,
                  name: "mda_account_sort_code",
                },
              ]}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
