import React, { useContext } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  InputGroup,
  Form,
  Dropdown,
  Modal,
} from "@themesberg/react-bootstrap";
import SearchInput from "../../../components/SearchInput";
import { useState } from "react";
import { toast } from 'react-hot-toast';
import { useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { apiURL, deleteApi, fetchApi, postApi } from "../../../redux/api";
import CustomTable from "../../../components/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faEdit,
  faEye,
  faSearch,
  faListUl,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../../routes";
import { formatNumber } from "../../../redux/utils";
import { SearchContext } from "../../HomePage";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { toHaveStyle } from "@testing-library/jest-dom";

function ApprovalCollectionIndex({ match, type = null }) {
  const history = useNavigate();
  const location = useLocation();
  const isPending = location.pathname.includes("payapp");
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
  const [filterText, setFilterText] = useState("");
  const batch_no = useParams().batch_no;
  const [budgetUtilization, setBudgetUtilization] = useState([]);
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState(type || "All");
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const [selectedApproval, setApprovalNo] = useState("");
  const [approvalImageInfo, setApprovalImageInfo] = useState([]);
  const [item, setItem] = useState({});
  const [_delete, setDelete] = useState(false);
  const [_loading, _setLoading] = useState(false);
  const toggleDelete = (item) => {
    setItem(item);
    setDelete(!_delete);
  };

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p);
  };

  const viewAttachment = (imageid, approval_no) => {
    setApprovalNo(approval_no);
    fetchApi(`fetch-approval-images?approval_no=${imageid}`).then((resp) => {
      attachmentModalToggle();
      if (resp.results && resp.results.length) {
        setApprovalImageInfo(resp.results);
      } else {
        setApprovalImageInfo({});
      }
    });
  };

  const getApprovalCollection = useCallback(() => {
    postApi(`post_approval_collection`, {
      form: { query_type: isPending ? "pending" : "by_mda", filter },
      // status : "Paid",
      // batch_no
    })
      .then((data) => {
        console.log("data", data);
        if (data.result) {
          const arr = [];
          data.result.forEach((item) => {
            arr.push({ ...item, isOpen: false });
          });
          setBudgetUtilization(data.result);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      });
  }, [filter, isPending]);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  let list = [];
  budgetUtilization.forEach((item) => {
    if (
      item.approval_no.indexOf(filterText) === -1 &&
      item.mda_name.toLowerCase().indexOf(filterText.toLowerCase()) === -1
    )
      return;
    else list.push(item);
  });
  const [showCommentModal, toggleCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");

  const handleAddCommentClick = () => {
    toggleCommentModal(true);
  };

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleCommentSubmit = () => {
    console.log("Comment submitted:", commentText);

    setCommentText("");
    toggleCommentModal();
  };


  const handleDelete = () => {
    _setLoading(true);
    deleteApi(`delete-approve-collection`, item)
      .then((res) => {
        toast.success("Deleted Successfully");
        _setLoading(false);
        getApprovalCollection();
        toggleDelete({});
      })
      .catch((err) => {
        toast.error(`error occured ${JSON.stringify(err)}`);
        _setLoading(false);
      });
  };

  const fields = [
    {
      title: "Action",
      custom: true,
      component: (item, index) => (
        <Dropdown>
        <Dropdown.Toggle as={Button} variant="primary" className="me-2 bg-success">
          <span>Action</span>
          <FontAwesomeIcon icon={faCaretDown} className="ms-2" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
  <Dropdown.Item onClick={() => viewAttachment(item.imageId, item.approval_no)}>
    <FontAwesomeIcon icon={faEye} className="me-2" /> View Attachment
  </Dropdown.Item>
   {/* <Dropdown.Item
              onClick={() =>
                history(
                  `${Routes.approval.newApproval}/${item.approval_no}`
                )
              }
            >
              <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => toggleDelete(item)}>
              <FontAwesomeIcon icon={faTrash} className="me-2" /> Delete
            </Dropdown.Item> */}
  <Dropdown.Item onClick={handleAddCommentClick}>
    <FontAwesomeIcon icon={faComment} className="me-2" /> Add comment
  </Dropdown.Item>
</Dropdown.Menu>
        </Dropdown>
      ),
    },
    // {
    //   title: 'S/N',
    //   custom: true,
    //   component: (item, index) => <div>{index + 1}</div>,
    // },
    {
      title: "Economic Code",
      value: "economic_code",
    },
    {
      title: "MDA Name",
      value: "mda_name",
    },
    {
      title: "Collection Date",
      value: "collection_date",
    },
    {
      title: "Approval Date",
      value: "approval_date",
    },
    {
      title: "Approval No",
      value: "approval_no",
    },

    {
      title: "Approval Type",
      value: "approved_by",
    },
    {
      title: "Status",
      value: "approval_status",
    },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
    },
  ];
  const getRowStyle = (row) => {
    switch (row.approval_status) {
      case "Approval Released":
      return { backgroundColor: "orange" };
      case "Approval Processed":
        return { backgroundColor: "lightblue" };
      case "Approval Approved":
        return { backgroundColor: "lightgreen" };
      case "Approval Queried":
        return { backgroundColor: "yellow" };
      case "Payment Schedule":
        return { backgroundColor: "green" };
      default:
        return {};
    }
  }

  const finalList = [];
  list.forEach((item) => {
    if (item.mda_name.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1)
      return;
    finalList.push(item);
  });

  useEffect(() => {
    return () => {
      setSearchTerm("");
    };
  }, [setSearchTerm]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <Button
          className="me-2 bg-success"
        >
          Add Auditor
        </Button> */}
        <div className="d-block mb-4 mb-md-0">
          <h4>
            {/* <FontAwesomeIcon icon={faListUl} className="me-2" />  */}
            Auditor Dashboard
          </h4>
        </div>
        {/* {JSON.stringify(searchTerm)} */}
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <SearchInput placeholder="Search for Auditors" value={searchTerm} onChange={({target:{value}}) => setSearchTerm(value)} />

      {/* <div className="d-flex align-items-center">
  <Form className="navbar-search">
    <Form.Group id="topbarSearch">
      <InputGroup className="input-group-merge search-bar">
        <InputGroup.Text>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
        <Form.Control
          value={searchTerm}
          onChange={({ target: { value } }) => setSearchTerm(value)}
          type="text"
          placeholder="Search for batch processing"
        />
      </InputGroup>
    </Form.Group>
  </Form>
</div> */}

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <CustomTable
            fields={fields}
            data={finalList}
            getRowStyle={data => getRowStyle(data)}
          />
        </Card.Body>

        <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
          <Modal.Header onHide={attachmentModalToggle}>
            Approval No.: {selectedApproval}
          </Modal.Header>
          <Modal.Body className="text-center">
            <Container>
              <div className="image-container">
                {approvalImageInfo.length ? (
                  approvalImageInfo.map((item, index) =>
                    imageExtensions.some((extension) =>
                      item.image_url.toLowerCase().endsWith(extension)
                    ) ? (
                      <Card.Img
                        top
                        src={`${apiURL}/uploads/${item.image_url}`}
                        alt={"approval"}
                      />
                    ) : (
                      <Card.Body>
                        <Card.Link
                          href={`${apiURL}/uploads/${item.image_url}`}
                          target="_blank"
                        >
                          View PDF
                        </Card.Link>
                      </Card.Body>
                    )
                  )
                ) : (
                  <h4>No attachment found.</h4>
                )}
              </div>
            </Container>
            {/* <h6>MDA: {approvalImageInfo.mda_name}</h6> */}
          </Modal.Body>
        </Modal>

        <Modal show={_delete} onHide={toggleDelete}>
          <Modal.Header onHide={toggleDelete}>Delete</Modal.Header>
          <Modal.Body className="text-center">
            <h5>Are you sure you want to delete {item.approval_no} </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button outline className="m-1 p-1 px-3" onClick={toggleDelete}>
                Cancel
              </Button>
              <Button
                color="danger"
                className="m-1 p-1 px-3"
                onClick={handleDelete}
                loading={_loading}
              >
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Card>
      <Modal show={showCommentModal} onHide={toggleCommentModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your comment..."
            value={commentText}
            onChange={handleCommentChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={toggleCommentModal}>Cancel</Button>
          <Button variant="primary" onClick={handleCommentSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ApprovalCollectionIndex;