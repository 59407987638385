import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { ModalHeader } from "react-bootstrap";
import CustomTable from "../../../components/CustomTable";
import { formatNumber } from "../../../redux/utils";
import {
  createTokenPin,
  fetchApi,
  postApi,
  verifyToken,
} from "../../../redux/api";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes";
import Cards from "react-credit-cards";
import toast from "react-hot-toast";
// import toast from "react-hot-toast";
import CustomButton from "../../../components/CustomButton";
import { bankList } from "../../accounts/AccountForm";
import { OAGOFFICE } from "../pv-collection/PVReviewIndex";

export default function PendingCashBacking({ type = null }) {
  const [selected, setSelected] = useState({});
  const [pin, setPin] = useState("");
  const [item, setItem] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const toggle = () => setModalIsOpen((p) => !p);
  const toggle1 = () => setModalIsOpen1((p) => !p);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState("name");
  const [filterText, setFilterText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState(type || "All");
  const [budgetUtilization, setBudgetUtilization] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const history = useNavigate();
  const [accountInfo, setAccountInfo] = useState([]);
  const [confirmationModalOpen, setConfirmationModal] = useState(false);

  const getApprovalCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: "cb_by_mda",
        filter: "Scheduled",
        mda_name: user ? user.mda_name : "",
      },
    })
      .then((data) => {
        if (data.result) {
          const arr = data.result.map((item) => ({ ...item, isOpen: false }));
          setBudgetUtilization(arr);
        }
      })
      .catch((err) => {
        console.error("Error fetching approval collection:", err);
      })
      .finally(() => setLoading(false));
  }, [filter]);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  let list = [];
  budgetUtilization.forEach((item) => {
    if (
      item.approval_no.indexOf(filterText) === -1 &&
      item.approval_no.toString().indexOf(filterText.toLowerCase()) === -1
    )
      return;
    else list.push(item);
  });

  const handleCashBackedAmountChange = (row, value) => {
    const newData = [];
    budgetUtilization.forEach((item) => {
      if (
        item.approval_no === row.approval_no &&
        item.mda_code === row.mda_code &&
        item.economic_code === row.economic_code
      ) {
        newData.push({ ...item, cb_amount: value, valueChanged: true });
      } else {
        newData.push(item);
      }
    });
    setBudgetUtilization(newData);
    // const updatedCashBackedAmounts = [...cashBackedAmounts];
    // updatedCashBackedAmounts[index] = value;
    // setCashBackedAmounts(updatedCashBackedAmounts);
  };

  const fields = [
    {
      title: "Approval No",
      value: "approval_no",
    },
    // {
    //   title: "Collection Date",
    //   value: "collection_date",
    // },
    {
      title: "Approval Date",
      value: "approval_date",
    },

    {
      title: "MDA Name",
      value: "mda_name",
    },
    {
      title: "Vote Charged",
      // value: "mda_code",
      custom: true,
      component: (item) => (
        <div className="text-end">
          {item.mda_code} /{" "}
          {item?.economic_code ? item.economic_code : item.program_code}
        </div>
      ),
    },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
    },
    {
      title: "Cash Backed Amount",
      custom: true,
      component: (item, index) => (
        <div style={{ width: "70%" }}>
          <Form className="navbar-search">
            <Form.Group id={`cashBackedAmount_${index}`}>
              <InputGroup className="input-group-merge search-bar">
                <Form.Control
                  value={item.cb_amount}
                  onChange={(e) =>
                    handleCashBackedAmountChange(item, e.target.value)
                  }
                  type="number"
                  placeholder="0.00"
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
      ),
    },
    // {
    //   title: "Action",
    //   custom: true,
    //   component: (item) => (
    //     <div className="text-end">
    //       <ButtonGroup>
    //         <Button
    //           variant="outline-primary"
    //           size="sm"
    //           onClick={() =>
    //             history(
    //               `${Routes.cashBacking.processCashBacking}/${item.approval_no}`
    //             )
    //           }
    //         >
    //           Process
    //         </Button>
    //       </ButtonGroup>
    //     </div>
    //   ),
    // },
  ];

  const modalFields = [
    {
      title: "Approval No",
      value: "approval_no",
    },
    // {
    //   title: "Collection Date",
    //   value: "collection_date",
    // },
    {
      title: "Approval Date",
      value: "approval_date",
    },

    {
      title: "MDA Name",
      value: "mda_name",
    },
    {
      title: "Vote Charged",
      // value: "mda_code",
      custom: true,
      component: (item) => (
        <div className="text-end">
          {item.mda_code} /{" "}
          {item?.economic_code ? item.economic_code : item.program_code}
        </div>
      ),
    },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
    },
    {
      title: "Cash Backed Amount",
      component: (item) => (
        <div className="text-end">{formatNumber(item.cb_amount)}</div>
      ),
      // custom: true,
      // component: (item, index) => (
      //   <div style={{ width: "70%" }}>
      //     <Form className="navbar-search">
      //       <Form.Group id={`cashBackedAmount_${index}`}>
      //         <InputGroup className="input-group-merge search-bar">
      //           <Form.Control
      //             value={item.cb_amount}
      //             onChange={(e) =>
      //               handleCashBackedAmountChange(item, e.target.value)
      //             }
      //             type="number"
      //             placeholder="0.00"
      //           />
      //         </InputGroup>
      //       </Form.Group>
      //     </Form>
      //   </div>
      // ),
    },
  ];

  const finalList = [];
  list.forEach((item) => {
    if (item.approval_no.toString().indexOf(searchTerm) === -1) return;
    finalList.push(item);
  });

  const getCard = useCallback(() => {
    setLoading(true);
    fetchApi(`get-details/by-type?type=MAIN ACCOUNT`)
      .then(async (resp) => {
        setLoading(false);
        console.log(resp);
        if (resp && resp.success) {
          let arr = [];
          resp.data.forEach((item) => {
            let selected = bankList.find((i) => i.code === item.bank_code);
            arr.push({
              ...item,
              bankName: selected.title,
            });
          });
          setAccountInfo(arr);
        }
      })
      .catch((err) => {
        setLoading(false);
        // setDetails(err.details);
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getCard();
  }, [getCard]);

  const [balanceLoading, setBalanceLoading] = useState(false);
  const getBalance = useCallback(
    async (id) => {
      setBalanceLoading(true);
      fetchApi(`autopay/balance/${id}/${createTokenPin({ vPinToken: pin })}`)
        .then(async (resp) => {
          setBalanceLoading(false);
          if (resp && resp.success) {
            let decoded = await verifyToken(resp.pan_info);
            setSelected({ ...resp, ...decoded });
            setPin("");
            setModalIsOpen1(false);
            setModalIsOpen(true);
          } else {
            toast.error(resp.message);
          }
        })
        .catch((err) => {
          setBalanceLoading(false);
          toast.error(err.details.error.message);
          toast.error("Error While getting the balance please try again");
          console.log(err);
        });
    },
    [pin]
  );

  const [loading1, setLoading1] = useState(false);
  const handleSubmit = async () => {
    setLoading1(true);
    const updatedList = budgetUtilization.filter((item) => item.valueChanged);
    const paymentResp = await fetchApi(
      "number-generator?query_type=select&description=payment"
    );

    if (
      paymentResp.success &&
      paymentResp.results &&
      paymentResp.results.length
    ) {
      const scheduleId = paymentResp.results[0].next_code;
      console.log(updatedList, "updatedList");
      for (let item of updatedList) {
        await postApi(`post_approval_collection`, {
          form: {
            query_type: "update_cash_backing",
            approval_status: "Approved",
            // item.mda_name === OAGOFFICE ? "Approved" : "Requested",
            approved_amount: item.cb_amount,
            mda_code: item.mda_code,
            mda_economic_code: item.economic_code,
            approval_no: item.approval_no,
          },
        });

        await postApi("update-approval", {
          query_type: "create_payment_request",
          remarks: scheduleId,
          status: "Payment Raised",
          approval_no: item.approval_no,
          userId: user.username,
          mda_name: user ? user.mda_name : "",
        });
      }

      setTimeout(async () => {
        await postApi("number-generator", {
          query_type: "update",
          description: "payment",
          code: scheduleId,
        });
        getApprovalCollection();
        setConfirmationModal(false);
        toast.success("Success");
        setLoading1(false);
      }, 2000);
    }

    // history(Routes.cashBacking.cashBackinPending);
  };

  const totalCashbackingAmount = finalList
    .filter((item) => item.valueChanged)
    .reduce((a, b) => a + parseFloat(b.cb_amount), 0);
  let d = verifyToken(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjYXJkIjoiNjI4MDUxMDEwNzYyOTgzMTAyMyIsInBpbiI6IjE5NjciLCJleHBpcnlfZGF0ZSI6IjI3MDUiLCJjdnYyIjoiMDAwIiwiaWF0IjoxNzIyNDM1NjgyfQ.CvcfhToTDb0zOfKLHFdn23dYfVu5mK7I7miQocw-edI"
  );
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Pending Cash Backing</h4>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          {/* {JSON.stringify(d)} */}
          <ButtonGroup>
            {accountInfo.map((item) => (
              <>
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => {
                    setItem(item);
                    toggle1();
                    // getPin()
                  }}
                >
                  {item.account_name} ({item.bankName})
                </Button>
              </>
            ))}
          </ButtonGroup>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginBottom: 10,
        }}
      >
        <Form className="navbar-search">
          <Form.Group id="topbarSearch">
            <InputGroup className="input-group-merge search-bar">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
                type="text"
                placeholder="Search"
              />
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body
          className="p-0"
          style={{ height: "60vh", overflow: "scroll" }}
        >
          {/* {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : ( */}
          <CustomTable fields={fields} data={finalList} />

          <Modal
            size="xl"
            onHide={() => setConfirmationModal((p) => !p)}
            show={confirmationModalOpen}
          >
            <Modal.Header>
              <h4>Confirm Cash Backing</h4>
            </Modal.Header>
            <Modal.Body>
              <CustomTable
                fields={modalFields}
                data={finalList.filter((item) => item.valueChanged)}
              />

              <h5 className="text-right">
                Total: N{formatNumber(totalCashbackingAmount)}
              </h5>
            </Modal.Body>
            <Modal.Footer>
              {finalList.filter((item) => item.valueChanged).length ? (
                <CustomButton loading={loading1} onClick={handleSubmit}>
                  Continue
                </CustomButton>
              ) : null}
            </Modal.Footer>
          </Modal>
        </Card.Body>

        {finalList.filter((item) => item.valueChanged).length ? (
          <Card.Footer className="text-center pb-2 pt-0">
            <Button
              style={{
                marginTop: 20,
              }}
              onClick={() => setConfirmationModal(true)}
            >
              Raise CashBacking
            </Button>
          </Card.Footer>
        ) : null}
      </Card>

      <Modal show={modalIsOpen}>
        <Modal.Header>Cash Backing</Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div
                onClick={() => setFocus((p) => (p === "cvc" ? "name" : "cvc"))}
              >
                <Cards
                  cvc={selected.cvv2 || "000"}
                  expiry={selected.expiry_date}
                  focused={focus}
                  name={selected.account_name?.slice(0, 30)}
                  number={selected.card}
                  // preview={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              <h6 className="text-center">
                Account: {selected.account_name}({selected.account_number})
              </h6>
              <h6 className="text-center">
                <b> {item.bankName}</b>
              </h6>
            </Col>
          </Row>
          <h5 className="text-center">{selected.balance}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={toggle}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalIsOpen1}>
        <Modal.Header>Confirmation</Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="mt-2">
              <h5 className="text-center">
                Account: {item.account_name}({item.account_number})
              </h5>
            </Col>
            <Row className="text-center">
              <div className=" pb-1 mb-1">
                <input
                  onChange={({ target: { value } }) => {
                    setPin(value);
                  }}
                  value={pin}
                  name="pin-main"
                  type="password"
                  required
                  maxLength="4"
                  className="form-control"
                  placeholder="Enter PIN"
                />
              </div>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" className="bg-danger" onClick={toggle1}>
            Close
          </Button>
          <CustomButton
            loading={balanceLoading}
            size="sm"
            onClick={() => {
              getBalance(item.id);
            }}
          >
            Confirm
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}
