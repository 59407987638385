
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, InputGroup } from '@themesberg/react-bootstrap'
import React from 'react'

const SearchInput = ({ value, onChange =f=>f, placeholder="Search" }) => {
  return (
    <Form className="navbar-search my-1">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control
                    value={value}
                    onChange={onChange}
                    type="text"
                    placeholder={placeholder}
                  />
                </InputGroup>
              </Form.Group>
            </Form>
  )
}

export default SearchInput