import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup } from "@themesberg/react-bootstrap";

import TsaFundList from "./FundList";
import {  useNavigate, useParams } from "react-router-dom";
import { fetchApi } from "../../../redux/api";
import { Routes } from "../../../routes";

function FaacTsaFunding({ match }) {
  const history = useNavigate();
  const batch_no = useParams().batch_no;
  const { types } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [tsaFundingList, setTsaFundingList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTsaAccounts = useCallback(() => {
    setLoading(true); // Set loading state to true when fetching data
    fetchApi(`tsa-account?query_type=summary&types=${types}`)
      .then((data) => {
        if (data.results) {
          setTsaFundingList(data.results);
          setLoading(false); // Set loading state to false when data is fetched
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false); // Set loading state to false on error
      });
  }, [types]);

  useEffect(() => {
    getTsaAccounts();
  }, [getTsaAccounts]);

  let rows = [];

  tsaFundingList &&
    tsaFundingList.forEach((item) => {
      if (
        item.funding_code &&
        item.funding_code.toLowerCase().indexOf(searchTerm) === -1 &&
        item.total_amount.indexOf(searchTerm) === -1 &&
        item.total_amount.toLowerCase().indexOf(searchTerm) === -1
      ) {
        return;
      }

      rows.push(item);
    });
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Button
          className="me-2 bg-success border-none"
          onClick={() =>
            history(`${Routes.tsafunding.newfunding}/${types}`)
          }
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Funding
        </Button>
        <div className="d-block mb-4 mb-md-0">
          <h4>{types}</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <TsaFundList list={rows} isLoading={loading} />{" "}
      {/* Pass loading state to TsaFundList */}
    </>
  );
}

export default FaacTsaFunding;
