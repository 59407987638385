import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Dropdown,
  Modal,
} from "@themesberg/react-bootstrap";

import { useState } from "react";
import { useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { apiURL, postApi } from "../../redux/api";
import { Routes } from "../../routes";
import CustomTable from "../../components/CustomTable";
import { formatNumber } from "../../redux/utils";

function PSDashboard() {
  const history = useNavigate();
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
  const [pvList, setPvList] = useState([]);
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const [selectedPV, setSelectedPvNo] = useState("");
  const [pvImageInfo, setPvImageInfo] = useState([]);
  const [item, setItem] = useState({});
  const [_delete, setDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleDelete = (item) => {
    setItem(item);
    setDelete(!_delete);
  };

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p);
  };

  const getPvCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_pv_collection`, {
      form: {
        query_type: "pending_by_approval",
        pv_status: "Commissioner Approved",
      },
    })
      .then((data) => {
        console.log("data", data);
        if (data.result) {
          const arr = [];
          data.result.forEach((item) => {
            arr.push({ ...item, isOpen: false });
          });
          setPvList(data.result);
        }
      })

      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getPvCollection();
  }, [getPvCollection]);

  const fields = [
    {
      title: "Action",
      custom: true,
      component: (item, index) => (
        <Button
          onClick={() =>
            history(
              `${Routes.commissioner_dashboard.PSDashboardPV}/${item.approval_no}`
            )
          }
        >
          View
        </Button>
      ),
    },
    {
      title: "Approval No.",
      value: "approval_no",
      style: { textAlign: "center" },
    },
    {
      title: "Number of Vouchers",
      custom: true,
      component: (item) => <div>{item.no_of_pvs}</div>,
      style: { textAlign: "center" },
    },
    {
      title: "Status",
      value: "status",
      style: { textAlign: "center" },
    },
    {
      title: "Amount (N)",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>PS Dashboard</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body
          className="p-0"
          style={{ height: "90vh", overflow: "scroll" }}
        >
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <CustomTable fields={fields} data={pvList} />
          )}
        </Card.Body>

        <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
          <Modal.Header onHide={attachmentModalToggle}>
            PV No.: {selectedPV}
          </Modal.Header>
          <Modal.Body className="text-center">
            <Container>
              <div className="image-container">
                {pvImageInfo.length ? (
                  pvImageInfo.map((item, index) =>
                    imageExtensions.some((extension) =>
                      item.image_url.toLowerCase().endsWith(extension)
                    ) ? (
                      <Card.Img
                        top
                        src={`${apiURL}/uploads/${item.image_url}`}
                        alt={"pv"}
                      />
                    ) : (
                      <Card.Body>
                        <Card.Link
                          href={`${apiURL}/uploads/${item.image_url}`}
                          target="_blank"
                        >
                          View PDF
                        </Card.Link>
                      </Card.Body>
                    )
                  )
                ) : (
                  <h4>No attachment found.</h4>
                )}
              </div>
            </Container>
          </Modal.Body>
        </Modal>

        <Modal show={_delete} onHide={toggleDelete}>
          <Modal.Header onHide={toggleDelete}>Delete</Modal.Header>
          <Modal.Body className="text-center">
            <h5>Are you sure you want to delete {item.pv_code} </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button outline className="m-1 p-1 px-3" onClick={toggleDelete}>
                Cancel
              </Button>
              <Button color="danger" className="m-1 p-1 px-3" loading={loading}>
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Card>
    </>
  );
}

export default PSDashboard;
