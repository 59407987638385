import React, { useState, useEffect, useContext } from 'react'
import PaymentAdvicePDFTemplate from './pdf-templates/pay-advice'
import { useSelector } from 'react-redux'
import { postApi } from '../../../redux/api'
import { useParams } from 'react-router-dom'
const COL1_WIDTH = 40
const COL_AMT_WIDTH = 20
const COLN_WIDTH = (100 - (COL1_WIDTH + COL_AMT_WIDTH)) / 2

export default function PaymentAdvicePDF({ match }) {
  const user = useSelector((state) => state.auth.user)
  const batch_no = useParams().batch_no
  const [remittance, setRemittance] = useState([])
  const [
    tresuryDetails,
    // setTresuryDetails
  ] = useState({})

  const [mdaDetails, setMdaDetails] = useState([])

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: 'select_distinct',
      status: 'committed',
      batch_no,
      type: user.role,
    })
      .then((data) => {
        console.log('select_distinct', data)
        if (data.result) {
          const Rem = data.result
          console.log([Rem[0]], 'yyyyyyyyyyyyyyyyyyyyyyyyyyyyyy')
          const newArr = Rem.filter(
            (item, idx) => item.description === null,
          ).slice(0, 1)
          setRemittance([Rem[0]])
        }
      })

      .catch((err) => {
        console.log(err)
      })
  }, [batch_no, user.role])

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: 'select_distinct_mda_info',
      status: 'committed',
      batch_no,
    })
      .then((data) => {
        console.log('ggy', data)
        if (data.result) {
          setMdaDetails(data.result)
          console.log('ggy', data)
          // mda()
        }
      })

      .catch((err) => {
        console.log(err)
      })
  }, [batch_no])

  return (
    <>
      <PaymentAdvicePDFTemplate
        tresuryDetails={tresuryDetails}
        mdaDetails={mdaDetails}
        remittance={remittance}
      />
    </>
  )
}
