import React from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";

import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { postApi } from "../../../redux/api";
import CustomTable from "../../../components/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-hot-toast";
import {
  faBook,
  faChartLine,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import BackButton from "../../../components/BackButton";
import { Routes } from "../../../routes";
// import PaymentModal from './PaymentModal'
import { MODULE_TYPES, formatNumber } from "../../../redux/utils";
import CustomButton from "../../../components/CustomButton";

function STReceivingOfficeViewPayment({
  match,
  pageType = "batch",
  type = null,
}) {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  const [deleteAllWarningModalIsOpen, setDeleteAllWarningModalOpen] =
    useState(false);
  const [deleteSignleWarningModalIsOpen, setDeleteSignleWarningModalOpen] =
    useState(false);
  const toggleDeleteAll = () => setDeleteAllWarningModalOpen((p) => !p);
  const toggleDeleteSingle = () => setDeleteSignleWarningModalOpen((p) => !p);
  const [itemToDelete, setItemToDelete] = useState("");
  const [_loading, _setLoading] = useState(false);
  const isReceivingOffice = location.pathname.includes("receiving-office");
  const isCheckingOffice = location.pathname.includes("checking-office");
  const isHeadChequesOffice = location.pathname.includes("head-cheques-office");
  const isDSTOffice = location.pathname.includes("dst-office");

  const history = useNavigate();
  const batch_no = useParams().batch_no;
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([]);
  const [tresuryDetails, setTresuryDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [submitting, setSubmitting] = useState(false)
  const [changeAmount, setChangeAmount] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal((p) => !p);

  const [form, setForm] = useState({
    batch_number: "",
    date: "",
    no_of_mda: "",
    no_of_payments: "",
    total_amount: "",
    cheque_number: "",
    query_type: "insert",
    status: "uncommited",
    amount: "",
  });
  const isST = user.role === "sub_treasury";
  const isUpdatePage = pageType === "update";
  const isCommitPage = pageType === "commit";

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: "select_by_batch_no",
      status: isReceivingOffice
        ? "Director Expenditure Control Approved"
        : isCheckingOffice
        ? "Schedule Approved By Receiving Office"
        : isHeadChequesOffice
        ? "Schedule Approved By DST"
        : "",
      batch_no,
      // type: user.role,
    })
      .then((data) => {
        console.log("data", data);
        if (data.result && data.result.length) {
          setPaymentScheduleTable(data.result);
          setTresuryDetails(data.result[0]);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      });
  }, [batch_no, isReceivingOffice]);

  const handleApi = () => {
    setSubmitting(true)
    // if (isST) {
    postApi(`post_contractor_payment_schedule_array`, {
      paymentScheduleTable,
      query_type: "update_schedule",
      status: isReceivingOffice
        ? "Schedule Approved By Receiving Office"
        : isCheckingOffice
        ? "Schedule Approved By Checking Office"
        : isHeadChequesOffice
        ? "Schedule Approved By Head Cheques Office"
        : "Approved",
      type: user.role,
    })
      .then((data) => {
        if (data.success) {
          toast.success("Payment Approved Successfully");
          if (isReceivingOffice) {
            history(`${Routes.subTreasury.receivingOfficeIndex}`);
          } else if (isCheckingOffice) {
            history(`${Routes.subTreasury.checkingOfficeIndex}`);
          } else if (isDSTOffice) {
            history(`${Routes.subTreasury.dstOfficeIndex}`);
          }
        }
      })

      .catch((err) => {
        toast.error(err);
      }).finally(() => setSubmitting(false))
    // } else {
    //   postApi(`post_payment_schedule_array`, {
    //     paymentScheduleTable,
    //     query_type: 'update',
    //     // status:isExpControl ? "Director Expenditure Control Approved" : isDAGOffice ? 'DAG Approved' : 'auditor_approved',
    //     type: user.role,
    //   })
    //     .then((data) => {
    //       if (data.success) {
    //         toast.success('Payment Approved Successfully')
    //         // if(isExpControl) {
    //         //   history(Routes.expenditureControl.dirExpPaymentScheduleIndex)
    //         // } else if (isDAGOffice) {
    //         //   history(Routes.dag.dagIndex)
    //         // }
    //       }
    //     })

    //     .catch((err) => {
    //       toast.error(err)
    //     })
    // }
  };

  const handleAllReturned = () => {
    if (isST) {
      postApi(`post_contractor_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: "update",
        status: "remove",
        type: user.role,
        contractor_code: batch_no,
      })
        .then((data) => {
          if (data.success) {
            console.log(data);
            history(-2);
          }
        })

        .catch((err) => {
          console.log(err);
          toast.success("An error occurred");
        });
    } else {
      postApi(`post_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: "update",
        status: "remove",
        type: user.role,
        contractor_code: batch_no,
      })
        .then((data) => {
          if (data.success) {
            console.log(data);
            history(-2);
          }
        })

        .catch((err) => {
          console.log(err);
          toast.error("An error occurred");
        });
    }
  };

  const submitIndividualItem = (id) => {
    const newArray = paymentScheduleTable.filter((itm, idx) => itm.id === id);
    if (isST) {
      postApi(`post_contractor_payment_schedule_array`, {
        paymentScheduleTable: newArray,
        query_type: "update",
        type: user.role,
        status: "remove",
      })
        .then((data) => {
          if (data.success) {
            toast.success("Deleted Successfully");
            history(-2);
          }
        })

        .catch((err) => {
          toast.error(err);
        });
    } else {
      console.log("newArray", newArray);
      postApi(`post_payment_schedule_array`, {
        paymentScheduleTable: newArray,
        query_type: "update",
        type: user.role,
        status: "remove",
      })
        .then((data) => {
          if (data.success) {
            console.log(data);
            history(-2);
          }
        })

        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const handleChange = (id, name, value, e) => {
    // const {name, value} = e.target

    // const value1 = addCommas(removeNonNumeric(value))
    // const value1 = addCommas(value)
    const newArray = [];

    paymentScheduleTable.forEach((itm, idx) => {
      if (itm.id === id) {
        newArray.push({ ...itm, [name]: value });
      } else {
        newArray.push(itm);
      }
    });

    setPaymentScheduleTable(newArray);
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;

    setForm((p) => ({ ...p, [name]: value }));
  };

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: "select_payment_list",
      status: isCommitPage ? "auditor_approved" : "pending",
      batch_no,
      type: user.role,
    })
      .then((data) => {
        console.log("gg", data);
        if (data.result) {
          setPaymentDetails(data.result);
          const formData = data.result[0];
          setForm((p) => ({ ...p, ...formData }));
          // setPaymentScheduleTable(data.result);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      });
  }, [batch_no, user.role, isCommitPage]);

  const updatePaymentSchedule = () => {
    if (isST) {
      postApi(`post_contractor_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: "update_by_batch_no",
        status: "Committed",
        cheque_number: form.cheque_number,
        arabic_date: form.arabic_date,
        type: user.role,
      })
        .then((data) => {
          if (data.success) {
            console.log("hell", data);
            // setOpenPdf(true)
            // if (type === MODULE_TYPES.MAIN_TREASURY) {
            //   history(`${Routes.payments.viewPDF}/${batch_no}`)
            // } else if (type === MODULE_TYPES.SUB_TREASURY) {
            history(`${Routes.payments.viewStPDF}/${batch_no}`);
            // }
          }
        })

        .catch((err) => {
          toast.error(err);
        });
    } else {
      postApi(`post_payment_schedule_array`, {
        paymentScheduleTable,
        query_type: "update_by_batch_no",
        status: "Committed",
        cheque_number: form.cheque_number,
        arabic_date: form.arabic_date,
        type: user.role,
      })
        .then((data) => {
          if (data.success) {
            console.log("hell", data, type);
            // setOpenPdf(true)
            if (type === MODULE_TYPES.MAIN_TREASURY) {
              history(`${Routes.payments.viewPDF}/${batch_no}`);
            } else if (type === MODULE_TYPES.SUB_TREASURY) {
              history(`${Routes.payments.viewStPDF}/${batch_no}`);
            }
          }
        })

        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const submitChequeNo = () => {
    postApi(`post_check_details`, {
      form,
      type: user.role,
    })
      .then((data) => {
        updatePaymentSchedule();
        if (data.success) {
          console.log("hello", data);

          // toggle()
          // history("/dashboard/intAudit")
        }
      })

      .catch((err) => {
        toast.error(err);
      });
  };

  const fields = [
    // {
    //   title: 'S/N',
    //   custom: true,
    //   component: (item, idx) => <span>{idx + 1}</span>,
    // },
    {
      title: "PV Code",
      custom: true,
      component: (item, idx) => <span>{item.pv_code}</span>,
    },
    {
      title: "MDA",
      value: "mda_name",
      //   className: 'text-center',
    },
    {
      title: "Description",
      custom: true,
      component: (item) => (
        <span>{isST ? item.project_description : item.description}</span>
      ),
    },
    {
      title: "Approval",
      tableHeadStyle: { textAlign: "right", color: "black" },
      custom: true,
      component: (item) => (
        <>
          <label style={{ float: "left" }}>{item.approval_no}</label>
          {item.approval_no === "No Approval" ? (
            <Form.Check
              type="checkbox"
              name="approval"
              value="skipped"
              onChange={(e) =>
                handleChange(item.id, e.target.name, e.target.value, e)
              }
            />
          ) : null}
        </>
      ),
    },
    // {
    //   title: 'Attachment',
    //   className: 'text-center',
    //   custom: true,
    //   // hidden: isST,
    //   component: (item) => (
    //     <>
    //       {item.attachment}
    //       {item.attachment === 'No Attachment' ? (
    //         <Form.Check
    //           type="checkbox"
    //           name="attachment"
    //           value="skipped"
    //           checked={item.attachment === 'skipped'}
    //           onChange={(e) =>
    //             handleChange(item.id, e.target.name, e.target.value, e)
    //           }
    //         />
    //       ) : null}
    //     </>
    //   ),
    // },
    // {
    //   title: 'Budget',
    //   // hidden: isST,
    //   tableHeadStyle: { textAlign: 'right', color: 'black' },
    //   custom: true,
    //   component: (item) => (
    //     <>
    //       {item.budget}
    //       {item.budget === 'No Budget' || item.budget === 'Budget Exausted' ? (
    //         <Form.Check
    //           type="checkbox"
    //           name="budget"
    //           value="skipped"
    //           onChange={(e) =>
    //             handleChange(item.id, e.target.name, e.target.value, e)
    //           }
    //         />
    //       ) : null}
    //     </>
    //   ),
    // },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">
          {!changeAmount ? (
            parseFloat(item.amount).toLocaleString("en")
          ) : (
            <Form.Control
              type="text"
              name="amount"
              value={item.amount}
              onChange={(e) =>
                handleChange(item.id, e.target.name, e.target.value, e)
              }
            />
          )}
        </div>
      ),
    },
    {
      title: "Action",
      tableHeadStyle: { textAlign: "right", color: "black" },
      custom: true,
      component: (item) => (
        <>
          {isUpdatePage ? (
            <>
              <Button
                size="sm"
                onClick={() =>
                  history(
                    `${Routes.batchProcessing.processUpdate}/${item.id}?imageId=${item.imageId}`
                  )
                }
              >
                Update
              </Button>
            </>
          ) : (
            <>
              <Button
                className="me-1"
                size="sm"
                onClick={() => {
                  history(
                    `${Routes.pv.pvIndividualView}/${item.approval_no}?approval_type=${item.approval_type}&pv_code=${item.pv_code}`
                  );
                }}
              >
                PV
              </Button>
              <Button
                className="me-1 btn-warning"
                size="sm"
                style={
                  {
                    // backgroundColor: "yellow",
                    // border: "none",
                    // height: "60%",
                    // //   padding: 5,
                    // paddingLeft: 10,
                    // paddingRight: 10,
                    // borderRadius: 10,
                    // cursor: "pointer",
                  }
                }
                onClick={() =>
                  history(
                    `${Routes.cashBacking.releaseCollectionPDF}/${item.approval_no}`
                  )
                }
              >
                Release
              </Button>
              <Button
                className="me-1"
                size="sm"
                disabled
                // onClick={() => {setItemToDelete(item.id); toggleDeleteSingle()}}
              >
                Attachment
              </Button>
              <Button
                className="btn-danger"
                size="sm"
                onClick={() => {
                  setItemToDelete(item.id);
                  toggleDeleteSingle();
                }}
              >
                Return
              </Button>
              {user.role === "sub_treasury" ? (
                <Button
                  className="btn-success mx-1"
                  size="sm"
                  onClick={() => {
                    if (isST) {
                      history(
                        `${Routes.paymentSchedule.viewStScheduleDetails}/${item.contract_code}`
                      );
                    } else {
                      history(
                        // `${Routes.paymentSchedule.view}/${item.id}`
                        `/dashboard/view_individual_batch/${item.id}`
                      );
                    }
                  }}
                >
                  View
                </Button>
              ) : null}
            </>
          )}
        </>
      ),
    },
  ];

  const totalAmount = paymentScheduleTable.reduce(
    (a, b) => a + parseFloat(b.amount),
    0
  );

  return (
    <>
      <Row>
        <Col md={3} className="">
          <BackButton />
        </Col>
        <Col md={6}>
          <h4 className="text-center">
            <FontAwesomeIcon icon={faBook} className="me-2" />{" "}
            {isUpdatePage || isCommitPage
              ? "Payment List"
              : "Review Payment Schedules"}
          </h4>
        </Col>
      </Row>
      {/* {JSON.stringify(type)} */}
      {isUpdatePage ? null : (
        <Card className="p-2">
          <Row>
            <Col md={6}>
              {/* {isST ? null : (
                <h6>Bank Name : {tresuryDetails.treasury_bank_name}</h6>
              )} */}

              <h6>Batch No. : {batch_no}</h6>
              {
                //<h6>Balance :</h6>
              }
            </Col>
            <Col md={6}>
              <h6>Total Amount : {formatNumber(totalAmount)}</h6>
            </Col>
          </Row>
        </Card>
      )}
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <CustomTable
            size="sm"
            fields={fields}
            data={paymentScheduleTable}
            // currentPage={currentPage}
            // handlePageChange={handlePageChange}
          />
        </Card.Body>
        {isUpdatePage ? null : (
          <Card.Footer>
            <center>
              {isCommitPage ? null : (
                <Button
                  className="btn-danger me-3 "
                  onClick={() => {
                    setDeleteAllWarningModalOpen(true);
                  }}
                >
                  Return
                </Button>
              )}{" "}
              <CustomButton
                className="" loading={submitting}
                onClick={isCommitPage ? toggle : handleApi}
              >
                <FontAwesomeIcon icon={faCheck} className="me-2" />
                {isCommitPage ? "Continue" : "Approve Schedule"}
              </CustomButton>
            </center>
          </Card.Footer>
        )}
      </Card>
      {/* <PaymentModal
        toggle={toggle}
        submitChequeNo={submitChequeNo}
        modal={modal}
        paymentDetails={paymentDetails}
        form={form}
        handleChange={handleChange1}
      /> */}
      {/* <Modal show={deleteAllWarningModalIsOpen} onHide={toggleDeleteAll}>
          <Modal.Header onHide={toggleDeleteAll}>Return</Modal.Header>
          <Modal.Body className="text-center">
            <h5>Are you sure you want to return all the items in this schedule? </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button outline className="m-1 p-1 px-3" onClick={toggleDeleteAll}>
                Cancel
              </Button>
              <Button
                color="danger"
                className="m-1 p-1 px-3"
                onClick={handleAllReturned}
                loading={_loading}
              >
                Return
              </Button>
            </div>
          </Modal.Footer>
        </Modal> */}
      {/* <Modal show={deleteSignleWarningModalIsOpen} onHide={toggleDeleteSingle}>
          <Modal.Header onHide={toggleDeleteSingle}>Return</Modal.Header>
          <Modal.Body className="text-center">
            <h5>Are you sure you want to return this item? </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              
              <Button outline className="btn-primary m-1 p-1 px-3" onClick={toggleDeleteAll}>
                Cancel
              </Button>
              <Button
                color="danger"
                className="m-1 p-1 px-3"
                onClick={() => submitIndividualItem(itemToDelete)}
                loading={_loading}
              >
                Return
              </Button>
            </div>
          </Modal.Footer>
        </Modal> */}
    </>
  );
}

export default STReceivingOfficeViewPayment;
