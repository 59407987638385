import Payment from "payment";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function checkStrEmpty(str) {
  return !(str && str.length > 1 && str.split(" ").join("").length > 0);
}

export function formatNumber(n = 0) {
  if (typeof n !== "number" && typeof n !== "string") {
    return "0";
  }

  // Convert n to a number if it's a string and round it to a certain number of decimal places
  n = parseFloat(n);

  if (isNaN(n)) {
    return "0";
  }

  // Format the number with commas for thousands and round it to two decimal places
  const formattedNumber = n.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedNumber;
}

// export function formatNumber(n) {
//   let num = Math.round(parseInt(n), 0);
//   if (num) {
//     return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
//   } else {
//     return "0";
//   }

//   // Convert n to a number if it's a string and round it to a certain number of decimal places
//   n = parseFloat(n);

//   if (isNaN(n)) {
//     return "0";
//   }

//   // Format the number with commas for thousands and round it to two decimal places
//   const formattedNumber = n.toLocaleString(undefined, {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   });

//   return formattedNumber;
// }

export function selecOffer(n) {
  let num = Math.round(parseInt(n), 0);
  if (num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "0";
  }
}

export const getColor = (val) => {
  console.log(val);
  if (parseInt(val) >= 70 && parseInt(val) < 85) {
    return "yellow";
  } else if (parseInt(val) >= 85 && parseInt(val) < 99) {
    return "orange";
  } else if (parseInt(val) >= 99) {
    return "red";
  }
};

export const MODULE_TYPES = {
  MAIN_TREASURY: "MAIN_TREASURY",
  SUB_TREASURY: "SUB_TREASURY",
};

export const padAccountNo = (val) => {
  if (val === "N/A") {
    return val;
  } else {
    return val.padStart(10, "0");
  }
};
