import React, { Component } from "react";
import "./excel.css";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { useNavigate, useParams, withRouter } from "react-router-dom";
import { _postApi } from "../../../redux/Functions";
import { toast } from 'react-hot-toast';
import { apiURL } from "../../../redux/api";
import {
  Button,
  Card,
  Col,
  Container,
  Fade,
  FormGroup,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Input } from "react-bootstrap-typeahead";

class UploadBudgetSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dataLoaded: false,
      isFormInvalid: false,
      loadSpinner: false,
      rows: null,
      cols: null,
    };
    this.fileHandler = this.fileHandler.bind(this);
    this.toggle = this.toggle.bind(this);
    this.openFileBrowser = this.openFileBrowser.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.fileInput = React.createRef();
  }

  renderFile = (fileObj) => {
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({
          dataLoaded: true,
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  fileHandler = (event) => {
    if (event.target.files.length) {
      let fileObj = event.target.files[0];
      let fileName = fileObj.name;

      //check for file extension and pass only if it is .xlsx and display error message otherwise
      if (fileName.slice(fileName.lastIndexOf(".") + 1) === "xlsx") {
        this.setState({
          uploadedFileName: fileName,
          isFormInvalid: false,
        });
        this.renderFile(fileObj);
      } else {
        this.setState({
          isFormInvalid: true,
          uploadedFileName: "",
        });
      }
    }
  };

  handleReset = () => {
    this.setState({
      uploadedFileName: "",
      dataLoaded: false,
    });
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  openFileBrowser = () => {
    this.fileInput.current.click();
  };

  handleSubmit = () => {
    let columns = this.state.rows[0];
    let excelData = this.state.rows.filter((item, index) => index !== 0);
    let final = [];
    excelData.forEach((item) => {
      let obj = {};
      columns.forEach((col, colIndex) => {
        let colName = col.toLowerCase().split(" ").join("_");
        obj[colName] = item[colIndex];
      });
      final.push(obj);
    });

    // console.log(final)
    _postApi(
      `${apiURL}/batch-upload-budget`,
      { data: final },
      () => {
        toast.success("Success");
      },
      () => {
        toast.error("An error occured");
      }
    );
  };

  // handleSubmit = () => {
  //   let excelData = this.state.rows.filter((item, index) => index !== 1)
  //   this.setState({
  //     loadSpinner: true,
  //   })
  //   // console.log("newarray", newArray)
  //   const query_type = 'insert'
  //   fetch(`${api}/budget_summary`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       query_type,
  //       excelData,
  //     }),
  //   })
  //     .then(function (response) {
  //       return response.json()
  //     })
  //     .then((data) => {
  //       console.log(data)
  //       alert('Data posted successfully')
  //       this.handleReset()
  //       this.setState({
  //         loadSpinner: false,
  //       })
  //     })

  //     .catch((err) => {
  //       console.log(err)
  //       this.setState({
  //         loadSpinner: false,
  //       })
  //     })
  // }

  render() {
    return (
      <div>
        {/* <button className='btn btn-success' onClick={() => this.props.goBack()}>Go Back</button> */}
        <div className="card">
          <div
            className="card-header"
            // style={{ backgroundColor: '#FE9C40', color: 'white' }}
          >
            <h5 className="text-center">Upload Budget Summary</h5>
          </div>

          <div className="card-body">
            <Container>
              <form>
                <Button onClick={this.handleSubmit} size="sm" className="mb-2">
                  <span
                    className={
                      // 'bg-success'
                      this.state.loadSpinner
                        ? "spinner-border spinner-border-sm"
                        : null
                    }
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Submit
                </Button>

                <FormGroup row>
                  {
                    // <Label for="exampleFile" xs={6} sm={4} lg={2} size="lg">Upload</Label>
                  }
                  <Col>
                    <InputGroup>
                      <div addonType="prepend">
                        <Button
                          color="info"
                          style={{ color: "white", zIndex: 0 }}
                          onClick={this.openFileBrowser.bind(this)}
                        >
                          <i className="cui-file"></i> Browse&hellip;
                        </Button>
                        <input
                          type="file"
                          hidden
                          onChange={this.fileHandler.bind(this)}
                          ref={this.fileInput}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          style={{ padding: "10px" }}
                        />
                      </div>
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.uploadedFileName}
                        readOnly
                        invalid={this.state.isFormInvalid}
                      />
                    </InputGroup>
                  </Col>
                  <div className="my-2">
                    <div
                      in={this.state.isFormInvalid}
                      tag="h6"
                      style={{ fontStyle: "italic" }}
                    >
                      Please select a .xlsx file only !
                    </div>
                  </div>
                </FormGroup>
              </form>
              {/* xxxxxxxxxxxxxxx */}

              {this.state.dataLoaded && (
                <>
                  <div>
                    <Card
                      body
                      outline
                      color="secondary"
                      className="restrict-card"
                    >
                      <OutTable
                        data={this.state.rows}
                        columns={this.state.cols}
                        tableClassName="ExcelTable2007"
                        tableHeaderRowClass="heading"
                      />
                    </Card>
                  </div>
                  <br />
                </>
              )}
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

function WrappedComponent(props) {
  // Access the navigation function
  const navigate = useNavigate();

  // Access URL parameters
  const { id } = useParams();

  // Pass down navigate and id as props to the class component
  return <UploadBudgetSummary navigate={navigate} id={id} {...props} />;
}

export default WrappedComponent;

// export default withRouter(UploadBudgetSummary);
