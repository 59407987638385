import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faUnlockAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert } from '@themesberg/react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Routes } from "../../routes";
import { _postApi, _fetchApi } from "../../redux/Functions"; 
import { apiURL } from "../../redux/api";

const initFeedback = {
  show: false,
  status: null,
  message: "",
};

const successFeedback = {
  show: true,
  status: "success",
  message: "Password reset successfully",
};

const errorFeedback = {
  show: true,
  status: "danger",
  message: "An error occurred, try again later",
};

const initialForm = {
  email: "",
  retypeNewPassword: "",
  newPassword: "",
};

const ResetPassword = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getIdFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get('id');
  };

  const id = getIdFromUrl();
  const [resetPassInfo, setResetPassInfo] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(initFeedback);

  const handleResetPassFormChange = (e) => {
    const { value, name } = e.target;
    setResetPassInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const closeFeedback = () => setTimeout(() => setFeedback(initFeedback), 2000);

  const confirmReset = useCallback(
    (id) => {
      _fetchApi(
        `${apiURL}/confirm-reset-password?id=${id}`,
        (resp) => {
          if (resp.success) {
            setResetPassInfo((p) => ({ ...p, ...resp.user }));
          } else {
            alert("Invalid or expired link!");
            // navigate("");
          }
        },
        (err) => {
          // navigate("/sign-in");
        }
      );
    },
    [navigate]
  );

  useEffect(() => {
    if (!id) {
      navigate("");
    } else {
      confirmReset(id);
    }
  }, [id, navigate, confirmReset]);

  const resetForm = () => {
    setResetPassInfo(initialForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    let data = { 
      ...resetPassInfo
    };
  
    _postApi(
      `${apiURL}/api/users/updatepassword`,
      data,
      (data) => {
        setLoading(false);
        if (data.success) {
          setFeedback(successFeedback);
          resetForm();
          setTimeout(() => {
            navigate("/sign-in");
          }, 2000);
        } else {
          setFeedback({
            show: true,
            status: "danger",
            message: data.error,
          });
        }
      },
      (err) => {
        setLoading(false);
        setFeedback(errorFeedback);
        closeFeedback();
      }
    );
  };

  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset password</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control 
                        autoFocus 
                        required 
                        type="email" 
                        placeholder="example@company.com"
                        name="email"
                        value={resetPassInfo.email}
                        onChange={handleResetPassFormChange} 
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="newPassword" className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        type="password"
                        placeholder="New Password"
                        name="newPassword"
                        autoComplete="new-password"
                        value={resetPassInfo.newPassword}
                        onChange={handleResetPassFormChange}
                        isInvalid={
                          resetPassInfo.newPassword !== "" &&
                          resetPassInfo.newPassword.length < 6
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Password length should not be less than 6 characters!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="retypeNewPassword" className="mb-4">
                    <Form.Label>Re-type New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        type="password"
                        placeholder="Re-type New Password"
                        name="retypeNewPassword"
                        autoComplete="new-password"
                        value={resetPassInfo.retypeNewPassword}
                        onChange={handleResetPassFormChange}
                        isInvalid={
                          resetPassInfo.retypeNewPassword !== "" &&
                          resetPassInfo.newPassword !==
                            resetPassInfo.retypeNewPassword
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Passwords do not match!
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  {feedback.show && (
                    <Alert variant={feedback.status}>
                      {feedback.message}
                    </Alert>
                  )}
                  <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                    {loading ? "Processing..." : "Reset password"}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ResetPassword;
