import React from 'react'
import TextInput from './TextInput'
import SelectInput from './SelectInput'
import { Col, FormLabel, Row } from '@themesberg/react-bootstrap'

function CustomForm({
  fields = [],
  handleChange = (f) => f,
  handleRadioChange = (f) => f,
}) {
  return (
    <Row>
      {fields.map((el, i) => {
        if (el.type && el.type === 'select') {
          return (
            <Col md={el.col} key={i} className="mb-1">
              <SelectInput
                {...el}
                label={el.label}
                name={el.name}
                value={el.value}
                options={el.options || []}
                required={el.required}
                disabled={el.disabled}
                onChange={handleChange}
              />
            </Col>
          )
          // } else if (el.type && el.type === "radio") {
          //   return (
          //     <Col md={{ size: el.col || 4, offset: el.offset || "" }} key={i}>
          //       <RadioGroup
          //         container="d-flex flex-row"
          //         label={el.label}
          //         name={el.name}
          //         options={el.options}
          //         onChange={handleRadioChange}
          //         value={el.value}
          //       />
          //     </Col>
          //   );
          // } else if (el.type && el.type === "checkbox") {
          //   return (
          //     <Col md={{ size: el.col || 4, offset: el.offset || "" }} key={i}>
          //       <Checkbox
          //         container="d-flex flex-row"
          //         label={el.label}
          //         name={el.name}
          //         options={el.options}
          //         onChange={handleRadioChange}
          //         value={el.value}
          //       />
          //     </Col>
          //   );
        } else if (el.type && el.type === 'custom') {
          return (
            <Col className={el.container} md={el.col || 4} key={i}>
              {el.component()}
            </Col>
          )
        } else if (el.type === 'label') {
          return (
            <Col className={el.container} md={el.col || 4} key={i}>
              <FormLabel
                className="mr-1 font-weight-bold"
                style={{ fontSize: '17px' }}
              >
                {el.label}
              </FormLabel>
              <FormLabel>{el.value}</FormLabel>
              <FormLabel>{el.name}</FormLabel>

            </Col>
          )

          // } else if (el.type === "switch") {
          //   return <SwitchButton />;
        } else if (el.type === 'hidden') {
          return null
        } else {
          return (
            <Col md={el.col || 4} key={i} className="mb-1">
              <TextInput
                {...el}
                size='sm'
                label={el.label}
                type={el.type || 'text'}
                name={el.name}
                value={el.value}
                required={el.required}
                disabled={el.disabled}
                onChange={handleChange}
              />
            </Col>
          )
        }
      })}
    </Row>
  )
}

export default CustomForm
