import React, { useContext } from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  Spinner,
} from '@themesberg/react-bootstrap'
import SearchInput from '../../../components/SearchInput'
import { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useCallback } from 'react'
import BudgetUtilizationTable from './components/BudgetUtilizationTable'
import { useEffect } from 'react'
import {  postApi } from '../../../redux/api'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from 'moment-timezone'
import { SearchContext } from '../../HomePage'
import { formatNumber } from '../../../redux/utils'

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

function BudgetUtilizationIndex() {
  const [fetchingReport,setFetchingReport] = useState(false)
  const { searchTerm, setSearchTerm } = useContext(SearchContext)
  // const [searchTerm, setSearchTerm] = useState('')
  const [budgetUtilization, setBudgetUtilization] = useState([])
  const [budgetUtilizationReport, setBudgetUtilizationReport] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10
  // const [tresuryDetails, setTresuryDetails] = useState({})
  // const [changeAmount, setChangeAmount] = useState(false)
  const [modal, setModal] = useState(false)

  // fetch budget summary from the e-budget app
  const fetchDataFromBudget = useCallback(() => {
    const offset = (currentPage - 1) * pageSize

    postApi('budget-dashboard', {
      query_type: 'select_budget_utilzation',
      offset: offset,
      limit: pageSize,
      search: searchTerm,
    })
      .then((data) => {
        if (data.result) {
          setBudgetUtilization(data.result)
        }
      })
      .catch((err) => {
        console.log(err)
        // Handle error
      })
  }, [currentPage, searchTerm])

  const fetchData = useCallback(() => {
    const offset = (currentPage - 1) * pageSize

    postApi('post_payment_schedule', {
      query_type: 'select_budget_utilzation',
      offset: offset,
      limit: pageSize,
      search: searchTerm,
    })
      .then((data) => {
        if (data.result) {
          setBudgetUtilization(data.result)
        }
      })
      .catch((err) => {
        console.log(err)
        // Handle error
      })
  }, [currentPage, searchTerm])

  const fetchDataReport = () => {
    setFetchingReport(true)
    const offset = (currentPage - 1) * pageSize

    postApi('post_payment_schedule', {
      query_type: 'select_budget_utilzation_complete',
      offset: offset,
      limit: pageSize,
      search: searchTerm,
    })
      .then((data) => {
        setFetchingReport(false)
        if (data.result && data.result.length) {
          let finalResult = data.result.map(p => ({...p, 
            "Approved Budget": formatNumber(p["Approved Budget"]),
            Utilized: formatNumber(p.Utilized),
            Variance: formatNumber(p.Variance),
             "Variance (%)": parseFloat(p["Variance (%)"]).toFixed(2),  

            }))
          // console.log(finalResult)
          exportToCSV(finalResult, `Budget Utilization Report ${moment().format('YYYYMMDD')}`);
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error("Error exporting this report, please try again later.")
        setFetchingReport(false)
        // Handle error
      })
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (value) => {
    setSearchTerm(value)
    setCurrentPage(1) // Reset to the first page when performing a new search
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    return () => {
      setSearchTerm("")
    }
  }, [setSearchTerm])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Budget Utilization</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm" onClick={fetchDataReport}>
              {fetchingReport && <Spinner />}
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>
        <SearchInput placeholder="Search for Budget Utilization Report" value={searchTerm} onChange={({target:{value}}) => setSearchTerm(value)} />
      <BudgetUtilizationTable
        list={budgetUtilization}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </>
  )
}

export default BudgetUtilizationIndex
