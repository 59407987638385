import React from "react";
import { Table, Spinner, Alert } from "@themesberg/react-bootstrap"; // Import Spinner from React Bootstrap
import { checkStrEmpty } from "../redux/utils";

function CustomTable(props) {
  const {
    fields = [],
    data = [],
    isLoading = false,
    className = "",
    tableHeadStyle = {},
    headBackground = "#099019",
    headColor = "white",
    getRowStyle = f=>f
  } = props;

  return (
    <>
      <Table
      
        hover
        className={`user-table align-items-center ${className}`}
        style={{ width: "100%" }}
        {...props}
        bordered
      >
        <thead className="table-header">
          <tr className="file-list-item">
            {fields.map((_item, _idx) => {
               if (_item.hidden) {
                return null;
              } else return (
              <th
                key={_idx}
                style={{
                  ...tableHeadStyle,
                  backgroundColor: headBackground,
                  color: headColor,
                  textAlign: "center",
                }}
              >
                {_item.headerComponent ? _item.headerComponent()
              : _item.title}
              </th>
            )})}
          </tr>
        </thead>
        <tbody>
          {isLoading ? ( // Check if loading
            <tr>
              <td colSpan={fields.length} className="text-center">
                <Spinner animation="border" variant="primary" />{" "}
                {/* Display Spinner */}
              </td>
            </tr>
          ) : (
            data.map((item, idx) => (
              <tr key={idx} style={getRowStyle(item)}>
                {fields.map((_item, _idx) => {
                  let val = item[_item.value] || "";
                  let value_alt =
                    (_item.value_alt && item[_item.value_alt]) || "";
                  if (_item.component) {
                    return (
                      <td
                        key={_idx}
                        className={`${_item.className}`}
                        style={
                          typeof _item.style === "function"
                            ? _item.style(item)
                            : _item.style
                        }
                      >
                        {_item.component(item, idx)}
                      </td>
                    );
                  } else if (_item.hidden) {
                    return null;
                  } else {
                    return (
                      <td
                        key={_idx}
                        className={`${_item.className}`}
                        style={
                          typeof _item.style === "function"
                            ? _item.style(item)
                            : _item.style
                        }
                      >
                        {checkStrEmpty(val.toString())
                          ? value_alt
                          : val.toString()}
                      </td>
                    );
                  }
                })}
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {data.length ? null : (
        <Alert className="text-center">No record found.</Alert>
      )}
    </>
  );
}

export default CustomTable;
