import React from "react";
import PropTypes from "prop-types";
import { Typeahead } from "react-bootstrap-typeahead";

const CustomTypeahead = React.forwardRef((props, ref) => {
  const {
    options,
    onInputChange,
    onChange,
    labelKey,
    label,
    placeholder,
    col,
    multiple = false,
  } = props;
  return (
    <>
      <label className="font-weight-bold">{label}</label>
      <Typeahead
        id="basic-typeahead-single"
        labelKey={labelKey}
        multiple={multiple}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        placeholder={placeholder || ""}
        ref={ref}
        className="border-radius"
        {...props}
      />
    </>
  );
});

export default CustomTypeahead;
