import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'gray',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

function Attachment({ acceptedFiles = [] }) {
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = (files) => {
    const file = files[0];
    if (file.size > 1048576) {
      alert('File size must not exceed 1 MB');
      return;
    }
    if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
      alert('Only PDF, JPEG, and PNG files are accepted');
      return;
    }
    setErrorMessage('');
    acceptedFiles.push(file);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: '.jpg,.png,.pdf',
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes <span>X</span>
    </li>
  ));

  return (
    <div className="mv-2">
      <h4>Upload Attachment</h4>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} accept="application/pdf,image/jpeg,image/png" />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <p style={{ color: 'red', marginTop: 6 }}>
        <b>Note:</b> Ensure that the file size does not exceed 1 MB.
      </p>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {files.length ? (
        <aside>
          <h4>Files</h4>
          <ul>{files}</ul>
        </aside>
      ) : null}
    </div>
  );
}

export default Attachment;
