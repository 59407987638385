import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import DM_SANS_NORMAL from "../../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../../../assets/DM_Sans/DM_Sans/static/DMSans-Italic.ttf";

Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    fontSize: 10,
    width: "100%",
    pageBreakInside: "avoid",
    paddingTop: 20,
    fontSize: 10,
    marginTop: "20%",
  },
  table: {
    width: "100%",
    flexDirection: "row",
    borderBottom: 1,
  },
});

export const CashBackingPDF = ({ data = [], selecOffer = (f) => f }) => {
  const calculateTotalApprovedAmount = () => {
    const totalApprovedAmount = data.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );

    return totalApprovedAmount;
  };

  const dataByMDA = {};
  data.forEach((item) => {
    if (Object.keys(dataByMDA).includes(item.mda_code)) {
      dataByMDA[item.mda_code] = [...dataByMDA[item.mda_code], item];
    } else {
      dataByMDA[item.mda_code] = [item];
    }
  });

  return (
    <Document>
      <Page size="A4">
        <View style={{ paddingLeft: 50, paddingRight: 30 }}>
          <View style={styles.body}>
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                  fontSize: 14,
                  width: "100%",
                  textAlign: "center",
                  textDecoration: "underline",
                  textTransform: "uppercase",
                }}
              >
                Cash Backing
              </Text>
              <View
                style={{
                  width: "100%",
                  border: 1,
                  marginTop: 10,
                }}
              >
                <View style={styles.table}>
                  <Text
                    style={{
                      width: "20%",
                      borderRight: 1,
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    Code
                  </Text>
                  <Text
                    style={{
                      width: "60%",
                      borderRight: 1,
                      textAlign: "center",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    MINISTRY/DEPARTMENT
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                      borderRight: 1,
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    AMOUNT (N)
                  </Text>
                </View>
                {Object.keys(dataByMDA).map((group, grpIdx) => {
                  const total = dataByMDA[group].reduce(
                    (a, b) => a + parseFloat(b.amount),
                    0
                  );
                  return (
                    <>
                      <View
                        key={grpIdx}
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          borderBottom: 1,
                        }}
                      >
                        <Text
                          style={{
                            width: "20%",
                            borderRight: 1,
                            textAlign: "justify",
                            paddingLeft: 5,
                          }}
                        >
                          {group}
                        </Text>
                        <Text
                          style={{
                            width: "60%",
                            textAlign: "center",
                            borderRight: 1,
                          }}
                        >
                          {dataByMDA[group][0].mda_name}
                        </Text>
                        <Text
                          style={{
                            width: "20%",
                            borderRight: 1,
                            textAlign: "right",
                            paddingLeft: 5,
                          }}
                        >
                          N{selecOffer(total)}
                        </Text>
                      </View>
                      {dataByMDA[group].map((item, index) => (
                        <>
                          <View
                            key={index}
                            style={{
                              width: "100%",
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={{
                                width: "20%",
                                fontSize: 11,
                                borderRight: 1,
                                textAlign: "justify",
                                paddingLeft: 4,
                              }}
                            >
                              {item.mda_economic_code}
                            </Text>
                            <Text
                              style={{
                                width: "60%",
                                fontSize: 11,
                                borderRight: 1,
                                textAlign: "justify",
                                paddingLeft: 4,
                              }}
                            >
                              {item.description}
                            </Text>
                            <Text
                              style={{
                                width: "20%",
                                fontSize: 11,
                                textAlign: "right",
                                paddingRight: 5,
                              }}
                            >
                              N{selecOffer(item.amount)}
                            </Text>
                          </View>
                          <View
                            key={index}
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              borderBottom: 1,
                            }}
                          >
                            <Text
                              style={{
                                width: "20%",
                                fontSize: 11,
                                borderRight: 1,
                                textAlign: "justify",
                                paddingLeft: 4,
                              }}
                            >
                              {/* {item.mda_economic_code} */}
                            </Text>
                            <Text
                              style={{
                                width: "60%",
                                fontSize: 11,
                                borderRight: 1,
                                textAlign: "justify",
                                paddingLeft: 4,
                              }}
                            >
                              {/* {item.description} */}
                            </Text>
                            <Text
                              style={{
                                width: "20%",
                                fontSize: 11,
                                textAlign: "right",
                                paddingRight: 5,
                              }}
                            >
                              {/* N{selecOffer(item.approved_amount)} */}
                            </Text>
                          </View>
                        </>
                      ))}
                    </>
                  );
                })}
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      width: "20%",
                      fontSize: 11,
                      borderRight: 1,
                      textAlign: "justify",
                      paddingLeft: 4,
                    }}
                  ></Text>
                  <Text
                    style={{
                      fontFamily: "DM_SANS",
                      fontStyle: "bold",
                      width: "60%",
                      fontSize: 11,
                      borderRight: 1,
                      textAlign: "right",
                      paddingRight: 4,
                    }}
                  >
                    TOTAL
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                      fontSize: 11,
                      textAlign: "right",
                      paddingRight: 5,
                    }}
                  >
                    N{selecOffer(calculateTotalApprovedAmount())}
                  </Text>
                </View>
                {/* {data.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      borderBottom: 1,
                    }}
                  >
                    <Text
                      style={{
                        width: "35%",
                        borderRight: 1,
                        textAlign: "justify",
                        paddingLeft: 5,
                      }}
                    >
                      {item.mda_name}
                    </Text>
                    <Text
                      style={{
                        width: "20%",
                        textAlign: "center",
                        borderRight: 1,
                      }}
                    >
                      {item.economic_code}
                    </Text>
                    <Text
                      style={{
                        width: "35%",
                        borderRight: 1,
                        textAlign: "justify",
                        paddingLeft: 5,
                      }}
                    >
                      {item.economic_description}
                    </Text>
                    <Text
                      style={{
                        width: "35%",
                        textAlign: "right",
                      }}
                    >
                      {parseInt(item?.amount || 0)?.toLocaleString()}
                    </Text>
                  </View>
                ))} */}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

// export default NewPvViewPdf;
export default CashBackingPDF;
