import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Form, Modal } from '@themesberg/react-bootstrap';
import BackButton from '../../../components/BackButton';
import CustomForm from '../../../components/CustomForm';
import { postApi } from '../../../redux/api';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../../../routes';

export default ({ match }) => {
  const [form, setForm] = useState({
    account_name: '',
    account_description: '',
    account_number: '',
    sort_code: '',
    bank_name: '',
    account_type: '',
  });
  const [loading, setLoading] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const history = useNavigate();

  const params = useParams();
  const id = params.id || '';

  const param = Object.entries(params).length;

  const handleChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleReset = () => {
    setForm({
      account_name: '',
      account_description: '',
      account_number: '',
      sort_code: '',
      bank_name: '',
    });
  };

  const handleSave = () => {
    setShowSaveModal(true);
    setLoading(true);
    postApi(`select_mda_bank_details/${id}`, {
      query_type: 'INSERT',
      ...form,
    })
      .then(() => {
        setLoading(false);
        // setShowSaveModal(true);
        handleReset();
        history(Routes.bank.bankIndex);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleUpdate = () => {
    setShowUpdateModal(true);
    setLoading(true);
    postApi(`select_mda_bank_details/${id}`, {
      query_type: 'UPDATE',
      ...form,
    })
      .then(() => {
        setLoading(false);
        setShowUpdateModal(true);
        handleReset();
        history(Routes.bank.bankIndex);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    postApi(`select_mda_bank_details/${id}`, {
      query_type: 'select_by_id',
    }).then((data) => {
      if (data?.result?.length) {
        setForm(data.result[0]);
      }
    });
  }, [id]);

  return (
    <>
      <BackButton />
      <Card>
        <Card.Body>
          <h5 className="mb-4">
            <FontAwesomeIcon icon={faEdit} className="me-2" /> Bank Details
          </h5>
          <Form>
            <CustomForm
              handleChange={handleChange}
              fields={[
                {
                  label: 'Account Name',
                  value: form.account_name,
                  name: 'account_name',
                },
                {
                  label: 'Bank Name',
                  value: form.bank_name,
                  name: 'bank_name',
                },
                {
                  label: 'Account Number',
                  value: form.account_number,
                  name: 'account_number',
                },
                {
                  label: 'Sort code',
                  value: form.sort_code,
                  name: 'sort_code',
                },
              ]}
            />
            <div className="mt-3">
              {param > 0 ? (
                <Button variant="primary" onClick={handleUpdate} disabled={loading}>
                  Update
                </Button>
              ) : (
                <Button variant="primary" onClick={handleSave} disabled={loading}>
                  Save
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
      {/* Save Modal */}
      <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bank details saved successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSaveModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update Modal */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bank details updated successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
