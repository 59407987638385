import React, { useState, useEffect, useCallback } from "react";
import { Col, Form, Row, Button, Card } from "@themesberg/react-bootstrap";
import BackButton from "../../../components/BackButton";
import { postApi } from "../../../redux/api";
import { useNavigate, useParams } from "react-router-dom";
import {
  faCheck,
  faCheckDouble,
  faEye,
  faReplyAll,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Routes } from "../../../routes";
import toast from "react-hot-toast";
import { Alert, Modal, Spinner, Table } from "react-bootstrap";
import { formatNumber } from "../../../redux/utils";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";

function ViewApproval() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const { id: approval_no } = useParams();
  const [form, setForm] = useState({
    collection_date: "",
    approval_date: "",
    approval_type: "",
    mda_name: "",
    amount: "",
    status: "",
    economic_code: "",
    approval_no: approval_no,
  });
  const [tableData, setTableData] = useState([]);
  const [showRemark, setShowRemark] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [approvalMessage, setApprovalMessage] = useState(null);
  const [remark, setRemark] = useState("");
  const [returnModal, setReturnModal] = useState(false);

  const toggleRemark = () => setShowRemark((p) => !p);
  const toggleReturnModal = () => setReturnModal((p) => !p);

  const getApprovalCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: "select_by_approval_no",
        approval_no: approval_no,
      },
    })
      .then((data) => {
        console.log("data", data);
        if (data.result && data.result.length > 0) {
          const result = data.result[0];
          setForm(result);
          setTableData([result]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [approval_no]);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  const updateApproval = (approval_no, status) => {
    // alert("HERE!!!")
    // const approvalIsPending = form?.approval_status === "Approval Released";
    // const approvalIsReceived = form?.approval_status==="Approval Received"
    console.log("form?.approval_status", form?.approval_status);
    console.log(
      "user.mda_name",
      user.mda_name,
      user.mda_name === "Office of the Accountant-General"
    );
    setApprovalLoading(true);
    if (status.includes("Return")) {
      postApi("update-approval", {
        remarks: remark,
        status: form?.approval_status.includes("Approval Released")
          ? "Approval Received - Returned"
          : form?.approval_status.includes("Approval Received")
          ? "Approval Reviewed - Returned"
          : form?.approval_status.includes("Approval Reviewed") ||
            form?.approval_status.includes("approval Reviewed")
          ? user.mda_name === "Office of the Accountant-General"
            ? "Approved by Director Exp. Cont. - Returned"
            : "Approval Released - Returned"
          : "Approval Released - Returned",
        approval_no,
        userId: user.username,
        mda_name: user ? user.mda_name : "",
      })
        .then((resp) => {
          setApprovalLoading(false);
          if (resp && resp.success) {
            setApprovalMessage("Returned Successfully");
            setTimeout(() => {
              //   toggleRemark();
              // getApprovalCollection();
              if (form?.approval_status.includes("Approval Released")) {
                navigate(Routes.approval.pendingApproval);
              } else if (form?.approval_status.includes("Approval Received")) {
                navigate(Routes.approval.reviewReleases);
              } else if (
                form?.approval_status.includes("Approval Reviewed") ||
                form?.approval_status.includes("approval Reviewed")
              ) {
                navigate(Routes.approval.approvalReleases);
              }
            }, 1000);
          }
        })
        .catch((err) => {
          setApprovalLoading(false);
          toast.error("An error occured, please try again later");
          console.log(err);
        });
    } else {
      postApi("update-approval", {
        remarks: remark,
        status:
          form?.approval_status === "Approval Released"
            ? "Approval Received"
            : form?.approval_status === "Approval Received"
            ? "Approval Reviewed"
            : form?.approval_status === "Approval Reviewed" ||
              form?.approval_status === "approval Reviewed"
            ? user.mda_name === "Office of the Accountant-General"
              ? "Approved by Director Exp. Cont."
              : "Approval Processed"
            : "Approval Processed",
        approval_no,
        userId: user.username,
        mda_name: user ? user.mda_name : "",
      })
        .then((resp) => {
          setApprovalLoading(false);
          if (resp && resp.success) {
            setApprovalMessage("Approved Successfully");
            setTimeout(() => {
              toggleRemark();
              // getApprovalCollection();
              if (form?.approval_status === "Approval Released") {
                navigate(Routes.approval.pendingApproval);
              } else if (form?.approval_status === "Approval Received") {
                navigate(Routes.approval.reviewReleases);
              } else if (
                form?.approval_status === "Approval Reviewed" ||
                form?.approval_status === "approval Reviewed"
              ) {
                navigate(Routes.approval.approvalReleases);
              } else if (
                form?.approval_status === "Approved by Director Exp. Cont."
              ) {
                navigate(Routes.approval.adExpContrReleases);
              }
            }, 1000);
          }
        })
        .catch((err) => {
          setApprovalLoading(false);
          toast.error("An error occured, please try again later");
          console.log(err);
        });
    }
  };

  return (
    <>
      <BackButton />
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          {/* {JSON.stringify(tableData)} */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            <div>
              <h5 className="mb-4">
                <FontAwesomeIcon icon={faCheckDouble} className="me-2" />
                Release No.: {form.approval_no}
              </h5>
            </div>
            {/* {JSON.stringify(form)} */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <button
                style={{
                  backgroundColor: "yellow",
                  border: "none",
                  height: "60%",
                  //   padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                onClick={() =>
                  history(
                    `${Routes.cashBacking.releaseCollectionPDF}/${form.approval_no}`
                  )
                }
              >
                <FontAwesomeIcon icon={faEye} className="me-1" />
                View Release Doc
              </button>

              <button
                className="bg-danger"
                style={{
                  border: "none",
                  color: "#fff",
                  height: "60%",
                  //   padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                onClick={() => toggleReturnModal()}
              >
                <FontAwesomeIcon icon={faReplyAll} className="me-1" />
                Return
              </button>
              <button
                className="bg-primary"
                style={{
                  border: "none",
                  color: "#fff",
                  height: "60%",
                  //   padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  toggleRemark();
                }}
              >
                <FontAwesomeIcon icon={faCheck} className="me-1" />
                Approve
              </button>
            </div>
          </div>
          <Form>
            <Row>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Collection Date</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={form.collection_date}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Approval Date</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={form.approval_date}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Approval Type</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={form.approval_type}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <h5
                  style={{
                    width: "100%",
                    textAlign: "right",
                    marginTop: 40,
                  }}
                >
                  Total: {formatNumber(form.amount)}
                </h5>
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  width: "100%",
                  height: "47vh",
                  //   overflowX: "auto",
                }}
              >
                {loading ? (
                  <center>
                    <span size="sm">
                      Please wait
                      <Spinner color="primary" size="sm" />
                    </span>
                  </center>
                ) : null}
                <table id="customers">
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: "20%",
                        }}
                      >
                        MDA Name
                      </th>
                      <th
                        style={{
                          width: "20%",
                        }}
                      >
                        Admin code
                      </th>
                      {form.program_code ? <th>Program Code</th> : null}
                      <th
                        style={
                          form.program_code
                            ? {
                                display: "none",
                              }
                            : {
                                width: "20%",
                              }
                        }
                      >
                        Economic code
                      </th>
                      <th
                        style={
                          form.program_code
                            ? {
                                display: "none",
                              }
                            : {
                                width: "20%",
                              }
                        }
                      >
                        Description
                      </th>
                      <th
                        style={{
                          width: "20%",
                        }}
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, idx) => (
                      <tr key={idx}>
                        <td
                          style={{
                            width: "20%",
                          }}
                        >
                          {item.mda_name}
                        </td>
                        <td
                          style={{
                            width: "20%",
                          }}
                        >
                          {item.mda_code}
                        </td>
                        {form.program_code ? (
                          <td>{item.program_code}</td>
                        ) : null}
                        <td
                          style={
                            form.program_code
                              ? {
                                  display: "none",
                                }
                              : {
                                  width: "20%",
                                }
                          }
                        >
                          {item.economic_code}
                        </td>
                        <td
                          style={
                            form.program_code
                              ? {
                                  display: "none",
                                }
                              : {
                                  width: "20%",
                                }
                          }
                        >
                          {item.description_code}
                        </td>
                        <td
                          style={{
                            width: "20%",
                            textAlign: "right",
                          }}
                        >
                          {formatNumber(item.amount)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!tableData.length ? (
                  <Alert>
                    <div className="text-center">No Record Found</div>
                  </Alert>
                ) : null}
                <h5
                  style={{
                    width: "100%",
                    textAlign: "right",
                    marginTop: 10,
                  }}
                >
                  Total: {formatNumber(form.amount)}
                </h5>
              </div>
            </Row>
          </Form>
        </Card.Body>

        <Modal show={showRemark} onHide={toggleRemark}>
          {approvalLoading ? null : (
            <Modal.Header onHide={toggleRemark}>Confirmation</Modal.Header>
          )}
          {approvalLoading ? (
            <span>
              Please wait
              <Spinner color="primary" size="xl" />
            </span>
          ) : approvalMessage && approvalMessage !== "" ? (
            <h5>{approvalMessage}</h5>
          ) : (
            <>
              <Modal.Body className="text-center">
                <h5>
                  Are you sure you want to approve this Release with
                  No.: {form.approval_no}{" "}
                </h5>

                <div>
                  <h6>Add Remarks</h6>
                  <textarea
                    value={remark}
                    onChange={({ target: { value } }) => setRemark(value)}
                    className="form-control"
                    placeholder="Add your remarks here"
                  />
                </div>
              </Modal.Body>
            </>
          )}
          {approvalLoading ? null : (
            <Modal.Footer>
              <div>
                <CustomButton
                  color="danger"
                  outline
                  className="m-1 p-1 px-3 bg-danger"
                  onClick={toggleRemark}
                 
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  className="m-1 p-1 px-3"
                  onClick={() =>
                    updateApproval(form.approval_no, "Approval Received")
                  }
                  loading={loading}
                >
                  Approve
                </CustomButton>
              </div>
            </Modal.Footer>
          )}
        </Modal>

        <Modal show={returnModal} onHide={setReturnModal}>
          {approvalLoading ? null : (
            <Modal.Header onHide={setReturnModal}>Confirmation</Modal.Header>
          )}
          {approvalLoading ? (
            <span>
              Please wait
              <Spinner color="primary" size="xl" />
            </span>
          ) : approvalMessage && approvalMessage !== "" ? (
            <h5>{approvalMessage}</h5>
          ) : (
            <>
              <Modal.Body className="text-center">
                <h5>
                  Are you sure you want to return this Release with
                  No.: {form.approval_no}{" "}
                </h5>

                <div>
                  <h6>Add Remarks</h6>
                  <textarea
                    value={remark}
                    onChange={({ target: { value } }) => setRemark(value)}
                    className="form-control"
                    placeholder="Add your remarks here"
                  />
                </div>
              </Modal.Body>
            </>
          )}
          {approvalLoading ? null : (
            <Modal.Footer>
              <div>
                <Button
                  color="danger"
                  outline
                  className="m-1 p-1 px-3"
                  onClick={toggleRemark}
                >
                  Cancel
                </Button>
                <Button
                  className="m-1 p-1 px-3"
                  onClick={() => updateApproval(form.approval_no, "Returned")}
                  loading={loading}
                >
                  Return
                </Button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
      </Card>
    </>
  );
}

export default ViewApproval;
