import React from "react";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";

const WhatsAppFloatingIcon = ({ phoneNumber }) => {
  return (
    <WhatsAppWidget
      phoneNumber={phoneNumber} // Your WhatsApp phone number
      textReplyTime="Typically replies in a few hours" // Message shown when the user hovers over the widget
      companyName="Katsina State (KTEB/KIFMIS) Support Line" // Your company's name
      message="Hello! How can we help you today? or call this number 09113472208" // Initial message sent to WhatsApp
    />
  );
};

export default WhatsAppFloatingIcon;
