import React from 'react'
import { useState } from 'react'

import { formatNumber } from '../../../redux/utils'
import TextInput from '../../../components/TextInput'
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, FormCheck, InputGroup } from '@themesberg/react-bootstrap';


const TaxItem = ({
  item = {},
  index = '',
  taxesApplied = [],
  setTaxesApplied = (f) => f,
  form = {},
}) => {
  const [isEdit, setIsEdit] = useState(false)
  let selectedApplied = taxesApplied.findIndex(
    (i) => i.description === item.tax_name,
  )
  let isSelected = selectedApplied !== -1
  let amount =
    item.rate_type === 'percentage'
      ? ((parseFloat(item.rate) / 100) * parseFloat(form.amount)).toFixed(2)
      : item.rate
  
  let checkBoxIsDisabled =
    !form.amount || form.amount === '' || form.amount === 0
  return (
    <Col md="3 mb-1">
      <div className="d-flex flex-direction-row ">
        <div className="mr-4">
          <label htmlFor={item.tax_name + index}>{item.tax_name}</label>
          <FormCheck
            id={item.tax_name + index}
            title={
              checkBoxIsDisabled ? 'Please enter an amount' : item.tax_name
            }
            disabled={checkBoxIsDisabled}
            type="checkbox"
            style={{ marginLeft: 10 }}
            checked={isSelected}
            onClick={() => {
              if (!isSelected) {
                // console.log('not selected', amount)
                // console.log(taxesApplied)
                let tax_obj = {
                  //   contract_code: '',
                  ...item,
                  description: item.tax_name,
                  amount,
                }
                setTaxesApplied((p) => [...p, tax_obj])
              } else {
                let newList = taxesApplied.filter(
                  (a) => a.description !== item.tax_name,
                )
                setTaxesApplied(newList)
              }
            }}
          />
        </div>
        <div style={{ width: 10 }}></div>
        {(isSelected && !isEdit) && (
          <div>
            (₦{formatNumber(taxesApplied[selectedApplied].amount)}){' '}
            <a href='#a' className='ml-5' onClick={e => {e.preventDefault();setIsEdit(true)}}>
            <FontAwesomeIcon icon={faEdit} className="me-2" /> edit</a>
            {/* <CustomButton size="sm" outline onClick={() => }>
              Edit
            </CustomButton> */}
          </div>
        )}
      </div>

      {isSelected && isEdit ? (
        <TextInput
          size="sm"
          label={'Enter new value for ' + item.tax_name}
          value={taxesApplied[selectedApplied].amount}
          onChange={({target:{value}}) => {
            // console.log(taxesApplied)
            let newTaxAppliedArr = []
            taxesApplied.forEach((tax) => {
              if(tax.description === taxesApplied[selectedApplied].description) {
                newTaxAppliedArr.push({ ...tax, amount: value })
              } else {
                newTaxAppliedArr.push(tax)
              }
            })

            // console.log(newTaxAppliedArr, item)

            setTaxesApplied(newTaxAppliedArr)
          }}
        />
      ) : null}
    </Col>
  )
}

export default TaxItem
