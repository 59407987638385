import { Card, Form } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Button, CardBody, CardHeader, Col, Modal, Row } from "react-bootstrap";
import CustomButton from "../../components/CustomButton";
import BackButton from "../../components/BackButton";
import { createToken, formatExpirationDate, postApi } from "../../redux/api";
import { formatCreditCardNumber, formatCVC } from "../../redux/utils";
// import {formatCreditCardNumber} from '../../'
import Cards from "react-credit-cards";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PinInput from "./Pin";
export const accountTypes = [
  { code: "00", title: "Default" },
  { code: "10", title: "Savings Account" },
  { code: "20", title: "Current Account" },
];
// [
//     { code: "044", title: "Access Bank Nigeria Plc" },
//     { code: "063", title: "Diamond Bank Plc" },
//     { code: "050", title: "Ecobank Nigeria" },
//     { code: "084", title: "Enterprise Bank Plc" },
//     { code: "070", title: "Fidelity Bank Plc" },
//     { code: "011", title: "First Bank of Nigeria Plc" },
//     { code: "214", title: "First City Monument Bank" },
//     { code: "058", title: "Guaranty Trust Bank Plc" },
//     { code: "301", title: "Jaiz Bank" },
//     { code: "082", title: "Keystone Bank Ltd" },
//     { code: "014", title: "Mainstreet Bank Plc" },
//     { code: "076", title: "Skye Bank Plc" },
//     { code: "039", title: "Stanbic IBTC Plc" },
//     { code: "232", title: "Sterling Bank Plc" },
//     { code: "032", title: "Union Bank Nigeria Plc" },
//     { code: "033", title: "United Bank for Africa Plc" },
//     { code: "215", title: "Unity Bank Plc" },
//     { code: "035", title: "WEMA Bank Plc" },
//     { code: "057", title: "Zenith Bank International" },
//     { code: "101", title: "Providus Bank" },
//     { code: "104", title: "PARALLEX BANK LIMITED" },
//     { code: "303", title: "LOTUS BANK LIMITED" },
//     { code: "105", title: "PREMIUM TRUST BANK LTD" },
//     { code: "106", title: "SIGNATURE BANK LTD" },
//     { code: "103", title: "GLOBUS BANK" }]
export const bankList = [
  { title: "ACCESS BANK NIGERIA", code: "044" },
  { title: "ACCESS MOBILE", code: "323" },
  { title: "Access Bank", code: "044" },
  { title: "AFRIBANK NIGERIA PLC", code: "014" },
  { title: "Aso Savings and Loans", code: "401" },
  { title: "DIAMOND BANK PLC", code: "063" },
  { title: "EcoBank PLC", code: "050" },
  { title: "ECOBANK NIGERIA PLC", code: "050" },
  { title: "Ecobank Mobile", code: "307" },
  { title: "ENTERPRISE BANK LIMITED", code: "084" },
  { title: "FBN MOBILE", code: "309" },
  { title: "FIDELITY BANK PLC", code: "070" },
  { title: "Fidelity Bank", code: "070" },
  { title: "FINATRUST MICROFINANCE BANK", code: "608" },
  { title: "FIRST BANK PLC", code: "011" },
  { title: "First Bank PLC", code: "011" },
  { title: "FIRST CITY MONUMENT BANK PLC", code: "214" },
  { title: "First City Monument Bank", code: "214" },
  { title: "Globus Bank", code: "103" },
  { title: "GTBANK PLC", code: "058" },
  { title: "GTBank Mobile Money", code: "315" },
  { title: "HERITAGE BANK", code: "030" },
  { title: "JAIZ BANK", code: "301" },
  { title: "KEYSTONE BANK PLC", code: "082" },
  { title: "Paga", code: "327" },
  { title: "Parkway", code: "311" },
  { title: "PAYCOM", code: "305" },
  { title: "Polaris Bank", code: "076" },
  { title: "Polaris bank", code: "076" },
  { title: "Providus Bank PLC", code: "101" },
  { title: "ProvidusBank PLC", code: "101" },
  { title: "Rand merchant Bank", code: "502" },
  { title: "SKYE BANK PLC", code: "076" },
  { title: "STANBIC IBTC BANK PLC", code: "221" },
  { title: "Stanbic Mobile", code: "304" },
  { title: "STANDARD CHARTERED BANK NIGERIA LIMITED", code: "068" },
  { title: "STERLING BANK PLC", code: "232" },
  { title: "Suntrust Bank", code: "100" },
  { title: "UNION BANK OF NIGERIA PLC", code: "032" },
  { title: "Union Bank PLC", code: "032" },
  { title: "UNITED BANK FOR AFRICA PLC", code: "033" },
  { title: "United Bank for Africa", code: "033" },
  { title: "UNITY BANK PLC", code: "215" },
  { title: "WEMA BANK PLC", code: "035" },
  { title: "Wema Bank PLC", code: "035" },
  { title: "ZENITH BANK PLC", code: "057" },
  { title: "Zenith Bank PLC", code: "057" },
  { title: "ZENITH Mobile", code: "322" },
];

function AccountForm() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [focus, setFocus] = useState("name");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toggle = (e) => {
    e.preventDefault();
    setModalIsOpen((p) => !p);
  };
  const [form, setForm] = useState({
    exp: "",
    account_name: "",
    account_number: "",
    account_type: "",
    card: "",
    pin: "",
    cvv2: "",
    type: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleInputChangeCVV = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: formatCVC(value) });
  };

  const handleInputChangeNum = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const card_info = {
      card: form.card,
      pin: form.pin,
      expiry_date: form?.exp.replace("/", ""),
      cvv2: form.cvv2 ? form.cvv2 : "000",
    };
    const token = createToken(card_info);
    const data = {
      pan_info: token,
      account_name: form.account_name,
      account_number: form.account_number,
      account_type: form.account_type,
      bank_cbn_code: form.bank_cbn_code,
      type: form.type,
    };
    postApi("pan_details/create", data)
      .then((resp) => {
        if (resp.success) {
          toast.success("Success");
          navigate(-2);
        } else {
          toast.error("Failed");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <BackButton />
      <Card>
        <CardHeader>
          <h4>Card Information</h4>
        </CardHeader>
        <CardBody className="row">
          <Form onSubmit={toggle}>
            <Row>
              <div className="col-md-4 my-1">
                <label>Select Bank:</label>
                <select
                  onChange={handleInputChange}
                  value={form.bank_cbn_code}
                  name="bank_cbn_code"
                  className="form-control"
                  required
                >
                  <option value={""}>--select--</option>
                  {bankList.map((item, id) => (
                    <option key={id} value={item.code}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {/* <input value={form.bank_cbn_code} name='bank_cbn_code' type="text" className="form-control" placeholder="Enter account number" /> */}
              </div>
              <div className="col-md-4 my-1">
                <label>Account Number:</label>
                <input
                  onChange={handleInputChange}
                  value={form.account_number}
                  name="account_number"
                  type="text"
                  required
                  className="form-control"
                  placeholder="Enter account number"
                />
              </div>
              <div className="col-md-4 my-1">
                <label>Account Name:</label>
                <input
                  onChange={handleInputChange}
                  value={form.account_name}
                  name="account_name"
                  type="text"
                  required
                  className="form-control"
                  placeholder="Enter account name"
                />
              </div>

              <div className="col-md-4 my-1">
                <label>Account Type:</label>
                <select
                  onChange={handleInputChange}
                  name="account_type"
                  //   required
                  className="form-control"
                >
                  <option value={""}>--select--</option>
                  {accountTypes.map((item, id) => (
                    <option key={id} value={item.code}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {/* <input value={form.account_type} name='account_type' type="text" className="form-control" placeholder="Enter account number" /> */}
              </div>

              <div className="col-md-4 my-1">
                <label>Card:</label>
                <input
                  onChange={handleInputChangeNum}
                  value={form.card}
                  name="card"
                  className="form-control"
                  placeholder="Enter card number"
                  type="tel"
                  pattern="[\d| ]{16,22}"
                  maxLength="19"
                  required
                />
              </div>

              {/* {JSON.stringify(form)} */}
              <div className="col-md-4 my-1">
                <label>Expiry Date:</label>
                <input
                  onChange={({ target }) => {
                    target.value = formatExpirationDate(target.value);
                    const { name, value } = target;
                    setForm({ ...form, [name]: value });
                  }}
                  value={form.exp}
                  name="exp"
                  type="tel"
                  className="form-control"
                  placeholder="YY/MM"
                  pattern="\d\d/\d\d"
                  required
                />
              </div>
              <div className="col-md-4 my-1">
                <label>CVV:</label>
                <input
                  onChange={handleInputChangeCVV}
                  value={form.cvv2}
                  name="cvv2"
                  type="tel"
                  //   required
                  className="form-control"
                  pattern="\d{3}"
                  placeholder="Enter cvv2 code eg. "
                />
              </div>
              <div className="col-md-4 my-1">
                <label>PIN:</label>
                <input
                  onChange={handleInputChange}
                  value={form.pin}
                  name="pin"
                  type="password"
                  required
                  maxLength="4"
                  className="form-control"
                  placeholder="Enter pin number"
                />
              </div>
              {/* <PinInput /> */}
              {/* <OtpInput
                value={form.pin}
                onChange={(e) => {
                  setForm({ ...form, pin: e });
                }}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input name="pin" type="password" {...props} />
                )}
              /> */}
              <div className="col-md-4 my-1">
                <label>Type of Account:</label>
                <select
                  onChange={handleInputChange}
                  value={form.type}
                  name="type"
                  className="form-control"
                  required
                >
                  <option value={""}>--select--</option>
                  {["MAIN ACCOUNT", "SUB-TREASURY ACCOUNT", "OTHERS"].map(
                    (item, id) => (
                      <option key={id} value={item}>
                        {item}
                      </option>
                    )
                  )}
                </select>
              </div>
              <center className="my-2">
                <CustomButton type="submit">Submit</CustomButton>
              </center>
            </Row>
          </Form>
        </CardBody>

        <Modal show={modalIsOpen} onHide={toggle}>
          <Modal.Header>Bank Info Preview</Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <div
                  onClick={() =>
                    setFocus((p) => (p === "cvc" ? "name" : "cvc"))
                  }
                >
                  <Cards
                    cvc={form.cvv2 || "000"}
                    expiry={form.exp}
                    focused={focus}
                    name={form.account_name}
                    number={form.card}
                    // preview={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mt-2">
                <h6 className="text-center">
                  Account: {form.account_name}({form.account_number})
                </h6>
              </Col>
            </Row>
            <h5 className="text-center">
              The card details are encrypted end-to-end.
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" onClick={toggle}>
              Close
            </Button>
            <CustomButton loading={loading} size="sm" onClick={handleSubmit}>
              Submit
            </CustomButton>
          </Modal.Footer>
        </Modal>
      </Card>
    </>
  );
}

export default AccountForm;
