import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Alert,
  Button,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { _postApi, _fetchApi } from "../../redux/Functions";
import { apiURL } from "../../redux/api";

const initFeedback = {
  show: false,
  status: null,
  message: "",
};

const successFeedback = {
  show: true,
  status: "success",
  message: "Password reset successfully",
};

const errorFeedback = {
  show: true,
  status: "danger",
  message: "An error occurred, try again later",
};

const initialForm = {
  retypeNewPassword: "",
  newPassword: "",
};

const NewPassword = (props) => {
  const navigate = useNavigate();
  const id = props.location?.search.split("=")[1];
  const [resetPassInfo, setResetPassInfo] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(initFeedback);

  const handleResetPassFormChange = (e) => {
    const { value, name } = e.target;
    setResetPassInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const closeFeedback = () =>
    setTimeout(() => {
      setFeedback(initFeedback);
    }, 2000);

  const confirmReset = useCallback(
    (id) => {
      _fetchApi(
        `${apiURL}/confirm-reset-password?id=${id}`,
        (resp) => {
          console.log(resp);
          if (resp.success) {
            setResetPassInfo((p) => ({ ...p, ...resp.user }));
          } else {
            console.log("bad");
            alert("Invalid or expired link!");
          }
        },
        (err) => {
          navigate("sign-in");
        }
      );
    },
    [navigate]
  );

  useEffect(() => {
    if (!id) {
      navigate("sign-in");
    }
    confirmReset(id);
  }, [id, navigate, confirmReset]);

  const resetForm = () => {
    setResetPassInfo(initialForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = { ...resetPassInfo };
    _postApi(
      `${apiURL}/users/updatepassword`,
      data,
      (data) => {
        setLoading(false);
        if (data.success) {
          setFeedback(successFeedback);
          resetForm();
          setTimeout(() => {
            navigate("sign-in");
          }, 2000);
        } else {
          setFeedback({
            show: true,
            status: "danger",
            message: data.error,
          });
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        setFeedback(errorFeedback);
        closeFeedback();
      }
    );
  };

  return (
    <div
      className="d-flex bg-default flex-row align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <Card.Body className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h3>Provide your new password</h3>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control
                  placeholder="New Password"
                  type="password"
                  autoComplete="new-retype-password"
                  name="newPassword"
                  onChange={handleResetPassFormChange}
                  value={resetPassInfo.newPassword}
                  invalid={
                    resetPassInfo.newPassword !== "" &&
                    resetPassInfo.newPassword.length < 6
                  }
                />
                {resetPassInfo.newPassword !== "" && (
                  <div className="text-danger">
                    Password length is not less than 6 characters!
                  </div>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Control 
                  placeholder="Re-type New Password"
                  type="password"
                  autoComplete="new-retype-password"
                  name="retypeNewPassword"
                  onChange={handleResetPassFormChange}
                  value={resetPassInfo.retypeNewPassword}
                  invalid={
                    resetPassInfo.retypeNewPassword !== "" &&
                    resetPassInfo.newPassword !==
                      resetPassInfo.retypeNewPassword
                  }
                />
                {resetPassInfo.retypeNewPassword !== "" && (
                  <div className="text-danger">Passwords do not match!</div>
                )}
              </Form.Group>
              {feedback.show && (
                <Alert color={feedback.status} className="text-center">
                  {feedback.message}
                </Alert>
              )}

              <div className="text-center">
                <Button
                  loading={loading}
                  className="my-4"
                  color="primary"
                  type="submit"
                >
                  Continue
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <Row className="mt-3">
          <Col className="" xs="6">
            <Link className="text-light" to="/auth/register">
              <small>Create new account</small>
            </Link>
          </Col>
          <Col xs="6" className="text-right">
            <Link className="text-light" to="sign-in">
              <small>Login</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default NewPassword;
