import React, { useState, useEffect } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer'
import moment from 'moment'
import RobotoItalic from '../../../assets/fonts/Roboto-Italic.ttf'
import RobotoRegular from '../../../assets/fonts/Roboto-Regular.ttf'
import RobotoBold from '../../../assets/fonts/Roboto-Bold.ttf'
import { postApi } from '../../../redux/api'
import QRCode from 'qrcode'
import CustomButton from '../../../components/CustomButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import {  useNavigate, useParams } from 'react-router-dom'
import { Button, Col, Row } from '@themesberg/react-bootstrap'

const COL1_WIDTH = 40
const COL_AMT_WIDTH = 20
const COLN_WIDTH = (100 - (COL1_WIDTH + COL_AMT_WIDTH)) / 2

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
    {
      src: RobotoItalic,
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 30,
    paddingLeft: 30,
    fontFamily: 'Times-Roman',
  },

  headerPage: {},

  topRightHeader: {
    // display : 'flex',
    // justifyContent : 'between',
    //  flexDirection: 'row',
    textAlign: 'right',
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },

  topLeftHeader: {
    // flexDirection: 'row',
    // textAlign : 'left',
    // marginTop : '100px',
    marginBottom: '25px',
    fontSize: '12px',
    color: 'black',
    fontWeight: 'bold',
    lineHeight: 1.5,
    flexDirection: 'row',

    justifyContent: 'space-between',
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },
  middleHeader: {
    // flexDirection: 'row',
    padding: 10,
    fontSize: '14px',
    textAlign: 'center',
    // textDecoration : 'underline',
    fontWeight: 'bolder',
    lineHeight: 1.2,
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },
  middleHeader1: {
    // flexDirection: 'row',
    padding: 10,
    fontSize: '14px',
    textAlign: 'center',
    textDecoration: 'underline',
    fontWeight: 'bolder',
    lineHeight: 1.2,
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },

  body1: {
    textAlign: 'justify',
    marginTop: '10px',
    fontSize: 14,
    lineHeight: 2,
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },

  // table :{fontSize : '12px',
  // border : '1px solid black',
  // padding : 10},

  table: {
    display: 'table',
    width: '100%',
    fontSize: 12,
    // marginVertical: 6,
    border: '1px solid black',
    padding: 5,
    // textAlign : 'center'
  },

  table1: {
    display: 'table',
    width: '100%',
    fontSize: 12,
    // marginVertical: 6,
    border: '1px solid black',
    padding: 5,
    textAlign: 'center',
    fontWeight: 'bold',
  },

  tableRow: {
    flexDirection: 'row',
  },
  tableRowTotal: {
    flexDDirection: 'row',
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%',
  },
  tableColHeader: {
    width: COLN_WIDTH + '%',
  },
  tableColAmtHeader: {
    width: COL_AMT_WIDTH + '%',
  },
  tableCol1: {
    width: COL1_WIDTH + '%',
  },
  tableColAmt: {
    width: COL_AMT_WIDTH + '%',
  },
  tableCol: {
    width: COLN_WIDTH + '%',
  },
  tableColTotal: {
    width: 2 * COLN_WIDTH + '%',
  },
  tableCellHeader: {
    // marginRight: 5,
    fontWeight: 'bold',
  },
  tableCell: {
    marginVertical: 1,
    // marginRight: 4,
  },

  lineHead: {
    marginVertical: 10,
    fontSize: 13,
    marginLeft: 22,
  },
})

export default function RemittanceSlipPDF({ match }) {
  const history = useNavigate()
  const batch_no = useParams().batch_no
  const [remittance, setRemittance] = useState([])
  const [
    tresuryDetails,
    // setTresuryDetails
  ] = useState({})
  const [mdaDetails, setMdaDetails] = useState([])

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: 'select_distinct',
      status: 'committed',
      batch_no,
    })
      .then((data) => {
        console.log('select_distinct', data)
        if (data.result) {
          const Rem = data.result
          const newArr = Rem.filter(
            (item, idx) => item.description === null,
          ).slice(0, -1)
          setRemittance(newArr)
        }
      })

      .catch((err) => {
        console.log(err)
      })
  }, [batch_no])

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: 'select_distinct_mda_info',
      status: 'committed',
      batch_no,
    })
      .then((data) => {
        console.log('ggy', data)
        if (data.result) {
          setMdaDetails(data.result)
          console.log('ggy', data)
          // mda()
        }
      })

      .catch((err) => {
        console.log(err)
      })
  }, [batch_no])

  const [qrCodeDataUri, setQrCodeDataUrl] = useState('')

  useEffect(() => {
    QRCode.toDataURL('test').then((data) => {
      setQrCodeDataUrl(data)
    })
  }, [])

  return (
    <>
      <Row className="my-1">
        <Col md={9}></Col>
        <Col>
          <Button
            className="btn-danger"
            onClick={() => history('/remittance/index')}
          >
            <FontAwesomeIcon icon={faTimes} className="me-2" /> Close
          </Button>
        </Col>
      </Row>
      {/* {JSON.stringify({remittance, mdaDetails, batch_no})} */}
      <RenderPDF
        remittance={remittance}
        mdaDetails={mdaDetails}
        tresuryDetails={tresuryDetails}
        qrImage={qrCodeDataUri}
      />
    </>
  )
}

function RenderPDF({ mdaDetails, remittance, tresuryDetails, qrImage = '' }) {
  return (
    <PDFViewer height="1000" width="1000">
      <Document>
        {remittance.map((item) => (
          <Page size="A4" style={styles.page} key={item.id}>
            <View style={{ textAlign: 'right' }}>
              <Text>TKS 131</Text>
            </View>

            <View style={styles.middleHeader}>
              <Text>OFFICE OF THE ACCOUNTANT GENERAL </Text>
              <Text style={{ marginTop: 5 }}> MINISTRY OF FINANCE</Text>
              <Text style={{ marginTop: 5 }}> KATISNA STATE</Text>
            </View>

            <View style={styles.topRightHeader}>
              <View></View>

              <View
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  lineHeight: '1.5',
                }}
              >
                <Text>{tresuryDetails.treasury_source_account}</Text>
              </View>
            </View>

            <View style={styles.topLeftHeader}>
              <View>
                <Text
                  style={{
                    textDecoration: 'underline',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                >
                  {item.mda_name}
                </Text>
                <Text>____</Text>
                <Text>____</Text>
              </View>

              <View>
                <Text>Date: {moment(item.date).format('DD/MM/YYYY')}</Text>
              </View>
            </View>

            <View style={styles.middleHeader1}>
              <Text>CASH REMITTANCE SLIP </Text>
              <Text style={{ marginVertical: 10 }}>
                Month of Account {moment(item.date).format('MMMM, YYYY')}
              </Text>
            </View>

            <View style={styles.body1}>
              <Text>
                Please note that an instruction has been sent to your Bank
                through {item.mda_bank_name} Lagos Street / Bank Road, Katsina to
                credit your Account No: {item.mda_account_no} with the sum of{' '}
                {`N${parseFloat(item.amount).toLocaleString('en')}`}
              </Text>
            </View>

            <View>
              {mdaDetails
                .filter((itf) => itf.mda_name === item.mda_name)
                .map((itk, idx) => (
                  <>
                    <Text style={{ fontSize: 14 }}>
                      {idx + 1}.{itk.narration}
                      {`N${parseFloat(itk.amount).toLocaleString('en')}`}
                    </Text>
                    <Text style={styles.lineHead}>Code: __</Text>
                  </>
                ))}
            </View>

            <View>
              <Text style={{ fontSize: 14, marginVertical: 15 }}>
                Let us have your official reciept as early as possible
              </Text>
            </View>
            <Text>__</Text>

            <View style={{ display: 'flex', justifyContent: 'between' }}>
              <Text style={{ fontSize: 13, marginTop: 15 }}>
                For Accountant General
              </Text>
              <Text style={{ fontSize: 13, marginTop: 10 }}>Katsina State</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 40,
              }}
            >
              <Image
                source={{ uri: qrImage }}
                style={{ height: 100, width: 100 }}
              />
            </View>
          </Page>
        ))}
      </Document>
    </PDFViewer>
  )
}
