import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Form, InputGroup, Modal } from "react-bootstrap";
import CustomTable from "../../../components/CustomTable";
import { formatNumber } from "../../../redux/utils";
import { postApi } from "../../../redux/api";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import toast from "react-hot-toast";

export default function ReviewCashBanking({ type = null }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false)
  const [filterText, setFilterText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState(type || "All");
  const [budgetUtilization, setBudgetUtilization] = useState([]);
  const [selectedReleases, setSelectedReleases] = useState([])
  const user = useSelector((state) => state.auth.user);
  const [confirmationModalOpen, setConfirmationModal] = useState(false)
  const history = useNavigate()

  const getApprovalCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: "cb_by_mda",
        filter: "requested",
        mda_name: user ? user.mda_name : "",
      },
    })
      .then((data) => {
        if (data.result) {
          const arr = data.result.map((item) => ({ ...item, isOpen: false }));
          setBudgetUtilization(arr);
        }
      })
      .catch((err) => {
        console.error("Error fetching approval collection:", err);
      })
      .finally(() => setLoading(false));
  }, [filter]);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  let list = [];
  budgetUtilization.forEach((item) => {
    if (
      item.approval_no.indexOf(filterText) === -1 &&
      item.approval_no.toString().indexOf(filterText.toLowerCase()) === -1
    )
      return;
    else list.push(item);
  });

  const finalList = [];
  list.forEach((item) => {
    if (item.approval_no.toString().indexOf(searchTerm) === -1) return;
    finalList.push(item);
  });

  const onCheck = (item) => {
    let newList = []
    let itemFound = selectedReleases.find(s => s.approval_no === item.approval_no)
    if(itemFound) {
        newList = selectedReleases.filter(s => s.approval_no !== item.approval_no)
    } else {
        newList = [...selectedReleases,item]
    }
    setSelectedReleases(newList)
  }

  const onCheckAll = () => {
    if(selectedReleases.length) {
        setSelectedReleases([])
    } else {
        setSelectedReleases(finalList)
    }
  }

  const fields = [
    {
      title: "Selection",
      headerComponent: () => <div>
            <input type='checkbox' checked={selectedReleases.length === finalList.length} onChange={() => onCheckAll()} />
        </div>,
      custom: true,
      component: (item, index) =>(
        <div>
            <input type='checkbox' checked={selectedReleases.findIndex(s => s.approval_no === item.approval_no) !== -1} onChange={() => onCheck(item)} />
        </div>
      )
    },  
    {
      title: "Approval No",
      value: "approval_no",
    },
    // {
    //   title: "Collection Date",
    //   value: "collection_date",
    // },
    {
      title: "Approval Date",
      value: "approval_date",
    },

    {
      title: "MDA Name",
      value: "mda_name",
    },
    {
      title: "Vote Charged",
      // value: "mda_code",
      custom: true,
      component: (item) => (
        <div className="text-end">
          {item.mda_code} /{" "}
          {item?.economic_code ? item.economic_code : item.program_code}
        </div>
      ),
    },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
    },
    {
      title: "Cash Backed Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.cb_amount)}</div>
      ),
    }
    // {
    //   title: "Action",
    //   custom: true,
    //   component: (item) => (
    //     <div className="text-end">
    //       <ButtonGroup>
    //         <Button
    //           variant="outline-primary"
    //           size="sm"
    //           onClick={() =>
    //             history(
    //               `${Routes.cashBacking.processCashBacking}/${item.approval_no}`
    //             )
    //           }
    //         >
    //           Process
    //         </Button>
    //       </ButtonGroup>
    //     </div>
    //   ),
    // },
  ];

  const modalFields = [
    
    {
      title: "Approval No",
      value: "approval_no",
    },
    // {
    //   title: "Collection Date",
    //   value: "collection_date",
    // },
    {
      title: "Approval Date",
      value: "approval_date",
    },

    {
      title: "MDA Name",
      value: "mda_name",
    },
    {
      title: "Vote Charged",
      // value: "mda_code",
      custom: true,
      component: (item) => (
        <div className="text-end">
          {item.mda_code} /{" "}
          {item?.economic_code ? item.economic_code : item.program_code}
        </div>
      ),
    },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
    },
    {
      title: "Cash Backed Amount",
      component: (item) => (
        <div className="text-end">{formatNumber(item.cb_amount)}</div>
      ),
      // custom: true,
      // component: (item, index) => (
      //   <div style={{ width: "70%" }}>
      //     <Form className="navbar-search">
      //       <Form.Group id={`cashBackedAmount_${index}`}>
      //         <InputGroup className="input-group-merge search-bar">
      //           <Form.Control
      //             value={item.cb_amount}
      //             onChange={(e) =>
      //               handleCashBackedAmountChange(item, e.target.value)
      //             }
      //             type="number"
      //             placeholder="0.00"
      //           />
      //         </InputGroup>
      //       </Form.Group>
      //     </Form>
      //   </div>
      // ),
    },
  ];

  // const fields = [
  //   {
  //     title: "Approval No",
  //     value: "approval_no",
  //   },
  //   // {
  //   //   title: "Collection Date",
  //   //   value: "collection_date",
  //   // },
  //   {
  //     title: "Approval Date",
  //     value: "approval_date",
  //   },

  //   {
  //     title: "Approval Type",
  //     value: "approved_by",
  //   },
  //   // {
  //   //   title: "Status",
  //   //   value: "approval_status",
  //   // },
  //   {
  //     title: "Amount",
  //     custom: true,
  //     component: (item) => (
  //       <div className="text-end">{formatNumber(item.amount)}</div>
  //     ),
  //   },
  //   {
  //     title: "Cash Backed Amount",
  //     custom: true,
  //     component: (item) => (
  //       <div className="text-end">{formatNumber(item.cb_amount)}</div>
  //     ),
  //   },
  //   {
  //     title: "Action",
  //     custom: true,
  //     component: (item) => (
  //       <div className="text-end">
  //         <ButtonGroup>
  //           <Button
  //             variant="outline-primary"
  //             size="sm"
  //             onClick={() =>
  //               history(
  //                 `${Routes.cashBacking.reviewProcessCashBacking}/${item.approval_no}`
  //               )
  //             }
  //           >
  //             Process
  //           </Button>
  //         </ButtonGroup>
  //       </div>
  //     ),
  //   },
  // ];

  const totalCashbackingAmount = selectedReleases
  .reduce((a, b) => a + parseFloat(b.cb_amount), 0);

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      for(let item of selectedReleases) {
        await postApi(`post_approval_collection`, {
           form: {
             query_type: "update_cash_backing",
             approval_status: "Reviewed",
             approved_amount: item.cb_amount,
             mda_code: item.mda_code,
             mda_economic_code: item.economic_code,
             approval_no: item.approval_no,
           },
         })
       }

       setSubmitting(false)
       setSelectedReleases([])
       getApprovalCollection()
       toast.success("Submition Successful");
       setConfirmationModal(false)
    } catch (error) {
       setSubmitting(false)
       toast.warning("Fail to Submit");
      console.log("Error submitting data:", error);
    }
  
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Review Cash Backing</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          marginBottom: 10,
        }}
      >
        <Form className="navbar-search">
          <Form.Group id="topbarSearch">
            <InputGroup className="input-group-merge search-bar">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
                type="text"
                placeholder="Search"
              />
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body
          className="p-0"
          style={{ height: "60vh", overflow: "scroll" }}
        >
          {/* {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : ( */}
          <CustomTable fields={fields} data={finalList} />
          {/* )} */}

          <Modal
            size="xl"
            onHide={() => setConfirmationModal((p) => !p)}
            show={confirmationModalOpen}
          >
            <Modal.Header>
              <h4>Confirm Cash Backing</h4>
            </Modal.Header>
            <Modal.Body>
              <CustomTable
                fields={modalFields}
                data={selectedReleases}
              />

              <h5 className="text-right">
                Total: N{formatNumber(totalCashbackingAmount)}
              </h5>
            </Modal.Body>
            <Modal.Footer>
              {selectedReleases.length ? (
                <CustomButton onClick={handleSubmit} loading={submitting}>Continue</CustomButton>
              ) : null}
            </Modal.Footer>
          </Modal>
        </Card.Body>

        {selectedReleases.length ? (
          <Card.Footer className="text-center pb-2 pt-0">
            <Button
              style={{
                marginTop: 20,
              }}
              onClick={() => setConfirmationModal(true)}
            >
              Review CashBacking
            </Button>
          </Card.Footer>
        ) : null}
      </Card>
    </>
  );
}
