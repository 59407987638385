import React from "react";

function Scrollbar({ children, height }) {
  return (
    <div
      style={{
        // minHeight: "90vh",
        height: height ? height : "65vh",
        overflow: "scroll",
        margin: 0,
        padding: 0,
      }}
    >
      {children}
    </div>
  );
}

export default Scrollbar;
