import React, { useEffect, useState } from "react";
import { Button, Card } from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../../routes";
import CustomTable from "../../../components/CustomTable";
import { formatNumber } from "../../../redux/utils";

const TsaFundList = ({ list = [] }) => {
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const fields = [
    {
      title: "S/N",
      custom: true,
      component: (item, idx) => <span>{idx + 1}</span>,
    },
    { title: "Account Name", value: "account_name", className: "text-left" },
    {
      title: "Account Number",
      value: "account_number",
      className: "text-center",
    },
    { title: "Bank Name", value: "bank_name", className: "text-left" },

    {
      title: "Balance",
      tableHeadStyle: { textAlign: "right", color: "black" },
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>{formatNumber(item.balance)}</div>
      ),
    },
    {
      title: "Action",
      className: "text-right",
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>
          <Button
            item={item}
            onClick={() =>
              history(
                `${Routes.tsafunding.fundingReport}/${item.account_number}/${item.account_name}`
              )
            }
            className="bg-success w-110 text-white"
            size="sm"
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <CustomTable
        size="sm"
        className="file-list"
        fields={fields}
        data={list}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default TsaFundList;
