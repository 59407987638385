import React, { useState, useEffect } from "react";
import {
  Routes as RoutesContainer,
  useLocation,
  useNavigate,
  Route,
} from "react-router-dom";
import { Routes } from "../routes";

import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import NewPassword from "./examples/NewPassword";

import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";
// import TaaxSetup from  "../pages/main-treasury/taxsetup/TaxSetup"
import FaacTsaFunding from "./main-treasury/tsa-funding/FaacTsaFunding";
import NewFundingForm from "./main-treasury/tsa-funding/NewFundingForm";
import BudgetUtilizationIndex from "./main-treasury/budget-utilization/BudgetUtilizationIndex";
import { createContext } from "react";
import BudgetUtilizationReport from "./main-treasury/budget-utilization/BudgetUtilizationReport";
import BankIndex from "./main-treasury/bank/BankIndex";
import EditBankDetails from "./main-treasury/bank/EditBankDetails";
import ApprovalCollectionIndex from "./main-treasury/approval-collection/ApprovalCollectionIndex";
import PendingApproval from "./main-treasury/approval-collection/PendingApproval";
import ReviewReleases from "./main-treasury/approval-collection/ReviewReleases";
import ApprovalReleases from "./main-treasury/approval-collection/ApprovalReleases";
import EditApproval from "./main-treasury/approval-collection/EditApproval";
import NewApprovalCollection from "./main-treasury/approval-collection/NewApprovalCollection";
import Auditordashboard from "./main-treasury/Auditor-dashboard/Auditor";
import PaymentScheduleIndex from "./main-treasury/payment-schedule/PaymentScheduleIndex";
import NewSchedule from "./main-treasury/payment-schedule/NewSchedule";
import BatchProcessingIndex from "./main-treasury/batch-processing/BatchProcessingIndex";
// import AgDashboard from "./main-treasury/AgDashboard";
import AgDashboard from "./DashboardNew/index";
import { useDispatch } from "react-redux";
import { init } from "../redux/actions/auth";
import Process from "./main-treasury/batch-processing/Process";
import { MODULE_TYPES } from "../redux/utils";
import PaymentPDF from "./main-treasury/batch-processing/PaymentPDF";
import PVCollectionIndex from "./sub-treasury/pv-collection/PVCollectionIndex";
import NewPVCollection from "./sub-treasury/pv-collection/NewPVCollection";
import ContractorScheduleIndex from "./sub-treasury/contractor-schedule/ContractorScheduleIndex";
import NewContractorSchedule from "./sub-treasury/contractor-schedule/NewContractorSchedule";
import PaymentAdvicePDF from "./main-treasury/batch-processing/PaymentAdvicePDF";
import FundingReport from "./main-treasury/tsa-funding/FundingReport";
import RemittanceSlipPDF from "./main-treasury/batch-processing/RemittanceSlipPDF";
import UsersList from "./examples/user-admin/UsersList";
import NewUser from "./examples/user-admin/NewUser";
import STLetterPDF from "./main-treasury/batch-processing/STLetterPDF";
import ViewContractor from "./sub-treasury/contractor-schedule/components/ViewContractorSchedule";
import ViewContractScheduleWithTaxDetails from "./sub-treasury/contractor-schedule/ViewContractScheduleWithTaxDetails";
import ExpenditureReport from "./sub-treasury/reports/ExpenditureReport";
import BudgetDashboard from "./main-treasury/budget-utilization/BudgetDashboard";
import UploadBudgetSummary from "./main-treasury/budget-utilization/UploadBudgetSummary";
import Footer from "../components/Footer";
import TaxSetup from "../pages/main-treasury/taxsetup/TaxSetup";
import CashBackingIndex from "./sub-treasury/cash-backing/CashBackingIndex";
import ViewPV from "./sub-treasury/cash-backing/ViewPV";
import PaymentScheduleRecurrent from "./main-treasury/payment-schedule/PaymentScheduleRecurrent";
import PaymentScheduleCapital from "./main-treasury/payment-schedule/PaymentScheduleCapital";
import NewScheduleList from "./sub-treasury/contractor-schedule/NewScheduleList";
import NewPv from "./sub-treasury/pv-collection/NewPv";
import PvCollectionReports from "./sub-treasury/pv-collection/PVCollectionReport";
import NewViewPV from "./sub-treasury/cash-backing/NewPvView";
import CashBackingPDFPage from "./sub-treasury/cash-backing/CashBackingPDFPage";
import NewRecurrentPaymentSchedule from "./main-treasury/payment-schedule/NewRecurrentPaymentSchedule";
import PvPdfPage from "./sub-treasury/pv-collection/PvPdfPage";
import PVReviewIndex from "./sub-treasury/pv-collection/PVReviewIndex";
import ReceivingOfficeExpIndex from "./expenditure-control/receiving-office/ReceivingOfficeExpIndex";
import ReceivingOfficePvListView from "./expenditure-control/receiving-office/ReceivingOfficePvListView";
import STReceivingOffice from "./sub-treasury/st-office/STReceivingOffice";
import STReceivingOfficeViewPayment from "./sub-treasury/st-office/STReceivingOfficeViewPayment";
import CommissionerDashboard from "./dashboard/CommissionerDashboard";
import PSDashboard from "./dashboard/PSDashboard";
import ReviewCashBanking from "./sub-treasury/cash-backing/ReviewCashBanking";
import PendingCashBacking from "./sub-treasury/cash-backing/PendingCashBacking";
import ApprovedCashBacking from "./sub-treasury/cash-backing/ApprovedCashBacking";
import ProcessCashBacking from "./sub-treasury/ProcessCashBacking/PendingProcessCashBacking";
import ReviewProcessCashBanking from "./sub-treasury/ProcessCashBacking/ReviewProcessCashBanking";
import ApprovedProcessCashBanking from "./sub-treasury/ProcessCashBacking/ApprovedProcessCashBanking";
import DSTOffice from "./sub-treasury/st-office/DST";
import CashBackingRequest from "./main-treasury/approval-collection/CashBackingRequest";
import CashBackingRequestPDF from "./main-treasury/batch-processing/CashBackingRequestPDF";
import ViewCashbackingRequest from "./main-treasury/approval-collection/ViewCashbackingRequest";
import ViewApproval from "./main-treasury/approval-collection/ViewApproval";

import Profile from "../components/Profile";
import ApprovalCollectionAgDash from "./main-treasury/approval-collection/ApprovalCollectionAgDash";
import { PaymentSchedulePdf } from "./sub-treasury/pv-collection/PaymentSchedulePdf";
import ManageAccount from "./accounts/ManageAccount";
import AccountReport from "./accounts/AccountReport";
import AccountForm from "./accounts/AccountForm";
import ViewAccount from "./accounts/ViewAccount";
import ADExpContrApprovals from "./main-treasury/approval-collection/ADExpContrApprovals";
import AuditorGeneralViewApproval from "./main-treasury/approval-collection/AuditorGeneralViewApproval";
import AuditorpvDashboard from "./sub-treasury/pv-collection/AuditorpvDashboard";
import AuditorPvPdfPage from "./sub-treasury/pv-collection/AuditorPVPdf";
import EditPvCollection from "./sub-treasury/pv-collection/EditPvCollection";
import ViewApprovalAG from "./main-treasury/approval-collection/ViewApprovalAg";
import AGApprovedReleases from "./main-treasury/approval-collection/AGApprovedReleases";
import PaymentRequestIndex from "./main-treasury/payment-request/PaymentRequestIndex";
import ApprovalCollectionAuditorGeneralDash from "./main-treasury/approval-collection/ApprovalCollectionAuditorGeneralDash";
import ReviewPaymentRequest from "./main-treasury/payment-request/ReviewPaymentRequest";
import ViewPaymentRequestDetail from "./main-treasury/payment-request/ViewPaymentRequestDetail";
import ApprovePaymentRequest from "./main-treasury/payment-request/ApprovePaymentRequest";
import ViewPaymentRequestDetailForFinalApproval from "./main-treasury/payment-request/ViewPaymentRequestDetailForFinalApproval";
import EditReturnPvCollection from "./sub-treasury/pv-collection/EditReturnPvCollection";
import ReleaseCollectionPDFContainer from "./main-treasury/approval-collection/ReleaseCollectionPDFContainer";
import { ReleaseCollectionPDF } from "./main-treasury/approval-collection/ReleaseCollectionMainPDF";
import BackButton from "../components/BackButton";

export const LoaderWrapper = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {" "}
      <Preloader show={loaded ? false : true} /> {props.children}{" "}
    </>
  );
};

export const SearchContext = createContext("");

const RouteWithSidebar = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <>
      <Preloader show={loaded ? false : true} />
      <Sidebar />

      <main className="content">
        <Navbar />
        <div className="px-2">{props.children}</div>
        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
      </main>
    </>
  );
};

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const initialRoute = location.pathname;
    dispatch(
      init(
        () => {
          history(initialRoute);
        },
        () => {
          if (initialRoute.includes("sign-in")) {
            // history(Routes.Signin.path);
          // } else if (initialRoute.includes("sign-up")) {
          //   console.log(".");
          } else if (initialRoute.includes("reset-password") ||
          initialRoute.includes("sign-in")) {
            history(Routes.ForgotPassword.path);
          } else {
            // history(`${Routes.Signin.path}?rdr=` + initialRoute);
          }
        }
      )
    );
  }, [dispatch, location.pathname, history]);

  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm }}>
      <RoutesContainer>
        <Route exact path={Routes.Presentation.path} element={<Signin />} />
        <Route exact path={Routes.Signin.path} element={<Signin />} />
        <Route exact path={Routes.Signup.path} element={<Signup />} />
        <Route exact path={Routes.ForgotPassword.path} element={<ForgotPassword />} />
        <Route
          exact
          path={Routes.ResetPassword.path}
          element={<ResetPassword />}
        />
        <Route exact path={Routes.NewPassword.path} element={<NewPassword />} />
        <Route exact path={Routes.Lock.path} element={<Lock />} />
        <Route exact path={Routes.NotFound.path} element={<NotFoundPage />} />
        <Route exact path={Routes.ServerError.path} element={<ServerError />} />

        <Route
          exact
          path={Routes.DashboardOverview.path}
          element={
            <RouteWithSidebar>
              <AgDashboard />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.tsafunding.faacTsaFundingWithParams}
          element={
            <RouteWithSidebar>
              <FaacTsaFunding />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.tsafunding.newfundingWithParams}
          element={
            <RouteWithSidebar>
              <NewFundingForm />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.tsafunding.fundingReportWithParams}
          element={
            <RouteWithSidebar>
              <FundingReport />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.budget.budgetUtlization}
          element={
            <RouteWithSidebar>
              <BudgetUtilizationIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.budget.uploadBudget}
          element={
            <RouteWithSidebar>
              <UploadBudgetSummary />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.budget.budgetDashboard}
          element={
            <RouteWithSidebar>
              <BudgetDashboard />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.budget.budgetUtlizationReport}
          element={
            <RouteWithSidebar>
              <BudgetUtilizationReport />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.budget.budgetUtlizationReportDynamicRoute}
          element={
            <RouteWithSidebar>
              <BudgetUtilizationReport />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.bank.bankIndex}
          element={
            <RouteWithSidebar>
              <BankIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.bank.editBank}
          element={
            <RouteWithSidebar>
              <EditBankDetails />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.bank.editBankDynamicRoute}
          element={
            <RouteWithSidebar>
              <EditBankDetails />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.auditordashboard.auditorIndex}
          element={
            <RouteWithSidebar>
              <Auditordashboard />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.taxSetup.taxIndex}
          element={
            <RouteWithSidebar>
              <TaxSetup />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.approval.approvalIndex}
          element={
            <RouteWithSidebar>
              <ApprovalCollectionIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.approval.pendingApproval}
          element={
            <RouteWithSidebar>
              <PendingApproval />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.approval.agApprovalDashboard}
          element={
            <RouteWithSidebar>
              <ApprovalCollectionAgDash />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.approval.auditorGeneralApprovalDashboard}
          element={
            <RouteWithSidebar>
              <ApprovalCollectionAuditorGeneralDash />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.approval.reviewReleases}
          element={
            <RouteWithSidebar>
              <ReviewReleases />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.approval.approvalReleases}
          element={
            <RouteWithSidebar>
              <ApprovalReleases />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.approval.newApproval}
          element={
            <RouteWithSidebar>
              <NewApprovalCollection />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.approval.editApprovalRouteDynamic}
          element={
            <RouteWithSidebar>
              <ViewApproval />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.approval.editApprovalRouteDynamicAg}
          element={
            <RouteWithSidebar>
              <ViewApprovalAG />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.approval.agApprovedReleases}
          element={
            <RouteWithSidebar>
              <AGApprovedReleases />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.approval.AuditorGeneraleditApprovalRouteDynamic}
          element={
            <RouteWithSidebar>
              <AuditorGeneralViewApproval />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.paymentSchedule.index}
          element={
            <RouteWithSidebar>
              <PaymentScheduleIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.paymentSchedule.capital}
          element={
            <RouteWithSidebar>
              <PaymentScheduleCapital />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.paymentSchedule.recurrent}
          element={
            <RouteWithSidebar>
              <PaymentScheduleRecurrent />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.paymentSchedule.viewSchedule}
          element={
            <RouteWithSidebar>
              <BatchProcessingIndex pageType="committed" status="committed" />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.paymentSchedule.newSchedule}
          element={
            <RouteWithSidebar>
              <NewSchedule />
            </RouteWithSidebar>
          }
        />
        {/* <Route exact path={Routes.paymentSchedule.newScheduleWithoutApproval} element={<RouteWithSidebar><NewSchedule /></RouteWithSidebar>} /> */}
        <Route
          exact
          path={Routes.paymentSchedule.newScheduleWithApproval}
          element={
            <RouteWithSidebar>
              <NewSchedule />
            </RouteWithSidebar>
          }
        />

        {/* <Route
          exact
          path={Routes.batchProcessing.index}
          element={
            <RouteWithSidebar>
              <BatchProcessingIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.batchProcessing.process}
          element={
            <RouteWithSidebar>
              <Process />
            </RouteWithSidebar>
          }
        /> */}

        <Route
          exact
          path={Routes.expenditureControl.dirExpPaymentScheduleIndex}
          element={
            <RouteWithSidebar>
              <BatchProcessingIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.expenditureControl.dirExpPaymentScheduleViewDynamic}
          element={
            <RouteWithSidebar>
              <Process />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.dag.dagIndex}
          element={
            <RouteWithSidebar>
              <BatchProcessingIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.dag.dagApprovalViewDynamic}
          element={
            <RouteWithSidebar>
              <Process />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.expenditureControl.assDirPsIndex}
          element={
            <RouteWithSidebar>
              <BatchProcessingIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.expenditureControl.assDirPSViewDynamic}
          element={
            <RouteWithSidebar>
              <Process />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.receivingOfficeIndex}
          element={
            <RouteWithSidebar>
              <STReceivingOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.receivingOfficeViewScheduleDynamic}
          element={
            <RouteWithSidebar>
              <STReceivingOfficeViewPayment />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.checkingOfficeIndex}
          element={
            <RouteWithSidebar>
              <STReceivingOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.checkingOfficeViewScheduleDynamic}
          element={
            <RouteWithSidebar>
              <STReceivingOfficeViewPayment />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.dstOfficeIndex}
          element={
            <RouteWithSidebar>
              <STReceivingOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.dstOfficeViewScheduleDynamic}
          element={
            <RouteWithSidebar>
              {/* <STReceivingOfficeViewPayment /> */}
              <DSTOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.dstPendingScheduleOfficeIndex}
          element={
            <RouteWithSidebar>
              <STReceivingOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.dstPendingScheduleOfficeViewScheduleDynamic}
          element={
            <RouteWithSidebar>
              {/* <STReceivingOfficeViewPayment /> */}
              <DSTOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.subTreasurerPaymentReviewOfficeIndex}
          element={
            <RouteWithSidebar>
              <STReceivingOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.paymentRequest.raisePaymentRequest}
          element={
            <RouteWithSidebar>
              <PaymentRequestIndex />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.paymentRequest.reviewPaymentRequest}
          element={
            <RouteWithSidebar>
              <ReviewPaymentRequest />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.paymentRequest.viewPaymentRequestDetailDynamic}
          element={
            <RouteWithSidebar>
              <ViewPaymentRequestDetail />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.paymentRequest.viewPaymentRequestDetailFinalDynamic}
          element={
            <RouteWithSidebar>
              <ViewPaymentRequestDetailForFinalApproval />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.paymentRequest.approvePaymentRequest}
          element={
            <RouteWithSidebar>
              <ApprovePaymentRequest />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={
            Routes.subTreasury
              .subTreasurerPaymentReviewOfficeViewScheduleDynamic
          }
          element={
            <RouteWithSidebar>
              {/* <STReceivingOfficeViewPayment /> */}
              <DSTOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.approvedPaymentsIndex}
          element={
            <RouteWithSidebar>
              <STReceivingOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.approvedPaymentsViewScheduleDynamic}
          element={
            <RouteWithSidebar>
              {/* <STReceivingOfficeViewPayment /> */}
              <DSTOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.headChequesOfficeIndex}
          element={
            <RouteWithSidebar>
              <STReceivingOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.subTreasury.headChequesOfficeViewScheduleDynamic}
          element={
            <RouteWithSidebar>
              <DSTOffice />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.paymentSchedule.paymentSchedulePdfDynamic}
          element={
            <RouteWithSidebar>
              <PaymentSchedulePdf />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.batchProcessing.processWithParams}
          element={
            <RouteWithSidebar>
              <Process />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.batchProcessing.update}
          element={
            <RouteWithSidebar>
              <BatchProcessingIndex pageType="update" />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.batchProcessing.viewPaymentList}
          element={
            <RouteWithSidebar>
              <Process pageType="update" />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.batchProcessing.viewPaymentListWithParams}
          element={
            <RouteWithSidebar>
              <Process pageType="update" />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.batchProcessing.processUpdate}
          element={
            <RouteWithSidebar>
              <Process pageType="update" />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.batchProcessing.processUpdateWithParams}
          element={
            <RouteWithSidebar>
              <Process pageType="update" />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.cashBackingPDFDynamic}
          element={
            <RouteWithSidebar>
              <CashBackingPDFPage />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.cashBackingRequest}
          element={
            <RouteWithSidebar>
              <CashBackingRequest />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.ViewCashBackingRequestDynamic}
          element={
            <RouteWithSidebar>
              <ViewCashbackingRequest />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.cashBackingRequestPDF}
          element={
            <RouteWithSidebar>
              <CashBackingRequestPDF />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.releaseCollectionPDFDynamic}
          element={
            <RouteWithSidebar>
              <ReleaseCollectionPDF />
              {/* <ReleaseCollectionPDFContainer /> */}
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.processCashBackingDynamic}
          element={
            <RouteWithSidebar>
              <ProcessCashBacking />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.reviewProcessCashBackingDynamic}
          element={
            <RouteWithSidebar>
              <ReviewProcessCashBanking />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.approvedProcessCashBackingDynamic}
          element={
            <RouteWithSidebar>
              <ApprovedProcessCashBanking />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.payments.index}
          element={
            <RouteWithSidebar>
              <BatchProcessingIndex pageType="commit" />
            </RouteWithSidebar>
          }
        />
        {/* <Route exact path={Routes.payments.process} element={<RouteWithSidebar><Process /></RouteWithSidebar>} props={{ pageType: 'commit'}} type={MODULE_TYPES.MAIN_TREASURY} /> */}
        <Route
          exact
          path={Routes.payments.processWithParams}
          element={
            <RouteWithSidebar>
              <Process pageType="commit" type={MODULE_TYPES.MAIN_TREASURY} />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.payments.viewPDFWithParams}
          element={
            <RouteWithSidebar>
              <PaymentPDF pageType="commit" type={MODULE_TYPES.MAIN_TREASURY} />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.payments.viewStPDFWithParams}
          element={
            <RouteWithSidebar>
              <STLetterPDF pageType="commit" type={MODULE_TYPES.SUB_TREASURY} />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.remittance.index}
          element={
            <RouteWithSidebar>
              <BatchProcessingIndex pageType="remittance" />
            </RouteWithSidebar>
          }
        />
        {/* <Route exact path={Routes.remittance.process} element={<RouteWithSidebar><Process /></RouteWithSidebar>}  type={MODULE_TYPES.MAIN_TREASURY} /> */}
        <Route
          exact
          path={Routes.remittance.processWithParams}
          element={
            <RouteWithSidebar>
              <Process
                pageType="remittance"
                type={MODULE_TYPES.MAIN_TREASURY}
              />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.remittance.viewPDFWithParams}
          element={
            <RouteWithSidebar>
              <RemittanceSlipPDF
                pageType="remittance"
                type={MODULE_TYPES.MAIN_TREASURY}
              />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.remittance.paymentAdviceIndex}
          element={
            <RouteWithSidebar>
              <BatchProcessingIndex
                pageType="remittance"
                type={MODULE_TYPES.SUB_TREASURY}
              />
            </RouteWithSidebar>
          }
        />
        {/* <Route exact path={Routes.remittance.process} element={<RouteWithSidebar><Process /></RouteWithSidebar>} props={{ pageType: 'remittance'}} type={MODULE_TYPES.MAIN_TREASURY} /> */}
        {/* <Route exact path={Routes.remittance.viewPaymentAdvice} element={<RouteWithSidebar><Process /></RouteWithSidebar>} props={{ pageType: 'remittance', type:MODULE_TYPES.SUB_TREASURY}}  /> */}
        <Route
          exact
          path={Routes.remittance.viewPaymentAdviceWithParams}
          element={
            <RouteWithSidebar>
              <PaymentAdvicePDF
                pageType="remittance"
                type={MODULE_TYPES.SUB_TREASURY}
              />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.pv.pvlIndex}
          element={
            <RouteWithSidebar>
              <PVCollectionIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.pvReviewIndex}
          element={
            <RouteWithSidebar>
              <PVReviewIndex />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.pv.pvCheckIndex}
          element={
            <RouteWithSidebar>
              <PVReviewIndex />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.pv.pvADCheckIndex}
          element={
            <RouteWithSidebar>
              <PVReviewIndex />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.pv.UserProfile}
          element={
            <RouteWithSidebar>
              <Profile />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.commissioner_dashboard.commissionerDashboardIndex}
          element={
            <RouteWithSidebar>
              <CommissionerDashboard />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.pvApproveIndex}
          element={
            <RouteWithSidebar>
              <PVReviewIndex />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.commissioner_dashboard.psDashboard}
          element={
            <RouteWithSidebar>
              <PSDashboard />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.pvReports}
          element={
            <RouteWithSidebar>
              <PvCollectionReports />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.auditorpvReports}
          element={
            <RouteWithSidebar>
              <AuditorpvDashboard />
            </RouteWithSidebar>
          }
        />
        {/* <Route exact path={Routes.pv.newPvDynamic} element={<RouteWithSidebar><NewPv /></RouteWithSidebar>} /> */}
        <Route
          exact
          path={Routes.pv.newPvDynamic}
          element={
            <RouteWithSidebar>
              <NewPVCollection />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.editnewPvDynamic}
          element={
            <RouteWithSidebar>
              <EditReturnPvCollection />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.reviewPvDynamic}
          element={
            <RouteWithSidebar>
              <NewPVCollection />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.approvePvDynamic}
          element={
            <RouteWithSidebar>
              <NewPVCollection />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.cashBackingIndex}
          element={
            <RouteWithSidebar>
              <CashBackingIndex />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.cashBackinReview}
          element={
            <RouteWithSidebar>
              <ReviewCashBanking />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.cashBackinPending}
          element={
            <RouteWithSidebar>
              <PendingCashBacking />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.cashBackinApproved}
          element={
            <RouteWithSidebar>
              <ApprovedCashBacking />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.viewPVDynamic}
          element={
            <RouteWithSidebar>
              <ViewPV />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.NewViewPVDynamic}
          element={
            <RouteWithSidebar>
              <NewViewPV />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.commissioner_dashboard.commissionerDashboardPVDynamic}
          element={
            <RouteWithSidebar>
              <NewViewPV />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.commissioner_dashboard.PSDashboardPVDynamic}
          element={
            <RouteWithSidebar>
              <NewViewPV />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.cashBacking.NewViewPVDynamicPdf}
          element={
            <RouteWithSidebar>
              <PvPdfPage />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.pv.pvReportViewDynamic}
          element={
            <RouteWithSidebar>
              <PvPdfPage />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.pv.AuditorpvReportViewDynamic}
          element={
            <RouteWithSidebar>
              <AuditorPvPdfPage />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.pvIndividualViewDynamic}
          element={
            <RouteWithSidebar>
              <PvPdfPage />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.pv.viewPvReviewDynamic}
          element={
            <RouteWithSidebar>
              <PvPdfPage />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.viewPvGeneratedDynamic}
          element={
            <RouteWithSidebar>
              <PvPdfPage />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.editPvDynamic}
          element={
            <RouteWithSidebar>
              <EditPvCollection />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.pv.viewPvCheckDynamic}
          element={
            <RouteWithSidebar>
              <PvPdfPage />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.pv.viewPvADCheckDynamic}
          element={
            <RouteWithSidebar>
              <PvPdfPage />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.pv.viewPvApproveDynamic}
          element={
            <RouteWithSidebar>
              <PvPdfPage />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.paymentSchedule.stIndex}
          element={
            <RouteWithSidebar>
              <NewScheduleList type={MODULE_TYPES.SUB_TREASURY} />
            </RouteWithSidebar>
          }
        />
        {/* <Route exact path={Routes.paymentSchedule.stIndex} element={<RouteWithSidebar><ContractorScheduleIndex type ={MODULE_TYPES.SUB_TREASURY} /></RouteWithSidebar>}  /> */}
        <Route
          exact
          path={Routes.paymentSchedule.newStSchedule}
          element={
            <RouteWithSidebar>
              <NewContractorSchedule type={MODULE_TYPES.SUB_TREASURY} />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.paymentSchedule.viewStScheduleDynamic}
          element={
            <RouteWithSidebar>
              <ViewContractor type={MODULE_TYPES.SUB_TREASURY} />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.paymentSchedule.viewStScheduleDetailsDynamic}
          element={
            <RouteWithSidebar>
              <ViewContractScheduleWithTaxDetails
                type={MODULE_TYPES.SUB_TREASURY}
              />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.contractorSchedule.index}
          element={
            <RouteWithSidebar>
              <NewScheduleList />
              {/* <ContractorScheduleIndex /> */}
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.contractorSchedule.processRecurrentDynamic}
          element={
            <RouteWithSidebar>
              <NewRecurrentPaymentSchedule />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.contractorSchedule.processCapitalDynamic}
          element={
            <RouteWithSidebar>
              <NewRecurrentPaymentSchedule />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.contractorSchedule.newSchedule}
          element={
            <RouteWithSidebar>
              <ContractorScheduleIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.reports.expenditureReport}
          element={
            <RouteWithSidebar>
              <ExpenditureReport />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.userAdmin.index}
          element={
            <RouteWithSidebar>
              <UsersList />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.userAdmin.editUser}
          element={
            <RouteWithSidebar>
              <NewUser />
            </RouteWithSidebar>
          }
        />

        {/* expenditure control */}

        <Route
          exact
          path={Routes.expenditureControl.receivingOfficeIndex}
          element={
            <RouteWithSidebar>
              <ReceivingOfficeExpIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.expenditureControl.receivingOfficeViewDynamic}
          element={
            <RouteWithSidebar>
              <ReceivingOfficePvListView />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.expenditureControl.dirExpIndex}
          element={
            <RouteWithSidebar>
              <ReceivingOfficeExpIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.expenditureControl.dirExpViewDynamic}
          element={
            <RouteWithSidebar>
              <ReceivingOfficePvListView />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.expenditureControl.dirExpFinalIndex}
          element={
            <RouteWithSidebar>
              <ReceivingOfficeExpIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.expenditureControl.dirExpFinalViewDynamic}
          element={
            <RouteWithSidebar>
              <ReceivingOfficePvListView />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.expenditureControl.assDirExpIndex}
          element={
            <RouteWithSidebar>
              <ReceivingOfficeExpIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.expenditureControl.assDirFinalExpIndex}
          element={
            <RouteWithSidebar>
              <ReceivingOfficeExpIndex />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.expenditureControl.assDirExpViewDynamic}
          element={
            <RouteWithSidebar>
              <ReceivingOfficePvListView />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.expenditureControl.assDirFinalExpViewDynamic}
          element={
            <RouteWithSidebar>
              <ReceivingOfficePvListView />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.expenditureControl.expControlIndex}
          element={
            <RouteWithSidebar>
              <ReceivingOfficeExpIndex />
              {/* <ReceivingOfficeExpIndex /> */}
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.expenditureControl.expControlViewDynamic}
          element={
            <RouteWithSidebar>
              <ReceivingOfficePvListView />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.dag.dagPendingPVIndex}
          element={
            <RouteWithSidebar>
              <ReceivingOfficeExpIndex />
              {/* <ReceivingOfficeExpIndex /> */}
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.dag.dagPendingPVViewDynamic}
          element={
            <RouteWithSidebar>
              <ReceivingOfficePvListView />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.expenditureControl.expControlScheduleIndex}
          element={
            <RouteWithSidebar>
              <NewScheduleList />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.expenditureControl.expControlScheduleViewDynamic}
          element={
            <RouteWithSidebar>
              <ReceivingOfficePvListView />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.approval.adExpContrReleases}
          element={
            <RouteWithSidebar>
              <ADExpContrApprovals />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.userAdmin.editUserDynamicRoute}
          element={
            <RouteWithSidebar>
              <NewUser />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.account.manageAccountMainDynamic}
          element={
            <RouteWithSidebar>
              <ManageAccount />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.account.viewAccountDynamic}
          element={
            <RouteWithSidebar>
              <ViewAccount />
            </RouteWithSidebar>
          }
        />

        <Route
          exact
          path={Routes.account.accountForm}
          element={
            <RouteWithSidebar>
              <AccountForm />
            </RouteWithSidebar>
          }
        />
        <Route
          exact
          path={Routes.account.accountReport}
          element={
            <RouteWithSidebar>
              <AccountReport />
            </RouteWithSidebar>
          }
        />

        {/* <Redirect to={Routes.NotFound.path} /> */}
        {/* <Route path="*" element={<RouteWithSidebar><Navigate t</RouteWithSidebar>o={Routes.DashboardOverview} />} /> */}
      </RoutesContainer>
    </SearchContext.Provider>
  );
};
