import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getContractorBank, getContractorDetails } from "./api";

import { apiURL } from "../../../redux/api";
import { Button, Col, Table } from "@themesberg/react-bootstrap";
import { _fetchApi } from "../../../redux/Functions";
import { toast } from "react-hot-toast";
import { formatNumber } from "../../../redux/utils";

const ViewContractScheduleWithTaxDetails = ({ match }) => {
  const history = useNavigate();
  const id = useParams().id;
  const [data, setData] = useState([]);
  const [contractDetails, setContractDetails] = useState({});
  const [contractorDetails, setContractorDetails] = useState({});
  const [contractorBankDetails, setContractorBank] = useState([]);
  const [taxes, setTaxes] = useState([]);

  const getContractor = (id) => {
    getContractorDetails({ query_type: "info", contractor_code: id }, (d) => {
      if (d && d.length) {
        setContractorDetails(d[0]);
      }
    });
  };

  const getBankList = (id) => {
    getContractorBank(
      { query_type: "select_by_contractor", contractor_code: id },
      (d) => {
        if (d && d.length) {
          setContractorBank(d.result);
        }
      }
    );
  };

  const _getContractorTaxes = useCallback(
    (contractor_code = "") => {
      _fetchApi(
        `${apiURL}/get_taxes?query_type=get_by_contract&contract_code=${id}&contractor_code=${contractor_code}`,
        (data) => {
          if (data && data.results) {
            setTaxes(data.results);
          }
        }
      );
    },
    [id]
  );

  useEffect(() => {
    fetch(`${apiURL}/post_contractor_schedule`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        query_type: "select_by_id",
        batch_id: id,
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data?.result?.length) {
          let contract = data.result[0];
          // alert(JSON.stringify(contract))
          setContractDetails(contract);
          getContractor(contract.contractor_code);
          getBankList(contract.contractor_code);
          _getContractorTaxes(contract.contractor_code);
          // setData(data.result)
          // console.log(data.result)
        }
      })

      .catch((err) => {
        toast.error(err);
        // setLoadSpinner(false);
      });
  }, [id, _getContractorTaxes]);

  const totalTaxes = taxes.reduce(
    (a, b) => parseInt(a) + parseInt(b.amount),
    0
  );

  return (
    <>
      <Button onClick={() => history(-2)} className="btn-success">
        Click to Go Back
      </Button>
      <div className="card">
        <div className="card-header" style={{}}>
          <h3 className="text-center">Individual Details</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <h5 className="text-center">Contract Details</h5>
            <KeyVal
              label="Contractor"
              value={contractorDetails.contractor_name}
            />
            <KeyVal label="MDA Name" value={contractDetails.mda_name} />
            <KeyVal
              label="Contractor TIN"
              value={contractorDetails.contractor_tin_no}
            />
            <KeyVal label="MDA TIN" value={contractDetails.mda_tin} />
            <KeyVal
              label="Project Name"
              value={contractDetails.project_description}
            />
            <KeyVal label="Project Type" value={contractDetails.project_type} />
            <KeyVal label="Payment Type" value={contractDetails.payment_type} />
            <KeyVal
              label="Gross Amount"
              value={formatNumber(contractDetails.amount)}
            />
            <KeyVal
              label="Project Classification"
              value={contractDetails.project_classification}
            />
            <KeyVal
              label="Total Taxes"
              value={formatNumber(contractDetails.total_taxes)}
            />
            {/* <KeyVal label="" value={''} /> */}
            <div className="col-md-6 row m-0 p-0 my-1"></div>
            <KeyVal
              label="Net Amount"
              value={formatNumber(
                parseFloat(contractDetails.amount) -
                  parseFloat(contractDetails.total_taxes)
              )}
            />
          </div>

          {/* {JSON.stringify({ id, contractDetails })} */}
          {/* {JSON.stringify({ contractorDetails })} */}

          {/* {JSON.stringify({ taxes })} */}
          {/* {JSON.stringify({ contractorBankDetails })} */}
          {/* {data.map((item) => {
					return(
					<Row>
            <Col md={6}>
              <p>    <b>Contractor Name:</b>  </p>  
              <p>    <b>Address:</b> {item.address}</p>
              <p>    <b>Project Details:</b>{item.project_description}</p>
              <p>    <b>Contract No:</b></p>
              <p>    <b>Amount:</b>{item.amount}</p>
              <p className="text-center">    <b>Taxes</b></p>

              <p>    <b>VAT:</b> {item.VAT}</p>
              <p>    <b>WHT:</b> {item.WHT}</p> 
              <p>    <b>SD:</b> {item.SD}</p>
              <p>    <b>WR:</b> {item.WR}</p>
              <p>    <b>Tender:</b> {item.tender}</p>
              <p>    <b>Others:</b> {item.others}</p>
              <p>    <b>NCT:</b></p>
            </Col>

    

    <Col md={6}>
    
    <p>   <b>PV Date:</b> {item.date}</p>
    <p>    <b>Phone:</b></p>
    <p>    <b>Project Classification:</b> {item.project_classification}</p> 
    <p>    <b>MDA:</b> {item.mda_name}</p>
    </Col>
    </Row>
				)})
			} */}
          <div className="mt-4">
            <h6 style={{ fontWeight: "bold" }}>Tax Details</h6>
            <Col md={6}>
              <Table size="sm" bordered>
                <thead>
                  <tr>
                    <th>Tax Description</th>
                    <th>Amount (N)</th>
                  </tr>
                </thead>
                <tbody>
                  {taxes.map((item, i) => (
                    <tr key={i}>
                      <td>{item.description}</td>
                      <td style={{ textAlign: "right" }}>
                        {formatNumber(item.amount)}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <th>Total Taxes</th>
                    <th style={{ textAlign: "right" }}>
                      {formatNumber(totalTaxes)}
                    </th>
                  </tr>
                </tbody>
              </Table>
            </Col>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

function KeyVal({ label = "", value = "" }) {
  return (
    <div className="col-md-6 row m-0 p-0 my-1">
      <div className="col-md-5 fw-bold">{label}:</div>
      <div className="col-md-7">{value}</div>
    </div>
  );
}

export default ViewContractScheduleWithTaxDetails;
