import React, { useEffect, useState, useCallback } from "react";
import { Card, Form } from "@themesberg/react-bootstrap";
import CustomTable from "../../../components/CustomTable";
import BackButton from "../../../components/BackButton";
import useQuery from "../../../hooks/useQuery";
import { fetchApi } from "../../../redux/api";
import { useParams } from "react-router-dom";

export default function FundingReport({ match }) {
  const query = useQuery();
  const params = useParams();
  const accountTitle = params.account_title;
  // const account_number = params.account_code
  console.log("match...", match);
  const [searchTerm, setSearchTerm] = useState("");
  const funding_code = params.account_code;
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([]);
  const [
    ,
    // tresuryDetails
    setTresuryDetails,
  ] = useState({});

  const fields = [
    // {
    //   title: 'S/N',
    //   custom: true,
    //   component: (item, idx) => <span>{idx + 1}</span>,
    // },
    {
      title: "Serial No",
      component: (i) => i.Serial_number,
      className: "text-center",
    },
    { title: "Fund Date", value: "fund_date", className: "text-center" },

    { title: "Account No", value: "Account_number", className: "text-center" },

    {
      title: "Dr",
      value: "dr",
      className: "text-right",
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>
          {parseFloat(item.dr).toLocaleString("en")}
        </div>
      ),
    },

    {
      title: "Cr",
      tableHeadStyle: { textAlign: "right", color: "black" },
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>
          {parseFloat(item.cr).toLocaleString("en")}
        </div>
      ),
    },
  ];

  const getAccountHistory = useCallback(() => {
    fetchApi(`tsa-account?query_type=history&account=${funding_code}`)
      .then((data) => {
        if (data.results) {
          setPaymentScheduleTable(data.results);
          setTresuryDetails(data.results[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [funding_code]);

  useEffect(() => {
    getAccountHistory();
    // fetch(`${api}/post_tsa_funding_s`, {
    //   method: "POST",
    //   headers: {
    //     "Content-type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     query_type : 'select_by_mda_account_number',
    //     account_number : account_number

    //   }),
    // }).then(function (response) {
    //   return response.json();
    // })
    //   .then((data) => {
    //     console.log("data", data)
    //    if(data.result){
    //     setPaymentScheduleTable(data.result);
    //     setTresuryDetails(data.result[0])

    //    }
    //   })

    //   .catch((err) => {
    //     // alert(err);
    //     // setLoadSpinner(false);
    //   });
  }, [getAccountHistory]);

  let rows = [];

  paymentScheduleTable &&
    paymentScheduleTable.forEach((item) => {
      if (
        item.funding_code &&
        item.funding_code.toLowerCase().indexOf(searchTerm) === -1 &&
        item.total_amount.indexOf(searchTerm) === -1 &&
        item.total_amount.toLowerCase().indexOf(searchTerm) === -1
      ) {
        return;
      }

      rows.push(item);
    });

  return (
    <>
      <BackButton />
      {/* <Button
          onClick={() => history(-2)}
          style={{ backgroundColor: 'rgb(25, 135, 84)', color: 'white' }}
        >
          Back
        </Button> */}
      <Card>
        <Card.Header>{accountTitle}</Card.Header>
        <Card.Body>
          <form>
            <Form.Control
              placeholder="Search"
              style={{ padding: "4px", margin: "5px", width: "100%" }}
            />
          </form>

          <CustomTable
            headBackground="white"
            headColor="black"
            size="sm"
            className="file-list"
            fields={fields}
            data={rows}
          />
        </Card.Body>
      </Card>
    </>
  );
}
