import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createToken,
  fetchApi,
  postApi,
  updateApi,
  verifyToken,
} from "../../redux/api";
import Cards from "react-credit-cards";
import { Card, Row, Col, Button } from "@themesberg/react-bootstrap";
import { Alert, Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import CustomButton from "../../components/CustomButton";
import BackButton from "../../components/BackButton";
// import { useHistory } from "react-router-dom";

function ViewAccount() {
  const { id } = useParams();
  const [pin, setPin] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenUp, setModalIsOpenUp] = useState(false);
  const [modalIsOpenChange, setModalIsOpenChange] = useState(false);
  const [focus, setFocus] = useState("name");
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingChange, setLoadingChange] = useState(false);
  const navigate = useNavigate();
  const [accountInfo, setAccountInfo] = useState({
    cvv2: "",
    expiry_date: "",
    account_name: "",
    card: "",
  });
  const [details, setDetails] = useState("");
  const toggle1 = () => setModalIsOpenUp((p) => !p);

  const toggle = () => setModalIsOpen((p) => !p);
  const getCard = useCallback(() => {
    setLoading(true);
    fetchApi(`pan_details/get-unique/${id}`)
      .then(async (resp) => {
        setLoading(false);
        console.log(resp);
        if (resp && resp.success) {
          let decoded = await verifyToken(resp.data[0].pan_info);
          setAccountInfo({ ...resp.data[0], ...decoded });
        }
        setDetails(
          `${resp.details?.error?.message}(Error Code:${resp.details?.error?.code})`
        );
        console.log("resp");
      })
      .catch((error) => {
        setLoading(false);
        setDetails(error.details?.error?.message);
        console.log("err");
        console.log(error);
        console.log("err");
      });
  }, [id]);
  useEffect(() => {
    getCard();
  }, [getCard]);

  const handleInputChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));
  const handleUpdate = () => {
    setLoadingUpdate(true);
    updateApi("pan_details/update-pin", {
      status: "de-activated",
      id: accountInfo.id,
    })
      .then((resp) => {
        if (resp) {
          toast.success("Card De-Activated");
          navigate(-2);
        }
        setLoadingUpdate(false);
      })
      .catch((err) => {
        setLoadingUpdate(false);
        toast.error("Error Occured");
        console.log(err);
      });
  };

  const handleChangePin = () => {
    const token = createToken({
      newPin: form.newPin,
      oldPin: form.oldPin,
    });

    if (form.newPin === "" || form.oldPin === "") {
      alert("The form is not completed");
    } else {
      setLoadingChange(true);
      postApi("autopay/change-pin", { token, id })
        .then((resp) => {
          setLoadingChange(false);
          if (resp.success) {
            toast.success("Done");
            toggle();
          } else {
            toast.error("Failed");
          }
        })
        .catch((err) => {
          setLoadingChange(false);
          toast.error("Failed");
          console.log(err);
        });
    }
  };

  return (
    <>
      <BackButton />
      <Card body>
        {loading ? (
          <center>
            {" "}
            <Spinner size="sm" />
          </center>
        ) : (
          <>
            {/* {JSON.stringify()} */}
            {accountInfo.card !== "" ? (
              <>
                {" "}
                <div className="my-4">
                  <Button onClick={toggle} size="sm" className="me-4">
                    Change Card PIN
                  </Button>
                  <Button onClick={toggle1} size="sm">
                    Deactivate Card
                  </Button>
                </div>
                <Row>
                  <Col>
                    <div
                      onClick={() =>
                        setFocus((p) => (p === "cvc" ? "name" : "cvc"))
                      }
                    >
                      <Cards
                        cvc={accountInfo.cvv2}
                        expiry={accountInfo.expiry_date}
                        focused={focus}
                        name={accountInfo.account_name}
                        number={accountInfo.card}
                        preview={false}
                        //   preview={false}
                      />
                    </div>
                  </Col>
                  <Col>
                    <h1>{accountInfo?.account_number}</h1>
                    <div>
                      Card Name:{" "}
                      {`${accountInfo.account_name}(${accountInfo.account_name})`}
                    </div>
                    <div>Card Number: {accountInfo.card}</div>
                    <div>Valid Till: {accountInfo.expiry_date}</div>
                    <div>CVV2: {accountInfo.cvv2}</div>
                  </Col>
                </Row>
                <Modal show={modalIsOpen} onHide={toggle}>
                  <Modal.Header>Change Card PIN</Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col>
                        <label>Old PIN</label>
                        <input
                          onChange={handleInputChange}
                          name="oldPin"
                          className="form-control"
                          value={form.oldPin}
                          type="password"
                          required
                          maxLength="4"
                        />
                      </Col>
                      <Col>
                        <label>New PIN</label>
                        <input
                          onChange={handleInputChange}
                          name="newPin"
                          className="form-control"
                          value={form.newPin}
                          type="password"
                          required
                          maxLength="4"
                        />
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={toggle}>Close</Button>
                    <CustomButton
                      loading={loadingChange}
                      onClick={handleChangePin}
                    >
                      Submit
                    </CustomButton>
                  </Modal.Footer>
                </Modal>
                <Modal show={modalIsOpenUp} onHide={toggle}>
                  <Modal.Header>Change Card PIN</Modal.Header>
                  <Modal.Body>
                    <Row>
                      <h4 className="text-center">
                        Are you sure you want to de-activated{" "}
                        {`${accountInfo.account_name}(${accountInfo.accountNumber})`}
                      </h4>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={toggle1}>Close</Button>
                    <CustomButton
                      loading={loadingUpdate}
                      onClick={handleUpdate}
                    >
                      Submit
                    </CustomButton>
                  </Modal.Footer>
                </Modal>
              </>
            ) : (
              <>
                {" "}
                <Alert>
                  <h4 className="text-center">
                    {details ||
                      "Card provider error; please click refresh the page."}
                  </h4>
                </Alert>
                <div className="text-center">
                  <button type="button" class="btn btn-link " onClick={getCard}>
                    Refresh
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
}

export default ViewAccount;
