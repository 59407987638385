import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postApi } from "../../../redux/api";
import { Button, Col, FormLabel, Row, Spinner } from "react-bootstrap";
import { Card } from "@themesberg/react-bootstrap";
import { Input } from "react-bootstrap-typeahead";
import TextInput from "../../../components/TextInput";
import { toast } from "react-hot-toast";

function ViewPV() {
  const history = useNavigate();
  const pv_code = useParams().pv_code;
  const [pvInfo, setPvInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (pv_code) {
      setLoading(true);
      postApi(`post_pv_collection`, {
        form: { query_type: "select_by_pvcode", pv_code },
        // status : "Paid",
        // batch_no
      })
        .then((data) => {
          console.log("data", data);
          if (data.result && data.result.length) {
            setPvInfo(data.result[0]);
          }
        })

        .catch((err) => {
          console.log(err);
          // alert(err);
          // setLoadSpinner(false);
        })
        .finally(() => setLoading(false));
    }
  }, [pv_code]);

  const handleApprove = () => {
    setSubmitting(true);

    postApi(`post_pv_collection`, {
      form: {
        query_type: "update_cash_backing",
        pv_code,
        approval_no: pv_code, // pvInfo.approval_no,
      },
      // status : "Paid",
      // batch_no
    })
      .then((data) => {
        console.log("data", data);
        if (data.success) {
          toast.success("PV Approved Successfully!");
          history(-2);
        } else {
          toast.error("An error occurred, please try again later.");
        }
      })
      .catch((err) => {
        console.log(err);
        // alert(err);
        // setLoadSpinner(false);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Card className="my-2">
      <Card.Header>
        <h4>Release No.: {pv_code}</h4>
      </Card.Header>
      <Card.Body>
        {loading && <Spinner />}

        {/* {JSON.stringify(pvInfo)} */}
        <Row>
          <Col md={3} className="my-2">
            <TextInput
              label="Approval No."
              disabled
              value={pvInfo.approval_no}
            />
          </Col>
          <Col md={3} className="my-2">
            <TextInput label="PV Date" disabled value={pvInfo.pv_date} />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Project Type"
              disabled
              value={pvInfo.project_type}
            />
          </Col>

          <Col md={3} className="my-2">
            <TextInput label="MDA Name" disabled value={pvInfo.mda_name} />
          </Col>

          <Col md={3} className="my-2">
            <TextInput label="Admin Code" disabled value={pvInfo.admin_code} />
          </Col>

          <Col md={3} className="my-2">
            <TextInput
              label="Admin Description"
              disabled
              value={pvInfo.admin_description}
            />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Economic Code"
              disabled
              value={pvInfo.economic_code}
            />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Economic Description"
              disabled
              value={pvInfo.economic_description}
            />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Vote Balance"
              disabled
              value={pvInfo.vote_amount}
            />
          </Col>
          <Col md={3} className="my-2">
            <TextInput label="Amount" disabled value={pvInfo.amount} />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Payment Type"
              disabled
              value={pvInfo.payment_type}
            />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Payment Class"
              disabled
              value={pvInfo.payment_class}
            />
          </Col>
          <h5 className="my-2 mt-4">Payee Details</h5>
          <Col md={3} className="my-2">
            <TextInput label="Name" disabled value={pvInfo.contractor_name} />
          </Col>
          <Col md={3} className="my-2">
            <TextInput label="Phone" disabled value={pvInfo.contractor_phone} />
          </Col>
          <Col md={3} className="my-2">
            <TextInput label="Email" disabled value={pvInfo.contractor_email} />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Address"
              disabled
              value={pvInfo.contractor_address}
            />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Account Name"
              disabled
              value={pvInfo.contractor_acc_name}
            />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Bank Name"
              disabled
              value={pvInfo.contractor_bank_name}
            />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Account Number"
              disabled
              value={pvInfo.contractor_acc_no}
            />
          </Col>
          <Col md={3} className="my-2">
            <TextInput
              label="Sort Code"
              disabled
              value={pvInfo.contractor_address}
            />
          </Col>
        </Row>

        <center className="my-4">
          <Button onClick={handleApprove}>
            {submitting ? (
              <>
                <Spinner /> Please wait..
              </>
            ) : (
              "Approve"
            )}
          </Button>
        </center>
      </Card.Body>
    </Card>
  );
}

export default ViewPV;
