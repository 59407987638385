import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Form, InputGroup } from "react-bootstrap";
import CustomTable from "../../../components/CustomTable";
import { formatNumber } from "../../../redux/utils";
import { postApi } from "../../../redux/api";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import useQuery from "../../../hooks/useQuery";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Routes } from "../../../routes";

export default function ProcessCashBacking() {
  const [cashBackedAmounts, setCashBackedAmounts] = useState([]);
  const [mda_data, setMda_data] = useState([]);
  const approval_no = useParams().approval_no;
  const history = useNavigate();

  const handleCashBackedAmountChange = (row, value) => {
    const newData = [];
    mda_data.forEach((item) => {
      if (
        item.approval_no === row.approval_no &&
        item.mda_code === row.mda_code &&
        item.economic_code === row.economic_code
      ) {
        newData.push({ ...item, cb_amount: value, valueChanged: true });
      } else {
        newData.push(item);
      }
    });
    setMda_data(newData);
    // const updatedCashBackedAmounts = [...cashBackedAmounts];
    // updatedCashBackedAmounts[index] = value;
    // setCashBackedAmounts(updatedCashBackedAmounts);
  };

  const fields = [
    {
      title: "S/N",
      custom: true,
      component: (item, index) => (
        <div className="text-center">{index + 1}</div>
      ),
    },
    {
      title: "MDA Name",
      value: "mda_name",
    },
    {
      title: "Admin Code",
      value: "mda_code",
    },
    {
      title: "Economic Code",
      value: "economic_code",
    },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
    },
    {
      title: "Cash Backed Amount",
      custom: true,
      component: (item, index) => (
        <div style={{ width: "70%" }}>
          <Form className="navbar-search">
            <Form.Group id={`cashBackedAmount_${index}`}>
              <InputGroup className="input-group-merge search-bar">
                <Form.Control
                  value={item.cb_amount}
                  onChange={(e) =>
                    handleCashBackedAmountChange(item, e.target.value)
                  }
                  type="number"
                  placeholder="0.00"
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
      ),
    },
    {
      title: "Description",
      value: "description_code",
    },
  ];

  useEffect(() => {
    if (approval_no) {
      postApi(`post_approval_collection`, {
        form: {
          query_type: "select_by_approval_no",
          approval_no,
        },
      })
        .then((data) => {
          if (data && data.result && data.result.length) {
            setMda_data(data.result);
            setCashBackedAmounts(new Array(data.result.length).fill(""));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [approval_no]);

  const handleSubmit = () => {
    const updatedList = mda_data.filter((item) => item.valueChanged);

    console.log(updatedList, "updatedList");
    for (let item of updatedList) {
      postApi(`post_approval_collection`, {
        form: {
          query_type: "update_cash_backing",
          approval_status: "Requested",
          approved_amount: item.cb_amount,
          mda_code: item.mda_code,
          mda_economic_code: item.economic_code,
          approval_no: item.approval_no,
        },
      });
    }

    toast.success("Success");
    history(Routes.cashBacking.cashBackinPending);
  };

  return (
    <>
      <ButtonGroup>
        <Button
          size="sm"
          style={{
            marginTop: 10,
          }}
          onClick={() => history(-2)}
        >
          <FontAwesomeIcon icon={faArrowCircleLeft} /> Go Back
        </Button>
      </ButtonGroup>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div
          className="d-block mb-4 mb-md-0"
          style={{
            width: "100%",
          }}
        >
          <h4>Approve Cash Backing</h4>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Approval No.: {approval_no}
          </p>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body
          className="p-0"
          style={{ height: "90vh", overflow: "scroll" }}
        >
          {/* {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : ( */}
          <CustomTable fields={fields} data={mda_data} />
          {/* )} */}
          <div
            style={{
              width: "100%",
            }}
          >
            <center>
              <ButtonGroup>
                <Button
                  size="sm"
                  style={{
                    marginTop: 20,
                  }}
                  onClick={handleSubmit}
                >
                  Raise CashBacking
                </Button>
              </ButtonGroup>
            </center>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
