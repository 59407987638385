import React, { useEffect } from "react";
import { Col, Row, Card, Form } from "@themesberg/react-bootstrap";
import SelectInput from "../../../components/SelectInput";
import { mainTreasuryModules } from "./modules";
import { useState } from "react";
import { getSector, postApi } from "../../../redux/api";
import { fetchApi } from "../../../redux/api";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "../../../components/BackButton";
import { toast } from "react-hot-toast";
import CustomTypeahead from "../../components/CustomTypeahead";
import { useNavigate } from "react-router-dom";

export function getDistinctObjects(array, key1, key2) {
  return array?.filter(
    (obj, index, self) =>
      index ===
      self?.findIndex((o) => o[key1] === obj[key1] && o[key2] === obj[key2])
  );
}

export default () => {
  const user = useSelector((state) => state.auth.user);
  const [form, setForm] = useState({
    accessTo: [],
    functionalities: [],
    role: "",
  });
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const history = useNavigate();
  // const [functionalities, setfunctionalities] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchApi("your-backend-api-endpoint")
      .then((data) => {
        setUsers(data); // Assuming data is an array of user objects
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  }, []);

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const handleCheckboxChange = (item) => {
    if (form.accessTo.includes(item.title)) {
      let newList = form.accessTo.filter((a) => a !== item.title);
      setForm((prevForm) => ({ ...prevForm, accessTo: newList }));
      setIsOpen(!isOpen);
    } else {
      let newList = [...form.accessTo, item.title];
      setForm((prevForm) => ({ ...prevForm, accessTo: newList }));
      setIsOpen(!isOpen);
    }
  };

  const handleChildChechBoxChange = (item) => {
    if (form.functionalities.includes(item.title)) {
      let newList = form.functionalities.filter((a) => a !== item.title);
      setForm((prevForm) => ({ ...prevForm, functionalities: newList }));
      // setIsOpen(!isOpen);
    } else {
      let newList = [...form.functionalities, item.title];
      setForm((prevForm) => ({ ...prevForm, functionalities: newList }));
      // setIsOpen(!isOpen);
    }
  };

  const createUser = () => {
    setLoading(true);
    postApi(`treasury-app/sign_up`, {
      ...form,
      accessTo: form.accessTo.join(","),
      role: form.role,
      functionalities: form.functionalities.join(","),
    })
      .then((resp) => {
        setLoading(false);
        history(-2);
        if (resp.success) {
          alert(resp.msg);
          setForm({ accessTo: [], functionalities: [], role: user.role });
        } else {
          alert(resp.msg);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error("An error occurred");
      });
  };
  const [results4, setResult4] = useState([]);
  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });
  }, []);

  let aheadData = results4?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));
  const [budgetSummary, setBudgetSummary] = useState([]);
  const getList = () => {
    fetchApi(`get-budget-summary?query_type=list`)
      .then((d) => {
        console.log("d", d);
        if (d.result) {
          setBudgetSummary(d.result);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, []);

  const renderSubMenu = (subMenu) => {
    return subMenu.map((subItem, index) => (
      <div
        key={index}
        style={{
          marginLeft: 20,
        }}
      >
        <label>
          <input
            className="pr-3"
            type="checkbox"
            checked={form.functionalities.includes(subItem.title)}
            onChange={() => handleChildChechBoxChange(subItem)}
          />
          {subItem.title}
        </label>
      </div>
    ));
  };

  return (
    <>
      <BackButton />
      <Card border="light" className="bg-white shadow-sm mb-4">
        {/* {JSON.stringify(form)} */}
        <Card.Body>
          <h5 className="mb-4">Create New User</h5>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="username"
                    value={form.username}
                    onChange={handleChange}
                    placeholder="Enter your username"
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="fullname"
                    value={form.fullname}
                    onChange={handleChange}
                    placeholder="Enter your Full Name"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="emal">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="***********"
                    name="password"
                    value={form.password}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="phone">
                  <SelectInput
                    label="Role"
                    size="md"
                    options={["Admin", "MDA ADMIN", "USER"]}
                    name="role"
                    value={form.role}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <CustomTypeahead
                  labelKey="mda_name"
                  label="Select MDA"
                  options={getDistinctObjects(
                    budgetSummary,
                    "mda_name",
                    "mda_code"
                  )}
                  onChange={(s) => {
                    if (s.length) {
                      console.log(s);
                      // navigate(`/admin-maping?code=${s[0].code}`);
                      setForm((p) => ({
                        ...p,
                        mda_code: s[0].mda_code,
                        mda_name: s[0].mda_name,
                      }));
                    }
                  }}
                />
              </Col>
              <Col md={6}>
                <Form.Group id="Email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                    placeholder="Enter your Email Addrress"
                  />
                </Form.Group>
              </Col>
            </Row>

            <h5 className="my-4">Permission and Access Level</h5>
            <Row style={{ margin: 0 }}>
              {mainTreasuryModules.map((item, index) => (
                <Col md="4" key={index}>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={form.accessTo.includes(item.title)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                      <b>{item.title}</b>
                    </label>
                  </div>
                  {form.accessTo.includes(item.title)
                    ? item.subMenu && renderSubMenu(item.subMenu)
                    : ""}
                </Col>
              ))}
              <Col md="4">
                <input
                  type="checkbox"
                  checked={form.accessTo.includes("User Admin")}
                  onChange={() => handleCheckboxChange("User Admin")}
                />
                <label>{"User Admin"}</label>
              </Col>
            </Row>
            <div className="mt-3 text-center">
              <CustomButton
                variant="primary"
                type="submit"
                onClick={createUser}
                loading={loading}
              >
                <FontAwesomeIcon icon={faPlus} /> Submit
              </CustomButton>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
