import React, { useState, useEffect, useContext } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import { useNavigate, useParams } from "react-router-dom";
import { PDFViewer, Font } from "@react-pdf/renderer";
import moment from "moment";
import { apiURL } from "../../../redux/api";
import { formatNumber } from "../../../redux/utils";
import { toNairaWords, toWordsconver } from "./PaymentPDF";

// import font from './styles/localFonts/Poppins-Medium.ttf'
// Create styles
const COL1_WIDTH = 40;
const COL_AMT_WIDTH = 20;
const COLN_WIDTH = (100 - (COL1_WIDTH + COL_AMT_WIDTH)) / 2;

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 70,
    paddingBottom: 70,
    paddingRight: 20,
    paddingLeft: 20,
    fontFamily: "Times-Roman",
    fontSize: 14,
    // fontFamily: "Roboto"
  },

  headerPage: {},

  topRightHeader: {
    // display : 'flex',
    // justifyContent : 'between',
    //  flexDirection: 'row',
    textAlign: "right",
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },

  topLeftHeader: {
    // flexDirection: 'row',
    textAlign: "left",
    marginTop: "100px",
    marginBottom: "25px",
    fontSize: "14px",
    color: "black",
    fontWeight: "bold",
    lineHeight: 1.5,
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },
  middleHeader: {
    // flexDirection: 'row',
    padding: 10,
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "underline",
    lineHeight: 1.2,
    fontWeight: "bold",
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },

  body1: {
    textAlign: "justify",
    marginTop: "10px",
    fontSize: 14,
    lineHeight: 1.5,
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },

  // table :{fontSize : '12px',
  // border : '1px solid black',
  // padding : 10},

  table: {
    display: "table",
    width: "18%",
    fontSize: 10,
    // fontFamily: 'Roboto',
    // marginVertical: 6,
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    paddingHorizontal: 5,
    paddingVertical: 2,
    textAlign: "center",
    fontWeight: "bold",
  },

  tablel: {
    display: "table",
    width: "18%",
    fontSize: 10,
    // fontFamily: 'Roboto',
    // marginVertical: 6,
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    paddingHorizontal: 5,
    paddingVertical: 2,
    textAlign: "left",
  },

  tableTN: {
    display: "table",
    width: "35.9%",
    fontSize: 14,
    // marginVertical: 6,
    borderBottom: "1px solid black",

    paddingHorizontal: 5,
    paddingVertical: 2,
    // textAlign : 'center'
  },

  tableSN: {
    display: "table",
    width: "7%",
    fontSize: 10,
    // marginVertical: 6,
    // border : '1px solid black',
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    // borderBottom : '1px solid black',
    borderLeft: "1px solid black",

    paddingHorizontal: 5,
    paddingVertical: 2,
    textAlign: "center",
  },
  tableLg: {
    display: "table",
    width: "21%",
    fontSize: 10,
    // marginVertical: 6,
    // border : '1px solid black',
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    // borderBottom : '1px solid black',
    // borderLeft: '1px solid black',

    paddingHorizontal: 5,
    paddingVertical: 2,
    textAlign: "center",
  },
  table2: {
    display: "table",
    width: "18%",
    fontSize: 10,
    borderBottom: "1px solid black",
    borderRight: "1px solid black",

    // marginVertical: 6,
    // border : '1px solid black',
    paddingHorizontal: 5,
    paddingVertical: 2,
    textAlign: "right",
    // textAlign : 'center'
  },

  table3: {
    display: "table",
    width: "18%",
    fontSize: 14,
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",

    // marginVertical: 6,
    // border : '1px solid black',
    padding: 5,
    textAlign: "right",
    // textAlign : 'center'
  },

  table1: {
    display: "table",
    width: "100%",
    fontSize: 14,
    // marginVertical: 6,
    border: "1px solid black",
    padding: 5,
    textAlign: "center",
    fontWeight: "bold",
  },
  leftAlign: {
    textAlign: "left",
  },

  tableRow: {
    flexDirection: "row",
  },
  tableRowTotal: {
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
  },
  tableColAmtHeader: {
    width: COL_AMT_WIDTH + "%",
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
  },
  tableColAmt: {
    width: COL_AMT_WIDTH + "%",
  },
  tableCol: {
    width: COLN_WIDTH + "%",
  },
  tableColTotal: {
    width: 2 * COLN_WIDTH + "%",
  },
  tableCellHeader: {
    // marginRight: 5,
    fontWeight: "bold",
  },
  tableCell: {
    marginVertical: 1,
    // marginRight: 4,
  },
  middleHeader: {
    padding: 10,
    fontSize: "14px",
    textAlign: "left",
    fontWeight: "bolder",
    lineHeight: 1.2,
    fontSize: 14,
  },
});

// System for American Numbering
var th_val = ["", "thousand", "million", "billion", "trillion"];
// System for uncomment this line for Number of English
// var th_val = ['','thousand','million', 'milliard','billion'];

var dg_val = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];
var tn_val = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];
var tw_val = [
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

const toCamelCase = (str = "") => {
  return str && str[0].toUpperCase() + str.substr(1);
};

// Create Document Component
export default function CashBackingRequestPDF({
  match,
  type = "sub_treasury",
}) {
  const history = useNavigate();

  console.log("matched", match);
  // const batch_no = 90
  const batch_no = useParams().id;
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([]);
  const [tresuryDetails, setTresuryDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [taxSchedule, setTaxSchedule] = useState([]);
  const today = moment().format("DD MMMM, YYYY");

  const [form, setForm] = useState({
    batch_number: "",
    date: "",
    no_of_mda: "",
    no_of_payments: "",
    total_amount: "",
    cheque_number: "",
    query_type: "insert",
    status: "uncommited",
  });

  console.log("batch_no", batch_no);

  useEffect(() => {
    fetch(`${apiURL}/get_batch_list`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        query_type: "select_by_batch_no",
        status: "Committed",
        batch_no,
        type,
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        console.log("data", data);
        if (data.result && data.result.length) {
          setPaymentScheduleTable(data.result);
          setTresuryDetails(data.result[0]);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      });
  }, [batch_no, type]);

  useEffect(() => {
    fetch(`${apiURL}/get_batch_list`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        query_type: "select_payment_list",
        status: "Committed",
        batch_no,
        type,
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        console.log("gg", data);
        if (data.result) {
          setPaymentDetails(data.result);
          const formData = data.result[0];
          setForm((p) => ({ ...p, ...formData }));
          // setPaymentScheduleTable(data.result);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      });
  }, [batch_no, type]);

  useEffect(() => {
    fetch(`${apiURL}/tax-schedule?batch_id=${batch_no}&query_type=tax_schedule`)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        // console.log(data.schedule, 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
        if (data.schedule) {
          setTaxSchedule(data.schedule);
        }
      })

      .catch((err) => {
        console.log(err);
        // alert(err);
        // setLoadSpinner(false);
      });
  }, [batch_no]);

  const newDate = moment(form.date).format("DD/MM/YYYY");

  const totalGross = paymentScheduleTable.reduce(
    (a, b) => a + parseFloat(b.amount),
    0
  );
  const totalTaxes = paymentScheduleTable.reduce(
    (a, b) => a + parseFloat(b.total_taxes),
    0
  );
  const totalNet = paymentScheduleTable.reduce(
    (a, b) => a + (parseFloat(b.amount) - parseFloat(b.total_taxes)),
    0
  );

  // const totalWHT = taxSchedule.reduce((a, b) => a + parseFloat(b.WHT), 0)
  // const totalVAT = taxSchedule.reduce((a, b) => a + parseFloat(b.VAT), 0)
  // const totalSD = taxSchedule.reduce((a, b) => a + parseFloat(b.SD), 0)

  return (
    <>
      <PDFViewer height="1000" width="1000">
        <Document>
          <Page size="A4" style={styles.page}>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  fontSize: 14,
                  paddingLeft: 10,
                  paddingBottom: 20,
                }}
              >
                KTSJ/CRH/137/ VL. I
              </Text>
              <Text
                style={{
                  width: "50%",
                  fontSize: 14,
                  paddingRight: 10,
                  paddingBottom: 20,
                  textAlign: "right",
                }}
              >
                {today}
              </Text>
            </View>
            <View style={styles.middleHeader}>
              <Text>OFFICE OF THE ACCOUNTANT GENERAL </Text>
              <Text style={{ marginTop: 5 }}> MINISTRY OF FINANCE</Text>
              <Text style={{ marginTop: 5 }}> KATISNA STATE</Text>
            </View>

            <View
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontStyle: "bold",
                  textDecoration: "underline",
                }}
              >
                RE: SPECIAL RELEASE OF FUNDS
              </Text>
            </View>

            <View style={styles.body1}>
              <Text
                style={{
                  fontSize: 14,
                }}
              >
                I wish to write and refer to the letter no.
                S/MBEP/BD/REC/FA/24/VOL.I/0022 dated, {today}
                and request for the release of Fifty Million Naira
                (50,000,000.00) only to the Chief Registrar High Court of
                Justice Katsina to enable the High Court to effect payment of
                Robe allowance to Judicial Officers (I.e. High Court Judges,
                Chief Registrar, Magistrates, Legal Assistants, Sharia Court
                Judges and Assistant Alkalis) of the state for the year 2024.
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                Attached to this request is photocopy of the release letter for
                your further action.
              </Text>
            </View>

            {/* <View style={(styles.body1, { marginTop: 50 })}>
              <Text>(KABIRU AHMAD GARKO)</Text>
              <Text>SUB-TREASURER</Text>
            </View> */}

            <View
              style={{
                width: "100%",
                marginTop: 40,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "20%",
                  textAlign: "left",
                }}
              >
                <Text>Account Name</Text>
                <Text style={{ marginTop: 5 }}>Bank Name</Text>
                <Text style={{ marginTop: 5 }}>Account Number</Text>
              </View>
              <View>
                <Text> : </Text>
                <Text style={{ marginTop: 5 }}> : </Text>
                <Text style={{ marginTop: 5 }}> : </Text>
              </View>
              <View
                style={{
                  textAlign: "right",
                  marginRight: 20,
                }}
              >
                <Text>Chief Registrar, High Court of Justice Katsina</Text>
                <Text style={{ marginTop: 5 }}>First Bank Plc. Katsina</Text>
                <Text style={{ marginTop: 5 }}>2019123576</Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 20,
                width: "100%",
              }}
            >
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                Acknowledge the receipt on the duplicate copy of this letter
                please.
              </Text>
            </View>
            <View
              style={{
                marginTop: 20,
                width: "100%",
              }}
            >
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 14,
                  textAlign: "justify",
                }}
              >
                Yours Faithfully
              </Text>
            </View>
            <View
              style={{
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                }}
              >
                (ABDU IDRIS)
              </Text>
              <Text
                style={{
                  fontSize: 14,
                }}
              >
                DIRECTOR FINANCE & ACCOUNTS
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}
