import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import { _postApi } from "../../redux/Functions";
import { apiURL } from "../../redux/api";


const ForgotPassword = () => {
  const [form, setForm] = useState({ email: "" });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const getRememberedMail = () => {
    let rememberedUser = localStorage.getItem("@@pharmpay_rem_email") || "";
    setForm({ email: rememberedUser });
  };

  useEffect(() => {
    getRememberedMail();
  }, []);

  const handleChange = ({ target: { value, name } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    _postApi(
      `${apiURL}/api/users/password-reset`,
      { email: form.email },
      (data) => {
        setLoading(false);
        if (data.success) {
          setMessage("Your password reset link has been sent to the provided email and the registered phone number");
        } else {
          setMessage("Check your mails");
        }
      },
      (err) => {
        setLoading(false);
        setError("An error occurred, try again later.");
      }
    );
  };

  const formIsValid = form.email !== "";

  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">Your password reset link will be sent to your email!</p>
                <Form onSubmit={handleSubmit}>
                  {!message && !error && (
                    <>
                      <div className="mb-4">
                        <Form.Label htmlFor="email">Your Email</Form.Label>
                        <InputGroup id="email">
                          <Form.Control
                            required
                            autoFocus
                            type="email"
                            name="email"
                            placeholder="abubakar@tbeams.com"
                            // value={form.email}
                            onChange={handleChange}
                          />
                        </InputGroup>
                      </div>
                      <Button variant="primary" type="submit" className="w-100" disabled={!formIsValid || loading}>
                        {loading ? "Sending..." : "Recover password"}
                      </Button>
                    </>
                  )}
                  {message && (
                    <>
                      <h1 className='text-center text-primary'>Success</h1>
                      <Alert variant="primary" className="text-center">
                        {message}
                      </Alert>
                    </>
                  )}
                  {error && (
                    <Alert variant="danger" className="text-center">
                      {error}
                    </Alert>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ForgotPassword;
