/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import {
  Alert,
  Button,
  Form,
  ButtonGroup,
  InputGroup,
  Card,
  Container,
  Dropdown,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";
import { useState } from "react";
import { useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { apiURL, deleteApi, fetchApi, postApi } from "../../../redux/api";
import CustomTable from "../../../components/CustomTable";
import SearchInput from "../../../components/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fetchUserData } from '';
import {
  faCheck,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../../routes";
import { formatNumber } from "../../../redux/utils";
import { SearchContext } from "../../HomePage";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import moment from "moment-timezone";
import CustomButton from "../../../components/CustomButton";

function PaymentRequestIndex({ match, type = null }) {
  const user = useSelector((state) => state.auth.user);
  const history = useNavigate();
  const location = useLocation();
  const isPending = location.pathname.includes("payapp");
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
  const [filterText, setFilterText] = useState("");
  const batch_no = useParams().batch_no;
  const [budgetUtilization, setBudgetUtilization] = useState([]);
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState(type || "All");
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const [selectedApproval, setApprovalNo] = useState("");
  const [returndApproval, retApprovalNo] = useState("");

  const [approvalImageInfo, setApprovalImageInfo] = useState([]);
  const [item, setItem] = useState({});
  const [_delete, setDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

  const toggleDelete = (item) => {
    setItem(item);
    setDelete(!_delete);
  };
  const [showRemark1, setShowRemark1] = useState(false);
  const [showRemark, setShowRemark] = useState(false);
  const [remark, setRemark] = useState("");
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [returnlLoading, setReturnLoading] = useState(false);
  const [returnlMessage, setReturnMessage] = useState(null);
  const [approvalMessage, setApprovalMessage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [selectedReleases, setSelectedReleases] = useState([])
  const [scheduleModalOpen, setScheduleModalOpen ] = useState(false)
  const [preparingSchedule, setPreparingSchedule] = useState(false)

  const toggleRemark = () => setShowRemark((p) => !p);
  const toggleRemark1 = () => setShowRemark1((p) => !p);

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p);
  };
  
  const getApprovalCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: "pending_payment",
        // filter: "Pending",
        approval_status: "Pending",
        // mda_name: user ? user.mda_name : "",
      },
    })
      .then((data) => {
        if (data.result) {
          const arr = data.result.map((item) => ({ ...item, isOpen: false }));
          setBudgetUtilization(arr);
        }
      })
      .catch((err) => {
        console.error("Error fetching approval collection:", err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  // const handleFilter = () => {
  //   const filteredList = budgetUtilization.filter((item) => {
  //     const dateFromCondition = dateFrom ? new Date(item.collection_date) >= new Date(dateFrom) : true;
  //     const dateToCondition = dateTo ? new Date(item.collection_date) <= new Date(dateTo) : true;
  //     return dateFromCondition && dateToCondition;
  //   });
  //   return filteredList;
  // };

  // useEffect(() => {
  //   handleFilter();
  // }, [dateFrom, dateTo]);

  // const filteredList = handleFilter();

  let list = [];
  budgetUtilization.forEach((item) => {
    // filteredList.forEach((item) => {
    if (
      item.approval_no.indexOf(filterText) === -1 &&
      item.approval_no.toString().indexOf(filterText.toLowerCase()) === -1
    )
      return;
    else list.push(item);
  });

  const updateReturnApproval = (approval_no, status) => {
    setReturnLoading(true);
    postApi("return-approval", {
      remarks: remark,
      status,
      approval_no,
      userId: user.username,
    })
      .then((resp) => {
        setReturnLoading(false);
        if (resp && resp.success) {
          setReturnMessage("Return Successfully");
          setTimeout(() => {
            toggleRemark();
            getApprovalCollection();
          }, 1000);
        }
      })
      .catch((err) => {
        setReturnLoading(false);
        toast.error("An error occured, please try again later");
        console.log(err);
      });
  };

  const updateApproval = (approval_no, status) => {
    setApprovalLoading(true);
    postApi("update-approval", {
      query_type: 'update_ag_status',
      remarks: remark,
      status: "AG Approved",
      approval_no,
      userId: user.username,
      mda_name: user.mda_name,
    })
      .then((resp) => {
        setApprovalLoading(false);
        if (resp && resp.success) {
          setApprovalMessage(
            status === "Return Processed"
              ? "Returned Releases Successfully"
              : "Approved Successfully"
          );
          setTimeout(() => {
            toggleRemark();
            getApprovalCollection();
          }, 1000);
        }
      })
      .catch((err) => {
        setApprovalLoading(false);
        toast.error("An error occured, please try again later");
        console.log(err);
      });
  };

  const finalList = [];
  list.forEach((item) => {
    if (item.approval_no.toString().indexOf(searchTerm) === -1) return;
    finalList.push(item);
  });

  const onCheck = (item) => {
    let newList = []
    let itemFound = selectedReleases.find(s => s.id === item.id)
    if(itemFound) {
        newList = selectedReleases.filter(s => s.id !== item.id)
    } else {
        newList = [...selectedReleases,item]
    }
    setSelectedReleases(newList)
  }

  const onCheckAll = () => {
    if(selectedReleases.length) {
        setSelectedReleases([])
    } else {
        setSelectedReleases(finalList)
    }
  }

  const fields = [
    {
      title: "Action",
      headerComponent: () => <div>
            <input type='checkbox' checked={selectedReleases.length === finalList.length} onChange={() => onCheckAll()} />
        </div>,
      custom: true,
      component: (item, index) =>(
        <div>
            <input type='checkbox' checked={selectedReleases.findIndex(s => s.id === item.id) !== -1} onChange={() => onCheck(item)} />
        </div>
      )
       
    },  
    {
      title: "Release Date",
      value: "approval_date",
    },
    // {
    //   title: 'S/N',
    //   custom: true,
    //   component: (item, index) => <div>{index + 1}</div>,
    // },
    {
      title: "Approval No",
      value: "approval_no",
    },

    {
      title: "MDA Name",
      value: "mda_name",
    },
    // {
    //   title: "Economic Code",
    //   value: "economic_code",
    // },
    // {
    //   title: "Collection Date",
    //   value: "collection_date",
    // },
  

    // {
    //   title: "Approval Type",
    //   value: "approved_by",
    // },
    // {
    //   title: "Status",
    //   value: "approval_status",
    // },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.cb_amount)}</div>
      ),
    },
    {
        title: "Action",
        custom:true,
        component: item => (
            <div>
                <Button
                // style={{
                //   backgroundColor: "yellow",
                //   border: "none",
                //   height: "60%",
                //   //   padding: 5,
                //   paddingLeft: 10,
                //   paddingRight: 10,
                //   borderRadius: 10,
                //   cursor: "pointer",
                // }}
                onClick={() =>
                  history(
                    `${Routes.cashBacking.releaseCollectionPDF}/${item.approval_no}`
                  )
                }
              >
                <FontAwesomeIcon icon={faPrint} className="me-1" />
                View Release
              </Button>
            </div>
        )
    }
  ];

  const modalFields = [
    {
      title: "Approval No",
      value: "approval_no",
    },
 {
      title: "Release Date",
      value: "approval_date",
    },
    {
      title: "MDA Name",
      value: "mda_name",
    },
   
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.cb_amount)}</div>
      ),
    },
  ];

  const handleDelete = () => {
    setLoading(true);
    deleteApi(`delete-approve-collection`, item)
      .then((res) => {
        toast.success("Deleted Successfully");
        setLoading(false);
        getApprovalCollection();
        toggleDelete({});
      })
      .catch((err) => {
        toast.error(`error occured ${JSON.stringify(err)}`);
        setLoading(false);
      });
  };
  // const ApprovalCollectionIndex = () => {
  //   const { searchTerm, setSearchTerm } = useContext(SearchContext);
  //   // ... rest of the code
  // };

  useEffect(() => {
    return () => {
      setSearchTerm("");
    };
  }, [setSearchTerm]);

  const totalReleases = selectedReleases.reduce((a,b) => a + parseFloat(b.cb_amount), 0)

  const prepareSchedule = async () => {
    setPreparingSchedule(true)
    const paymentResp = await fetchApi('number-generator?query_type=select&description=payment')

    // console.log(paymentResp, 'paymentResp')
    if(paymentResp.success && paymentResp.results && paymentResp.results.length) {
        const scheduleId = paymentResp.results[0].next_code;
        console.log(scheduleId, 'scheduleId');

        for(let item of selectedReleases) {
            await postApi("update-approval", {
                query_type: 'create_payment_request',
                remarks: scheduleId,
                status: "Payment Raised",
                approval_no: item.approval_no,
                userId: user.username,
                mda_name: user ? user.mda_name : "",
            })
        }

        await postApi('number-generator', {
            query_type: 'update',
            description: 'payment',
            code: scheduleId
        })
    }
    
    setTimeout(() => {
        setPreparingSchedule()
        setScheduleModalOpen(false)
    getApprovalCollection()
    }, 1000);
  }

  return (
    <>
      {/* {JSON.stringify(approval_no)}  */}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <Button
          onClick={() => history(Routes.approval.newApproval)}
          className="me-2 bg-success"
        >
          Add Approval
        </Button> */}
        <div className="d-block mb-4 mb-md-0">
          <h4>
            {/* <FontAwesomeIcon icon={faListUl} className="me-2" />  */}
            Raise Payment Request  
          </h4>
        </div>
        {/* {JSON.stringify(searchTerm)} */}
        <div className="btn-toolbar mb-2 mb-md-0">
          <h5>Total Amount Selected: {formatNumber(totalReleases)}</h5>
        </div>
      </div>
      {/* <SearchInput
        placeholder="Search For Releases"
        value={searchTerm}
        onChange={({ target: { value } }) => setSearchTerm(value)}
      /> */}

      {/* <Row>
        <Col md={6} style={{
          marginTop: 28
        }}> */}
          <SearchInput
            placeholder="Search For Releases"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        {/* </Col>
        <Col md={6}>
          <Row style={{
            marginBottom: 10
          }}>
            <Col md={6}>
              <Form.Group id="date_from">
                <Form.Label>Date From</Form.Label>
                <Form.Control
                  type="date"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="date_to">
                <Form.Label>Date to</Form.Label>
                <Form.Control
                  type="date"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ height: "60vh", overflow: "scroll" }}>
              <CustomTable
                fields={fields}
                data={finalList}
              />
            </div>
          )}
          {/* {JSON.stringify(selectedReleases)} */}
        </Card.Body>

        <Modal size="xl" show={scheduleModalOpen} onHide={() => setScheduleModalOpen(p=>!p)}>
            <Modal.Header><h5>Confirm Your Selection</h5></Modal.Header>
            <Modal.Body>
            <CustomTable
                fields={modalFields}
                data={selectedReleases}
              />
            </Modal.Body>
            <Modal.Footer>
                <CustomButton loading={preparingSchedule} onClick={prepareSchedule}><FontAwesomeIcon icon={faCheck} /> Continue</CustomButton >
            </Modal.Footer>
        </Modal>
        
       {finalList.length ? <center>
            <CustomButton loading={preparingSchedule} onClick={() => setScheduleModalOpen(true)}>Raise Payment Request</CustomButton>
        </center> : null}
      </Card>
    </>
  );
}

export default PaymentRequestIndex;
