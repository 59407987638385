import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { CashBackingPDF } from "./CashBackingPDF";
import { useParams } from "react-router-dom";
import { postApi } from "../../../redux/api";
import { selecOffer } from "../../../redux/utils";

export default function CashBackingPDFPage() {
  const approval_no = useParams().approval_no;
  const [loading, setLoading] = useState(false);
  const [pvInfo, setPvInfo] = useState([]);

  useEffect(() => {
    if (approval_no) {
      setLoading(true);
      postApi(`post_pv_collection`, {
        form: {
          query_type: "pv_by_approval",
          approval_no: approval_no,
          pv_status: "Approved",
        },
      })
        .then((data) => {
          console.log("data", data);
          if (data.result && data.result.length) {
            setPvInfo(data.result);
          } else {
            setPvInfo([]); // Set an empty array if no data is available
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [approval_no]);

  if (!pvInfo || pvInfo.length === 0) {
    return (
      <div style={{ textAlign: "center", marginTop: "20%" }}>
        <h1>404 Error</h1>
        <p>The data is not available.rrerr</p>
      </div>
    );
  }
  return (
    <div>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <CashBackingPDF data={pvInfo} selecOffer={selecOffer} />
      </PDFViewer>
    </div>
  );
}
