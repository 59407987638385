import { PDFViewer } from "@react-pdf/renderer";
import React, { useCallback, useEffect, useState } from "react";
import { PaymentVoucherPdf } from "./PVPDF";
import { postApi } from "../../../redux/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import { Form, Modal, Spinner } from "react-bootstrap";
import TextInput from "../../../components/TextInput";
import CustomButton from "../../../components/CustomButton";
import { Routes } from "../../../routes";
import toast from "react-hot-toast";
import BackButton from "../../../components/BackButton";
import useQuery from "../../../hooks/useQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import SupportingDocumentsPv from "./SupportingDocuments";

export default function AuditorPvPdfPage() {
  const pv_code = useParams().pv_code;
  const query = useQuery();
  const actualPvCode = query.get("pv_code") || "";
  const history = useNavigate();
  const location = useLocation();
  const isReport = location.pathname.includes("report");
  const isReview = location.pathname.includes("review");
  const isCheck = location.pathname.includes("check");
  const isApprove = location.pathname.includes("approve");
  const isIndividual = location.pathname.includes("individual");
  const { id: approval_no } = useParams();

  const [form, setForm] = useState({
    collection_date: "",
    approval_date: "",
    approval_type: "",
    mda_name: "",
    amount: "",
    status: "",
    economic_code: "",
    pv_code: pv_code,
  });

  const [pvInfo, setPvInfo] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [showWarning, setShowWarning] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [pvHistory, setpvHistory] = useState([]);
  const [addRemark, setAddRemark] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleAPI = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const data = {
      pv_code: form.pv_code,
      user_id: user.id,
      remark: form.comments,
    };
    postApi("auditor_general_remark", data)
      .then((resp) => {
        setSubmitLoading(false);
        if (resp.success) {
          console.log("data", resp.success);
          toast.success("Remark submitted successfully!");
          setAddRemark(false);
          //   getApprovalCollection(); 
        } else {
          toast.error("Failed to submit remark");
        }
      })
      .catch((err) => {
        setSubmitLoading(false);
        toast.error("An error occurred, please try again later");
        console.log(err);
      });
  };

  useEffect(() => {
    if (pv_code) {
      setLoading(true);
      postApi(`get_pv_collection`, {
        form: {
          query_type: isIndividual
            ? "select_by_pvcode"
            : isReport
              ? "pv_by_approval_report"
              : "pv_by_approval",
          approval_no: pv_code,
          pv_status: isApprove ? "Reviewed" : isReview ? "Checked" : "pending",
          mda_name: user.mda_name,
          pv_code: actualPvCode,
        },
      })
        .then((data) => {
          console.log("data", data);
          if (data?.result && data?.result.length) {
            setPvInfo(data?.result);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [pv_code, isIndividual, isReport, isApprove, user.mda_name, actualPvCode]);

  useEffect(() => {
    if (pv_code) {
      setLoading(true);
      postApi(`get_pv_collection`, {
        form: {
          query_type: "taxes_by_approval",
          approval_no: pv_code,
          // pv_status: "pending",
        },
        // status : "Paid",
        // batch_no
      })
        .then((data) => {
          console.log("datadddd", data);
          if (data?.result && data?.result.length) {
            setTaxes(data?.result);
          }
        })

        .catch((err) => {
          console.log(err);
          // alert(err);
          // setLoadSpinner(false);
        })
        .finally(() => setLoading(false));
    }
  }, [pv_code]);

  const toggleWarning = () => setShowWarning((p) => !p);

  const handleReturn = () => {
    for (let pv of pvInfo) {
      postApi(`post_pv_collection`, {
        form: {
          query_type: "update_cash_backing",
          pv_code: pv.pv_code,
          approval_no: pv.pv_code,
          pv_status: isReview
            ? "Returned from Reviewer"
            : isCheck
              ? "Returned from Checker"
              : "Returned from Approver",
          userId: user.username,
          remarks,
        },
      });
    }

    toggleWarning();
    toast.success("PV Returned Successfully!");
    if (isReview) {
      history(Routes.pv.pvReviewIndex);
    } else if (isCheck) {
      history(Routes.pv.pvCheckIndex);
    } else {
      history(Routes.pv.pvApproveIndex);
    }
  };

  // const getpvHistory = useCallback(() => {
  //   postApi(`get_pv_collection`, {
  //     query_type: "get_history",
  //     pv_code: pv_code,
  //   })
  //     .then((data) => {
  //       console.log("History data", data);
  //       if (data?.result && data?.result.length) {
  //         setpvHistory(data.result);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("This is the Error that occurred", err);
  //     });
  // }, [pv_code]);

  // useEffect(() => {
  //   getpvHistory();
  // }, [getpvHistory]);

  useEffect(() => {
    if (pv_code) {
      setLoading(true);
      postApi(`get_pv_collection`, {
        form: {
          query_type: "get_history",
          approval_no: actualPvCode,
        },
      })
        .then((data) => {
          console.log("My History", data);
          if (data?.result && data?.result.length) {
            setpvHistory(data?.result);
          }
        })

        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [pv_code]);

  const handleApprove = () => {
    for (let pv of pvInfo) {
      postApi(`post_pv_collection`, {
        form: {
          query_type: "update_cash_backing",
          pv_code: pv.pv_code,
          approval_no: pv.pv_code,
          pv_status: isReview ? "Reviewed" : isCheck ? "Checked" : "Approved",
          userId: user.username,
          remarks,
        },
      });
    }

    toast.success("PV Approved Successfully!");
    if (isReview) {
      history(Routes.pv.pvReviewIndex);
    } else if (isCheck) {
      history(Routes.pv.pvCheckIndex);
    } else {
      history(Routes.pv.pvApproveIndex);
    }
  };

  if (pvInfo && pvInfo.length) {
    return (
      <>
        {/* {JSON.stringify(pvInfo)} */}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: "space-between"
        }}>
          <BackButton />
          <button
            className="bg-primary"
            style={{
              border: "none",
              color: "#fff",
              height: "60%",
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 10,
              cursor: "pointer",
            }}
            onClick={() => setAddRemark(true)}
          >
            <FontAwesomeIcon icon={faCheck} className="me-1" />
            Add Remarks
          </button>
        </div>
        {addRemark ? (
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  size="sm"
                  as="textarea"
                  value={form.comments}
                  onChange={(e) =>
                    setForm({ ...form, comments: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <button
                style={{
                  backgroundColor: "green",
                  border: "none",
                  color: "#fff",
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                  cursor: "pointer",
                  float: "right",
                }}
                onClick={handleAPI}
                disabled={submitLoading} // Disable button while loading
              >
                {submitLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </Col>
          </Row>
        ) : null}
        <Card className=" p-4">
          {isReview || isCheck || isApprove ? (
            <Row>
              <Col md={10}></Col>
              <Col>
                <Button
                  className="btn-danger me-2 my-2"
                  onClick={toggleWarning}
                >
                  Return
                </Button>
                <Button onClick={handleApprove}>Approve</Button>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md={8}>
              <PDFViewer
                style={{
                  width: "100%",
                  height: 1400,
                }}
              >
                <PaymentVoucherPdf
                  form={{}}
                  user={user}
                  data={pvInfo}
                  taxesApplied={taxes}
                />
              </PDFViewer>
            </Col>
            <Col md={4}>
              <SupportingDocumentsPv pvInfo={pvInfo} />
            </Col>
          </Row>

          <Modal show={showWarning} onShow={toggleWarning}>
            <Modal.Header>Add Remarks</Modal.Header>
            <Modal.Body>
              <h4>Please provide reason for return</h4>
              <TextInput
                value={remarks}
                onChange={({ target: { value } }) => setRemarks(value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <CustomButton onClick={handleReturn}>Return</CustomButton>
            </Modal.Footer>
          </Modal>
        </Card>
      </>
    );
  } else {
    return <p>No data found.</p>;
  }
}
