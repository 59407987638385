import React from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Dropdown,
  Modal,
} from '@themesberg/react-bootstrap'

import { useState } from 'react'
import { useCallback } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { apiURL, deleteApi, fetchApi, postApi } from '../../../redux/api'
import CustomTable from '../../../components/CustomTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faEdit,
  faEye,
  faListUl,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Routes } from '../../../routes'
import { formatNumber } from '../../../redux/utils'

function NewScheduleList({ match, type = null }) {
  const history = useNavigate()
  const location = useLocation()
  const isPending = location.pathname.includes('payapp')

  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif']
  const [filterText, setFilterText] = useState('')
  const batch_no = useParams().batch_no
  const [pvList, setPvList] = useState([])
  const [modal, setModal] = useState(false)
  const [filter, setFilter] = useState(type || 'All')
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false)
  const [selectedPV, setSelectedPvNo] = useState('')
  const [pvImageInfo, setPvImageInfo] = useState([])
  const [item, setItem] = useState({})
  const [_delete, setDelete] = useState(false)
  const  [loading, setLoading] = useState(false)
  // const [_loading, _setLoading] = useState(false)
  const toggleDelete = (item) => {
    setItem(item)
    setDelete(!_delete)
  }
  const [approvalList, setApprovalList] = useState([])

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p)
  }

//   useEffect(() => {
//     setLoading(true);

//       postApi(`post_approval_collection`, {
//         form: { query_type: "by_approval_type", filter: "Capital" },
//       })
//         .then((data) => {
//           if (data && data.result && data.result.length) {
//             setApprovalList(data.result);
//           }
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//       // getApprovalCollectList({ query_type: 'select' }, (d) => {
//       //   if (d && d.result) {
//       //     setApprovalList(d.result)
//       //   }
//       // })
    
//   }, []);

  const getPvCollection = useCallback(() => {
    setLoading(true)
    postApi(`get_pv_collection`, {
      form: { query_type: 'pending_by_approval', pv_status:'DAG Approved' },
      // status : "Paid",
      // batch_no
    })
      .then((data) => {
        console.log('data', data)
        if (data.result) {
          const arr = []
          data.result.forEach((item) => {
            arr.push({ ...item, isOpen: false })
          })
          setPvList(data.result)
        }
      })

      .catch((err) => {
        console.log(err)
        // alert(err);
        // setLoadSpinner(false);
      }).finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    getPvCollection()
  }, [getPvCollection])

  // const handleDelete = () => {
  //   _setLoading(true)
  //   deleteApi(`delete-pv-collection`, item)
  //     .then(() => {
  //       alert('Deleted Successfully')
  //       _setLoading(false)
  //       getPvCollection()
  //       toggleDelete({})
  //     })
  //     .catch((err) => {
  //       alert(`error occured ${JSON.stringify(err)}`)
  //       _setLoading(false)
  //     })
  // }

  const gotoSchedule = (item) => {
    postApi(`post_approval_collection`, {
        form: { query_type: "select_by_approval_no", approval_no: item.approval_no },
        // status : "Paid",
        // batch_no
      })
        .then((data) => {
          if (data.result) {
            const apprv = data.result[0]
              console.log("data", data, 'item', item);
            if(apprv &&apprv.approval_type&&apprv.approval_type === "Capital") {
            // if(item.no_of_pvs > 1) {
                history(`${Routes.contractorSchedule.processCapital}/${apprv.approval_no}?pv_code=${item.pv_code}&approval_no=${item.approval_no}`)
            } else {
                history(`${Routes.contractorSchedule.processRecurrent}/${apprv.approval_no}`)///${item.mda_name}/${apprv.economic_code}/${item.approval_no}/0/${item.description_code}/${item.mda_name}/${item.amount}/${apprv.narration}?pv_code=${item.pv_code}`)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          // alert(err);
          // setLoadSpinner(false);
        })
  }

  const fields = [
    {
      title: 'Action',
      custom: true,
      component: (item, index) => (
        <Button onClick={() => gotoSchedule(item)}>
          Create Schedule
        </Button>
        // <Dropdown>
        //   <Dropdown.Toggle as={Button} variant="primary" className="me-2">
        //     <span>Action</span>
        //     <FontAwesomeIcon icon={faCaretDown} className="ms-2" />
        //   </Dropdown.Toggle>
        //   <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
        //     <Dropdown.Item
        //       onClick={() => viewAttachment(item.imageId, item.pv_code)}
        //     >
        //       <FontAwesomeIcon icon={faEye} className="me-2" /> View Attachment
        //     </Dropdown.Item>
        //     <Dropdown.Item
        //       onClick={() =>
        //         history(
        //           `${Routes.pv.newPv}/${item.pv_code}`,
        //         )
        //       }
        //     >
        //       <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
        //     </Dropdown.Item>
        //     <Dropdown.Item onClick={() => toggleDelete(item)}>
        //       <FontAwesomeIcon icon={faTrash} className="me-2" /> Delete
        //     </Dropdown.Item>
        //   </Dropdown.Menu>
        // </Dropdown>
      ),
    },
    // {
    //   title: 'S/N',
    //   custom: true,
    //   component: (item, index) => <div>{index + 1}</div>,
    // },
    {
      title: 'Approval No.',
      value: 'approval_no',
      style: { textAlign: 'center'}
    },
    // {
    //   title: 'MDA Name',
    //   value: 'mda_name',
    //   // hidden: true
    // },
    {
      title: 'Number of Vouchers',
      // value: 'description_code',
      custom: true,
      component: (item) => <div className='text-center'>{item.no_of_pvs}</div>,
    },
    // {
    //   title: 'Project Type',
    //   value: 'project_type',
    // },
    // {
    //   title: 'Payment Type',
    //   value: 'payment_type',
    // },
    {
      title: 'Status',
      value: 'status',
      style: { textAlign: 'center'}

    },
    {
      title: 'Amount (N)',
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
      //   value: 'amount',
    },
    // {
    //   title: 'Balance (N)',
    //   custom: true,
    //   component: (item) => (
    //     <div className="text-end">{formatNumber(item.balance)}</div>
    //   ),
    //   //   value: 'amount',
    // }
  ]

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          {/* <Button
            onClick={() => history(Routes.pv.newPv)}
            className="me-2"
          > <FontAwesomeIcon icon={faPlus} className='me-2' />
            Add PV
          </Button> */}
        <div className="d-block mb-4 mb-md-0">
          <h4>
            {/* <FontAwesomeIcon icon={faListUl} className="me-2" />  */}
            Expenditure Control
          </h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      {/* {JSON.stringify(approvalList)} */}

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0" style={{ height: '90vh', overflow: 'scroll' }}>
        {loading ? (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ) : (
          <CustomTable
            fields={fields}
            data={pvList}
            // currentPage={currentPage}
            // handlePageChange={handlePageChange}
          />
          )}
        </Card.Body>

        <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
          <Modal.Header onHide={attachmentModalToggle}>
            PV No.: {selectedPV}
          </Modal.Header>
          <Modal.Body className="text-center">
            <Container>
              <div className="image-container">
                {pvImageInfo.length ? (
                  pvImageInfo.map((item, index) =>
                    imageExtensions.some((extension) =>
                      item.image_url.toLowerCase().endsWith(extension),
                    ) ? (
                      <Card.Img
                        top
                        src={`${apiURL}/uploads/${item.image_url}`}
                        alt={'pv'}
                      />
                    ) : (
                      <Card.Body>
                        <Card.Link
                          href={`${apiURL}/uploads/${item.image_url}`}
                          target="_blank"
                        >
                          View PDF
                        </Card.Link>
                      </Card.Body>
                    ),
                  )
                ) : (
                  <h4>No attachment found.</h4>
                )}
              </div>
            </Container>
            {/* <h6>MDA: {approvalImageInfo.mda_name}</h6> */}
          </Modal.Body>
        </Modal>

        <Modal show={_delete} onHide={toggleDelete}>
          <Modal.Header onHide={toggleDelete}>Delete</Modal.Header>
          <Modal.Body className="text-center">
            <h5>Are you sure you want to delete {item.pv_code} </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button outline className="m-1 p-1 px-3" onClick={toggleDelete}>
                Cancel
              </Button>
              <Button
                color="danger"
                className="m-1 p-1 px-3"
                // onClick={handleDelete}
                loading={loading}
              >
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Card>
    </>
  )
}

export default NewScheduleList
