import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { Routes } from "../../../routes";
import {
  faBabyCarriage,
  faBoxTissue,
  faCoins,
  faFileContract,
  faMoneyBill,
  faPiggyBank,
} from "@fortawesome/free-solid-svg-icons";
import {
  fa500px,
  faAccessibleIcon,
  faAccusoft,
  faAdn,
  faAngellist,
  faCcPaypal,
  faReacteurope,
} from "@fortawesome/free-brands-svg-icons";

export const mainTreasuryModules = [
  {
    title: "Dashboard",
    route: Routes.DashboardOverview.path,
    icon: faAddressCard,
  },
  {
    title: "TSA Funding",
    route: Routes.tsafunding.faacTsaFunding,
    icon: faAddressCard,
    subMenu: [
      {
        title: "FAAC TSA Funding",
        route: Routes.tsafunding.faacTsaFunding + "/FAAC TSA Funding",
      },
      {
        title: "JAAC TSA Funding",
        route: Routes.tsafunding.faacTsaFunding + "/JAAC TSA Funding",
      },
    ],
  },
  {
    title: "Budget",
    route: Routes.budget.budgetUtlization,
    icon: faMoneyBill,
    subMenu: [
      {
        title: "Budget Utilization",
        route: Routes.budget.budgetUtlization,
      },
      {
        title: "Upload Budget",
        route: Routes.budget.uploadBudget,
      },
    ],
  },
  {
    title: "MDA Banks",
    route: Routes.bank.bankIndex,
    icon: faPiggyBank,
  },
  {
    title: "Collection For Release",
    route: Routes.approval.approvalIndex,
    icon: faBoxTissue,
    subMenu: [
      {
        title: "Pending Releases",
        route: Routes.approval.pendingApproval,
      },
      {
        title: "Review Releases",
        route: Routes.approval.reviewReleases,
      },
      {
        title: "Approve Releases",
        route: Routes.approval.approvalReleases,
      },
      {
        title: "All Releases",
        route: Routes.approval.approvalIndex,
      },
      {
        title: "AG Releases Dashboard",
        route: Routes.approval.agApprovalDashboard,
      },
      {
        title: "AG Approved Releases",
        route: Routes.approval.agApprovedReleases,
      },
      {
        title: "Auditor Releases Dashboard",
        route: Routes.approval.auditorGeneralApprovalDashboard,
      },
      {
        title: "AD Exp. Control Releases",
        route: Routes.approval.adExpContrReleases,
      },
    ],
  },
  {
    title: "Payment Vouchers",
    route: Routes.DashboardOverview,
    icon: faCcPaypal,
    subMenu: [
      {
        title: "Generate PV",
        route: Routes.pv.pvlIndex,
      },
      {
        title: "Check PV",
        route: Routes.pv.pvCheckIndex,
      },
      // {
      //   title: "AD PV",
      //   route: Routes.pv.pvADCheckIndex,
      // },
      {
        title: "Review PV",
        route: Routes.pv.pvReviewIndex,
      },
      {
        title: "Approve PV",
        route: Routes.pv.pvApproveIndex,
      },
      {
        title: "PV Reports",
        route: Routes.pv.pvReports,
      },
      {
        title: "Auditor PV Dashboard",
        route: Routes.pv.auditorpvReports,
      },
    ],
  },
  {
    title: "Cash Backing",
    route: Routes.DashboardOverview,
    icon: faCoins,
    subMenu: [
      {
        title: "Pending Cash Backing",
        route: Routes.cashBacking.cashBackinPending,
      },
      // {
      //   title: "Review Cash Backing",
      //   route: Routes.cashBacking.cashBackinReview,
      // },
      // {
      //   title: "Approve Cash Backing",
      //   route: Routes.cashBacking.cashBackinApproved,
      // },
    ],
  },
  {
    title: "Payments",
    route: Routes.DashboardOverview,
    icon: faAccessibleIcon,
    subMenu: [
      // {
      //   title: "Raise Payment Request",
      //   route: Routes.paymentRequest.raisePaymentRequest,
      // },
      // {
      //   title: "Review Payment Request",
      //   route: Routes.paymentRequest.reviewPaymentRequest,
      // },
      {
        title: "Approve Payment Request",
        route: Routes.paymentRequest.approvePaymentRequest,
      },
    ],
  },
  {
    title: "Self Accounting MDA",
    route: Routes.DashboardOverview,
    icon: faAccessibleIcon,
    subMenu: [
      {
        title: "Cash Backing Request",
        route: Routes.cashBacking.cashBackingRequest,
      },
    ],
  },

  {
    title: "Expenditure Control",
    route: Routes.DashboardOverview,
    icon: faFileContract,
    subMenu: [
      {
        title: "Receiving Office",
        route: Routes.expenditureControl.receivingOfficeIndex,
      },

      {
        title: "Expenditures Control",
        route: Routes.expenditureControl.expControlIndex,
      },
      // {
      //   title: "Expenditures Control",
      //   route: Routes.expenditureControl.expControlIndex,
      // },
      {
        title: "Payment Schedule",
        route: Routes.expenditureControl.expControlScheduleIndex,
      },
    ],
  },
  {
    title: "Dir. Exp. Control",
    route: Routes.DashboardOverview,
    icon: faAccusoft,
    subMenu: [
      // {
      //   title: "Reveiw PVs",
      //   route: Routes.expenditureControl.dirExpIndex,
      // },
      {
        title: "Reveiw PVs",
        route: Routes.expenditureControl.dirExpFinalIndex,
      },
      {
        title: "Reveiw Payment Schedule",
        route: Routes.expenditureControl.dirExpPaymentScheduleIndex,
      },
    ],
  },
  // {
  //   title: "Ass. Dir. Exp. Control",
  //   route: Routes.DashboardOverview,
  //   icon: faAccusoft,
  //   subMenu: [
  //     // {
  //     //   title: "Review PV (ADEC)",
  //     //   route: Routes.expenditureControl.assDirExpIndex,
  //     // },
  //     // {
  //     //   title: "AD Exp. PV Reviewal",
  //     //   route: Routes.expenditureControl.assDirFinalExpIndex,
  //     // },
  //     {
  //       title: "Review Payment Schedule",
  //       route: Routes.expenditureControl.assDirPsIndex,
  //     },
  //   ],
  // },
  {
    title: "DAG",
    route: Routes.DashboardOverview,
    icon: fa500px,
    subMenu: [
      {
        title: "Approve PVs (DAG)",
        route: Routes.dag.dagPendingPVIndex,
      },
      {
        title: "Approve Schedules",
        route: Routes.dag.dagIndex,
      },
    ],
  },
  {
    title: "Sub Treasury",
    route: Routes.DashboardOverview,
    icon: faAngellist,
    subMenu: [
      {
        title: "Receiving Office (ST)",
        route: Routes.subTreasury.receivingOfficeIndex,
      },
      {
        title: "Checking Office (ST)",
        route: Routes.subTreasury.checkingOfficeIndex,
      },
      // {
      //   title: "Cheques Office (ST)",
      //   route: Routes.subTreasury.headChequesOfficeIndex,
      // },
      {
        title: "Cash Office (ST)",
        route: Routes.subTreasury.headChequesOfficeIndex,
      },
      {
        title: "DST",
        route: Routes.subTreasury.dstOfficeIndex,
      },
      {
        title: "Review Payment (DST)",
        route: Routes.subTreasury.dstPendingScheduleOfficeIndex,
      },
      {
        title: "Sub Treasurer",
        route: Routes.subTreasury.subTreasurerPaymentReviewOfficeIndex,
      },
      {
        title: "Approved Payments",
        route: Routes.subTreasury.approvedPaymentsIndex,
      },
    ],
  },
  {
    title: "Remittance",
    route: Routes.DashboardOverview,
    icon: faBabyCarriage,
    subMenu: [
      {
        title: "Remittance Slip",
        route: Routes.remittance.index,
      },
    ],
  },
  {
    title: "Reports",
    route: Routes.DashboardOverview,
    icon: faReacteurope,
    subMenu: [
      {
        title: "Budget Utilization Reports",
        route: Routes.budget.budgetUtlization,
      },
      {
        title: "Expenditure Reports",
        route: Routes.reports.expenditureReport,
      },
    ],
  },

  {
    title: "Accounts",
    route: Routes.DashboardOverview,
    icon: faReacteurope,
    subMenu: [
      // {
      //   title: "Manage Accounts",
      //   route: Routes.account.manageAccount,
      // },
      {
        title: "Manage Main Accounts",
        route: `${Routes.account.manageAccountMain}/MAIN ACCOUNT`
      },
      {
        title: "Manage Sub Accounts",
        route: `${Routes.account.manageAccountMain}/SUB-TREASURY ACCOUNT`,
      },
      {
        title: "Manage Other Accounts",
        route: `${Routes.account.manageAccountMain}/OTHERS`,
      },
      {
        title: "Payment Reports",
        route: Routes.account.accountReport,
      },
    ],
  },
  // {
  //   title: "Dashboard",
  //   route: Routes.DashboardOverview.path,
  //   icon: faAddressCard,
  // },
  {
    title: "Admin",
    route: Routes.userAdmin.index + "?role=admin",
    icon: faReacteurope,
  },
  // {title: "Payment Schedule", route: Routes.DashboardOverview },
  // {title: "Internal Audit", route: Routes.DashboardOverview },
  // {title: "Payments", route: Routes.DashboardOverview },
  // {title: "Remittance", route: Routes.DashboardOverview },
  // {title: "Reports", route: Routes.DashboardOverview },
  {title: "Tax Setup", route: Routes.taxSetup.taxIndex },
  // {title: "Auditor Dashboard", route: Routes.auditordashboard.auditorIndex },
  // {title: "Admin", route: Routes.DashboardOverview },

];