import React, { useEffect, useState } from 'react';
import { Col, Form, FormLabel, Row } from '@themesberg/react-bootstrap';
import CustomForm from '../../../components/CustomForm';
import { postApi } from '../../../redux/api';
import { accountTypes, bankList } from '../../accounts/AccountForm';
import { useParams } from 'react-router-dom';

export default ({ form = {}, setForm = (f) => f }) => {

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type === "checkbox") {
      setForm((p) => ({ ...p, [name]: value }));
    } else {
      setForm((p) => ({ ...p, [name]: value }));
    }
  };

  return (
    // <>
    //   <h6 className="my-2 text-center">Bank Detail</h6>
    //   <Form>
    //     <CustomForm
    //       handleChange={handleChange}
    //       fields={[
    //         {
    //           label: 'Account Name',
    //           value: form.contractor_acc_name,
    //           name: 'contractor_acc_name',
    //         },
    //         {
    //           label: 'Bank Name',
    //           name: 'contractor_bank_name',
    //           value: form.contractor_bank_name,
    //         },
    //         {
    //           label: 'Account Number',
    //           name: 'contractor_acc_no',
    //           value: form.contractor_acc_no,
    //         },
    //         {
    //           label: 'Sort code',
    //           value: form.sort_code,
    //           name: 'sort_code',
    //         },
    //       ]}
    //     />
    //   </Form>
    // </>
    <Row>
          <h6 className="my-2 text-center">Bank Details</h6>
          <Col md={12}>
            <CustomForm
              handleChange={handleChange}
              fields={[
                {
                  label: "Account Name",
                  name: "mda_account_name",
                  value: form.mda_account_name,
                },
                {
                  label: "Bank Name",
                  type: "custom",
                  component: () => (
                    <>
                      <FormLabel>Bank Name</FormLabel>
                      <select
                        onChange={({ target: { value } }) => {
                          setForm((p) => ({
                            ...p,
                            mda_account_bank_name: value,
                          }));
                        }}
                        value={form.mda_account_bank_name}
                        name="mda_account_bank_name"
                        className="form-control form-control-sm"
                      >
                        <option value={""}>--select--</option>
                        {bankList.map((item, id) => (
                          <option key={id} value={item.title}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </>
                  ),
                  // name: "mda_account_bank_name",
                  // value: form.mda_account_bank_name,
                },
                {
                  label: "Account Type",
                  type: "custom",
                  component: () => (
                    <>
                      <FormLabel>Account Type</FormLabel>
                      <select
                        onChange={({ target: { value } }) => {
                          setForm((p) => ({
                            ...p,
                            mda_account_type: value,
                          }));
                        }}
                        value={form.mda_account_type}
                        name="mda_account_type"
                        className="form-control form-control-sm"
                      >
                        <option value={""}>--select--</option>
                        {accountTypes.map((item, id) => (
                          <option key={id} value={item.title}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </>
                  ),
                  // name: "mda_account_bank_name",
                  // value: form.mda_account_bank_name,
                },
                {
                  label: "Account Number",
                  name: "mda_acc_no",
                  value: form.mda_account_no,
                },
                {
                  label: "Sort code",
                  value: form.mda_account_sort_code,
                  name: "mda_account_sort_code",
                },
              ]}
            />
          </Col>
        </Row>
  );
};
