import React, { useEffect, useState  } from 'react'
import { fetchApi } from '../../../redux/api'
import BudgetUtilizationTable from './components/BudgetUtilizationTable'

function BudgetDashboard() {
    const [report, setReport] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchApi('budget-dashboard').then(data => {
            if(data && data.success) {
                setReport(data.data)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])
  return (
    <div>
        <h1>Budget Dashboard</h1>
        {JSON.stringify(report)}

        <BudgetUtilizationTable
        list={report}
        // currentPage={currentPage}
        // handlePageChange={handlePageChange}
      />
    </div>
  )
}

export default BudgetDashboard