import React, { useCallback, useEffect, useState } from 'react'
import { formatNumber } from '../../../redux/utils'
import { fetchApi, postApi } from '../../../redux/api';
import moment from "moment";
import useQuery from '../../../hooks/useQuery';
import { useSelector } from 'react-redux';
import { _fetchApi } from '../../../redux/Functions';
import toast from 'react-hot-toast';
import { NewRelisePdf } from './NewReleasePDF';
import { useParams } from 'react-router-dom';
import DM_SANS_NORMAL from "../../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../../../assets/DM_Sans/DM_Sans/static/DMSans-Italic.ttf";
import {
    Document,
    Font,
    Image,
    PDFViewer,
    Page,
    StyleSheet,
    Text,
    View,
  } from "@react-pdf/renderer";
import BackButton from '../../../components/BackButton';

Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  image1: {
    width: 70,
    height: 50,
    marginLeft: -15,
    marginTop: 50,
  },
  header: {
    fontSize: 20,
    marginTop: 10,
  },
  para: {
    fontStyle: "italic",
    fontSize: 12,
    // fontStyle: "bold",
  },
  para1: {
    fontSize: 12,
    fontFamily: "DM_SANS",
    fontStyle: "bold",
  },
  bodyHeader: {
    textDecoration: "underline",
    fontFamily: "DM_SANS",
    fontStyle: "bold",
    fontSize: 12,
    marginTop: 10,
    fontWeight: 20,
  },
});

export const ReleaseCollectionPDF = ({ today = (f) => f }) => {
  const [loading, setLoading] = useState(false);
  //   const history = useNavigate();
  const { approval_no } = useParams();
  const [form, setForm] = useState({
    collection_date: "",
    approval_date: "",
    approval_type: "",
    mda_name: "",
    amount: "",
    status: "",
    economic_code: "",
    approval_no: approval_no,
    mda_economic_code: "",
  });
  const taday = moment(form.approval_date).format("Do MMMM, YYYY");
  const [signatureInfo, setSignatureInfo] = useState({});

    useEffect(() => {
      fetchApi(`sign-by-id-new?id=${form.approved_ids}`)
      .then(
        (d) => {
          if (d.success) {
            setSignatureInfo(d?.data[0]);
          }
        })
        .catch(
        (err) => {
          console.log(err);
        })
    }, [form.approved_ids])
  
  const [ccCopies, setCcCopies] = useState([]);

  const getCcCopieds = (mda_name) => {
    postApi(`post-cc?query_type=select_mda`, { mda_name })
      .then((data) => {
        console.log("Data", data);
        if (data.results && data.results.length) {
          // alert('sdfd')
          setCcCopies(data.results);

            setForm((p) => ({
              ...p,
            //   ..._data,
            //   approved_amount: _data.amount,
              copies1: data.results[0],
            //   mda_economic_code: _data.economic_code,
            }));
        } else {
          console.error("No data found");
        }
      })
      .catch((err) => {
        console.error("Error fetching ccCopies:", err);
      });
  };

  const getApprovalCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: "select_by_approval_no",
        approval_no: approval_no,
      },
    })
      .then((data) => {
        // console.log("data", data);
        // console.log(result);
        if (data.result && data.result.length) {
          const result = data.result[0];
          setForm(result);
          getCcCopieds(result.mda_name);
          console.log(result.mda_name);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [approval_no]);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  const ignoreMDA =
    form.mda_name === "Ministry of Finance" ||
    form.mda_name === "Government House";

  return (
    <>
      <div>
        <BackButton />
        {/* {JSON.stringify({d: form?.copies1})} */}
      </div>
      <PDFViewer height="1000" width="1000">
        <Document>
          <Page size="A4">
            <View style={{ paddingLeft: 50, paddingRight: 30 }}>
            <View style={styles.body}>
                <View style={{ width: "100%", alignItems: "center" }}>
                  <Image
                    src={require("../../../assets/img/KTSG.png")}
                    style={styles.image1}
                  />

                  <Text style={styles.header}>
                    MINISTRY OF BUDGET & ECONOMIC PLANNING
                  </Text>
                  <Text style={[styles.para, { marginTop:10}]}>
                    Old Government House Office Complex, Katsina
                  </Text>
                  <Text
                    style={{
                      fontFamily: "DM_SANS",
                      fontStyle: "italic",
                      fontSize: 12,
                      marginTop:7
                    }}
                  >
                    P.MB 2132, KATSINA
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  paddingBottom: 5,
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "70%",
                    flexDirection: "row",
                    marginTop:7
                  }}
                >
                  <Text style={styles.para1}>Our Ref: </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "DM_SANS",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    S/MBEP/BD/{form.approval_type === "Recurrent" ? "REC" : "CAP"}
                    /FA/{moment().format("YY")}/VOL.I/
                    {form?.approval_no.substring(4)}
                  </Text>
                </View>
                <View
                  style={{
                    width: "30%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "DM_SANS",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {taday}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    marginBottom: 3,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  The Accountant General,
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    marginBottom: 3,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Ministry of Finance,
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Katsina,
                </Text>
              </View>

              <View
                style={{
                  width: "100%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Text style={styles.bodyHeader}>SPECIAL RELEASE OF FUNDS</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  textAlign: "justify",
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    marginTop: 7,
                    fontStyle: "bold",
                  }}
                >
                  I am directed to convey approval for the release of
                  <Text
                    style={{
                      fontFamily: "DM_SANS",
                      fontStyle: "bold",
                    }}
                  >
                    {" "}
                    (N{formatNumber(form.amount)}) only to
                  </Text>{" "}
                  <Text>
                    {form?.copies1?.officeTitle &&
                      form?.copies1?.officeTitle !== null
                      ? form?.copies1?.officeTitle + " "
                      : ""}
                    {form?.copies?.officeTitle &&
                      form?.copies?.officeTitle !== null
                      ? " payable to the "
                      : ""}{" "}
                    {form?.copies?.officeTitle}
                  </Text>
                  {form.narration}.
                  <Text
                    style={{
                      fontFamily: "DM_SANS",
                      fontStyle: "bold",
                    }}
                  >
                    The Vote of Charge is{" "}
                    {form.projectType === "payable-to"
                      ? form.send_to_office
                      : form.mda_code}
                    /
                    {form.approval_type === "Recurrent"
                      ? form.economic_code
                      : form.program_code}
                    .
                  </Text>
                </Text>
                <Text style={{ marginVertical: 15 }}></Text>
                <Text
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    marginTop: 10,
                  }}
                >
                  This letter is copied to the{" "}
                  {ignoreMDA ? null : form?.copies?.officeTitle}{" "}
                  {form?.copies1 && form?.copies1?.officeTitle}, the PS, (GHKT),
                  the HC (MOF), the Auditor-General, the Office of the
                  Auditor-General for the State, and the Director of Main Account,
                  Ministry of Finance.
                </Text>
              </View>
              <View
                style={{
                  marginLeft: "auto",
                  marginTop: 40,
                  width: "40%",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                  }}
                >
                  (SAMINU MUH'D K/SOLI)
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                  }}
                >
                  FOR: HON, COMMISSIONER
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: 12,
                    marginTop: -15,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                  }}
                >
                  CC:
                </Text>
              </View>

              <View
                style={{
                  marginTop: -5,
                }}
              >
                {ignoreMDA ? null : (
                  <View>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: 50,
                        fontFamily: "DM_SANS",
                        fontStyle: "italic",
                      }}
                    >
                      {form?.copies?.officeTitle &&
                        form?.copies?.officeTitle !== null
                        ? form?.copies?.officeTitle + ","
                        : null}
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: 50,
                        fontFamily: "DM_SANS",
                        fontStyle: "italic",
                      }}
                    >
                      Katsina,
                    </Text>
                  </View>
                )}
                {form?.copies1?.officeTitle &&
                  form?.copies1?.officeTitle !== null && (
                    <View>
                      <Text
                        style={{
                          marginTop: 5,
                          fontSize: 8,
                          marginLeft: 50,
                          fontFamily: "DM_SANS",
                          fontStyle: "italic",
                        }}
                      >
                        {form?.copies1?.officeTitle + ","}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          marginLeft: 50,
                          fontFamily: "DM_SANS",
                          fontStyle: "italic",
                        }}
                      >
                        Katsina,
                      </Text>
                    </View>
                  )}
                {/* <Text
              style={{
                fontSize: 8,
                marginLeft: 50,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Katsina,
            </Text> */}
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  The Permanent Secretary,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Government House,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Katsina,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  The Hon. Commissioner,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Ministry of Finance,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Katsina,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  The Auditor General,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Office of the Auditor-General for the State,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Katsina,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  The Director Main-Account,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Ministry of Finance,
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 50,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Katsina,
                </Text>
                {form.cc === "kirs" && (
                  <>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: 50,
                        fontFamily: "DM_SANS",
                        fontStyle: "italic",
                      }}
                    >
                      Board Of Internal Revenue,
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: 50,
                        fontFamily: "DM_SANS",
                        fontStyle: "italic",
                      }}
                    >
                      Katsina
                    </Text>
                  </>
                )}
              </View>
              <View
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    marginTop: 10,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Above for your information and awareness, please.
                </Text>
              </View>
              <View>
                <View
                  style={{
                    marginLeft: "auto",
                    marginTop: 5,
                    width: "35%",
                    textAlign: "center",
                  }}
                >
                  {signatureInfo && signatureInfo.imageURL ? (
                <Image
                  src={signatureInfo.imageURL}
                  style={{
                    marginHorizontal: "2px",
                    alignItems: "center",
                    height: "50%",
                    width: "50%",
                  }}
                />
              ) : null}
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "DM_SANS",
                      fontStyle: "bold",
                    }}
                  >
                    (SAMINU MUH'D K/SOLI)
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "DM_SANS",
                      fontStyle: "bold",
                    }}
                  >
                    FOR: HON, COMMISSIONER
                  </Text>
                </View>
              </View>
            </View>
          </Page>

          <Page size="A4">
            <View style={{ paddingLeft: 50, paddingRight: 30 }}>
              <View style={styles.body}>
                <View style={{ width: "100%", alignItems: "center" }}>
                  <Image
                    src={require("../../../assets/img/KTSG.png")}
                    style={styles.image1}
                  />

                  <Text style={styles.header}>
                    MINISTRY OF BUDGET & ECONOMIC PLANNING
                  </Text>
                  <Text style={styles.para}>
                    Old Government House Office Complex, Katsina
                  </Text>
                  <Text
                    style={{
                      fontFamily: "DM_SANS",
                      fontStyle: "italic",
                      fontSize: 12,
                    }}
                  >
                    P.MB 2132, KATSINA
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  paddingBottom: 5,
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "70%",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.para1}>Our Ref: </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "DM_SANS",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    S/MBEP/BD/{form.approval_type === "Recurrent" ? "REC" : "CAP"}
                    /FA/{moment().format("YY")}/VOL.I/
                    {form?.approval_no.substring(4)}
                  </Text>
                </View>
                <View
                  style={{
                    width: "30%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "DM_SANS",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {taday}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    marginBottom: 3,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  The Accountant General,
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    marginBottom: 3,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Ministry of Finance,
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                  }}
                >
                  Katsina,
                </Text>
              </View>

              <View
                style={{
                  width: "100%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Text style={styles.bodyHeader}>SPECIAL RELEASE OF FUNDS</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  textAlign: "justify",
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    marginTop: 7,
                    fontStyle: "bold",
                  }}
                >
                  I am directed to convey approval for the release of
                  <Text
                    style={{
                      fontFamily: "DM_SANS",
                      fontStyle: "bold",
                    }}
                  >
                    {" "}
                    (N{formatNumber(form.amount)}) only
                  </Text>
                  <Text>
                    {" "}
                    to the{" "}
                    {form?.copies1?.officeTitle &&
                      form?.copies1?.officeTitle !== null
                      ? form?.copies1?.officeTitle + " "
                      : ""}
                    {form?.copies1?.officeTitle &&
                      form?.copies1?.officeTitle !== null
                      ? " payable to the "
                      : ""}{" "}
                    {form?.copies?.officeTitle}
                  </Text>
                  {form.narration}.
                  <Text
                    style={{
                      fontFamily: "DM_SANS",
                      fontStyle: "bold",
                    }}
                  >
                    {" "}
                    The Vote of Charge is{" "}
                    {form.projectType === "payable-to"
                      ? form.send_to_office
                      : form.mda_code}
                    /
                    {form.approval_type === "Recurrent"
                      ? form.economic_code
                      : form.program_code}
                    .
                  </Text>
                </Text>

                <Text
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    marginTop: 7,
                  }}
                >
                  This letter is copied to the{" "}
                  {ignoreMDA ? null : form?.copies?.officeTitle},{" "}
                  {form?.copies1 && form?.copies1?.officeTitle}, the PS, (GHKT), the
                  HC (MOF), the Auditor-General, the Office of the Auditor-General
                  for the State, and the Director of Main Account, Ministry of
                  Finance.
                </Text>
              </View>

              <View
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {/* <Text
              style={{
                fontSize: 12,
                marginTop: 20,
                fontFamily: "DM_SANS",
                fontStyle: "italic",
              }}
            >
              Above for your information and awareness, please.
            </Text> */}
              </View>
              <View>
                <View
                  style={{
                    marginLeft: "auto",
                    marginTop: 5,
                    width: "35%",
                    textAlign: "center",
                  }}
                >
                  {signatureInfo && signatureInfo.imageURL ? (
                <Image
                  src={signatureInfo.imageURL}
                  style={{
                    marginHorizontal: "2px",
                    // marginLeft: "auto",
                    height: "50%",
                    width: "50%",
                  }} 
                  />
                  ) : null}
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "DM_SANS",
                      fontStyle: "bold",
                    }}
                  >
                    (SAMINU MUH'D K/SOLI)
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "DM_SANS",
                      fontStyle: "bold",
                    }}
                  >
                    FOR: HON, COMMISSIONER
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};
