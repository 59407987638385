import React, { useContext } from "react";
import {
  Alert,
  Button,
  Form,
  ButtonGroup,
  InputGroup,
  Card,
  Container,
  Dropdown,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";
import { useState } from "react";
import { useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { apiURL, deleteApi, fetchApi, postApi } from "../../../redux/api";
import CustomTable from "../../../components/CustomTable";
import EditApproval from "./EditApproval";
import { toast } from 'react-hot-toast';
import SearchInput from "../../../components/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fetchUserData } from '';
import {
  faBackward,
  faCaretDown,
  faSearch,
  faEdit,
  faEye,
  faListUl,
  faMarker,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../../routes";
import { formatNumber } from "../../../redux/utils";
import { SearchContext } from "../../HomePage";
// import { toast } from 'react-hot-toast';
import { useSelector } from "react-redux";
import { toHaveStyle } from "@testing-library/jest-dom";

function ADExpContrApprovals({ match, type = null }) {
  const user = useSelector((state) => state.auth.user);
  const history = useNavigate();
  const location = useLocation();
  const isPending = location.pathname.includes("payapp");
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
  const [filterText, setFilterText] = useState("");
  const batch_no = useParams().batch_no;
  const [budgetUtilization, setBudgetUtilization] = useState([]);
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState(type || "All");
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const [selectedApproval, setApprovalNo] = useState("");

  const [returndApproval, retApprovalNo] = useState("");

  const [approvalImageInfo, setApprovalImageInfo] = useState([]);
  const [item, setItem] = useState({});
  const [_delete, setDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleDelete = (item) => {
    setItem(item);
    setDelete(!_delete);
  };
  const [showRemark1, setShowRemark1] = useState(false)
  const [showRemark, setShowRemark] = useState(false);
  const [remark, setRemark] = useState("");
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [returnlLoading, setReturnLoading] = useState(false);
  const [returnlMessage, setReturnMessage] = useState(null);
  const [approvalMessage, setApprovalMessage] = useState(null);
  const [approvalInfo, setApprovalInfo] = useState({})
  const [userData, setUserData] = useState(null);
  const toggleRemark = () => setShowRemark((p) => !p);
  const toggleRemark1 = () => setShowRemark1((p) => !p);

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p);
  };
  // postApi(`post_approval_collection`, {
  //   form: { query_type: isPending ? "pending" : "by_mda", filter },
  //   // status : "Paid",
  //   // batch_no
  // })

  const handleViewClick = async (item) => {
    try {
      // const response = await postApi(`post_approval_collection`, {
      //   form: {
      //     query_type: "select_by_approval_no",
      //     approval_no: item.approval_no,
      //   },
      // });

      // if (!response.ok) {
      //   throw new Error(`Request failed with status: ${response}`);
      // }

      // const userData = await response.json();
      // setUserData(userData);

      // Navigate to edit approval route
      history(`${Routes.approval.editApprovalRoute}/${item.approval_no}`);
    } catch (error) {
      // console.log(error)
      // console.error("Error Fetching Release Collection Data:", error);
      // // Log response data if available
      // if (error.response) {
      //   console.error("Response Data:", await error.response.json());
      // }
    }
  };

  const fetchApprovalData = async (approvalNumber) => {
    try {
      const response = await fetch(
        `your-api-endpoint?approvalNumber=${approvalNumber}`
      );
      const data = await response.json();
      history.push(`${Routes.approval.editApprovalRoute}/${item.approval_no}`);
      return data;
    } catch (error) {
      throw new Error("Error fetching approval data");
    }
  };

  const viewAttachment = (imageid, approval_no) => {
    setApprovalNo(approval_no);
    fetchApi(`fetch-approval-images?approval_no=${imageid}`).then((resp) => {
      attachmentModalToggle();
      if (resp.results && resp.results.length) {
        setApprovalImageInfo(resp.results);
      } else {
        setApprovalImageInfo({});
      }
    });
  };

  const getApprovalCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_approval_collection`, {
        form: { query_type: "by_mda", filter: "Approved by Director Exp. Cont.", mda_name: user ? user.mda_name : ""},
    //   form: { query_type:  "by_mda" },
    //   filter : "Approval Releases",
    //   filter : "Approval_status",
      // batch_no
    })
      .then((data) => {
        console.log("data", data);
        if (data.result) {
          const arr = [];
          data.result.forEach((item) => {
            arr.push({ ...item, isOpen: false });
          });
          setBudgetUtilization(data.result);
        }
      })

      .catch((err) => {
        toast.error(err);
        // setLoadSpinner(false);
      })
      .finally(() => {
        setLoading(false);
      });
   }, [user.mda_name]);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  let list = [];
  budgetUtilization.forEach((item) => {
    if (
      item.approval_no.indexOf(filterText) === -1
    )
      return;
    else list.push(item);
  });

  const updateReturnApproval = (approval_no, status) => {
    setReturnLoading(true);
    postApi("return-approval", {
      remarks: remark,
      status,
      approval_no,
      userId: user.username,
    })
      .then((resp) => {
        setReturnLoading(false);
        if (resp && resp.success) {
          setReturnMessage("Return Successfully");
          setTimeout(() => {
            toggleRemark();
            getApprovalCollection();
          }, 1000);
        }
      })
      .catch((err) => {
        setReturnLoading(false);
        toast.error("An error occured, please try again later");
        console.log(err);
      });
  };


  const updateApproval = (approval_no, status) => {
    setApprovalLoading(true);
    postApi("update-approval", {
      remarks: remark,
      status: status,
      approval_no,
      userId: user.username,
      mda_name: approvalInfo.mda_name,
    })
      .then((resp) => {
        setApprovalLoading(false);
        if (resp && resp.success) {
          setApprovalMessage(status==="Return Processed"?"Returned Approval Successfully":"Approved Successfully");
          setTimeout(() => {
            toggleRemark();
            getApprovalCollection();
          }, 1000);
        }
      })
      .catch((err) => {
        setApprovalLoading(false);
        toast.error("An error occured, please try again later");
        console.log(err);
      });
  };

  const fields = [
    {
      title: "Action",
      custom: true,
      component: (item, index) => (
        <div>
          <Button onClick={() =>
            history(
              `${Routes.approval.editApprovalRoute}/${item.approval_no}`
            )
          }>View</Button>
        </div>
        // <Dropdown>
        //   <Dropdown.Toggle
        //     as={Button}
        //     variant="primary"
        //     className="me-2 bg-success"
        //   >
        //     <span>Action</span>
        //     <FontAwesomeIcon icon={faCaretDown} className="ms-2" />
        //   </Dropdown.Toggle>
        //   <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
        //     <Dropdown.Item
        //       onClick={() => {
        //         setApprovalInfo(item)
        //         setApprovalNo(item.approval_no);
        //         toggleRemark();
        //       }}
        //       // onClick={() => viewAttachment(item.imageId, item.approval_no)}
        //     >
        //       <FontAwesomeIcon icon={faEdit} className="me-2" /> Approve 
        //     </Dropdown.Item>
        //     <Dropdown.Item 
        //     // onClick={handleViewClick}
        //     onClick={() =>
        //       history(
        //         `${Routes.approval.editApprovalRoute}/${item.approval_no}`
        //       )
        //     }
        //     >
        //       <FontAwesomeIcon icon={faEye} className="me-2" /> View
        //       {userData && <EditApproval userData={userData} />}
        //     </Dropdown.Item>
        //     {/* <Dropdown.Item
        //       onClick={() =>
        //         history(
        //           `${Routes.approval.editApprovalRoute}/${item.approval_no}`
        //         )
        //       }
        //     >
        //       <FontAwesomeIcon icon={faEye} className="me-2" /> View
        //     </Dropdown.Item> */}
        //     {/* <Dropdown.Item onClick={() => toggleDelete(item)}>
        //       <FontAwesomeIcon icon={faTrash} className="me-2" /> Delete
        //     </Dropdown.Item> */}
        //     <Dropdown.Item
        //       onClick={() => {
        //         setApprovalNo(item.approval_no);
        //         toggleRemark1();
        //       }}
        //     >
        //       <FontAwesomeIcon icon={faBackward} className="me-2" /> Return
        //     </Dropdown.Item>
        //   </Dropdown.Menu>
        // </Dropdown>
      ),
    },
    // {
    //   title: 'S/N',
    //   custom: true,
    //   component: (item, index) => <div>{index + 1}</div>,
    // },
    // {
    //   title: "Economic Code",
    //   value: "economic_code",
    // },
    {
      title: "MDA Name",
      value: "mda_name",
    },
    // {
    //   title: "Collection Date",
    //   value: "collection_date",
    // },
    {
      title: "Approval Date",
      value: "approval_date",
    },
    {
      title: "Approval No",
      value: "approval_no",
    },
    {
      title: "Approval Type",
      value: "approved_by",
    },
    {
      title: "Status",
      value: "approval_status",
    },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
    },
  ];

  const getRowStyle = (row) => {
    switch (row.approval_status) {
      case "Approval Released":
        return { backgroundColor: "orange" };
        case "Return Released":
        return { backgroundColor: "orange" };
      case "Approval Processed":
        return { backgroundColor: "lightblue" };
        case "Return Processed":
        return { backgroundColor: "lightblue" };
      case "Approval Approved":
        return { backgroundColor: "lightgreen" };
        case "Return Approved":
        return { backgroundColor: "lightgreen" };
      case "Approval Queried":
        return { backgroundColor: "yellow" };
        case "Return Queried":
        return { backgroundColor: "yellow" };
      case "Payment Schedule":
        return { backgroundColor: "green" };
        case "Return Schedule":
        return { backgroundColor: "green" };
      default:
        return {};
    }
  };

  const handleDelete = () => {
    setLoading(true);
    deleteApi(`delete-approve-collection`, item)
      .then((res) => {
        toast.success("Deleted Successfully");
        setLoading(false);
        getApprovalCollection();
        toggleDelete({});
      })
      .catch((err) => {
        toast.error(`error occured ${JSON.stringify(err)}`);
        setLoading(false);
      });
  };
  // const ApprovalCollectionIndex = () => {
  //   const { searchTerm, setSearchTerm } = useContext(SearchContext);
  //   // ... rest of the code
  // };

  const finalList = [];
  list.forEach((item) => {
    if (item.approval_no.indexOf(searchTerm.toLowerCase()) === -1)
      return;
    finalList.push(item);
  });

  useEffect(() => {
    return () => {
      setSearchTerm("");
    };
  }, [setSearchTerm]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <Button
          onClick={() => history(Routes.approval.newApproval)}
          className="me-2 bg-success"
        >
          Add Approval
        </Button> */}
        <div className="d-block mb-4 mb-md-0">
          <h4>
            {/* <FontAwesomeIcon icon={faListUl} className="me-2" />  */}
            Assistant Director Releases Dashboard
          </h4>
        </div>
        {/* {JSON.stringify(user)} */}
        {/*  user.mda_name === "Office of the Accountant-General" */}
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <SearchInput placeholder="Search For Approve Releases" value={searchTerm} onChange={({target:{value}}) => setSearchTerm(value)} />

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
        {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
          <div style={{ height: "80vh", overflow: "scroll" }}>
            <CustomTable
              fields={fields}
              data={finalList}
              // currentPage={currentPage}
              // handlePageChange={handlePageChange}
              getRowStyle={(data) => getRowStyle(data)}
            />
          </div>
          )}
        </Card.Body>

        <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
          <Modal.Header onHide={attachmentModalToggle}>
            Approval No.: {selectedApproval}
          </Modal.Header>
          <Modal.Body className="text-center">
            <Container>
              {/* <div className="image-container">
                {approvalImageInfo.length ? (
                  approvalImageInfo.map((item, index) =>
                    imageExtensions.some((extension) =>
                      item.image_url.toLowerCase().endsWith(extension)
                    ) ? (
                      <Card.Img
                        top
                        src={`${apiURL}/uploads/${item.image_url}`}
                        alt={"approval"}
                      />
                    ) : (
                      <Card.Body>
                        <Card.Link
                          href={`${apiURL}/uploads/${item.image_url}`}
                          target="_blank"
                        >
                          View PDF
                        </Card.Link>
                      </Card.Body>
                    )
                  )
                ) : (
                  <h4>No attachment found.</h4>
                )}
              </div> */}
            </Container>
            {/* <h6>MDA: {approvalImageInfo.mda_name}</h6> */}
          </Modal.Body>
        </Modal>

        <Modal show={showRemark} onHide={toggleRemark}>
          {approvalLoading ? null : (
            <Modal.Header onHide={toggleRemark}>Confirmation</Modal.Header>
          )}
          {approvalLoading ? (
            <span>
              Please wait
              <Spinner color="primary" size="xl" />
            </span>
          ) : approvalMessage && approvalMessage !== "" ? (
            <h5>{approvalMessage}</h5>
          ) : (
            <>
            <Modal.Body className="text-center">
                  <h5>
                    Are you sure you want to approve this Release with
                    No.: {selectedApproval}{" "}
                  </h5>

                  <div>
                    <h6>Add Remarks</h6>
                    <textarea
                      value={remark}
                      onChange={({ target: { value } }) => setRemark(value)}
                      className="form-control"
                      placeholder="Add your remarks here" />
                  </div>
                </Modal.Body>
                  </>
          )}
          {approvalLoading ? null : (
            <Modal.Footer>
              <div>
                <Button color="danger" outline className="m-1 p-1 px-3" onClick={toggleRemark}>
                  Cancel
                </Button>
                <Button
                  className="m-1 p-1 px-3"
                  onClick={() =>
                    updateApproval(selectedApproval, "Approval Processed")
                  }
                  loading={loading}
                >
                  Approve
                </Button>
              </div>
            </Modal.Footer>
          )}
        </Modal>



        <Modal show={showRemark1} onHide={toggleRemark1}>
          {returnlLoading ? null : (
            <Modal.Header onHide={toggleRemark1}>Confirmation</Modal.Header>
          )}
          {approvalLoading ? (
            <span>
              Please wait
              <Spinner color="primary" size="xl" />
            </span>
          ) : approvalMessage && approvalMessage !== "" ? (
            <h5 className="text-center">{approvalMessage}</h5>
          ) : (
            <>
            <Modal.Body className="text-center">
                  <h5>
                    Are you sure you want to return this Release with
                    No.: {returndApproval}{" "}
                  </h5>

                  <div>
                    <h6>Add Remarks</h6>
                    <textarea
                      value={remark}
                      onChange={({ target: { value } }) => setShowRemark1(value)}
                      className="form-control"
                      placeholder="Add Reason For Return" />
                  </div>
                </Modal.Body>
                  </>
          )}
          {approvalLoading && approvalMessage && approvalMessage!=="" ? null : (
            <Modal.Footer>
              <div>
                <Button outline className="m-1 p-1 px-3" color="danger" onClick={toggleRemark1}>
                  Cancel
                </Button>
                <Button
                  color="danger"
                  className="m-1 p-1 px-3"
                  onClick={() =>
                    updateApproval(selectedApproval, "Approval Received")
                    // updateReturnApproval(returndApproval, "Return Processed")
                  }
                  loading={loading}
                >
                  Return
                </Button>
              </div>
            </Modal.Footer>
          )}
        </Modal>

        <Modal show={_delete} onHide={toggleDelete}>
          <Modal.Header onHide={toggleDelete}>Delete</Modal.Header>
          <Modal.Body className="text-center">
            <h5>Are you sure you want to delete {item.approval_no} </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button outline className="m-1 p-1 px-3" onClick={toggleDelete}>
                Cancel
              </Button>
              <Button
                color="danger"
                className="m-1 p-1 px-3"
                onClick={handleDelete}
                loading={loading}
              >
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Card>
    </>
  );
}

export default ADExpContrApprovals;
