import { Button, Spinner } from '@themesberg/react-bootstrap'
import React from 'react'

function CustomButton(props) {
  const { loading = false, variant = 'primary', type = 'input' } = props
  return (
    <Button variant={variant} type={type}  disabled={loading} {...props}>
      {loading ? 'Please wait...' : props.children}
    </Button>
  )
}

export default CustomButton
