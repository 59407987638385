import React from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Row,
  Spinner,
} from '@themesberg/react-bootstrap'

import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { formatNumber } from '../../../redux/utils'
import { toast } from 'react-hot-toast';
import CustomTable from '../../../components/CustomTable'
import BackButton from '../../../components/BackButton'
import { fetchApi, postApi } from '../../../redux/api'
import useQuery from '../../../hooks/useQuery'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from 'moment-timezone'
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

function ExpenditureReport() {
  const history = useNavigate()
  const query = useQuery()
  const _mda_name = query.get('mda_name')
  const description = query.get('description')
  const amount = query.get('amount')
  const balance = query.get('balance')
  const percentage = query.get('percentage')
  const mda_code = query.get('mda_code')
  const budget_year = query.get('budget_year')
  // const usei = useQuery.get()
  const { mda_name } = useParams()
  console.log('mda_name', mda_name)
  const [fetchingReport,setFetchingReport] = useState(false)
  const [expenditureReport, setExpenditureReport] = useState([])
  const [tresuryDetails, setTresuryDetails] = useState({})
  const [changeAmount, setChangeAmount] = useState(false)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [amountIndex, setAmountIndex] = useState(0)
  const [form, setForm] = useState({
    batch_number: '',
    date: '',
    no_of_mda: '',
    no_of_payments: '',
    total_amount: '',
    cheque_number: '',
    query_type: 'insert',
    status: 'uncommited',
    amount: '',
  })

  useEffect(() => {
    postApi('get_batch_list', {
      query_type: 'select_payment_list',
      status: 'pending',
    })
      .then((data) => {
        console.log('gg', data)
        if (data.result) {
          // setPaymentDetails(data.result)
          const formData = data.result[0]
          setForm((p) => ({ ...p, ...formData }))
          // setPaymentScheduleTable(data.result);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [])

  const fetchDataReport = () => {
    setFetchingReport(true)

    fetchApi(`get-report?query_type=expenditure`)
      .then((data) => {
        setFetchingReport(false)
        if (data.result && data.result.length) {
          let finalResult = data.result.map(p => ({...p, 
            gross: formatNumber(p.gross),
            total_taxes: formatNumber(p.total_taxes),
            net: formatNumber(p.net),


            }))
          // setBudgetUtilizationReport(data.result)
          // console.log(finalResult)
          exportToCSV(finalResult, `Expenditure Report ${moment().format('YYYYMMDD')}`);
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error("Error exporting this report, please try again later.")
        setFetchingReport(false)
        // Handle error
      })
  }

  useEffect(() => {
    fetchApi(`get-report?query_type=expenditure`)
      .then((data) => {
        console.log('data', data)
        if (data.result && data.result.length) {
          setForm(data.result[0])
          setExpenditureReport(data.result)
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
  }, [mda_name, budget_year, mda_code])

  const totalGross = expenditureReport.reduce((a,b) => a + parseFloat( b.gross), 0)
  const totalTaxes = expenditureReport.reduce((a,b) => a + parseFloat(b.total_taxes), 0)
  const totalNet = expenditureReport.reduce((a,b) => a + parseFloat( b.net), 0)

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <BackButton />
      <Card className="px-4">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center my-4">
          <div className="d-block mb-4 mb-md-0">
            <h4>Expenditure Report</h4>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <ButtonGroup>
              <Button variant="outline-primary" size="sm">
                Share
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={fetchDataReport}
              >
                {fetchingReport && <Spinner />}
                Export
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <div>
          <h6>Total Gross Amount: ₦{formatNumber(totalGross)}</h6>
          <h6>Total Taxes: ₦{formatNumber(totalTaxes)}</h6>
          <h6>Total Net Amount: ₦{formatNumber(totalNet)}</h6>
        </div>

        <div className="mt-2 table-responsive">
          <CustomTable
            reponsive
            fields={[
              {
                title: "S/N",
                custom: true,
                component: (item, index) => <div>{index + 1}</div>,
              },
              { title: "Date", value: "date" },
              {
                title: "MDA",
                custom: true,
                component: (item, index) => (
                  <div className="">{item.mda_name}</div>
                ),
              },
              {
                title: "CONTRACTOR",
                custom: true,
                component: (item, index) => (
                  <div className="">{item.contractor}</div>
                ),
              },
              {
                title: "GROSS",
                custom: true,
                component: (item, index) => (
                  <div className="text-end">{formatNumber(item.gross)}</div>
                ),
              },
              {
                title: "TOTAL TAXES",
                custom: true,
                component: (item, index) => (
                  <div className="text-end">
                    {formatNumber(item.total_taxes)}
                  </div>
                ),
              },
              {
                title: "NET",
                custom: true,
                component: (item, index) => (
                  <div className="text-end">{formatNumber(item.net)}</div>
                ),
              },
            ]}
            data={expenditureReport}
          />
          {/* {JSON.stringify(expenditureReport)} */}
        </div>
      </Card>
    </>
  );
}

export default ExpenditureReport
