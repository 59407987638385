/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Dropdown,
    Modal,
} from "@themesberg/react-bootstrap";

import { useState } from "react";
import { useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { apiURL, deleteApi, fetchApi, postApi } from "../../../redux/api";
import CustomTable from "../../../components/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faEdit,
    faEye,
    faListUl,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../../routes";
import { formatNumber } from "../../../redux/utils";
import { useSelector } from "react-redux";
import { SearchContext } from "../../HomePage";
import { Col, Form, Row } from "react-bootstrap";
import SearchInput from "../../../components/SearchInput";

function AuditorpvDashboard({ match, type = null }) {
    const history = useNavigate();
    const location = useLocation();
    const isPending = location.pathname.includes("payapp");
    const user = useSelector((state) => state.auth.user);
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const [filterText, setFilterText] = useState("");
    const batch_no = useParams().batch_no;
    const [pvList, setPvList] = useState([]);
    const [modal, setModal] = useState(false);
    const [filter, setFilter] = useState(type || "All");
    const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
    const [selectedPV, setSelectedPvNo] = useState("");
    const [pvImageInfo, setPvImageInfo] = useState([]);
    const [item, setItem] = useState({});
    const [_delete, setDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const { searchTerm, setSearchTerm } = useContext(SearchContext);
    const toggleDelete = (item) => {
        setItem(item);
        setDelete(!_delete);
    };
    const [approvalList, setApprovalList] = useState([]);

    const attachmentModalToggle = () => {
        setAttachmentModalIsOpen((p) => !p);
    };

    const viewAttachment = (imageid, pv) => {
        setSelectedPvNo(pv);
        fetchApi(`fetch-pv-images?pv_no=${imageid}`).then((resp) => {
            attachmentModalToggle();
            if (resp.results && resp.results.length) {
                setPvImageInfo(resp.results);
            } else {
                setPvImageInfo({});
            }
        });
    };

    const getPvCollection = useCallback(() => {

        postApi(`get_pv_collection`, {
            form: {
                query_type: "all_pvs_by_mda",
                mda_name: user ? user.mda_name : ""
            },
        })
            .then((data) => {
                console.log('data', data)
                if (data.result) {
                    const arr = []
                    data.result.forEach((item) => {
                        arr.push({ ...item, isOpen: false })
                    })
                    setPvList(data.result)
                }
            })

            .catch((err) => {
                // alert(err);
                // setLoadSpinner(false);
            })
    }, [filter])

    useEffect(() => {
        getPvCollection()
    }, [getPvCollection])

    const handleFilter = () => {
        const filteredList = pvList.filter((item) => {
            const dateFromCondition = dateFrom ? new Date(item.date) >= new Date(dateFrom) : true;
            const dateToCondition = dateTo ? new Date(item.date) <= new Date(dateTo) : true;
            return dateFromCondition && dateToCondition;
        });
        return filteredList;
    };

    useEffect(() => {
        handleFilter();
    }, [dateFrom, dateTo]);

    const filteredList = handleFilter();

    let list = [];
    filteredList.forEach((item) => {
        if (
            item.approval_no.indexOf(filterText) === -1 &&
            item.approval_no.toString().indexOf(filterText.toLowerCase()) === -1
        )
            return;
        else list.push(item);
    });

    // const handleDelete = () => {
    //   _setLoading(true)
    //   deleteApi(`delete-pv-collection`, item)
    //     .then(() => {
    //       alert('Deleted Successfully')
    //       _setLoading(false)
    //       getPvCollection()
    //       toggleDelete({})
    //     })
    //     .catch((err) => {
    //       alert(`error occured ${JSON.stringify(err)}`)
    //       _setLoading(false)
    //     })
    // }

    const fields = [
        {
            title: "Action",
            custom: true,
            component: (item, index) => (
                <Button
                    onClick={() => {
                        history(
                            `${Routes.pv.AuditorpvReportView}/${item.approval_no}?approval_type=${item.approval_type}`
                        );
                    }
                    }
                >
                    View
                </Button>

            ),
        },

        {
            title: "Approval No.",
            value: "approval_no",
        },

        {
            title: "Number of PVs",
            custom: true,
            value: "no_of_pvs",
            component: (item) => (
                <div className="text-end">{(item.no_of_pvs)}</div>
            ),
        },
        {
            title: "Status",
            value: "status",
        },
        {
            title: "Amount (N)",
            custom: true,
            component: (item) => (
                <div className="text-end">{formatNumber(item.amount)}</div>
            ),
            //   value: 'amount',
        },


    ];

    const finalList = [];
    list.forEach((item) => {
        if (item.approval_no.indexOf(searchTerm.toLowerCase()) === -1)
            return;
        finalList.push(item);
    });

    useEffect(() => {
        return () => {
            setSearchTerm("");
        };
    }, [setSearchTerm]);

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                {/* <Button
            onClick={() => history(Routes.pv.newPv)}
            className="me-2"
          > <FontAwesomeIcon icon={faPlus} className='me-2' />
            Add PV
          </Button> */}
                <div className="d-block mb-4 mb-md-0">
                    <h4>
                        {/* <FontAwesomeIcon icon={faListUl} className="me-2" />  */}
                        {/* Generate Payment Voucher */}
                        PV Reports
                    </h4>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm">
                            Share
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            Export
                        </Button>
                    </ButtonGroup>
                </div>
            </div>

            {/* {JSON.stringify(user)} */}

            <Row>
                <Col md={6} style={{
                    marginTop: 28
                }}>
                    <SearchInput
                        placeholder="Search For Review Releases"
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                    />
                </Col>
                <Col md={6}>
                    <Row style={{
                        marginBottom: 10
                    }}>
                        <Col md={6}>
                            <Form.Group id="date_from">
                                <Form.Label>Date From</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={dateFrom}
                                    onChange={(e) => setDateFrom(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group id="date_to">
                                <Form.Label>Date to</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={dateTo}
                                    onChange={(e) => setDateTo(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body
                    className="p-0"
                    style={{ height: "80vh", overflow: "scroll" }}
                >
                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <CustomTable
                            fields={fields}
                            data={finalList}
                        // currentPage={currentPage}
                        // handlePageChange={handlePageChange}
                        />
                    )}
                </Card.Body>

                <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
                    <Modal.Header onHide={attachmentModalToggle}>
                        PV No.: {selectedPV}
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Container>
                            <div className="image-container">
                                {pvImageInfo.length ? (
                                    pvImageInfo.map((item, index) =>
                                        imageExtensions.some((extension) =>
                                            item.image_url.toLowerCase().endsWith(extension)
                                        ) ? (
                                            <Card.Img
                                                top
                                                src={`${apiURL}/uploads/${item.image_url}`}
                                                alt={"pv"}
                                            />
                                        ) : (
                                            <Card.Body>
                                                <Card.Link
                                                    href={`${apiURL}/uploads/${item.image_url}`}
                                                    target="_blank"
                                                >
                                                    View PDF
                                                </Card.Link>
                                            </Card.Body>
                                        )
                                    )
                                ) : (
                                    <h4>No attachment found.</h4>
                                )}
                            </div>
                        </Container>
                        {/* <h6>MDA: {approvalImageInfo.mda_name}</h6> */}
                    </Modal.Body>
                </Modal>

                <Modal show={_delete} onHide={toggleDelete}>
                    <Modal.Header onHide={toggleDelete}>Delete</Modal.Header>
                    <Modal.Body className="text-center">
                        <h5>Are you sure you want to delete {item.pv_code} </h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button outline className="m-1 p-1 px-3" onClick={toggleDelete}>
                                Cancel
                            </Button>
                            <Button
                                color="danger"
                                className="m-1 p-1 px-3"
                                // onClick={handleDelete}
                                loading={loading}
                            >
                                Delete
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Card>
        </>
    );
}

export default AuditorpvDashboard;
