/* eslint-disable react-hooks/exhaustive-deps */
import { PDFViewer } from "@react-pdf/renderer";
import React, { useCallback, useEffect, useState } from "react";
import { PaymentVoucherPdf } from "./PVPDF";
import { apiURL, fetchApi, postApi } from "../../../redux/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card, Col, Row, Table } from "@themesberg/react-bootstrap";
import { Alert, Modal } from "react-bootstrap";
import TextInput from "../../../components/TextInput";
import CustomButton from "../../../components/CustomButton";
import { Routes } from "../../../routes";
import toast from "react-hot-toast";
import BackButton from "../../../components/BackButton";
import useQuery from "../../../hooks/useQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import CustomTable from "../../../components/CustomTable";
import SupportingDocumentsPv from "./SupportingDocuments";
import { OAGOFFICE } from "./PVReviewIndex";

export default function PvPdfPage() {
  const user = useSelector((state) => state.auth.user);
  const pv_code = useParams().pv_code;
  const approval_no = useParams().approval_no;
  const query = useQuery();
  const actualPvCode = query.get("pv_code") || "";
  const history = useNavigate();
  const location = useLocation();
  const isReport = location.pathname.includes("report");
  const isReview = location.pathname.includes("review");
  const isEdit = location.pathname.includes("generated");
  // const isAdCheck = location.pathname.includes("checkad");
  const isCheck = location.pathname.includes("check");
  const isApprove = location.pathname.includes("approve");
  const isDAG = isApprove && user.mda_name === OAGOFFICE
  const isIndividual = location.pathname.includes("individual");

  const [pvInfo, setPvInfo] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [pvHistory, setpvHistory] = useState([]);
  const [attachmentList, setPvAttachmentList] = useState([])
  const [approving, setApproving] = useState(false)
  const [commentExpanded, setCommentExpanded] = useState(false)
  const [commentObj, setCommentObj] = useState({})

  useEffect(() => {
    if (pv_code) {
      setLoading(true);
      if(user && user.mda_name === OAGOFFICE) {
        postApi(`get_pv_collection`, {
          form: {
            query_type: isIndividual
              ? "select_by_pvcode"
              : isReport
                ? "pv_by_approval_report"
                : "pv_by_approval",
            approval_no: pv_code,
            pv_status: isApprove ? "Reviewed" : isReview ? /*"AD Checked" : isAdCheck ?*/ "Checked" : isEdit ? 'Created - Returned' : "Pending",
            mda_name: user.mda_name,
            pv_code: actualPvCode,
          },
        })
          .then((data) => {
            // console.log("data", data);
            if (data?.result && data?.result.length) {
              setPvInfo(data?.result);
              const filteredStatus = `${isApprove ? "Reviewed" : isReview ? "Checked" : "Pending"} - Returned`
              let comm = data?.result[0].history.filter(a => a?.pv_status === filteredStatus)
              // console.log(comm,'commmmmmm')
              if (comm && comm.length) {
                setCommentObj(comm[0])
              }
              // console.log(filteredStatus)
            }
  
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => setLoading(false));
      } else {
      postApi(`get_pv_collection`, {
        form: {
          query_type: isIndividual
            ? "select_by_pvcode"
            : isReport
              ? "pv_by_approval_report"
              : "pv_by_approval",
          approval_no: pv_code,
          pv_status: isApprove ? "Reviewed" : isReview ? "Checked" : isEdit ? 'Created - Returned' : "Pending",
          mda_name: user.mda_name,
          pv_code: actualPvCode,
        },
      })
        .then((data) => {
          // console.log("data", data);
          if (data?.result && data?.result.length) {
            setPvInfo(data?.result);
            const filteredStatus = `${isApprove ? "Reviewed" : isReview ? "Checked" : "Pending"} - Returned`
            let comm = data?.result[0].history.filter(a => a?.pv_status === filteredStatus)
            // console.log(comm,'commmmmmm')
            if (comm && comm.length) {
              setCommentObj(comm[0])
            }
            // console.log(filteredStatus)
          }

        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
      }
    }
  }, [pv_code, isIndividual, isReport, isApprove, user.mda_name, actualPvCode]);

  useEffect(() => {
    if (pv_code) {
      setLoading(true);
      postApi(`get_pv_collection`, {
        form: {
          query_type: "taxes_by_approval",
          approval_no: pv_code,
          // pv_status: "pending",
        },
        // status : "Paid",
        // batch_no
      })
        .then((data) => {
          console.log("datadddd", data);
          if (data?.result && data?.result.length) {
            setTaxes(data?.result);
          }
        })

        .catch((err) => {
          console.log(err);
          // alert(err);
          // setLoadSpinner(false);
        })
        .finally(() => setLoading(false));
    }
  }, [pv_code]);

  const toggleWarning = () => setShowWarning((p) => !p);

  const handleReturn = async () => {
    setApproving(true)
    for (let pv of pvInfo) {
      await postApi(`post_pv_collection`, {
        form: {
          query_type: "update_cash_backing",
          pv_code: pv.pv_code,
          approval_no: pv.pv_code,
          pv_status: isReview
            ? "Pending - Returned"
            : isCheck
              ? "Created - Returned"
              : "Checked - Returned",
          userId: user.username,
          remarks,
        },
      });
    }

    toggleWarning();
    toast.success("PV Returned Successfully!");
    if (isReview) {
      history(Routes.pv.pvReviewIndex);
    } else if (isCheck) {
      history(Routes.pv.pvCheckIndex);
    } else {
      history(Routes.pv.pvApproveIndex);
    }
  };

  // const getpvHistory = useCallback(() => {
  //   postApi(`get_pv_collection`, {
  //     query_type: "get_history",
  //     pv_code: pv_code,
  //   })
  //     .then((data) => {
  //       console.log("History data", data);
  //       if (data?.result && data?.result.length) {
  //         setpvHistory(data.result);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("This is the Error that occurred", err);
  //     });
  // }, [pv_code]);

  // useEffect(() => {
  //   getpvHistory();
  // }, [getpvHistory]);

  useEffect(() => {
    if (pv_code) {
      setLoading(true);
      postApi(`get_pv_collection`, {
        form: {
          query_type: "get_history",
          approval_no: actualPvCode,
        },
      })
        .then((data) => {
          console.log("My History", data);
          if (data?.result && data?.result.length) {
            setpvHistory(data?.result);
          }
        })

        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [pv_code]);

  const handleApprove = async () => {
    setApproving(true)
    for (let pv of pvInfo) {
      // if(user && user.mda_name === OAGOFFICE) {
      //   alert("XXXMMM")
        await postApi(`post_pv_collection`, {
          form: {
            query_type: "update_cash_backing",
            pv_code: pv.pv_code,
            approval_no: pv.pv_code,
            pv_status: isDAG ? "DAG Approved" : isReview ? "Reviewed" : /*isAdCheck ? "AD Checked" :*/ isCheck ? "Checked" :  "Approved",
            userId: user.username,
            remarks,
          },
        });
      // }
      // else {
      //   await postApi(`post_pv_collection`, {
      //     form: {
      //       query_type: "update_cash_backing",
      //       pv_code: pv.pv_code,
      //       approval_no: pv.pv_code,
      //       pv_status: isReview ? "Reviewed" : isCheck ? "Checked" : "Approved",
      //       userId: user.username,
      //       remarks,
      //     },
      //   });
      // }
    }

    // setTimeout(() => {
    toast.success("PV Approved Successfully!");
    if (isReview) {
      history(Routes.pv.pvReviewIndex);
    } else if (isCheck) {
      history(Routes.pv.pvCheckIndex);
    } else {
      history(Routes.pv.pvApproveIndex);
    }
    // }, 2000);
  };

  if (pvInfo && pvInfo.length) {
    return (
      <>
        {/* {JSON.stringify({isAdCheck})}..xx */}
        {/* {JSON.stringify({commentObj,
          ll: `${isApprove ? "Reviewed" : isReview ? "Checked" : "Pending"} - Returned`
        })}..xx */}
        <div className="d-flex flex-direction-row justify-content-between">
          <BackButton num={-2}/>
          {isEdit ?
            <div className="mb-1">
              <button
                style={{
                  backgroundColor: "yellow",
                  border: "none",
                  // height: "60%",
                  // padding: 5,
                  // paddingLeft: 10,
                  // paddingRight: 10,
                  // borderRadius: 10, 
                  cursor: "pointer",
                }}
                className="me-2 py-2 rounded"
                onClick={() =>
                  history(
                    `${Routes.cashBacking.releaseCollectionPDF}/${pv_code}`
                  )
                }
              >
                <FontAwesomeIcon icon={faEye} className="me-1" />
                View Release Document
              </button>
              <Button onClick={() =>
                history(
                  `${Routes.pv.editnewPv}/${pv_code}`
                )
              }>
                <FontAwesomeIcon icon={"pencil"} size="lg" />
                Edit PV</Button>
            </div>
            : null}
        </div>
        {/* {JSON.stringify(pvInfo)} */}
        <Card className="p-4">

          {isEdit ? (
            <Row>
              {/* <p>{pv_code}, {approval_no}</p> */}
              <Col md={7}>

                {commentObj?.remarks && commentObj.remarks !== "" ? <Alert className="my-0 mb-1 py-1">
                  <h6>Comments:</h6>

                  {commentObj.remarks.length > 80 && !commentExpanded ?
                    <p>{commentObj.remarks?.substr(0, 80)} <span style={{ cursor: 'pointer' }} className="text-info" onClick={() => setCommentExpanded(true)}>See more</span></p>
                    : <p>{commentObj.remarks}</p>}

                  <div> - {commentObj.fullname}</div>
                </Alert> : null}
              </Col>
            </Row>
          ) : null}

          {isReview || isCheck || isApprove ? (
            <Row>
              <Col md={7}>

                {commentObj?.remarks && commentObj.remarks !== "" ? <Alert className="my-0 mb-1 py-1">
                  <h6>Comments:</h6>

                  {commentObj.remarks.length > 80 && !commentExpanded ?
                    <p>{commentObj.remarks?.substr(0, 80)} <span style={{ cursor: 'pointer' }} className="text-info" onClick={() => setCommentExpanded(true)}>See more</span></p>
                    : <p>{commentObj.remarks}</p>}

                  <div> - {commentObj.fullname}</div>
                </Alert> : null}
              </Col>

              <Col md={5} className="text-right">

                <Button
                  className="btn-danger me-2 my-2"
                  onClick={toggleWarning}
                >
                  Return
                </Button>
                <Button onClick={handleApprove}>Approve</Button>
              </Col>
            </Row>
          ) : null}



          {approving ? "Please wait..." : null}


          <Row>
            <Col md={8}>
            {/* {JSON.stringify(pvInfo)} */}
              <PDFViewer
                style={{
                  width: "100%",
                  height: 1000,
                }}
              >
                <PaymentVoucherPdf
                  form={{}}
                  user={user}
                  data={pvInfo}
                  taxesApplied={taxes}
                />
              </PDFViewer>
            </Col>

            <Col md={4}>
              <SupportingDocumentsPv pvInfo={pvInfo} />
            </Col>
          </Row>

          {/* <Table>
            <thead>
              <tr>
                <th>
                  File Name
                </th>
                <th>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {attachmentList.map((item, id) => (
                <tr key={id}>
                  <td >
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}

          <Modal show={showWarning} onHide={toggleWarning} size='md'>
            <Modal.Header>
              <h4>Please provide reason for return</h4>
            </Modal.Header>
            <Modal.Body>

              <textarea className="form-control"
                placeholder="Reason for return" rows={10}
                value={remarks}
                onChange={({ target: { value } }) => setRemarks(value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger" onClick={toggleWarning}>Cancel</button>
              <CustomButton onClick={handleReturn}>Return</CustomButton>
            </Modal.Footer>
          </Modal>
        </Card>
      </>
    );
  } else {
    return <p>No data found.</p>;
  }
}
