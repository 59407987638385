import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useNavigate, useParams } from "react-router-dom";
import { PDFViewer, Font } from "@react-pdf/renderer";
import moment from "moment";
import RobotoItalic from "../../../assets/fonts/Roboto-Italic.ttf";
import RobotoRegular from "../../../assets/fonts/Roboto-Regular.ttf";
import RobotoBold from "../../../assets/fonts/Roboto-Bold.ttf";
import { postApi } from "../../../redux/api";
import QRCode from "qrcode";
import CustomButton from "../../../components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row } from "@themesberg/react-bootstrap";
import { padAccountNo } from "../../../redux/utils";

// import font from './styles/localFonts/Poppins-Medium.ttf'
// Create styles
const COL1_WIDTH = 40;
const COL_AMT_WIDTH = 20;
const COLN_WIDTH = (100 - (COL1_WIDTH + COL_AMT_WIDTH)) / 2;

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
    {
      src: RobotoItalic,
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 70,
    paddingBottom: 70,
    paddingRight: 20,
    paddingLeft: 20,
    // fontFamily: 'Times-Roman',
    fontFamily: "Roboto",
  },

  headerPage: {},

  topRightHeader: {
    // display : 'flex',
    // justifyContent : 'between',
    //  flexDirection: 'row',
    textAlign: "right",
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },

  topLeftHeader: {
    // flexDirection: 'row',
    textAlign: "left",
    marginTop: "100px",
    marginBottom: "25px",
    fontSize: "14px",
    color: "black",
    fontWeight: "bold",
    lineHeight: 1.5,
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },
  middleHeader: {
    // flexDirection: 'row',
    padding: 10,
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "underline",
    lineHeight: 1.2,
    fontWeight: "bold",
    // backgroundColor: '#E4E4E4',
    // width : "900px"
  },

  body1: {
    textAlign: "justify",
    marginTop: "10px",
    fontSize: 14,
    lineHeight: 1.5,
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },

  // table :{fontSize : '12px',
  // border : '1px solid black',
  // padding : 10},

  table: {
    display: "table",
    width: "18%",
    fontSize: 12,
    fontFamily: "Roboto",
    // marginVertical: 6,
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    padding: 5,
    textAlign: "center",
    fontWeight: "bold",
  },

  tablel: {
    display: "table",
    width: "18%",
    fontSize: 12,
    fontFamily: "Roboto",
    // marginVertical: 6,
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    padding: 5,
    textAlign: "left",
  },

  tableTN: {
    display: "table",
    width: "35.9%",
    fontSize: 12,
    // marginVertical: 6,
    borderBottom: "1px solid black",

    padding: 5,
    // textAlign : 'center'
  },

  tableSN: {
    display: "table",
    width: "10%",
    fontSize: 12,
    // marginVertical: 6,
    // border : '1px solid black',
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    // borderBottom : '1px solid black',
    borderLeft: "1px solid black",

    padding: 5,
    textAlign: "center",
  },

  table2: {
    display: "table",
    width: "18%",
    fontSize: 12,
    borderBottom: "1px solid black",
    borderRight: "1px solid black",

    // marginVertical: 6,
    // border : '1px solid black',
    padding: 5,
    textAlign: "right",
    // textAlign : 'center'
  },

  table3: {
    display: "table",
    width: "18%",
    fontSize: 12,
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",

    // marginVertical: 6,
    // border : '1px solid black',
    padding: 5,
    textAlign: "right",
    // textAlign : 'center'
  },

  table1: {
    display: "table",
    width: "100%",
    fontSize: 12,
    // marginVertical: 6,
    border: "1px solid black",
    padding: 5,
    textAlign: "center",
    fontWeight: "bold",
  },

  tableRow: {
    flexDirection: "row",
  },
  tableRowTotal: {
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
  },
  tableColAmtHeader: {
    width: COL_AMT_WIDTH + "%",
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
  },
  tableColAmt: {
    width: COL_AMT_WIDTH + "%",
  },
  tableCol: {
    width: COLN_WIDTH + "%",
  },
  tableColTotal: {
    width: 2 * COLN_WIDTH + "%",
  },
  tableCellHeader: {
    // marginRight: 5,
    fontWeight: "bold",
  },
  tableCell: {
    marginVertical: 1,
    // marginRight: 4,
  },
});

// System for American Numbering
var th_val = ["", "thousand", "million, ", "billion, ", "trillion, "];
// System for uncomment this line for Number of English
// var th_val = ['','thousand','million', 'milliard','billion'];

var dg_val = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];
var tn_val = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];
var tw_val = [
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];
export function toWordsconver(s) {
  if (s) {
    s = s.toString() || 0;
    s = s.replace(/[\\, ]/g, "");
    if (s != parseInt(s)) return "not a number ";
    var x_val = s.indexOf(".");
    if (x_val == -1) x_val = s.length;
    if (x_val > 15) return "too big";
    var n_val = s.split("");
    var str_val = "";
    var sk_val = 0;
    for (var i = 0; i < x_val; i++) {
      if ((x_val - i) % 3 == 2) {
        if (n_val[i] == "1") {
          str_val += tn_val[Number(n_val[i + 1])] + " ";
          i++;
          sk_val = 1;
        } else if (n_val[i] != 0) {
          str_val += tw_val[n_val[i] - 2] + " ";
          sk_val = 1;
        }
      } else if (n_val[i] != 0) {
        str_val += dg_val[n_val[i]] + " ";
        if ((x_val - i) % 3 == 0) str_val += "hundred and ";
        sk_val = 1;
      }
      if ((x_val - i) % 3 == 1) {
        if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + " ";
        sk_val = 0;
      }
    }
    if (x_val != s.length) {
      var y_val = s.length;
      str_val += "point ";
      for (var e = x_val + 1; e < y_val; e++) str_val += dg_val[n_val[e]] + " ";
    }
    return str_val.replace(/\s+/g, " ");
  }
}

export const removeLastAnd = (string) => {
  if (string && typeof string === "string") {
    let splitted = string.trimEnd().split(" ");
    console.log("removing last", splitted);
    if (splitted[splitted.length - 1] === "and") {
      console.log("last is and");
      return splitted.slice(0, splitted.length - 1).join(" ");
    } else {
      return splitted.join(" ");
    }
  } else {
    return string;
  }
};

export const capitalizeFirst = (string) => {
  if (string && typeof string === "string") {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else return string;
};

export function toNairaWords(s) {
  const main = s.toString().split(".");
  const pre = toWordsconver(main[0]);
  let post = null;
  if (main[1] > 0) {
    post = toWordsconver(main[1]);
  }
  if (post) {
    return pre + "naira and " + post + "kobo only";
  } else {
    return pre + "naira only";
  }
}

const toCamelCase = (str = "") => {
  return str && str[0].toUpperCase() + str.substr(1);
};

// Create Document Component
export default function PaymentPDF({ match }) {
  const history = useNavigate();

  console.log("matched", match);
  // const batch_no = 90
  const batch_no = useParams().id;
  const [paymentScheduleTable, setPaymentScheduleTable] = useState([]);
  const [tresuryDetails, setTresuryDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState([]);

  const [form, setForm] = useState({
    batch_number: "",
    date: "",
    no_of_mda: "",
    no_of_payments: "",
    total_amount: "",
    cheque_number: "",
    query_type: "insert",
    status: "uncommited",
  });

  console.log("batch_no", batch_no);

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: "select_by_batch_no",
      status: "Committed",
      batch_no,
    })
      .then((data) => {
        console.log("data", data);
        if (data.result) {
          setPaymentScheduleTable(data.result);
          setTresuryDetails(data.result[0]);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      });
  }, [batch_no]);

  useEffect(() => {
    postApi(`get_batch_list`, {
      query_type: "select_payment_list",
      status: "Committed",
      batch_no,
    })
      .then((data) => {
        console.log("gg", data);
        if (data.result) {
          setPaymentDetails(data.result);
          const formData = data.result[0];
          setForm((p) => ({ ...p, ...formData }));
          // setPaymentScheduleTable(data.result);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      });
  }, [batch_no]);

  console.log("paymentScheduleTable", paymentScheduleTable);

  const newDate = moment(form.date).format("DD/MM/YYYY");

  // const qrCodeCanvas = document.querySelector('canvas')
  // const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3)
  const [qrCodeDataUri, setQrCodeDataUrl] = useState("");

  useEffect(() => {
    QRCode.toDataURL("test").then((data) => {
      setQrCodeDataUrl(data);
    });
  }, []);

  return (
    <>
      <Row className="my-1">
        <Col md={9}></Col>
        <Col>
          <Button className="btn-danger" onClick={() => history(-2)}>
            <FontAwesomeIcon icon={faTimes} className="me-2" /> Close
          </Button>
        </Col>
      </Row>
      {/* {JSON.stringify(tresuryDetails)} */}
      <RenderPDF
        tresuryDetails={tresuryDetails}
        newDate={newDate}
        form={form}
        paymentScheduleTable={paymentScheduleTable}
        qrImage={qrCodeDataUri}
      />
    </>
  );
}

function RenderPDF({
  tresuryDetails = {},
  newDate,
  form = {},
  paymentScheduleTable = [],
  qrImage = "",
}) {
  return (
    <PDFViewer height="1000" width="1000">
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ textAlign: "right" }}></View>

          <View
          // style={styles.topRightHeader}
          >
            <View></View>

            <View
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                lineHeight: "1.5",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View>
                <Text>{""}</Text>
                <Text></Text>
              </View>

              <View>
                <Text
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    marginLeft: 30,
                  }}
                >
                  C.1/AG/VOL.30/13127
                </Text>
              </View>

              <View>
                <Text style={{ fontWeight: "bold" }}>
                  {tresuryDetails.arabic_date}
                </Text>
                <Text>{newDate}</Text>
              </View>
            </View>
          </View>

          <View style={styles.topLeftHeader}>
            <Text>The Manager</Text>
            <Text>{tresuryDetails.treasury_bank_name}</Text>
          </View>

          <View style={styles.middleHeader}>
            <Text>RELEASE OF FUNDS TO MINISTRIES, DEPARTMENTS AND </Text>
            <Text style={{ marginTop: 5 }}> PARASTATALS</Text>
          </View>

          <View style={styles.body1}>
            <Text>
              Please, find attached our cheque No:{" "}
              <span style={{ fontWeight: "bold" }}>
                {tresuryDetails.cheque_number}
              </span>{" "}
              dated {newDate} in the Sum of{" "}
              {toCamelCase(
                toNairaWords(form.total_amount ? form.total_amount : 0)
              )}{" "}
              (N
              {parseFloat(form.total_amount).toLocaleString("en")}) drawn in
              your favour for the credit of various Ministries, Departments,
              Agencies and Parastatals.
            </Text>
          </View>

          <View style={styles.body1}>
            <Text> Please, acknowledge reciepts</Text>

            <Text>Yours faithfully</Text>
          </View>

          <View style={[styles.body1, { marginTop: 50 }]}>
            <Text>
              {" "}
              Mallam Nura Tela{" "}
              <Text style={{ fontFamily: "Roboto", fontStyle: "italic" }}>
                FCA
              </Text>
            </Text>

            <Text>Accountant General</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 40,
            }}
          >
            <Image
              source={{ uri: qrImage }}
              style={{ height: 100, width: 100 }}
            />
          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.table1}>
                RELEASE OF FUND {""} {newDate}
              </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.tableSN}>S/N</Text>
              <Text style={styles.table}>MDA</Text>
              <Text style={styles.table}>BANK</Text>
              <Text style={styles.table}>ACCOUNT No</Text>
              <Text style={styles.table}>SORT CODE</Text>
              <Text style={styles.table}>
                AMOUNT
                <span style={{ textDecoration: "lineThrough" }}>(N)</span>
              </Text>
            </View>
            {paymentScheduleTable.map((item, index) => (
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={styles.tableSN}>{index + 1}</Text>

                <Text style={styles.tablel}>{item.mda_name}</Text>
                <Text style={styles.tablel}>{item.mda_bank_name}</Text>
                <Text style={styles.table}>
                  {padAccountNo(item.mda_account_no)}
                </Text>
                <Text style={styles.table}>{item.mda_acct_sort_code}</Text>
                <Text style={styles.table2}>
                  {parseFloat(item.amount).toLocaleString("en")}
                </Text>
              </View>
            ))}
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.tableSN}></Text>
            <Text style={styles.table}></Text>
            <Text style={styles.table}>Total Amount</Text>
            <Text style={styles.tableTN}> </Text>

            <Text style={styles.table3}>
              {parseFloat(form.total_amount).toLocaleString("en")}
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
