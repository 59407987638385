import React, { useState, useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition } from "react-transition-group";
// import { Transition } from "react-transition-group";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 
  faSignOutAlt,
  faTimes,
  faBars,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Image,
  Button,
  Dropdown,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import ReactHero from "../assets/img/state-govt-logo.png";
import ProfilePicture from "../assets/img/default_avatar.png";
// import { faGratipay } from "@fortawesome/free-brands-svg-icons";
import { useSelector } from "react-redux";
import { mainTreasuryModules } from "../pages/examples/user-admin/modules";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const showClass = show ? "show" : "";
  const user = useSelector((state) => state.auth.user);
  const history = useNavigate();
  // const [show, setShow] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState([]);

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    const [isOpen, setIsOpen] = useState(defaultKey === eventKey);

    const isDropdownOpen = (eventKey) => openDropdowns.includes(eventKey);

    const handleDropdownToggle = (eventKey) => {
      const index = openDropdowns.indexOf(eventKey);
      if (index === -1) {
        setOpenDropdowns([eventKey]);
      } else {
        setOpenDropdowns([]);
      }
    };

    return (
      <div
      // style={{
      //   border: "1px solid #fff",
      //   borderRadius: 4,
      //   boxShadow:
      //     "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 #808080",
      // }}
      >
        <Nav.Item className="" onClick={() => handleDropdownToggle(eventKey)}>
          <Nav.Link className="d-flex flex-direction-row justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span
                className="sidebar-text"
                style={
                  isOpen
                    ? {
                        fontWeight: "bold",
                      }
                    : {}
                }
              >
                {title}
              </span>
            </span>
            <FontAwesomeIcon
              icon={isOpen ? faCaretUp : faCaretDown}
              size="20"
              className="ms-1"
            />
          </Nav.Link>
        </Nav.Item>
        {isDropdownOpen(eventKey) && (
          <Nav
            className="flex-column ps-4"
            style={{ backgroundColor: "green", borderRadius: 5 }}
          >
            {children}
          </Nav>
        )}
      </div>
    );
  };

  ////latest
  const NavItem = (props) => {
    const [isLoading, setIsLoading] = useState(false); //loading

    const { title, link, badgeText, icon } = props;

    const handleNavItemClick = () => {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        history(link);
      }, 10000);
    };

    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";

    const isActive = location.pathname.includes(link);
     
    return (
      <div
        onClick={() => {
          setShow(!show);
          history(link)
        }}
        style={{ cursor: "pointer" }}
        className={`${isActive ? "bg-success" : ""} py-2 px-2 rounded`}
      >
        <span className="sidebar-icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        {title}
      </div>
    );
  };

  return (
    <>
      <Navbar
        color="success"
        expand={false}
        collapseOnSelect
        variant="primary"
        className="navbar-theme-success px-3 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={ReactHero} height={55} style={{height: 45}} />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
         
        >
        
          <span className="navbar-toggler-icon" >
              <FontAwesomeIcon icon={faBars} className="w-100 h-100" />
          </span>
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-2 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, {user.username}</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.Signin.path}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <div onClick={() => history("/")} className="text-center">
                <Image
                  style={{
                    cursor: "pointer",
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                  src={ReactHero}
                  height={120}
                  width={120}
                />
                {/* <span className="sidebar-text">KIFMIS</span> */}
              </div>

              {mainTreasuryModules.map((module, index) => {
                const modKey = module.title.split(" ").join("-");
                if (module.subMenu && module.subMenu.length) {
                  if (user.accessTo && user.accessTo.includes(module.title)) {
                    return (
                      <div key={index}>
                        <CollapsableNavItem
                          eventKey={modKey}
                          title={module.title}
                          icon={module.icon}
                        >
                          {module.subMenu.map((item, idx) => {
                            if (
                              user.functionalities &&
                              user.functionalities.includes(item.title)
                            ) {
                              return (
                                <NavItem
                                  title={item.title}
                                  link={item.route}
                                  key={modKey}
                                  icon={item.icon}
                                  onClick={onCollapse}
                                />
                              );
                            }
                          })}
                        </CollapsableNavItem>
                      </div>
                    );
                  } else return null;
                } else {
                  if (user.accessTo && user.accessTo.includes(module.title)) {
                    return (
                      <NavItem
                        title={module.title}
                        link={module.route}
                        key={module.key}
                        onClick={onCollapse}
                      />
                    );
                  } else return null;
                }
              })}

              <Dropdown.Divider className="my-3 border-indigo" />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
