import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { postApi } from "../../../redux/api";
import { Button, Spinner, Table } from "react-bootstrap";
import { Card } from "@themesberg/react-bootstrap";
import { toast } from "react-hot-toast";
import Scrollbar from "./ScrollBar";
import { Routes } from "../../../routes";
import { formatNumber } from "../../../redux/utils";

function NewViewPV() {
  const history = useNavigate();
  const pv_code = useParams().pv_code;
  const [pvInfo, setPvInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();

  let isCommissioner = location.pathname.includes("commissioner");
  let isPS = location.pathname.includes("ps");

  useEffect(() => {
    if (pv_code) {
      setLoading(true);
      postApi(`post_pv_collection`, {
        form: {
          query_type: "pv_by_approval",
          approval_no: pv_code,
          pv_status: "Pending",
          // isCommissioner
          //   ? "pending"
          //   : isPS
          //   ? "Commissioner Approved"
          //   : "PS Approved",
        },
        // status : "Paid",
        // batch_no
      })
        .then((data) => {
          console.log("data", data);
          if (data.result && data.result.length) {
            setPvInfo(data.result);
          }
        })

        .catch((err) => {
          console.log(err);
          // alert(err);
          // setLoadSpinner(false);
        })
        .finally(() => setLoading(false));
    }
  }, [pv_code]);

  const handleApprove = () => {
    setSubmitting(true);

    postApi(`post_pv_collection`, {
      form: {
        query_type: "update_cash_backing",
        pv_status: isCommissioner
          ? "Commissioner Approved"
          : isPS
          ? "PS Approved"
          : "Approved",
        pv_code,
        approval_no: pv_code, // pvInfo.approval_no,
      },
      // status : "Paid",
      // batch_no
    })
      .then((data) => {
        console.log("data", data);
        if (data.success) {
          toast.success("PV Approved Successfully!");
          if (isCommissioner || isPS) {
            history(-2);
          } else {
            history(`${Routes.cashBacking.cashBackingPDF}/${pv_code}`);
          }
        } else {
          toast.error("An error occurred, please try again later.");
        }
      })
      .catch((err) => {
        console.log(err);
        // alert(err);
        // setLoadSpinner(false);
      })
      .finally(() => setSubmitting(false));
  };
  const totalAmount = pvInfo.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.amount);
  }, 0);

  return (
    <Card className="my-2">
      <Card.Header>
        <h4>Release No.: {pv_code}</h4>
      </Card.Header>
      <Card.Body>
        {loading && <Spinner />}

        {/* {JSON.stringify({
          isCommissioner, isPS
        })} */}
        <Scrollbar height={"50vh"}>
          <Table bordered striped>
            <thead>
              <th>Action</th>
              <th>Pv code</th>
              <th style={{ width: "10%" }}>Mda Name</th>
              <th>Admin Code</th>
              <th>Economic code</th>
              <th>Economic Description</th>
              <th>Amount</th>
            </thead>

            <tbody>
              {pvInfo &&
                pvInfo?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Button className="bg-danger">Return</Button>
                    </td>
                    <td>{item.pv_code}</td>
                    {/* <td>{item.pv_date}</td> */}
                    {/* <td>{item.project_type}</td>
                    <td>{item.payment_type}</td> */}
                    <td style={{ width: "10%" }}>{item.mda_name}</td>
                    <td>{item.mda_code}</td>
                    {/* <td>{item.description}</td> */}
                    {/* <td>{item.status}</td> */}
                    {/* <td>{item.project_class}</td>
                    <td>{item.approval_no}</td>
                    <td>{item.approval_no}</td> */}
                    <td>{item.economic_code}</td>
                    <td>{item.economic_description}</td>
                    <td style={{ textAlign: "right" }}>
                      {formatNumber(item?.amount)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <span style={{ float: "right" }}>
            <b>Total: </b>
            {formatNumber(totalAmount)}
          </span>
        </Scrollbar>
        {/* </Row> */}
        <center className="">
          <Button
            style={{
              marginRight: 10,
            }}
            className="bg-danger"
          >
            Return
          </Button>
          <Button onClick={handleApprove}>
            {submitting ? (
              <>
                <Spinner /> Please wait..
              </>
            ) : (
              "Approve All"
            )}
          </Button>
        </center>
      </Card.Body>
    </Card>
  );
}

export default NewViewPV;
