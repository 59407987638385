import React, { useContext } from "react";
// import React from "react";
import { Button, ButtonGroup, Card } from "@themesberg/react-bootstrap";

import { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CustomTable from "../../../components/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../../routes";
import moment from "moment-timezone";
import { getApprovalCollectList } from "./api";
import { postApi } from "../../../redux/api";
import { MODULE_TYPES } from "../../../redux/utils";
import { useSelector } from "react-redux";
import SearchInput from "../../../components/SearchInput";
import { SearchContext } from "../../HomePage";


function PaymentScheduleRecurrent({ match }) {
  const [filterText, setFilterText] = useState("");
  const [data, setApprovalList] = useState([]);
  const history = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const type = user.role;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (type === "main_treasury") {
      postApi(`post_approval_collection`, {
        form: { query_type: "by_mda", filter: "Approval Processed", approval_type: "Recurrent" },
      })
        .then((data) => {
          if (data && data.result && data.result.length) {
            setApprovalList(data.result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // getApprovalCollectList({ query_type: 'select' }, (d) => {
      //   if (d && d.result) {
      //     setApprovalList(d.result)
      //   }
      // })
    } else if (type === "sub_treasury") {
      postApi(`post_contractor_schedule`, {
        query_type: "select_by_batch",
      })
        .then((data) => {
          console.log("data", data);
          if (data.result) {
            setApprovalList(data.result);
            // setContractorTable(data.result)
          }
        })

        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false); // Set loading state to false after fetching data
        });
    }
  }, [type]);

  let list = [];
  data.forEach((item) => {
    if (
      type === "main_treasury" &&
      item.approval_no.indexOf(filterText) === -1 &&
      type === "sub_treasury" &&
      item.approval_no.indexOf(filterText) === -1 &&
      item.mda_name.toLowerCase().indexOf(filterText.toLowerCase()) === -1
    )
      return;
    else list.push(item);
  });

  const fields = [
    {
      title: "Action",
      custom: true,
      component: (item, index) => (
        <Button
          className="btn bg-success"
          size="sm"
          color="success"
          onClick={() => {
            history(
              `${Routes.paymentSchedule.newSchedule}/${
                item.mda_code || "null"
              }/existing/${item.economic_code || "null"}/${
                item.approval_no || "null"
              }/${item.imageId || "null"}/${item.description_code || "null"}/${
                item.mda_name || "null"
              }/${item.amount || "null"}/${item.narration || "null"}`
              // `${Routes.paymentSchedule.newSchedule}?mda_code=${item.mda_code}&name=existing&economic_code=${item.economic_code}&approval_no=${item.approval_no}&imageId=${item.imageId}&description_code=${item.description_code}&mda_name=${item.mda_name}&amount=${item.amount}`
            );
          }}
        >
          Procceed
        </Button>
      ),
    },
    {
      title: "Date",
      //   value: 'collected_date',
      custom: true,
      component: (item, index) => (
        <div>{moment(item.collected_date).format("YYYY-DD-MM")}</div>
      ),
    },
    {
      title: type === "main_treasury" ? "Approval No" : "Batch Code",
      custom: true,
      component: (item) => (
        <div className="text-center">
          {type === "main_treasury" ? item["approval_no"] : item["batch_id"]}
        </div>
      ),
      // value: type === "main_treasury" ? 'approval_no' : 'batch_id',
    },
    {
      title: "MDA Name",
      value: "mda_name",
    },
    {
      title: "Economic Code",
      value: "economic_code",
    },

    {
      title: "Approval Status",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <div className="badge bg-success p-1">{item.approval_status}</div>
        </div>
      ),
      //   value: 'approval_status',
    },
    // {
    //   title: 'Amount',
    //   custom: true,
    //   component: (item) => (
    //     <div className="text-end">{formatNumber(item.amount)}</div>
    //   ),
    //   //   value: 'amount',
    // },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Button
          onClick={() => history(`${Routes.paymentSchedule.newSchedule}`)}
          className="me-2 bg-success"
        >
          {" "}
          <FontAwesomeIcon icon={faPlus} className="me-2 " />
          Create new
        </Button>
        <div className="d-block mb-4 mb-md-0">
          <h4>
            {/* <FontAwesomeIcon icon={faHourglass} className="me-2" />  */}
            Payment Schedule
          </h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <SearchInput placeholder="Search for Budget Utilization Report" value={searchTerm} onChange={({target:{value}}) => setSearchTerm(value)} />


      {/* {JSON.stringify({type, data})} */}

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <CustomTable fields={fields} data={list} loading={loading} />
        </Card.Body>
      </Card>
    </>
  );
}

export default PaymentScheduleRecurrent;
