import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  Card,
  Spinner,
} from "@themesberg/react-bootstrap";
import BackButton from "../../../components/BackButton";
import { postApi } from "../../../redux/api";
import { useNavigate, useParams } from "react-router-dom";
import {
  faCheck,
  faCheckDouble,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Routes } from "../../../routes";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { formatNumber } from "../../../redux/utils";
import { useSelector } from "react-redux";

function AuditorGeneralViewApproval() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const { id: approval_no } = useParams();
  const [form, setForm] = useState({
    collection_date: "",
    approval_date: "",
    approval_type: "",
    mda_name: "",
    amount: "",
    status: "",
    economic_code: "",
    approval_no: approval_no,
  });
  const [tableData, setTableData] = useState([]);
  const [showRemark, setShowRemark] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [approvalMessage, setApprovalMessage] = useState(null);
  const [remark, setRemark] = useState("");
  const [addRemark, setAddRemark] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false); // Loading state for submit button

  const toggleRemark = () => setShowRemark((p) => !p);

  const getApprovalCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: "select_by_approval_no",
        approval_no: approval_no,
      },
    })
      .then((data) => {
        console.log("data", data);
        if (data.result && data.result.length > 0) {
          const result = data.result[0];
          setForm(result);
          setTableData([result]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [approval_no]);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  const updateApproval = (approval_no, status) => {
    setApprovalLoading(true);
    postApi("update-approval", {
      remarks: remark,
      status:
        form.approval_status === "Approval Released"
          ? "Approval Received"
          : form.approval_status === "Approval Received"
          ? "Approval Reviewed"
          : "Approval Released",
      approval_no,
      userId: user.username,
      mda_name: user ? user.mda_name : "",
    })
      .then((resp) => {
        setApprovalLoading(false);
        if (resp && resp.success) {
          setApprovalMessage(
            status === "Return Processed"
              ? "Returned Pending Successfully"
              : "Approved Successfully"
          );
          setTimeout(() => {
            toggleRemark();
            if (form.approval_status === "Approval Released") {
              navigate(Routes.approval.pendingApproval);
            } else if (form.approval_status === "Approval Received") {
              navigate(Routes.approval.reviewReleases);
            }
          }, 1000);
        }
      })
      .catch((err) => {
        setApprovalLoading(false);
        toast.error("An error occurred, please try again later");
        console.log(err);
      });
  };

  const handleAPI = (e) => {
    e.preventDefault();
    setSubmitLoading(true); 
    const data = {
      approval_no: form.approval_no,
      user_id: user.id,
      remark: form.comments,
    };
    postApi("auditor_general_remark", data)
      .then((resp) => {
        setSubmitLoading(false);
        if (resp.success) {
        console.log("data", resp.success);
          toast.success("Remark submitted successfully!");
          setAddRemark(false);
        //   getApprovalCollection(); 
        } else {
          toast.error("Failed to submit remark");
        }
      })
      .catch((err) => {
        setSubmitLoading(false);
        toast.error("An error occurred, please try again later");
        console.log(err);
      });
  };

  return (
    <>
      <BackButton />
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            <div>
              <h5 className="mb-4">
                <FontAwesomeIcon icon={faCheckDouble} className="me-2" />
                Releases No.: {form.approval_no}
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <button
                style={{
                  backgroundColor: "yellow",
                  border: "none",
                  height: "60%",
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `${Routes.cashBacking.releaseCollectionPDF}/${form.approval_no}`
                  )
                }
              >
                <FontAwesomeIcon icon={faEye} className="me-1" />
                View Release Doc
              </button>
              <button
                className="bg-primary"
                style={{
                  border: "none",
                  color: "#fff",
                  height: "60%",
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                onClick={() => setAddRemark(true)}
              >
                <FontAwesomeIcon icon={faCheck} className="me-1" />
                Add Remarks
              </button>
            </div>
          </div>
          <Form>
            {addRemark ? (
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      size="sm"
                      as="textarea"
                      value={form.comments}
                      onChange={(e) =>
                        setForm({ ...form, comments: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <button
                    style={{
                      backgroundColor: "green",
                      border: "none",
                      color: "#fff",
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 10,
                      cursor: "pointer",
                      float: "right",
                    }}
                    onClick={handleAPI}
                    disabled={submitLoading} // Disable button while loading
                  >
                    {submitLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Collection Date</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={form.collection_date}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Approval Date</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={form.approval_date}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Approval Type</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={form.approval_type}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <h5
                  style={{
                    width: "100%",
                    textAlign: "right",
                    marginTop: 40,
                  }}
                >
                  Total: {formatNumber(form.amount)}
                </h5>
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  width: "100%",
                  overflowX: "scroll",
                }}
              >
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    marginTop: 20,
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>MDA Name</th>
                      <th style={{ width: "20%" }}>MDA Code</th>
                      {form.program_code ? <th>Program Code</th> : null}
                      <th
                        style={
                          form.program_code
                            ? { display: "none" }
                            : { width: "20%" }
                        }
                      >
                        Economic Code
                      </th>
                      <th
                        style={
                          form.program_code
                            ? { display: "none" }
                            : { width: "20%" }
                        }
                      >
                        Description
                      </th>
                      <th style={{ width: "20%" }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, idx) => (
                      <tr key={idx}>
                        <td style={{ width: "20%" }}>{item.mda_name}</td>
                        <td style={{ width: "20%" }}>{item.mda_code}</td>
                        {form.program_code ? (
                          <td>{item.program_code}</td>
                        ) : null}
                        <td
                          style={
                            form.program_code
                              ? { display: "none" }
                              : { width: "20%" }
                          }
                        >
                          {item.economic_code}
                        </td>
                        <td
                          style={
                            form.program_code
                              ? { display: "none" }
                              : { width: "20%" }
                          }
                        >
                          {item.description_code}
                        </td>
                        <td
                          style={{
                            width: "20%",
                            textAlign: "right",
                          }}
                        >
                          {formatNumber(item.amount)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <h5
                  style={{
                    width: "100%",
                    textAlign: "right",
                    marginTop: 10,
                  }}
                >
                  Total: {formatNumber(form.amount)}
                </h5>
              </div>
            </Row>
          </Form>
        </Card.Body>

        <Modal show={showRemark} onHide={toggleRemark}>
          {approvalLoading ? null : (
            <Modal.Header onHide={toggleRemark}>Confirmation</Modal.Header>
          )}
          {approvalLoading ? (
            <span>
              Please wait
              <Spinner color="primary" size="xl" />
            </span>
          ) : approvalMessage && approvalMessage !== "" ? (
            <h5>{approvalMessage}</h5>
          ) : (
            <>
              <Modal.Body className="text-center">
                <h5>
                  Are you sure you want to approve this Release with
                  No.: {form.approval_no}{" "}
                </h5>

                <div>
                  <h6>Add Remarks</h6>
                  <textarea
                    value={remark}
                    onChange={({ target: { value } }) => setRemark(value)}
                    className="form-control"
                    placeholder="Add your remarks here"
                  />
                </div>
              </Modal.Body>
            </>
          )}
          {approvalLoading ? null : (
            <Modal.Footer>
              <div>
                <Button
                  color="danger"
                  outline
                  className="m-1 p-1 px-3"
                  onClick={toggleRemark}
                >
                  Cancel
                </Button>
                <Button
                  className="m-1 p-1 px-3"
                  onClick={() =>
                    updateApproval(form.approval_no, "Approval Received")
                  }
                  loading={loading}
                >
                  Approve
                </Button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
      </Card>
    </>
  );
}

export default AuditorGeneralViewApproval;
