import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faFileAlt,
  faHome,
  faPlus,
  faSearch,
  faTasks,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Card,
  Table,
  FormLabel,
} from "@themesberg/react-bootstrap";

import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../../redux/api";
import { Routes } from "../../../routes";
import { useSelector } from "react-redux";

function UsersList() {
  const history = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [userList, setUserList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const getUsers = () => {
    fetchApi(`users`)
      .then((d) => {
        if (d && d.users) {
          setUserList(d.users);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = userList.filter((user) =>
    user.fullname.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Button
          className="me-2"
          onClick={() => history(`${Routes.userAdmin.editUser}`)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" /> Create new user
        </Button>
        <div className="d-block mb-4 mb-md-0">
          <h4>Admin Dashboard</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>
      
      {/* Search Input */}
      <FormLabel>Search Users</FormLabel>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Search by fullname or username"
          aria-label="Search"
          aria-describedby="basic-addon2"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Button variant="outline-secondary" id="button-addon2">
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </InputGroup>

      <Card>
        <Table>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Username</th>
              <th>Department</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{user.fullname}</td>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>Active</td>
                <td>
                  <Button size="sm" className="btn-danger">
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
}

export default UsersList;
