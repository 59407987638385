import { Form, Modal, Image } from '@themesberg/react-bootstrap'
import React, { useState } from 'react'
import { formatNumber } from '../../../redux/utils'
import { toast } from 'react-hot-toast';
import CustomButton from '../../../components/CustomButton'
import {
Col,
Row,
} from "@themesberg/react-bootstrap";

const PaymentModal = (props) => {
  const {
    buttonLabel,
    className,
    callback,
    setter,
    modal,
    toggle,
    batchCode,
    paymentDetails,
    submitChequeNo,
    handleChange,
    form,
  } = props

  const [backdrop] = useState(true)
  const [keyboard] = useState(true)
  const [batch_no, setBatch_no] = useState({ batch_no: '' })
  const paymentDetails1 = paymentDetails[0]

  return (
    <div>
      <Modal
        show={modal}
        onHide={toggle}
        className={className}
        backdrop={backdrop}
        keyboard={keyboard}
      >
        <Modal.Body
          //   color="primary"
          style={{ color: 'white', backgroundColor: 'rgb(25, 135, 84)' }}
          className="text-left"
        >
          <h5 className="text-white">Date: {form.date}</h5>
          <h5 className="text-white">
            Total Amount: {formatNumber(form.total_amount)}
          </h5>
          <h5 className="text-white">Batch No: {form.batch_number}</h5>

          <h5 className="text-white my-2 text-center">Are you sure you want to proceed with the payment?</h5>

          {/* <h5 className="text-white">Enter Cheque No:</h5>
          <Form.Control
            type="text"
            name="cheque_number"
            value={form.cheque_number}
            onChange={handleChange}
          />

          <h5 className="text-white mt-2">Enter Arabic Date:</h5>
          <Form.Control
            type="text"
            name="arabic_date"
            value={form.arabic_date}
            onChange={handleChange}
          /> */}

          {
            // <h5>Date: {paymentDetails1.date}</h5>
            // <h5>Total Amount: {paymentDetails1.total_amount}</h5>
            // <h5>Cheque:</h5>
            //   <h5>Your Batch Code is: </h5>
          }
        </Modal.Body>
        <Modal.Footer
  style={{ color: 'white', backgroundColor: 'rgb(25, 135, 84)' }}
>
<Row>
  <CustomButton
    onClick={submitChequeNo}
    style={{ color: 'black', backgroundColor: "white" }}
  >
    Process Payment Manually
  </CustomButton>
  <CustomButton
    onClick={()=>toast('Please complete Interswitch setup')}
    style={{ backgroundColor: 'white', color: 'black ',  top: "-5px" }}
  >
    Process With  
    <Image
      src={require('../../../assets/img/interswitch-logo.png')}
      width={100}
      height={30}
      style={{ marginLeft: '10px' }}
    />
  </CustomButton>
</Row>
</Modal.Footer>
      </Modal>
    </div>
  )
}

export default PaymentModal
