import { Button, Modal } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import CustomTable from "../../../components/CustomTable";
import { formatNumber } from "../../../redux/utils";
import { accountTypes, bankList } from "../../accounts/AccountForm";
import { createToken, createTokenPin, postApi } from "../../../redux/api";
import toast from "react-hot-toast";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";

function PaymentPreviewModal({
  toggle = (f) => f,
  isOpen = false,
  paymentList = [],
  goBackFun = (f) => f,
}) {
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);

  const processPayment = () => {
    if (pin.length < 4) {
      alert("PIN is 4 digit");
    } else {
      console.log(paymentList);
      // alert('workin');
      setLoading(true);
      const finalList = [];
      paymentList.forEach((payment) => {
        let new_amount =
          (payment.approvalInfo ? payment.approvalInfo.cb_amount : 0) * 100;
        let input_amount = new_amount?.toString().split(".")[0];
        finalList.push({
          payment_ref: payment.pvInfo.pv_code,
          amount: input_amount,
          narration: payment.pvInfo.description?.replace("'", ""),
          beneficiary_code: payment.pvInfo.mda_code,
          ben_email: "",
          ben_bank_cbn_code:
            bankList.find((i) => i.title === payment.pvInfo.contractor_bank)
              ?.code || "044",
          ben_account_no: payment.pvInfo.contractor_acc_no,
          account_type:
            accountTypes.find((i) => i.title === payment.pvInfo.account_type)
              ?.code || "10", // payment.pvInfo.account_type ? payment.pvInfo.account_type : '10',
          is_prepaid_load: "FALSE",
          currency: "NGN",
          beneficiary_name: payment.pvInfo.contractor_acc_name?.replace("'", ""),
          beneficiary_mobile_no: "",
          type: 1,
        });
      });

      postApi("autogate/transfer", {
        details: finalList,
        description: paymentList[0].pvInfo.batch_description,
        id: paymentList[0].pvInfo.payment_account,
        vPinToken: createTokenPin({ vPinToken: pin }),
        callback_type: "st_payment",
        check: "sub",
        ids: "",
      })
        .then((res) => {
          if (res.success) {
            toggle();
            goBackFun();

            toast.success(res.message);
          } else {
            toast.error(res.message);
            // goBackFun();
          }

          setLoading(false);
        })
        .catch((err) => {
          alert(err.message);
          toast.error(err.message);
          setLoading(false);
        });
    }
  };

  return (
    <Modal show={isOpen} onHide={toggle} size="xl">
      <Modal.Header>
        <h4>Confirm Payment Selection</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="col-md-4 pb-1 mb-1">
          <input
            onChange={({ target: { value } }) => {
              setPin(value);
            }}
            value={pin}
            name="pin-st"
            type="password"
            required
            maxLength="4"
            className="form-control"
            placeholder="Enter PIN"
          />
        </div>
        {/* <div>{JSON.stringify(paymentList[1]?.pvInfo?.mda_code)}</div> */}
        <CustomTable
          responsive
          fields={[
            { title: "S/N", custom: true, component: (s, d) => d + 1 },
            {
              title: "Account Name",
              custom: true,
              component: (item) => item.pvInfo.contractor_acc_name,
            },
            {
              title: "Account Number",
              custom: true,
              component: (item) => item.pvInfo.contractor_acc_no,
            },
            {
              title: "Account Type",
              custom: true,
              component: (item) =>
                item.pvInfo
                  .account_type /**=== "20" ? "Current Account" : item.pvInfo.account_type === "10" ? "Savings Account" : "Defualt" */,
            },
            {
              title: "Bank Name",
              custom: true,
              component: (item) => item.pvInfo.contractor_bank,
            },
            {
              title: "Narration",
              custom: true,
              component: (item) => item.pvInfo.description,
            },
            {
              title: "Amount",
              custom: true,
              component: (item) => (
                <div className="text-end">
                  {item.approvalInfo
                    ? formatNumber(item.approvalInfo.cb_amount)
                    : "0"}
                </div>
              ),
            },
          ]}
          data={paymentList}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button color='danger' onClick={goBackFun}>Cancel</Button>
        <CustomButton loading={loading} onClick={processPayment}>
          Confirm
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
}

export default PaymentPreviewModal;
