import React, { useCallback } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Form,
  // InputGroup,
  Col,
  Row,
  Card,
} from "@themesberg/react-bootstrap";
// import { Input }
import InputGroup from "react-bootstrap/InputGroup";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Cards from "react-credit-cards";
import {
  createTokenPin,
  fetchApi,
  postApi,
  verifyToken,
} from "../../../redux/api";
import CustomTable from "../../../components/CustomTable";
import { Routes } from "../../../routes";
import { useContext } from "react";
import { SearchContext } from "../../HomePage";
import { useSelector } from "react-redux";
import { MODULE_TYPES } from "../../../redux/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchInput from "../../../components/SearchInput";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { bankList } from "../../accounts/AccountForm";
import toast from "react-hot-toast";
import CustomButton from "../../../components/CustomButton";

function STReceivingOffice({
  status = "pending",
  pageType = "batch",
  type = "main_treasury",
}) {
  const [pin, setPin] = useState("");
  const [item, setItem] = useState({});
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const toggle1 = () => setModalIsOpen1((p) => !p);
  const [selected, setSelected] = useState({});
  const [focus, setFocus] = useState("name");
  const user = useSelector((state) => state.auth.user);
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const location = useLocation();
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const history = useNavigate();
  const isST = location.pathname.includes("batch-process-st");
  // const [name, setName] = useContext(UserContext)
  // const details = useHistory()
  const [paymentSchedule, setPaymentScheduleTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const isReceivingOffice = location.pathname.includes("receiving-office");
  const isCheckingOffice = location.pathname.includes("checking-office");
  const isDSTOffice = location.pathname.includes("dst-office");
  const isHeadChequesOffice = location.pathname.includes("head-cheques-office");
  const isDSTPendingSchedule = location.pathname.includes(
    "dst-pending-schedule"
  );
  const isSTReview = location.pathname.includes("sub-treasurer-payment-review");
  const isApprovedPayment = location.pathname.includes("approved-payments");
  const isUpdatePage = pageType === "update";
  const isCommitPage = pageType === "commit";
  const isCommittedPage = pageType === "committed";
  const isRemittancePage = pageType === "remittance";

  // const [searchTerm, setSearchTerm] = useState('')
  const fields = [
    {
      title: "S/N",
      custom: true,
      component: (item, idx) => <span>{idx + 1}</span>,
      style: (item) =>
        item.status === "returned" ? { backgroundColor: "orange" } : {},
    },
    {
      title: "Batch No",
      // value: 'batch_number',
      custom: true,
      component: (item) => <div>{item.batch_number}</div>,
      className: "text-center",
      style: (item) =>
        item.status === "returned" ? { backgroundColor: "orange" } : {},
    },
    {
      title: "No. Vouchers",
      custom: true,
      component: (item) => <span>{item.no_of_payments}</span>,
      style: (item) =>
        item.status === "returned"
          ? { backgroundColor: "orange", textAlign: "center" }
          : { textAlign: "center" },
    },
    {
      title: "Total(N)",
      tableHeadStyle: { textAlign: "right", color: "black" },
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>
          {parseFloat(item.total_amount).toLocaleString("en")}
        </div>
      ),
      style: (item) =>
        item.status === "returned" ? { backgroundColor: "orange" } : {},
    },
    {
      title: "Action",
      className: "text-center",
      custom: true,
      component: (item) => (
        <div>
          {/* <Button className="bg-warning w-110 text-white me-1" size="sm">
              View Approval
            </Button> */}
          <Button
            item={item}
            onClick={() => {
              if (isReceivingOffice) {
                history(
                  `${Routes.subTreasury.receivingOfficeViewSchedule}/${item.batch_number}`
                );
              } else if (isCheckingOffice) {
                history(
                  `${Routes.subTreasury.checkingOfficeViewSchedule}/${item.batch_number}`
                );
              } else if (isHeadChequesOffice) {
                history(
                  `${Routes.subTreasury.headChequesOfficeViewSchedule}/${item.batch_number}`
                );
              } else if (isDSTPendingSchedule) {
                history(
                  `${Routes.subTreasury.dstPendingScheduleOfficeViewSchedule}/${item.batch_number}`
                );
              } else if (isSTReview) {
                history(
                  `${Routes.subTreasury.subTreasurerPaymentReviewOfficeViewSchedule}/${item.batch_number}`
                );
              } else if (isApprovedPayment) {
                history(
                  `${Routes.subTreasury.approvedPaymentsViewSchedule}/${item.batch_number}`
                );
              } else if (isDSTOffice) {
                history(
                  `${Routes.subTreasury.dstOfficeViewSchedule}/${item.batch_number}`
                );
              }
            }}
            className="bg-success w-110 text-white"
            size="sm"
          >
            Process
          </Button>
        </div>
      ),
      style: (item) =>
        item.status === "returned" ? { backgroundColor: "orange" } : {},
    },
  ];
  const [modal, setModal] = useState(false);
  const toggle = () => setModalIsOpen((p) => !p);
  const [accountInfo, setAccountInfo] = useState([]);
  const getCard = useCallback(() => {
    setLoading(true);
    fetchApi(`get-details/by-type?type=SUB-TREASURY ACCOUNT`)
      .then(async (resp) => {
        setLoading(false);
        console.log(resp);
        if (resp && resp.success) {
          let arr = [];
          resp.data.forEach((item) => {
            let selected = bankList.find((i) => i.code === item.bank_code);
            arr.push({ ...item, bankName: selected.title });
          });
          setAccountInfo(arr);
        }
      })
      .catch((err) => {
        setLoading(false);
        // setDetails(err.details);
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getCard();
  }, [getCard]);

  const getBalance = useCallback(
    async (id) => {
      setBalanceLoading(true);
      fetchApi(`autopay/balance/${id}/${createTokenPin({ vPinToken: pin })}`)
        .then(async (resp) => {
          setBalanceLoading(false);
          if (resp && resp.success) {
            let decoded = await verifyToken(resp.pan_info);
            setSelected({ ...resp, ...decoded });
            setPin("");
            setModalIsOpen1(false);
            setModalIsOpen(true);
          } else {
            toast.error(resp.message);
          }
        })
        .catch((err) => {
          setBalanceLoading(false);
          toast.error("Error While getting the balance please try again");
          // toast.error(err.message);
          console.log(err);
        });
    },
    [pin]
  );
  useEffect(() => {
    setLoading(true);
    postApi(`get_batch_list`, {
      query_type: "select",
      status: isReceivingOffice
        ? "Director Expenditure Control Approved"
        : isCheckingOffice
        ? "Schedule Approved By Receiving Office"
        : isDSTOffice
        ? "Schedule Approved By Checking Office"
        : isHeadChequesOffice
        ? "Schedule Approved By DST"
        : isDSTPendingSchedule
        ? "Schedule Approved By Cheques Office"
        : isSTReview
        ? "Payment Approved By DST"
        : isApprovedPayment
        ? "Payment Approved By Sub Treasurer"
        : "",
    })
      .then((data) => {
        console.log(data);
        if (data.result) {
          setPaymentScheduleTable(data.result);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [
    isReceivingOffice,
    isCheckingOffice,
    isDSTOffice,
    isHeadChequesOffice,
    isDSTPendingSchedule,
    isSTReview,
    isApprovedPayment,
  ]);

  let rows = [];

  paymentSchedule &&
    paymentSchedule.forEach((item) => {
      if (
        item?.batch_number &&
        item?.batch_number?.toLowerCase().indexOf(searchTerm.toLowerCase()) ===
          -1 /// &&
        // item?.no_of_payments?.indexOf(searchTerm.toLowerCase()) === -1 &&
        // item?.customer_code?.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1
      ) {
        return;
      }

      rows.push(item);
    });
  const getPin = async () => {
    let d = await verifyToken(
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjYXJkIjoiNjI4MDUxMDEwMzMwMjc5MjA4NyIsInBpbiI6IjE5NzIiLCJleHBpcnlfZGF0ZSI6IjI5MDciLCJjdnYyIjoiMDAwIiwiaWF0IjoxNzIyNDQyMzk1fQ.p53D5wU6O7SDlo2nV0iHxnqkKbRCxuLWFf6xbDXfqho"
    );
    console.log(d);
  };
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div className="">
            {isReceivingOffice && <h4>Receiving Office</h4>}
            {isCheckingOffice && <h4>Checking Office</h4>}

            <h5>
              {isReceivingOffice || isCheckingOffice ? "Pending Payment" : null}
            </h5>
            {/* {JSON.stringify(accountInfo)} */}
            {isSTReview ? (
              <div className="btn-toolbar mb-2 mb-md-0 text-right">
                <ButtonGroup>
                  {accountInfo.map((item) => (
                    <>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => {
                          setItem(item);
                          toggle1();
                          // getPin()
                        }}
                      >
                        {item.account_name} ({item.bankName})
                      </Button>
                    </>
                  ))}
                </ButtonGroup>
              </div>
            ) : null}
            <div></div>

            <Modal show={modalIsOpen}>
              <Modal.Header>Cash Backing</Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <div
                      onClick={() =>
                        setFocus((p) => (p === "cvc" ? "name" : "cvc"))
                      }
                    >
                      <Cards
                        cvc={selected.cvv2 || "000"}
                        expiry={selected.expiry_date}
                        focused={focus}
                        name={selected.account_name?.slice(0, 30)}
                        number={selected.card}
                        // preview={true}
                      />
                    </div>
                  </Col>
                </Row>
                {/* {JSON.stringify(selected)} */}
                <Row>
                  <Col className="mt-2">
                    <h6 className="text-center">
                      Account: {selected.account_name}-{selected.bankName}(
                      {selected.account_number})
                    </h6>
                    <h6 className="text-center">
                      <b> {item.bankName}</b>
                    </h6>
                  </Col>
                </Row>
                <h5 className="text-center">{selected.balance}</h5>
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" onClick={toggle}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <SearchInput
            placeholder="Search by batch..."
            value={searchTerm}
            onChange={({ target: { value } }) => setSearchTerm(value)}
          />
        </div>
      </div>

      {/* {JSON.stringify(paymentSchedule)} */}
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <CustomTable
              size="sm"
              fields={fields}
              data={rows}
              // currentPage={currentPage}
              // handlePageChange={handlePageChange}
            />
          )}
        </Card.Body>
        {/* <h2>hbcvvdk</h2> */}
      </Card>
      <Modal show={modalIsOpen1}>
        <Modal.Header>Confirmation</Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="mt-2">
              <h5 className="text-center">
                Account: {item.account_name}({item.account_number})
              </h5>
            </Col>
            <Row className="text-center">
              <div className=" pb-1 mb-1">
                <input
                  onChange={({ target: { value } }) => {
                    setPin(value);
                  }}
                  value={pin}
                  name="pin-st"
                  type="password"
                  required
                  maxLength="4"
                  className="form-control"
                  placeholder="Enter PIN"
                />
              </div>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" className="bg-danger" onClick={toggle1}>
            Close
          </Button>
          <CustomButton
            loading={balanceLoading}
            size="sm"
            onClick={() => {
              getBalance(item.id);
            }}
          >
            Confirm
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default STReceivingOffice;
