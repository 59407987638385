import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from "@themesberg/react-bootstrap";
import WhatsAppFloatingIcon from "../pages/components/WhatsAppFloatingIcon";

export default () => {
  const currentYear = moment().get("year");

  return (
    <div>
      <footer
        className="footer section py-5"
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        <Row className="m-0 p-0">
          <Col md={12} className="mb-4 mb-lg-0">
            <p className="mb-0 text-center">
              Copyright © {`${currentYear} `}
              <Card.Link
                href="#"
                target="_blank"
                className="text-blue text-decoration-none fw-normal"
              >
                KIFMIS
              </Card.Link>
            </p>
          </Col>
          <p
            className="text-gray text-decoration-none fw-normal"
            style={
              {
                // color: "#087A15",
              }
            }
          >
            Support Contact: <b>09113472208</b>
          </p>
        </Row>
        <div>
          <WhatsAppFloatingIcon Number="+2349113472208" />
        </div>
      </footer>
    </div>
  );
};
