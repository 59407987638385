/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/CustomTable";
import { apiURL, fetchApi } from "../../../redux/api";

function SupportingDocumentsPv({ pvInfo }) {
  const [attachmentList, setPvAttachmentList] = useState([]);

  useEffect(() => {
    if (pvInfo && pvInfo.length) {
      fetchApi(`fetch-pv-images?pv_no=${pvInfo[0].imageId}`).then((resp) => {
        // attachmentModalToggle();
        if (resp.results && resp.results.length) {
          setPvAttachmentList(resp.results);
        } else {
          // setPvAttachmentList({});
        }
      });
    }
  }, [pvInfo]);

  return (
    <div className="my-4">
      {/* {JSON.stringify({attachmentList})} */}
      <h5>Supporting Documents</h5>
      <CustomTable
        fields={[
          {
            title: "File Name",
            custom: true,
            component: (item) => (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`${apiURL}/uploads/${item.image_url}`, "_blank");
                }}
              >
                {item.image_url}
              </a>
            ),
          },
        ]}
        data={attachmentList}
      />
    </div>
  );
}

export default SupportingDocumentsPv;
