import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCog,
  faEnvelopeOpen,
  faSearch,
  faSignOutAlt,
  faUser,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  InputGroup,
} from "@themesberg/react-bootstrap";

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/default_avatar.png";
import { SearchContext } from "../pages/HomePage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Routes } from "../routes";
import { logout } from "../redux/actions/auth";

export default (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const areNotificationsRead = notifications.reduce(
    (acc, notif) => acc && notif.read,
    true
  );

  const handleLogout = () =>
    dispatch(logout(() => history(Routes.Signin.path)));

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map((n) => ({ ...n, read: true })));
    }, 300);
  };

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image
              src={image}
              className="user-avatar lg-avatar rounded-circle"
            />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <>
      <Navbar
        // variant="dark"
        expanded
        className="ps-0 pe-2 pb-0 bg-primary"
        style={{
          borderRadius: '15px',
          width: '99%',
          margin: '5px',
          marginInline: 'auto',
          padding: '10px'
        }}
      >
        {/* <h1>{user.role === 'main_treasury' ? "MAIN TREASURY" : "SUB TREASURY"}</h1> */}
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between align-items-center w-100">
            {/* <div className="d-flex align-items-center">
              <Form className="navbar-search">
                <Form.Group id="topbarSearch">
                  <InputGroup className="input-group-merge search-bar">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                      value={searchTerm}
                      onChange={({ target: { value } }) => setSearchTerm(value)}
                      type="text"
                      placeholder="Search"
                    />
                  </InputGroup>
                </Form.Group>
              </Form>
            </div> */}

            <center>
              <h4 style={{ color: "white", marginLeft: 5, fontWeight: "bold" }} className="display-sm-5">
                KATSINA STATE INTEGRATED FINANCIAL MANAGEMENT INFORMATION SYSTEM 
                <br />
                <span style={{ marginLeft: "18px", fontSize: "xs" }}>
                  (KIFMIS)
                </span>
              </h4>
              {user && user.mda_name && user.mda_name !== "" ? (
                <h4
                  style={{ color: "white", marginLeft: 5, fontWeight: "bold" }}
                >
                  {user && user.mda_name}
                </h4>
              ) : null}
            </center>

            <Nav className="align-items-center">
              <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  className="text-dark icon-notifications me-lg-3"
                >
                  <span className="icon icon-sm">
                    <FontAwesomeIcon
                      icon={faBell}
                      className="bell-shake text-white"
                    />
                    {areNotificationsRead ? null : (
                      <span className="icon-badge rounded-circle unread-notifications" />
                    )}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                  <ListGroup className="list-group-flush">
                    <Nav.Link
                      href="#"
                      className="text-center text-white fw-bold border-bottom border-light py-3"
                    >
                      Notifications
                    </Nav.Link>

                    {notifications.map((n) => (
                      <Notification key={`notification-${n.id}`} {...n} />
                    ))}

                    <Dropdown.Item className="text-center text-primary fw-bold py-3">
                      View all
                    </Dropdown.Item>
                  </ListGroup>
                </Dropdown.Menu>
              </Dropdown>
              {/* {JSON.stringify(user)} */}
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <Image
                      src={Profile3}
                      className="user-avatar md-avatar rounded-circle"
                    />
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold text-white">
                        {/* {user.fullname} */}
                      </span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My
                  Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" />{' '}
                  Messages
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" />{' '}
                  Support
                </Dropdown.Item> */}

                  {/* <Dropdown.Divider /> */}
                  <Dropdown.Item
                    className="fw-bold"
                    onClick={() => history(Routes.pv.UserProfile)}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-danger me-2"
                    />{" "}
                    Profile
                  </Dropdown.Item>

                  <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-danger me-2"
                    />{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
