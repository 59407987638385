/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import {
  //   Alert,
  //   Button,
  Form,
  //   ButtonGroup,
  //   InputGroup,
  Card,
  //   Container,
  //   Dropdown,
  Modal,
  Image,
  //   Spinner,
} from "@themesberg/react-bootstrap";
import { Button, Table } from "react-bootstrap";
import { useState } from "react";
import { useCallback } from "react";
import { useNavigate, useLocation, useParams, Route } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import {
  apiURL,
  createToken,
  createTokenPin,
  deleteApi,
  fetchApi,
  postApi,
} from "../../../redux/api";
import CustomTable from "../../../components/CustomTable";
import SearchInput from "../../../components/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fetchUserData } from '';
import {
  faBackward,
  faCheck,
  faPrint,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../../routes";
import { formatNumber } from "../../../redux/utils";
import { SearchContext } from "../../HomePage";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import moment from "moment-timezone";
import CustomButton from "../../../components/CustomButton";
import { accountTypes, bankList } from "../../accounts/AccountForm";

const tableHeaderStyle = {
  backgroundColor: "#099019",
  color: "#ffffff",
  textAlign: "center",
};

function ViewPaymentRequestDetailForFinalApproval({ match, type = null }) {
  const user = useSelector((state) => state.auth.user);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState();
  const [paymentAccountList, setPaymentAccountList] = useState([]);
  const history = useNavigate();
  const location = useLocation();
  const requestId = useParams().requestId;
  const isPending = location.pathname.includes("payapp");
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
  const [filterText, setFilterText] = useState("");
  const batch_no = useParams().batch_no;
  const [budgetUtilization, setBudgetUtilization] = useState([]);
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState(type || "All");
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const [selectedApproval, setApprovalNo] = useState("");
  const [returndApproval, retApprovalNo] = useState("");

  const [approvalImageInfo, setApprovalImageInfo] = useState([]);
  const [item, setItem] = useState({});
  const [_delete, setDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));

  const toggleDelete = (item) => {
    setItem(item);
    setDelete(!_delete);
  };
  const [showRemark1, setShowRemark1] = useState(false);
  const [showRemark, setShowRemark] = useState(false);
  const [remark, setRemark] = useState("");
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [returnlLoading, setReturnLoading] = useState(false);
  const [returnlMessage, setReturnMessage] = useState(null);
  const [approvalMessage, setApprovalMessage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [selectedReleases, setSelectedReleases] = useState([]);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [preparingSchedule, setPreparingSchedule] = useState(false);
  const [form, setForm] = useState({
    mainAccount: {},
    subAccount: {},
    mdaPayments: [],
    stPayments: [],
    bulk_description:"Disbursement"
  });

  const toggleRemark = () => setShowRemark((p) => !p);
  const toggleRemark1 = () => setShowRemark1((p) => !p);

  const selfAccounting = budgetUtilization?.filter(
    (a) => a.payment_acc_no && a.payment_acc_no !== ""
  );
  const others = budgetUtilization?.filter(
    (a) => !a.payment_acc_no || a.payment_acc_no === ""
  );

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p);
  };

  useEffect(() => {
    fetchApi("pan_details/get?type=st")
      .then((resp) => {
        if (resp) {
          setPaymentAccountList(resp.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getApprovalCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: "pending_payment",
        approval_status: "ViewDetail",
        approval_no: requestId,
        // mda_name: user ? user.mda_name : "",
      },
    })
      .then((data) => {
        if (data.result) {
          const arr = data.result.map((item) => ({ ...item, isOpen: false }));
          setBudgetUtilization(arr);
        }
      })
      .catch((err) => {
        console.error("Error fetching approval collection:", err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  const handleFilter = () => {
    const filteredList = budgetUtilization.filter((item) => {
      const dateFromCondition = dateFrom
        ? new Date(item.collection_date) >= new Date(dateFrom)
        : true;
      const dateToCondition = dateTo
        ? new Date(item.collection_date) <= new Date(dateTo)
        : true;
      return dateFromCondition && dateToCondition;
    });
    return filteredList;
  };

  // useEffect(() => {
  //   handleFilter();
  // }, [dateFrom, dateTo]);

  const filteredList = handleFilter();

  let list = [];
  filteredList.forEach((item) => {
    if (
      item.approval_no.indexOf(filterText) === -1 &&
      item.approval_no.toString().indexOf(filterText.toLowerCase()) === -1
    )
      return;
    else list.push(item);
  });

  const updateReturnApproval = (approval_no, status) => {
    setReturnLoading(true);
    postApi("return-approval", {
      remarks: remark,
      status,
      approval_no,
      userId: user.username,
    })
      .then((resp) => {
        setReturnLoading(false);
        if (resp && resp.success) {
          setReturnMessage("Return Successfully");
          setTimeout(() => {
            toggleRemark();
            getApprovalCollection();
          }, 1000);
        }
      })
      .catch((err) => {
        setReturnLoading(false);
        toast.error("An error occured, please try again later");
        console.log(err);
      });
  };

  const updateApproval = (approval_no, status) => {
    setApprovalLoading(true);
    postApi("update-approval", {
      query_type: "update_ag_status",
      remarks: remark,
      status: "AG Approved",
      approval_no,
      userId: user.username,
      mda_name: user.mda_name,
    })
      .then((resp) => {
        setApprovalLoading(false);
        if (resp && resp.success) {
          setApprovalMessage(
            status === "Return Processed"
              ? "Returned Releases Successfully"
              : "Approved Successfully"
          );
          setTimeout(() => {
            toggleRemark();
            getApprovalCollection();
          }, 1000);
        }
      })
      .catch((err) => {
        setApprovalLoading(false);
        toast.error("An error occured, please try again later");
        console.log(err);
      });
  };

  const finalList = [];
  list.forEach((item) => {
    if (item.approval_no.toString().indexOf(searchTerm) === -1) return;
    finalList.push(item);
  });

  const onCheck = (item) => {
    let newList = [];
    let itemFound = selectedReleases.find((s) => s.id === item.id);
    if (itemFound) {
      newList = selectedReleases.filter((s) => s.id !== item.id);
    } else {
      newList = [...selectedReleases, item];
    }
    setSelectedReleases(newList);
  };

  const onCheckAll = () => {
    if (selectedReleases.length) {
      setSelectedReleases([]);
    } else {
      setSelectedReleases(finalList);
    }
  };

  const fields = [
    // {
    //   title: "Action",
    //   headerComponent: () => <div>
    //         <input type='checkbox' checked={selectedReleases.length === finalList.length} onChange={() => onCheckAll()} />
    //     </div>,
    //   custom: true,
    //   component: (item, index) =>(
    //     <div>
    //         <input type='checkbox' checked={selectedReleases.findIndex(s => s.id === item.id) !== -1} onChange={() => onCheck(item)} />
    //     </div>
    //   )

    // },
    {
      title: "Release Date",
      value: "approval_date",
    },
    // {
    //   title: 'S/N',
    //   custom: true,
    //   component: (item, index) => <div>{index + 1}</div>,
    // },
    {
      title: "Approval No",
      value: "approval_no",
    },

    {
      title: "MDA Name",
      value: "mda_name",
    },
    // {
    //   title: "Economic Code",
    //   value: "economic_code",
    // },
    // {
    //   title: "Collection Date",
    //   value: "collection_date",
    // },

    // {
    //   title: "Approval Type",
    //   value: "approved_by",
    // },
    // {
    //   title: "Status",
    //   value: "approval_status",
    // },
    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.cb_amount)}</div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <Button
            // style={{
            //   backgroundColor: "yellow",
            //   border: "none",
            //   height: "60%",
            //   padding: 5,
            //   paddingLeft: 10,
            //   paddingRight: 10,
            //   borderRadius: 10,
            //   cursor: "pointer",
            // }}
            onClick={() =>
              history(
                `${Routes.cashBacking.releaseCollectionPDF}/${item.approval_no}`
              )
            }
          >
            <FontAwesomeIcon icon={faPrint} className="me-1" />
            View Release
          </Button>
        </div>
      ),
    },
  ];

  const handleDelete = () => {
    setLoading(true);
    deleteApi(`delete-approve-collection`, item)
      .then((res) => {
        toast.success("Deleted Successfully");
        setLoading(false);
        getApprovalCollection();
        toggleDelete({});
      })
      .catch((err) => {
        toast.error(`error occured ${JSON.stringify(err)}`);
        setLoading(false);
      });
  };
  // const ApprovalCollectionIndex = () => {
  //   const { searchTerm, setSearchTerm } = useContext(SearchContext);
  //   // ... rest of the code
  // };

  useEffect(() => {
    return () => {
      setSearchTerm("");
    };
  }, [setSearchTerm]);

  const totalReleases = budgetUtilization.reduce(
    (a, b) => a + parseFloat(b.cb_amount),
    0
  );
  const [pin, setPin] = useState("");

  const processPayment = () => {
    if (pin.length < 4) {
      alert("PIN is 4 digit");
    }
    setPreparingSchedule(true);
    const finalList = [];
    selfAccounting.forEach((item) => {
      let new_amount = parseFloat(item.cb_amount) * 100;
      let input_amount = new_amount?.toString().split(".")[0];
      finalList.push({
        payment_ref: item.id,
        amount: input_amount,
        narration: item.description_code?.replace("'", ""),
        beneficiary_code: item.mda_code,
        ben_email: "",
        ben_bank_cbn_code:
          bankList.find((i) => i.title === item.payment_bank_name)?.code ||
          "044",
        ben_account_no: item.payment_acc_no,
        account_type:
          accountTypes.find((i) => i.title === item.payment_acc_type)?.code ||
          "10",
        is_prepaid_load: "FALSE",
        currency: "NGN",
        beneficiary_name: item.payment_account_name?.replace("'", ""),
        beneficiary_mobile_no: "",
      });
    });

    const totalOthers = others.reduce((a, b) => a + parseFloat(b.cb_amount), 0);

    // others.forEach((item) => {
    let new_amount = parseFloat(totalOthers) * 100;
    let input_amount = new_amount?.toString().split(".")[0];
    finalList.push({
      payment_ref: others[0].id,
      amount: input_amount,
      narration: "TRANSFER TO SUB TREASURY", //others[0].description_code?.replace(/\&/g, '&amp;')?.replace("'", ''),
      beneficiary_code: others[0].mda_code,
      ben_email: "",
      ben_bank_cbn_code: form?.subAccount?.bank_code,
      ben_account_no: form?.subAccount?.account_number,
      account_type: form?.subAccount?.account_type
        ? form?.subAccount?.account_type
        : "10",
      is_prepaid_load: "FALSE",
      currency: "NGN",
      beneficiary_name: form?.subAccount?.account_name?.replace("'", ""),
      beneficiary_mobile_no: "",
      type: 0,
    });
    // });

    console.log({
      details: finalList,
      description: form.bulk_description,
      id: form.mainAccount.id,
    });

    postApi("autogate/transfer", {
      details: finalList,
      description: form.bulk_description,
      id: form.mainAccount.id,
      callback_type: "approval_payment",
      check: "main",
      ids: others.map((item) => item.id),
      vPinToken: createTokenPin({ vPinToken: pin }),
    })
      .then((res) => {
        if (res.success) {
          setModal(false);
          toast.success(res.message);
          history(Routes.paymentRequest.approvePaymentRequest);
        } else {
          toast.error(res.message);
        }
        setPreparingSchedule(false);
      })
      .catch((err) => {
        toast.error(err);
        setPreparingSchedule(false);
      });
  };

  const openConfirmationModal = () => {
    if (form?.mainAccount.account_number && form?.subAccount.account_number) {
      // let selectedMdaPayments = []
      // let selectedSTPayments = [];

      // budgetUtilization.forEach((item) => {

      // })
      setForm((prev) => ({
        ...prev,
      }));
      setModal(true);
    } else {
      toast.error("Please select the appropriate accounts!");
    }
  };

  return (
    <>
      {/* {JSON.stringify(approval_no)}  */}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <Button
          onClick={() => history(Routes.approval.newApproval)}
          className="me-2 bg-success"
        >
          Add Approval
        </Button> */}
        <div className="d-block mb-4 mb-md-0">
          <h4>
            {/* <FontAwesomeIcon icon={faListUl} className="me-2" />  */}
            Approve Payment Request
          </h4>
        </div>
        {/* {JSON.stringify(budgetUtilization)} */}
        <div className="btn-toolbar mb-2 mb-md-0">
          <h5>Total Amount Selected: {formatNumber(totalReleases)}</h5>
        </div>
      </div>
      {/* {JSON.stringify(form.subAccount)} */}
      {/* <SearchInput
        placeholder="Search For Releases"
        value={searchTerm}
        onChange={({ target: { value } }) => setSearchTerm(value)}
      /> */}

      {/* <Row>
        <Col md={6} style={{
          marginTop: 28
        }}>
          <SearchInput
            placeholder="Search For All Schedule"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </Col>
        <Col md={6}>
          <Row style={{
            marginBottom: 10
          }}>
            <Col md={6}>
              <Form.Group id="date_from">
                <Form.Label>Date From</Form.Label>
                <Form.Control
                  type="date"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="date_to">
                <Form.Label>Date to</Form.Label>
                <Form.Control
                  type="date"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <div className="m-2 row">
            <div className="col-md-6">
              <label>
                Select Source Account:
                <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                onChange={({ target: { value } }) => {
                  let selected = paymentAccountList.find((i) => i.id === value);
                  console.log({ selected, value });
                  setForm((p) => ({
                    ...p,
                    mainAccount: { ...p.mainAccount, ...selected },
                  }));
                }}
              >
                <option value="">--select--</option>
                {paymentAccountList
                  .filter((item) => item.type === "MAIN ACCOUNT")
                  .map((item, id) => {
                    let selected = bankList.find(
                      (i) => i.code === item.bank_code
                    );

                    return (
                      <option value={item.id} key={id}>
                        {item.account_name} ({item.account_number} -{" "}
                        {selected?.title})
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-md-6">
              {/* <label>
                Bulk Descripton
              </label>
              <input
              disabled={true}
                className="form-control"
                value={form.bulk_description}
                onChange={({ target: { value } }) =>
                  setForm((p) => ({ ...p, bulk_description: value }))
                }
              /> */}
            </div>
          </div>
          {/* {JSON.stringify(paymentAccountList)} */}
          <div className="m-2 row">
            <div className="col-md-6">
              <label>
                Select Sub Treasury Account
                <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                onChange={({ target: { value } }) => {
                  let selected = paymentAccountList.find((i) => i.id === value);

                  setForm((p) => ({
                    ...p,
                    subAccount: { ...p.subAccount, ...selected },
                  }));
                }}
              >
                <option value="">--select--</option>
                {paymentAccountList
                  .filter((item) => item.type === "SUB-TREASURY ACCOUNT")
                  .map((item, id) => {
                    let selected = bankList.find(
                      (i) => i.code === item.bank_code
                    );

                    return (
                      <option value={item.id} key={id}>
                        {item.account_name} ({item.account_number} -{" "}
                        {selected?.title})
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {/* {JSON.stringify({selfAccounting,others})} */}
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ height: "60vh", overflow: "scroll" }}>
              <CustomTable fields={fields} data={budgetUtilization} />
            </div>
          )}
        </Card.Body>

        {budgetUtilization.length ? (
          <center className="mb-2">
            <Button color="danger" className="me-2">
              <FontAwesomeIcon icon={faBackward} className="me-2" />
              Return
            </Button>
            <CustomButton
              loading={preparingSchedule}
              onClick={openConfirmationModal}
            >
              <img
                src="https://asset.brandfetch.io/idxE04AMjS/idL34Q6qIN.png"
                alt="interswitch_logo"
                height={"30px"}
                className="me-2"
              />
              Process Payment
            </CustomButton>
          </center>
        ) : null}
      </Card>

      <Modal show={modal} onHide={() => setModal((p) => !p)} size="xl">
        <Modal.Header>Confirmation</Modal.Header>
        <Modal.Body>
          <h4 className="text-center">
            Are you sure you want to transfer a total of N
            {formatNumber(totalReleases)} from the Main Account?
          </h4>

          <Row>
            <Col
              md={12}
              style={
                others && others.length === 0
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <h6>Sub Treasury Account Details </h6>
              <Row>
                <Col md={6} className="text-right">
                  Account Name:{" "}
                </Col>
                <Col md={6} className="fw-bold">
                  {form?.subAccount?.account_name}
                </Col>

                <Col md={6} className="text-right">
                  Bank:{" "}
                </Col>
                <Col md={6} className="fw-bold">
                  {
                    bankList.find((i) => i.code === form?.subAccount?.bank_code)
                      ?.title
                  }
                </Col>

                <Col md={6} className="text-right">
                  Account Type:{" "}
                </Col>
                <Col md={6} className="fw-bold">
                  {
                    accountTypes.find(
                      (i) => i.code === form?.subAccount?.account_type
                    )?.title
                  }
                </Col>

                <Col md={6} className="text-right">
                  Account Number:{" "}
                </Col>
                <Col md={6} className="fw-bold">
                  {form?.subAccount?.account_number}
                </Col>
              </Row>
              <div className="col-md-4 pb-1 mb-1">
                <input
                  onChange={({ target: { value } }) => {
                    setPin(value);
                  }}
                  value={pin}
                  name="pin-main"
                  type="password"
                  required
                  maxLength="4"
                  className="form-control"
                  placeholder="Enter PIN"
                />
              </div>
              <Table size="sm" bordered>
                <thead>
                  <tr>
                    <th style={tableHeaderStyle}>MDA Name</th>
                    <th style={tableHeaderStyle}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {others.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.mda_name}</td>
                      <td className="text-end">
                        {formatNumber(item?.cb_amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>

            <Col
              md={12}
              className="mt-4"
              style={
                selfAccounting && selfAccounting.length === 0
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <h6>Self Accounting MDAs </h6>
              <Table
                size="sm"
                bordered
                className={"user-table align-items-center"}
              >
                <thead className="table-header">
                  <tr>
                    <th style={tableHeaderStyle}>MDA Name</th>
                    <th style={tableHeaderStyle}>Bank Name</th>
                    <th style={tableHeaderStyle}>Account Name</th>
                    <th style={tableHeaderStyle}>Account No.</th>
                    <th style={tableHeaderStyle}>Account Type</th>
                    <th style={tableHeaderStyle}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {selfAccounting.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.mda_name}</td>
                      <td>{item?.payment_bank_name}</td>
                      <td>{item?.payment_account_name}</td>
                      <td>{item?.payment_acc_no}</td>
                      <td>{item?.payment_acc_type}</td>
                      <td className="text-center">
                        {formatNumber(item?.cb_amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModal(false)}>
            <FontAwesomeIcon icon={faTimes} className="me-2" /> Cancel
          </Button>
          <CustomButton loading={preparingSchedule} onClick={processPayment}>
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            Confirm and Continue
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ViewPaymentRequestDetailForFinalApproval;
