import React from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Form,
  // InputGroup,
  Col,
  Row,
  Card,
} from "@themesberg/react-bootstrap";
// import { Input }
import InputGroup from "react-bootstrap/InputGroup";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { postApi } from "../../../redux/api";
import CustomTable from "../../../components/CustomTable";
import { Routes } from "../../../routes";
import { useContext } from "react";
import { SearchContext } from "../../HomePage";
import { useSelector } from "react-redux";
import { MODULE_TYPES } from "../../../redux/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchInput from "../../../components/SearchInput";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function BatchProcessingIndex({
  status = "pending",
  pageType = "batch",
  type = "main_treasury",
}) {
  const user = useSelector((state) => state.auth.user);
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const location = useLocation();
  const history = useNavigate();
  const isST = location.pathname.includes("batch-process-st");
  // const [name, setName] = useContext(UserContext)
  // const details = useHistory()
  const [paymentSchedule, setPaymentScheduleTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const isDAGOffice = location.pathname.includes("dag-office")
  const isAssDirExpControl = location.pathname.includes("assistant-director-exp-control")
  const isExpControl = location.pathname.includes("dir-exp-control")
  const isUpdatePage = pageType === "update";
  const isCommitPage = pageType === "commit";
  const isCommittedPage = pageType === "committed";
  const isRemittancePage = pageType === "remittance";

  // const [searchTerm, setSearchTerm] = useState('')
  const fields = [
    {
      title: "S/N",
      custom: true,
      component: (item, idx) => <span>{idx + 1}</span>,
      style: (item) =>
        item.status === "returned" ? { backgroundColor: "orange" } : {},
    },
    {
      title: "Batch No",
      // value: 'batch_number',
      custom: true,
      component: (item) => <div>{item.batch_number}</div>,
      className: "text-center",
      style: (item) =>
        item.status === "returned" ? { backgroundColor: "orange" } : {},
    },  
    {
      title: "No. Vouchers",
      custom: true,
      component: (item) => <span>{item.no_of_payments}</span>,
      style: (item) =>
        item.status === "returned"
          ? { backgroundColor: "orange", textAlign: "center" }
          : { textAlign: "center" },
    },
    {
      title: "Total(N)",
      tableHeadStyle: { textAlign: "right", color: "black" },
      custom: true,
      component: (item) => (
        <div style={{ textAlign: "right" }}>
          {parseFloat(item.total_amount).toLocaleString("en")}
        </div>
      ),
      style: (item) =>
        item.status === "returned" ? { backgroundColor: "orange" } : {},
    },
    {
      title: "Action",
      className: "text-center",
      custom: true,
      component: (item) => (
        <div>
          {/* <Button className="bg-warning w-110 text-white me-1" size="sm">
              View Approval
            </Button> */}
          <Button
            item={item}
            onClick={() => {
              if(isDAGOffice ) {
                history(
                  `${Routes.dag.dagApprovalView}/${item.batch_number}`
                );
              }
              else if (isUpdatePage) {
                history(
                  `${Routes.batchProcessing.viewPaymentList}/${item.batch_number}`
                );
              } else if (isCommitPage) {
                history(`${Routes.payments.process}/${item.batch_number}`);
              } else if (isRemittancePage) {
                if (type === MODULE_TYPES.SUB_TREASURY) {
                  history(
                    `${Routes.remittance.viewPaymentAdvice}/${item.batch_number}`
                  );
                } else {
                  history(`${Routes.remittance.viewPDF}/${item.batch_number}`);
                }
              } else {
                if (status.toLowerCase() === "committed") {
                  history(`${Routes.payments.viewPDF}/${item.batch_number}`);
                } else {
                  history(
                    `${Routes.expenditureControl.dirExpPaymentScheduleView}/${item.batch_number}`
                  );
                  // history(`/dashboard/process/${item.batch_number}`)
                }
              }
            }}
            className="bg-success w-110 text-white"
            size="sm"
          >
            {isUpdatePage
              ? "View Batch"
              : isCommitPage
              ? "Process Approval"
              : isRemittancePage
              ? "Generate Slip"
              : status.toLowerCase() === "committed"
              ? "View Schedule"
              : "Process"}
          </Button>
        </div>
      ),
      style: (item) =>
        item.status === "returned" ? { backgroundColor: "orange" } : {},
    },
  ];

  useEffect(() => {
    setLoading(true);
    postApi(`get_batch_list`, {
      query_type: isUpdatePage ? "select_uncommitted" : "select",
      status:
        isUpdatePage || isRemittancePage
          ? "Committed"
          : isCommitPage||isDAGOffice
          ? "auditor_approved"
          : status,
      type: user.role,
    })
      .then((data) => {
        console.log(data);
        if (data.result) {
          setPaymentScheduleTable(data.result);
        }
        setLoading(false); 
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [user.role, status, isDAGOffice, isUpdatePage, isCommitPage, isRemittancePage]);
  

  let rows = [];

  paymentSchedule &&
    paymentSchedule.forEach((item) => {
      if (
        item?.batch_number &&
        item?.batch_number?.toLowerCase().indexOf(searchTerm.toLowerCase()) ===
          -1 /// &&
        // item?.no_of_payments?.indexOf(searchTerm.toLowerCase()) === -1 &&
        // item?.customer_code?.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1
      ) {
        return;
      }

      rows.push(item);
    });

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}>
          {isExpControl
              && <h4>Director Expenditure Control</h4>
          }
          {isDAGOffice
              && <h4>Deputy Accountant General</h4>
          }
          <h5>
            {/* <FontAwesomeIcon icon={faHourglass} className="me-2" />{' '} */}
            {isUpdatePage
              ? "Payment Update"
              : isCommitPage
              ? "Approve Payment"
              : isRemittancePage
              ? "Generate Remittance Slip"
              : isExpControl ? "Batch Processing (Payment Schedules)" : isDAGOffice ? "Approve Payment Schedules" : null}

          </h5>
           <SearchInput placeholder="Search For Batch Processing" value={searchTerm} onChange={({target:{value}}) => setSearchTerm(value)} />
          {/* <InputGroup className="mb-3">
          <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
            <Form.Control
             value={searchTerm}
             onChange={({ target: { value } }) => setSearchTerm(value)}
             type="text"
             placeholder="Search for batch processing"
            />
          </InputGroup> */}
          {/* <div class="input-group mb-3">
  <div class="input-group-prepend">
  <FontAwesomeIcon icon={faSearch} />
  </div>
  <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
</div> */}
         
          {/* <Row>
              <Col md={12}>
            <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Col md={12}>
                  <Form.Control
                    className="w-auto"
                    value={searchTerm}
                    onChange={({ target: { value } }) => setSearchTerm(value)}
                    type="text"
                    placeholder="Search for batch processing"
                  />
                  </Col>
                </InputGroup>
              </Form.Group>
            </Form>
            </Col>
            </Row> */}
        </div>
      </div>
      {/* {JSON.stringify(paymentSchedule)} */}
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
        {loading ? (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ) : (
          <CustomTable
            size="sm"
            fields={fields}
            data={rows}
            // currentPage={currentPage}
            // handlePageChange={handlePageChange}
          />
          )}
        </Card.Body>
        {/* <h2>hbcvvdk</h2> */}
      </Card>
    </>
  );
}

export default BatchProcessingIndex;
