import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Table,
  Row,
  Button,
  Card,
} from "@themesberg/react-bootstrap";
import { postApi } from "../../../redux/api";
import toast from "react-hot-toast";
import { accountTypes, bankList } from "../../accounts/AccountForm";

const TaxSetup = () => {
  const [loading, setLoading] = useState(false);
  const [taxesList, setTaxesList] = useState([]);
  // const [data, setData] = useState(taxesList); // Initialize data state with taxesList
  // const [tax_name, setTaxName] = useState("");
  // const [tax_type, setTaxType] = useState("State")
  // const [rate, setAmount] = useState("");
  // const [rate_type, setAmountType] = useState("fixed");
  const [editingIndex, setEditingIndex] = useState(null);
  const [form, setForm] = useState({
    tax_name: "",
    rate: "",
    tax_type:"State",
    rate_type: "fixed",
  })

  const getTaxes = () => {
    setLoading(true);
    postApi(`post_taxes`, {
      query_type: "select",
    })
      .then((data) => {
        console.log("Tax data response:", data);
        if (data.result) {
          setTaxesList(data.result);
        } else {
          throw new Error("No data found");
        }
      })
      .catch((error) => {
        console.error("Error fetching tax data:", error.message);
        toast.error("An error occurred while fetching data.");
      })
      .finally(() => {
        setLoading(false); // Move setLoading(false) to the finally block
      });
  };
  

  useEffect(() => {
   getTaxes()
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let formData = form;
      if(form.id) {
        formData.query_type= 'update' 
      } else {
        formData.query_type= 'insert' 
      }
      const response = await postApi(`post_taxes`, formData);

      if(response && response.success) {
        toast.success("submit success");
        getTaxes()
      } else {
      toast.error('An error occurred, please try again later.');
      }
      // if (editingIndex === null) {
      //   setData([...data, formData]);
      //   toast.success("submit success");
      // } else {
      //   const updatedData = data.map((item, index) =>
      //     index === editingIndex ? formData : item
      //   );
      //   setData(updatedData);
      //   setEditingIndex(null);
      // }

      // setTaxName("");
      // setAmount("");
      setForm({
        tax_name: "",
        rate: "",
        tax_type:"State",
        rate_type: "fixed",
        account_name:'',
        bank_name:'',
        account_no:'',
        account_type:''
      })
      
    } catch (error) {
      console.error("Error submitting form data:", error.message);
      toast.success(error);
    }
  };

  const handleChange = ({target:{name,value}}) => setForm(p => ({...p, [name]:value }))

  const handleEdit = (index) => {
    const item = taxesList[index];

    setForm(item)

    // setTaxName(item.tax_name);
    // setAmount(item.rate);
    // setAmountType(item.rate_type);
    // setTaxType(item.tax_type);
    setEditingIndex(index);
  };

  const handleDelete = (index) => {
    // const updatedData = data.filter((_, i) => i !== index);
    // setData(updatedData);
    const item = taxesList[index];
    postApi('post_taxes', { query_type: 'delete', id: item.id})
    .then(resp => {
      if(resp && resp.success) {
        toast.success("Successfully deleted")
        getTaxes()
      } else {
        toast.error("An error occured, please try again later.")
      }
    }).catch(err => {
      console.log(err)
      toast.error("An error occured, please try again later.")

    })
  };

  return (
    <Card>
      <Card.Body>
        <h4>Tax Setup</h4>
        <Form>
          <Row>
            <Col md={4}>
              <Form.Group controlId="taxName">
                <Form.Label>Tax Name</Form.Label>
                <Form.Control
                  type="text"
                  value={form.tax_name}
                  name="tax_name"size="sm"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col
              md={4}
              style={{
                marginTop: -26,
              }}
            >
              <Form.Group controlId="amount">
                <Form.Label> Amount</Form.Label>
                <Row>
                  <Col md={6}>
                    <Form.Check
                      type="radio"
                      label="Fixed"
                      name="amountType"
                      checked={form.rate_type === "fixed"}
                      onChange={() => setForm(p=>({...p, rate_type: "fixed"}))}
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Check
                      type="radio"
                      label="Percentage"
                      name="amountType"
                      checked={form.rate_type === "percentage"}
                      onChange={() => setForm(p=>({...p, rate_type: "percentage"}))}
                    />
                  </Col>
                </Row>
                <Form.Control
                  type="number"
                  value={form.rate}
                name="rate"size="sm"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="taxName">
                <Form.Label>Tax Type</Form.Label>
                {/* <Form.Control
                  type="text"
                  value={tax_type}
                  onChange={(e) => setTaxType(e.target.value)}
                /> */}
                <select className="form-control form-control-sm" onChange={handleChange} name="tax_type" value={form.tax_type}>
                  <option value={"State"}>State</option>
                  <option value={"Federal"}>Federal</option>
                </select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md={3}>
              <Form.Group controlId="taxName">
                <Form.Label>Bank Name</Form.Label>
                <select
                        onChange={({ target: { value } }) => {
                          setForm((p) => ({
                            ...p,
                            bank_name: value,
                          }));
                        }}
                        value={form.bank_name}
                        name="bank_name"
                        className="form-control form-control-sm"
                      >
                        <option value={""}>--select--</option>
                        {bankList.map((item, id) => (
                          <option key={id} value={item.title}>
                            {item.title}
                          </option>
                        ))}
                      </select>

              </Form.Group>
            </Col>
            <Col
              md={3}
              style={{

              }}
            >
              <Form.Group controlId="account_name">
                <Form.Label> Account Name</Form.Label>
                <Form.Control
                  type="text" size="sm"
                  value={form.account_name}
                name="account_name"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col
              md={3}
              style={{

              }}
            >
              <Form.Group controlId="account_no">
                <Form.Label> Account Number</Form.Label>
                <Form.Control
                  type="text"size="sm"
                  value={form.account_no}
                name="account_no"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="account_type">
                <Form.Label>Account Type</Form.Label>
                <select
                        onChange={({ target: { value } }) => {
                          setForm((p) => ({
                            ...p,
                            account_type: value,
                          }));
                        }}
                        value={form.account_type}
                        name="account_type"
                        className="form-control form-control-sm"
                      >
                        <option value={""}>--select--</option>
                        {accountTypes.map((item, id) => (
                          <option key={id} value={item.title}>
                            {item.title}
                          </option>
                        ))}
                      </select>
              </Form.Group>
            </Col>
          </Row>
          <center>
            <Button
              variant="primary"
              style={{
                marginTop: 29,
              }}
              onClick={handleSubmit}
            >
              {editingIndex === null ? "Submit" : "Save"}
            </Button>
          </center>
        </Form>
      </Card.Body>
      <Card.Body>
      {loading ? (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ) : (
        <><h4>Tax Data</h4><Table striped bordered hover>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Tax Name</th>
                  <th>Amount</th>
                  <th>Account Name</th>
                  <th>Account Number</th>
                  <th>Bank Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {taxesList.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {/* <td>{JSON.stringify(tableData[index])}</td> */}

                    <td>{item.tax_name}</td>
                    <td>{item.rate_type==='percentage' ? `${item.rate}%` : item.rate}</td>
                    <td>{item.account_name}</td>
                    <td>{item.account_no}</td>
                    <td>{item.bank_name}</td>
                    <td>
                      <Button className="me-2 mr-2" size="sm" variant="primary" onClick={() => handleEdit(index)}>
                        Edit
                      </Button>
                      <Button size="sm" variant="danger" onClick={() => handleDelete(index)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table></>
        )}
      </Card.Body>
    </Card>
  );
};

export default TaxSetup;
