/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import {
    Document,
    Font,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
  } from "@react-pdf/renderer";
  import React from "react";
  import DM_SANS_NORMAL from "../../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
  import DM_SANS_BOLD from "../../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-Bold.ttf";
  import DM_SANS_ITALIC from "../../../assets/DM_Sans/DM_Sans/static/DMSans-Italic.ttf";
  import moment from "moment";
  
  Font.register({
    // family: "DM_SANS",
    fonts: [
      { src: DM_SANS_NORMAL, fontWeight: 700 },
      {
        src: DM_SANS_BOLD,
        fontStyle: "bold",
      },
      {
        src: DM_SANS_ITALIC,
        fontStyle: "italic",
      },
    ],
  });
  
  const styles = StyleSheet.create({
    body: {
      width: "100%",
      flexDirection: "row",
      fontFamily: "DM_SANS",
      pageBreakInside: "avoid",
      paddingTop: 20,
      fontSize: 10,
    },
    image1: {
      width: 70,
      height: 50,
      marginLeft: -15,
    },
    header: {
      fontSize: 17,
      marginTop: 10,
    },
    para: {
      fontStyle: "italic",
      fontSize: 10,
      fontStyle: "bold",
    },
    para1: {
      fontSize: 10,
      fontStyle: "bold",
    },
  });
  
  export const NewRelisePdf = ({
    selectedData = [],
    selecOffer = (f) => f,
    copies = {},
    user,
  }) => {
    const form = selectedData ? selectedData[0] : {};
    const today = moment(form?.collection_date).format("Do MMMM, YYYY");
    const currentDate = moment();
  
    const formattedDate = currentDate.format("MMMM, YYYY");
    const calculateTotalApprovedAmount = () => {
      const totalApprovedAmount = selectedData.reduce(
        (total, item) => total + parseFloat(item.approved_amount),
        0
      );
  
      return totalApprovedAmount;
    };
  
    const dataByMDA = {};
    selectedData.forEach((item) => {
      if (Object.keys(dataByMDA).includes(item.mda_code)) {
        dataByMDA[item.mda_code] = [...dataByMDA[item.mda_code], item];
      } else {
        dataByMDA[item.mda_code] = [item];
      }
    });
  
    return (
      <Document>
        <Page size="A4">
          <View style={{ paddingLeft: 50, paddingRight: 30 }}>
            <View style={styles.body}>
              <View style={{ width: "100%", alignItems: "center" }}>
                <Image
                  src={require("../../../assets/img/KTSG.png")}
                  style={styles.image1}
                />
                <Text style={styles.header}>
                  MINISTRY OF BUDGET & ECONOMIC PLANNING
                </Text>
                <Text style={styles.para}>
                  Old Government House Office Complex, Katsina
                </Text>
                <Text
                  style={{
                    fontFamily: "DM_SANS",
                    fontStyle: "italic",
                    fontSize: 12,
                  }}
                >
                  P.M.B 2132, KATSINA
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                paddingBottom: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "70%",
                  flexDirection: "row",
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                <Text style={styles.para1}>Our Ref: </Text>
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                    textDecoration: "underline",
                  }}
                >
                  S/MBEP/BD/{form?.approval_type === "Recurrent" ? "REC" : "CAP"}
                  /FA/{moment().format("YY")}/VOL.I/
                  {form?.approval_no.substring(4)}
                </Text>
              </View>
              <View
                style={{
                  width: "30%",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                    textDecoration: "underline",
                  }}
                >
                  {today}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "50%",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  marginBottom: 7,
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                }}
              >
                The Accountant General,
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  marginBottom: 7,
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                }}
              >
                Ministry of Finance,
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                }}
              >
                Katsina,
              </Text>
            </View>
            <View
              style={{
                width: "50%",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  marginBottom: 7,
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                }}
              >
                The Auditor General,
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  marginBottom: 7,
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                }}
              >
                Office of the Auditor General
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                }}
              >
                Katsina,
              </Text>
            </View>
            <View
              style={{
                width: "50%",
              }}
            >
              {/* <Text
                style={{
                  fontSize: 10,
                  marginBottom: 7,
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                }}
              >
                {copies.officeTitle},
              </Text> */}
              <Text
                style={{
                  fontSize: 10,
                  marginBottom: 7,
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                }}
              >
                The Director Main Account,
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  marginBottom: 7,
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                }}
              >
                Ministry of Finance,
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "DM_SANS",
                  fontStyle: "italic",
                }}
              >
                Katsina,
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  textDecoration: "underline",
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                  fontSize: 11,
                  marginTop: 10,
                  fontWeight: 20,
                  textTransform: "uppercase",
                }}
              >
                {selectedData?.length ? selectedData[0]?.narration : ""}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                textAlign: "justify",
              }}
            >
              <Text
                style={{
                  fontSize: 11,
                  lineHeight: 1.5,
                  marginTop: 7,
                }}
              >
                I am directed to convey approval for the release of funds for
                recurrent services in respect of THE MONTH OF{" "}
                {formattedDate.toUpperCase()} to Ministries, Departments and
                Agencies as follows:
              </Text>
            </View>
            <View
              style={{
                border: 1,
                marginTop: 20,
              }}
            >
              <View
                style={{
                  width: "100%",
                  borderBottom: 1,
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                    width: "20%",
                    fontSize: 11,
                    borderRight: 1,
                    textAlign: "center",
                  }}
                >
                  CODE
                </Text>
                <Text
                  style={{
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                    width: "60%",
                    fontSize: 11,
                    borderRight: 1,
                    textAlign: "center",
                  }}
                >
                  MINISTRY/DEPARTMENT
                </Text>
                <Text
                  style={{
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                    width: "20%",
                    fontSize: 11,
                    textAlign: "center",
                  }}
                >
                  AMOUNT (N)
                </Text>
              </View>
              {Object.keys(dataByMDA).map((group, grpIdx) => {
                const total = dataByMDA[group].reduce(
                  (a, b) => a + parseFloat(b.approved_amount),
                  0
                );
                return (
                  <View key={grpIdx}>
                    <View
                      style={{
                        width: "100%",
                        borderBottom: 1,
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "DM_SANS",
                          fontStyle: "bold",
                          width: "20%",
                          fontSize: 11,
                          borderRight: 1,
                          textAlign: "justify",
                          paddingLeft: 4,
                        }}
                      >
                        {group}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "DM_SANS",
                          fontStyle: "bold",
                          width: "60%",
                          fontSize: 11,
                          borderRight: 1,
                          textAlign: "justify",
                          paddingLeft: 4,
                        }}
                      >
                        {dataByMDA[group][0].mda_name}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "DM_SANS",
                          fontStyle: "bold",
                          width: "20%",
                          fontSize: 11,
                          textAlign: "right",
                          paddingRight: 5,
                        }}
                      >
                        N{selecOffer(total)}
                      </Text>
                    </View>
                    {dataByMDA[group].map((item, index) => (
                      <>
                        <View
                          key={index}
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            borderBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              width: "20%",
                              fontSize: 11,
                              borderRight: 1,
                              textAlign: "justify",
                              paddingLeft: 4,
                            }}
                          >
                            {item.mda_economic_code}
                          </Text>
                          <Text
                            style={{
                              width: "60%",
                              fontSize: 11,
                              borderRight: 1,
                              textAlign: "justify",
                              paddingLeft: 4,
                            }}
                          >
                            {item.description}
                          </Text>
                          <Text
                            style={{
                              width: "20%",
                              fontSize: 11,
                              textAlign: "right",
                              paddingRight: 5,
                            }}
                          >
                            N{selecOffer(item.approved_amount)}
                          </Text>
                        </View>
                        <View
                          key={index}
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            borderBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              width: "20%",
                              fontSize: 11,
                              borderRight: 1,
                              textAlign: "justify",
                              paddingLeft: 4,
                            }}
                          >
                            {/* {item.mda_economic_code} */}
                          </Text>
                          <Text
                            style={{
                              width: "60%",
                              fontSize: 11,
                              borderRight: 1,
                              textAlign: "justify",
                              paddingLeft: 4,
                            }}
                          >
                            {/* {item.description} */}
                          </Text>
                          <Text
                            style={{
                              width: "20%",
                              fontSize: 11,
                              textAlign: "right",
                              paddingRight: 5,
                            }}
                          >
                            {/* N{selecOffer(item.approved_amount)} */}
                          </Text>
                        </View>
                      </>
                    ))}
                  </View>
                );
              })}
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "20%",
                    fontSize: 11,
                    borderRight: 1,
                    textAlign: "justify",
                    paddingLeft: 4,
                  }}
                ></Text>
                <Text
                  style={{
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                    width: "60%",
                    fontSize: 11,
                    borderRight: 1,
                    textAlign: "right",
                    paddingRight: 4,
                  }}
                >
                  TOTAL
                </Text>
                <Text
                  style={{
                    width: "20%",
                    fontSize: 11,
                    textAlign: "right",
                    paddingRight: 5,
                  }}
                >
                  N{selecOffer(calculateTotalApprovedAmount())}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginLeft: "auto",
                marginTop: 90,
                width: "100%",
                textAlign: "center",
              }}
            >
              {user && user.imageURL && (
                <Image
                  src={user.imageURL}
                  style={{
                    marginHorizontal: "2px",
                    marginLeft: "auto",
                    height: "50%",
                    width: "50%",
                  }}
                />
              )}
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                (SAMINU MUH'D K/SOLI)
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: "DM_SANS",
                  fontStyle: "bold",
                }}
              >
                FOR: HON, COMMISSIONER
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };
  