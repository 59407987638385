import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useCallback, useEffect } from "react";
import Roboto_BOLD from "../../../assets/fonts/Roboto-Bold.ttf";
import Roboto_ITALIC from "../../../assets/fonts/Roboto-Italic.ttf";
import Roboto_NORMAL from "../../../assets/fonts/Roboto-Regular.ttf";
import { postApi } from "../../../redux/api";
import { useState } from "react";
import { useParams } from "react-router";
import moment from "moment";
import { formatNumber } from "../../../redux/utils";
import {
  capitalizeFirst,
  removeLastAnd,
  toWordsconver,
} from "../../main-treasury/batch-processing/PaymentPDF";
import { bankList } from "../../accounts/AccountForm";
import BackButton from "../../../components/BackButton";
import { Col, Row } from "react-bootstrap";

Font.register({
  family: "Roboto",
  fonts: [
    { src: Roboto_NORMAL, fontWeight: 700 },
    {
      src: Roboto_BOLD,
      fontStyle: "bold",
    },
    {
      src: Roboto_ITALIC,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    width: "100%",
    fontFamily: "Roboto",
    pageBreakInside: "avoid",
    padding: 50,
    paddingTop: 100,
    fontSize: 12,
  },
  footer: {
    position: "fixed",
    bottom: 0,
    // left: 0,
    // right: 0,
    textAlign: "center",
    // padding: 20,
    // marginBottom: -120,
  },
  paras: {
    fontSize: 12,
    width: "40%",
  },
  table: {
    width: "100%",
    flexDirection: "row",
    borderBottom: 1,
  },
  image: {
    width: 30,
    height: 30,
  },
});

export const PaymentSchedulePdf = () => {
  const today = moment().format("YYYY-MM-DD");
  const [psHistoryData, setPsHistoryData] = useState([]);
  const batch_no = useParams().batch_no;
  const [history, setHistory] = useState([]);

  const getPsHistory = useCallback(() => {
    postApi(`get_batch_list`, {
      batch_no,
      query_type: "schedule_pdf",
    })
      .then((data) => {
        console.log("My data", data);
        if (data.result) {
          setPsHistoryData(data.result);
          setHistory(data.schedulehistory);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [batch_no]);

  useEffect(() => {
    getPsHistory();
  }, [batch_no, getPsHistory]);

  const total = psHistoryData.reduce((a, b) => a + parseFloat(b.cb_amount), 0);

  const paymentApprovedbySub = history
    ? history.find(
        (a) => a.payment_schedule_status === "Payment Approved By Sub Treasurer"
      )
    : null;

  const PaymentApprovedByDST = history
    ? history.find(
        (a) => a.payment_schedule_status === "Payment Approved By DST"
      )
    : null;

  return (
    <>
      <Row>
        {" "}
        <Col md={4}>
          {" "}
          <BackButton num={2} />
        </Col>
      </Row>
      <PDFViewer height="1400" width="800">
        <Document>
          <Page size="A4" orientation="landscape">
            <View style={styles.body}>
              <View
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: 20,
                }}
              >
                <Text
                  style={{
                    fontSize: 15,
                    fontFamily: "Roboto",
                    fontStyle: "bold",
                  }}
                >
                  SUB - TREASURY, KATSINA
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: 12,
                  }}
                >
                  The Manager..............................
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontSize: 12,
                  }}
                >
                  IPV
                  NO...................................................................
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  marginTop: 10,
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "25%",
                    fontSize: 12,
                  }}
                >
                  {psHistoryData.length && psHistoryData[0].payment_bank_code
                    ? bankList.find(
                        (i) => i.code === psHistoryData[0].payment_bank_code
                      )?.title
                    : "................................................."}
                  .......
                </Text>
                <Text
                  style={{
                    width: "35%",
                    fontSize: 12,
                  }}
                >
                  {psHistoryData.length && psHistoryData[0].payment_account_name
                    ? psHistoryData[0].payment_account_name
                    : "...................................................."}
                  .........Account
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontSize: 12,
                  }}
                >
                  DATE: {today}
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  marginTop: 10,
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    fontSize: 12,
                  }}
                >
                  Please Credit the Amount of the underlisted beneficiaries and
                  Debit account No.:{" "}
                  {psHistoryData.length &&
                  psHistoryData[0].payment_account_number
                    ? psHistoryData[0].payment_account_number
                    : "......................................................................."}
                </Text>
              </View>
              <View style={{ height: "80%" }}>
                <View
                  style={{
                    border: 1,
                    marginTop: 10,
                    width: "100%",
                  }}
                >
                  <View style={styles.table}>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "5%",
                        textAlign: "center",
                      }}
                    >
                      S/N
                    </Text>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      BENEFICIARY
                    </Text>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "15%",
                        textAlign: "center",
                      }}
                    >
                      BANK
                    </Text>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      BRANCH
                    </Text>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "13%",
                        textAlign: "center",
                      }}
                    >
                      ACCOUNT NO
                    </Text>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "15%",
                        textAlign: "center",
                      }}
                    >
                      AMOUNT
                    </Text>
                  </View>
                  {psHistoryData.map((item, idx) => (
                    <View style={styles.table} key={idx}>
                      <Text
                        style={{
                          borderRight: 1,
                          width: "5%",
                          textAlign: "justify",
                          padding: 8,
                        }}
                      >
                        {" "}
                        {idx + 1}
                      </Text>
                      <Text
                        style={{
                          borderRight: 1,
                          width: "45%",
                          textAlign: "justify",
                          padding: 8,
                        }}
                      >
                        {item.mda_name}{" "}
                      </Text>
                      <Text
                        style={{
                          borderRight: 1,
                          width: "15%",
                          textAlign: "justify",
                          padding: 8,
                        }}
                      >
                        {item.contractor_bank}
                      </Text>
                      <Text
                        style={{
                          borderRight: 1,
                          width: "10%",
                          textAlign: "justify",
                          padding: 8,
                        }}
                      >
                        N/A
                      </Text>
                      <Text
                        style={{
                          borderRight: 1,
                          width: "13%",
                          textAlign: "justify",
                          padding: 8,
                        }}
                      >
                        {item.contractor_acc_no}
                      </Text>
                      <Text
                        style={{
                          borderRight: 1,
                          width: "15%",
                          textAlign: "right",
                          padding: 8,
                        }}
                      >
                        N{formatNumber(item.cb_amount)}
                      </Text>
                    </View>
                  ))}
                  <View style={styles.table}>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "5%",
                        textAlign: "center",
                      }}
                    ></Text>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "45%",
                        textAlign: "center",
                        padding: 8,
                      }}
                    ></Text>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "15%",
                        textAlign: "center",
                        padding: 8,
                      }}
                    ></Text>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "10%",
                        textAlign: "center",
                        padding: 8,
                      }}
                    ></Text>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "13%",
                        textAlign: "center",
                        padding: 8,
                      }}
                    >
                      Total
                    </Text>
                    <Text
                      style={{
                        borderRight: 1,
                        borderBottom: 1,
                        width: "15%",
                        textAlign: "right",
                        padding: 8,
                      }}
                    >
                      N{formatNumber(total)}
                    </Text>
                  </View>
                </View>

                <View>
                  <Text
                    style={{
                      marginTop: 10,
                      // marginLeft: "auto",
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontFamily: "Roboto" }}>
                      Amount in words:
                    </Text>{" "}
                    {capitalizeFirst(removeLastAnd(toWordsconver(total)))} naira
                    only
                  </Text>
                </View>
              </View>
              <View
                style={styles.footer}
                render={() => (
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <View>
                      {/* <Text>{paymentApprovedbySub.signature}</Text> */}
                      {paymentApprovedbySub?.signature ? (
                        <Image
                          src={paymentApprovedbySub?.signature}
                          style={styles.image}
                        />
                      ) : (
                        <Text>
                          ............................................................
                        </Text>
                      )}
                      <Text
                        style={{
                          fontSize: 14,
                          width: "40%",
                          textAlign: "center",
                        }}
                      >
                        Authorized Signature
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          width: "40%",
                          textAlign: "center",
                        }}
                      >
                        (ABDULLAHI HARUNA)
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          width: "40%",
                          textAlign: "center",
                        }}
                      >
                        Sub - Treasurer, Kastina State
                      </Text>
                    </View>
                    <View>
                      {PaymentApprovedByDST?.signature ? (
                        <Image
                          src={PaymentApprovedByDST?.signature}
                          style={styles.image}
                        />
                      ) : (
                        <Text>
                          ............................................................
                        </Text>
                      )}
                      <Text
                        style={{
                          fontSize: 14,
                          width: "40%",
                          textAlign: "center",
                        }}
                      >
                        Authorized Signature
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          width: "40%",
                          textAlign: "center",
                        }}
                      >
                        (BISHIR KABIR)
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          width: "40%",
                          textAlign: "center",
                        }}
                      >
                        Deputy Sub - Treasurer, Kastina State
                      </Text>
                    </View>
                  </View>
                )}
              />
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};
