import React, { useState, useEffect, useCallback } from 'react';
import { Col, Form, Row, Button, Card } from "@themesberg/react-bootstrap";
import BackButton from '../../../components/BackButton'
import { postApi } from "../../../redux/api";
import { useNavigate, useParams } from "react-router-dom";
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Routes } from "../../../routes";
import toast from "react-hot-toast";


function EditApproval() {
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const [economic_code, setEconomicCode] = useState("");
  const [collection_date, setCollectionDate] = useState("");
  const [status, setStatus] = useState("");
  const [mda_name, setMdaName] = useState("");
  const [approval_date, setApprovalDate] = useState("");
  const [approval_type, setApprovalType] = useState("");
  const [amount, setAmount] = useState("");
  const  approval_no  = useParams()

  
  
  const getApprovalCollection = useCallback(() => {
    setLoading(true);
    postApi(`post_approval_collection`,
     {
      form: { query_type: "select_by_approval_no", approval_no: approval_no.id },
    })
  
    .then((data) => {
      console.log("data", data);
      if (data.result && data.result.length > 0) {
        const result = data.result[0];
        setEconomicCode(result.economic_code);
        setCollectionDate(result.collection_date);
        setStatus(result.approval_status);
        setMdaName(result.mda_name);
        setApprovalDate(result.approval_date);
        setApprovalType(result.approval_type);
        setAmount(result.amount);
      }
    })
    .catch((err) => {
      // Handle error
    })
    .finally(() => {
      setLoading(false);
    });
  }, [approval_no]);
  
  
  useEffect(() => {
    getApprovalCollection();
  }, [approval_no]);
  
const handleUpdate = () => {
  history(Routes.approval.approvalIndex)
  toast.success('update successfully')
}

  return (
    <>
    {/* {JSON.stringify(approval_no)} */}
      <BackButton />
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">
          <FontAwesomeIcon icon={faCheckDouble} className="me-2" />
          All Releases
        </h5>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formMdaName1">
                <Form.Label>Economic Code</Form.Label>
                <Form.Control type="text" placeholder="Enter Economic Code" value={economic_code} readOnly />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formCollectionDate">
                <Form.Label>Collection Date</Form.Label>
                <Form.Control type="date" placeholder="Enter Collection Date" value={collection_date} readOnly />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formApprovalNo">
                <Form.Label>Approval No</Form.Label>
                <Form.Control type="text" placeholder="Enter Approval Number" value={approval_no.id} readOnly />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control type="text" placeholder="Enter Status" value={status} readOnly />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="formMdaName2">
                <Form.Label>MDA Name</Form.Label>
                <Form.Control type="text" placeholder="Enter MDA Name" value={mda_name} readOnly />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formApprovalDate">
                <Form.Label>Approval Date</Form.Label>
                <Form.Control type="date" placeholder="Enter Approval Date" value={approval_date} readOnly />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formApprovalType">
                <Form.Label>Approval Type</Form.Label>
                <Form.Control type="text" placeholder="Enter Approval Type" value={approval_type} readOnly />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="number" placeholder="Enter Amount" value={amount} readOnly />
              </Form.Group>
            </Col>
          </Row>

          <center>
            <Button variant="primary" type="submit" loading={loading} onClick={handleUpdate}>
              Update
            </Button>
          </center>
        </Form>
      </Card.Body>
    </Card>
    </>
  );
}

export default EditApproval;
