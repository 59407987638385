import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner, Toast } from "react-bootstrap";
import { postApi } from "../redux/api";
import { useSelector } from "react-redux";

export default function ProfileUpdatePassword({ form, setForm }) {
  //   const [form, setForm] = useState({
  //     old_password: "",
  //     new_password: "",
  //     comfirm_password: "",
  //   });
  const auth = useSelector((auth) => auth.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleUpdate = () => {
    if (form.new_password !== form.comfirm_password) {
      alert("Passwords do not match");
      return;
    }

    setIsLoading(true);

    const data = {
      oldPassword: form.old_password,
      newPassword: form.new_password,
      username: auth.username,
    };

    postApi("treasury-app/change_password", data)
      .then((resp) => {
        if (resp.success) {
          alert("Submit Successful");
          setForm({
            old_password: "",
            new_password: "",
            comfirm_password: "",
          });
        } else {
          alert("Submit Failed");
        }
      })
      .catch((err) => {
        console.error(err);
        alert("An error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Row>
      <h5
        style={{
          width: "100%",
          borderTop: "1px solid #A3A3A3",
          marginTop: 20,
          textAlign: "center",
        }}
      >
        {" "}
        Change Password
      </h5>
      <Col md={6}>
        <Form.Group>
          <Form.Label>Old Password</Form.Label>
          <Form.Control
            size="sm"
            type="password"
            value={form.old_password}
            name="old_password"
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group>
          <Form.Label>New Password</Form.Label>
          <Form.Control
            size="sm"
            type="password"
            value={form.new_password}
            name="new_password"
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group>
          <Form.Label>Comfirm Password</Form.Label>
          <Form.Control
            size="sm"
            type="password"
            value={form.comfirm_password}
            name="comfirm_password"
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <div style={{ width: "100%", marginTop: 15 }}>
        <center>
          <Button size="sm" onClick={handleUpdate} disabled={isLoading}>
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Update Password"
            )}
          </Button>
        </center>
      </div>
    </Row>
  );
}
